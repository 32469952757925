import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ButtonBackToTop } from "components/button/back-to-top.button";

import { HeaderComponent } from "../header.component";
import { SidebarComponent } from "../sidebar.component";

export default function Layout({
	children,
	breadCumbTitle,
	breadCumbDesc,
	breadCumbDescChild,
	containerChildrenClassName = "p-5 bg-white rounded-lg drop-shadow-main filter-none",
	buttonToTop = false,
}) {
	const { pathname } = useLocation();

	const [showTopBtn, setShowTopBtn] = useState(false);

	/* Alywas update scroll position to top when the pathname changes. */
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, [pathname]);

	useEffect(() => {
		/* Listening to the scroll event and if the scroll is more than 800px,
		 it will show the button to top. */
		window.addEventListener("scroll", () => {
			if (window.scrollY > 800) {
				setShowTopBtn(true);
			} else {
				setShowTopBtn(false);
			}
		});
	}, []);

	return (
		<section className="flex">
			<SidebarComponent />
			<div className="w-[calc(100%-240px)] p-[30px]">
				<HeaderComponent
					breadCumbDesc={breadCumbDesc}
					breadCumbTitle={breadCumbTitle}
					breadCumbDesc2={breadCumbDescChild}
				/>
				<div className={containerChildrenClassName}>{children}</div>
			</div>
			{buttonToTop && showTopBtn && <ButtonBackToTop />}
		</section>
	);
}
