/**
 *
 * @param {*} fullName
 * @returns {string}
 *
 * Example:
 * getInitialsName('John Doe') => 'JD'
 * getInitialsName('Albus Percival Wulfric Brian dumbledore') => 'AD'
 * getInitialsName('Daniela') => 'D'
 *
 */

export function getInitialsName(fullName) {
	if (!fullName) return "";
	const allNames = fullName.trim().split(" ");
	const initials = allNames.reduce((acc, curr, index) => {
		if (index === 0 || index === allNames.length - 1) {
			acc = `${acc}${curr.charAt(0).toUpperCase()}`;
		}
		return acc;
	}, "");
	return initials;
}

export function generateUniqueId() {
	return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
	);
}

/**
 *
 * @param {*} url
 * @returns {boolean}
 *
 * Example:
 *
 */

export function isSelfHostedVideo(url) {
	if (!url) return false;

	const regex = /(youtube|facebook|vimeo)/i;
	return !regex.test(url);
}

export function convertToTitleCase(str) {
	if (!str) return null;
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export function IsJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return JSON.parse(str);
}

export function catchErrorMessage(error) {
	if (typeof error === "string") {
		return error;
	}
	if (Array.isArray(error)) {
		return error.map(err => err.msg).join(", ");
	}
	return "Opps! Something went wrong. Please try again later.";
}

// function to extract extension from a url with regex
export function isUrlMedia(url) {
	const imageExtension = ["jpg", "jpeg", "png", "webp"];
	const videoExtension = ["mp4", "webm", "ogg"];
	const rex = new RegExp("\\.([^/#?]+)([#?][^/]*)?$");
	rex.test(url);
	return {
		isImage:
			imageExtension.includes(RegExp.$1) || ["png", "jpg", "jpeg", "webp", "blob"].some(ext => url.includes(ext)),
		isVideo: videoExtension.includes(RegExp.$1),
		extension: RegExp.$1,
	};
}

export function convertToSlug(Text) {
	return Text.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");
}

export function urlify(text) {
	if (!text) return "";
	var urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, function (url) {
		return `<a href="${url}" class="text-red-40 hover:text-red-30" target="_blank" rel="noopener noreferrer">${url}</a>`;
	});
}
