import axios from "api/http.api";
import qs from "query-string";
import { useMutation } from "react-query";

// Services API for /registration/users [USER REGISTRATION LIST]
export function useListUser() {
	return useMutation(({ dataFilter, queryFilter }) => {
		return axios.post(`/api/v1/admin/user?${qs.stringify(queryFilter)}`, dataFilter).then(res => res.data);
	});
}

export function useApproveUser() {
	return useMutation(values => axios.post(`/api/v1/admin/user/approval`, values).then(res => res.data));
}

export function useExportDataUser() {
	return useMutation(({ filter, formData = {} }) =>
		axios
			.post(`/api/v2/admin/user/export?${qs.stringify(filter)}`, formData, {
				responseType: "blob",
			})
			.then(res => res),
	);
}

// Service API for /registration/setting [REGISTRATION SETTINGS]
export function useUpdateRegSettings() {
	return useMutation(values =>
		axios.post(`/api/v1/admin/settings/registration/update`, values).then(res => res.data),
	);
}

// Service API for /registration/setting [REGISTRATION TEST]
// to update test's settings data
export function useUpdateRegTestData() {
	return useMutation(values => axios.post(`/api/v1/admin/exam/save`, values).then(res => res.data));
}
