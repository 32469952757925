import axios from "api/http.api";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

export function useAddNewSkinType() {
	return useMutation(values => axios.post(`/api/v1/admin/beauty-profile/type/create`, values).then(res => res.data));
}
export function useEditSkinType() {
	const queryClient = useQueryClient();
	return useMutation(
		({ skinTypeId, formData }) =>
			axios.post(`/api/v1/admin/beauty-profile/type/update/${skinTypeId}`, formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["SKIN_TYPE_DETAILS"]);
				queryClient.removeQueries(["SKIN_TYPE_DETAILS"]);
			},
		},
	);
}

export function useDeleteSkinType() {
	return useMutation(({ skinTypeId }) =>
		axios.delete(`/api/v1/admin/beauty-profile/type/delete/${skinTypeId}`).then(res => res.data),
	);
}

export function useAddNewSkinItem() {
	return useMutation(values => axios.post(`/api/v1/admin/beauty-profile/item/create`, values).then(res => res.data));
}

export function useEditSkinItem() {
	const queryClient = useQueryClient();
	return useMutation(
		({ skinItemId, formData }) =>
			axios.post(`/api/v1/admin/beauty-profile/item/update/${skinItemId}`, formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["SKIN_ITEM_DETAILS"]);
				queryClient.removeQueries(["SKIN_ITEM_DETAILS"]);
			},
		},
	);
}

export function useDeleteSkinItem() {
	return useMutation(({ skinItemId }) =>
		axios.delete(`/api/v1/admin/beauty-profile/item/delete/${skinItemId}`).then(res => res.data),
	);
}

export function useUpgradeLevel() {
	const queryClient = useQueryClient();
	return useMutation(bodyRequest => axios.post(`/api/v1/admin/upgrade/level`, bodyRequest).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(["LEVEL_AND_BATCH"]);
		},
	});
}

export function useUpgradeBatch() {
	const queryClient = useQueryClient();
	return useMutation(() => axios.post(`/api/v1/admin/upgrade/batch`).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(["LEVEL_AND_BATCH"]);
		},
	});
}

export function useUpdatePointSetting() {
	const queryClient = useQueryClient();
	return useMutation(
		bodyRequest => axios.post(`/api/v1/admin/level/point/update`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["POINT_SETTING"]);
			},
		},
	);
}

export function useUpdateSettingFooter() {
	const queryClient = useQueryClient();
	return useMutation(
		({ slug, page, formData }) =>
			axios.post(`/api/v1/admin/settings/${page}/update/${slug}`, formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["FOOTER_SETTING"]);
			},
		},
	);
}
