import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import Layout from "components/layout";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { UserListTable } from "pages/registration/users/user-table.component";
import { useListBatch } from "hooks/discussion";
import { useClaimRewardList, useApproveRejectClaimReward, useExportClaimReward } from "hooks/reward";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { TableEventCategoryModal } from "components/table/event-category-filter.component";
import { ModalContainer } from "components/modal/modal.container";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

const QUERY_FILTER_INIT = {
	page: 1,
	limit: 20,
	order_by: "last_claimed_at",
	order: "desc",
	search: "",
};

const BODY_FILTER_INIT = {
	batch: "",
};

const sortOptions = [
	{ label: "Tanggal claim - Terbaru", sortType: "desc", sortBy: "last_claimed_at" },
	{ label: "Tanggal claim - Terdahulu", sortType: "asc", sortBy: "last_claimed_at" },
	{ label: "Nama User - [A-Z]", sortType: "asc", sortBy: "user_name" },
	{ label: "Nama User - [Z-A]", sortType: "desc", sortBy: "user_name" },
];

const TAB_STATUS = {
	SUBMITTED: "SUBMITTED",
	APPROVED: "APPROVED",
};

const MODAL_CONFIRMATION_TYPE = {
	REJECT: "REJECT",
	APPROVE: "APPROVE",
};

const getModalConfirmationWording = type => {
	if (type === MODAL_CONFIRMATION_TYPE.APPROVE) {
		return {
			title: "Approve Reward Claim",
			description: "Are you sure want to approve this reward claim?",
			buttonLabel: "Approve",
		};
	}
	return {
		title: "Reject Reward Claim",
		description: "Are you sure want to reject this reward claim?",
		buttonLabel: "Reject",
	};
};

const Status = [
	{
		label: TAB_STATUS.SUBMITTED,
		value: TAB_STATUS.SUBMITTED.toLowerCase(),
	},
	{
		label: TAB_STATUS.APPROVED,
		value: TAB_STATUS.APPROVED.toLowerCase(),
	},
];

// Main Page Component
export function ClaimRewardListPage() {
	const dispatch = useDispatch();

	// Selected user's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	const [modalKey, setModalKey] = useState(0);

	const [showModalAddressDetails, setShowModalAddressDetails] = useState({
		show: false,
		data: "",
	});

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalSort: false,
		showModalDataFilter: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "",
	});

	const [tabStatus, setTabStatus] = useState(TAB_STATUS.SUBMITTED);
	const [bodyFilter, setBodyFilter] = useState(BODY_FILTER_INIT);
	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState(QUERY_FILTER_INIT);

	// Hooks API Call
	// const { data, isLoading } = useGetRewardItemList({ filter: queryFilter });
	const { data, isLoading } = useClaimRewardList({ queryFilter, bodyFilter }, tabStatus.toLowerCase());
	const { mutate: executeAction, isLoading: isUpdating } = useApproveRejectClaimReward();
	const { mutate: exportData, isLoading: isExporting } = useExportClaimReward(queryFilter);
	const { data: batchs } = useListBatch();

	const filterOptions = useMemo(() => {
		if (batchs) return [{ name: "General", id: "" }, ...batchs?.data];
		return [];
	}, [batchs]);

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	const handleOnExecuteAction = () => {
		executeAction(
			{
				filter: bodyFilter,
				type: modalConfirmation.type.toLowerCase(),
				claim_ids: selectedIds,
			},
			{
				onSuccess: () => {
					setShowModalResult({
						showModal: true,
						message: `Successfully ${getModalConfirmationWording(modalConfirmation.type).title}!`,
					});
					setModalConfirmation(prevState => ({
						...prevState,
						showModal: false,
					}));
					resetCheckList();
				},
			},
		);
	};

	const handleExportData = () => {
		exportData(
			{
				status: tabStatus.toLowerCase(),
				filter: bodyFilter,
				claim_ids: selectedIds,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName(`ClaimRewardList_${tabStatus}`, EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const handleOnFilterDataChange = filter => {
		setBodyFilter({
			batch: filter.selected.id,
		});
		setShowTableFilterModal(prevState => ({
			...prevState,
			showModalDataFilter: false,
		}));
	};

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},

			{
				name: "User",
				className:
					"  w-[28%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis  text-sm font-semibold",
				dataIndex: "user",
				render: record => (
					<div className="w-[30%] text-grey-70 text-sm font-medium">
						<div className="pl-3">
							<div className="flex items-center mb-2 ">
								<div className="w-12">
									<img
										src={record?.user?.profile_picture || "/img/default-user.png"}
										className="overflow-hidden w-12 h-12 rounded-full"
										alt={record?.user?.full_name}
									/>
								</div>
								<div className="w-[calc(100%-48px)] pl-3">
									<div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm font-bold mb-1">
										{record?.user?.full_name}
									</div>
									<div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm ">
										{record?.shipping_address?.phone}
									</div>
								</div>
							</div>
							<div className="flex items-center">
								<div className="w-5">
									<img src="/img/icons/pin.svg" className="max-w-full" alt="Location" />
								</div>
								<div className="w-[calc(100%-20px)] pl-3">
									<div
										className="text-xs w-[90%] line-clamp-2 cursor-pointer hover:text-red-60"
										onClick={() => {
											setShowModalAddressDetails({
												show: true,
												data: `${record?.shipping_address?.complete_address} ${record?.shipping_address?.location}`,
											});
										}}>
										{`${record?.shipping_address?.complete_address} ${record?.shipping_address?.location}`}
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				name: "Beginner",
				className: "w-[18%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "beginner",
				render: (record, item_category) => (
					<div className="w-[18%] pr-6">
						{record?.beginner ? (
							<>
								<img
									src={record?.beginner?.image_desktop}
									alt={record?.beginner?.name}
									className="block w-10 h-10 mb-2"
								/>
								<div className="text-xs  ">{record?.beginner?.name}</div>
							</>
						) : (
							<div className="text-xs ">Item reward tidak tersedia</div>
						)}
					</div>
				),
			},

			{
				name: "Intermediate",
				className: " w-[20%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "intermediate",
				render: (record, code) => (
					<div className="w-[20%] pr-6">
						{record?.intermediate ? (
							<>
								<img
									src={record?.intermediate?.image_desktop}
									alt={record?.intermediate?.name}
									className="block w-10 h-10 mb-2"
								/>
								<div className="text-xs ">{record?.intermediate?.name}</div>
							</>
						) : (
							<div className="text-xs ">Item reward tidak tersedia</div>
						)}
					</div>
				),
			},
			{
				name: "Advance",
				className: " w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "advance",
				render: (record, qty) => (
					<div className="w-[20%] pr-6">
						{record?.advance ? (
							<>
								<img
									src={record?.advance?.image_desktop}
									alt={record?.advance?.name}
									className="block w-10 h-10 mb-2"
								/>
								<div className="text-xs ">{record?.advance?.name}</div>
							</>
						) : (
							<div className="text-xs ">Item reward tidak tersedia</div>
						)}
					</div>
				),
			},
			{
				name: "Batch",
				className:
					" w-[15%] text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "batch",
				render: record => (
					<div className="w-[15%] overflow-hidden whitespace-nowrap text-ellipsis text-sm text-center font-semibold">
						{`Batch ${record?.batch}`}
					</div>
				),
			},
		],
		[],
	);

	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setBodyFilter(BODY_FILTER_INIT);
		setQueryFilter(QUERY_FILTER_INIT);
		setModalKey(prev => prev + 1);
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabStatus]);

	useEffect(() => {
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter]);

	return (
		<Layout breadCumbTitle="Reward" breadCumbDesc="List Reward" buttonToTop>
			<div className="text-2xl text-grey-70 font-semibold mb-4">Claim Reward List</div>
			<div className=" border-b flex border-solid border-grey-10 px-5">
				{Status.map(statusItem => (
					<div className="inline-block mr-4">
						<button
							onClick={() => {
								setTabStatus(statusItem.value);
							}}
							class={clsx(
								statusItem.value === tabStatus.toLowerCase()
									? "rounded-tl-lg rounded-tr-lg uppercase text-sm font-semibold text-red-50  transition-all inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-grey-10"
									: "rounded-tl-lg rounded-tr-lg uppercase text-sm hover:text-red-50 transition-all font-semibold text-black inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-transparent",
							)}>
							{statusItem.label}
						</button>
					</div>
				))}
			</div>
			<div className="p-5">
				<div className="flex items-center justify-between mb-4">
					<div className="w-[80%] flex">
						<SearchFilterComponent
							key={`search-${modalKey}`}
							placeholder="Cari user..."
							containerClassName="relative md:w-2/5 lg:w-[40%] xl:w-2/5 mr-2"
							onSearch={search => {
								setQueryFilter({ ...queryFilter, search: search.keyword, page: 1 });
							}}
						/>
						<div className="mr-2">
							<ButtonSortComponent
								disabled={isLoading}
								onClick={() =>
									setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
								}
							/>
						</div>

						<div className="mr-2">
							<ButtonLimitComponent
								disabled={isLoading}
								value={queryFilter.limit}
								onClick={() =>
									setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
								}
							/>
						</div>
						<div className="mr-2">
							<ButtonDataFilterComponent
								disabled={isLoading}
								active={bodyFilter.batch !== ""}
								onClick={() => {
									setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: true }));
								}}
							/>
						</div>
						<div className="mr-2">
							<ButtonExport disabled={isLoading} onClick={handleExportData} loading={isExporting} />
						</div>
					</div>
					{tabStatus.toUpperCase() === TAB_STATUS.SUBMITTED && (
						<div className="flex w-[20%] justify-end">
							<div className="w-1/2"></div>
							<div className="w-1/2">
								<ButtonSubmit
									disabled={selectedIds.length === 0}
									onClick={() => {
										setModalConfirmation({
											...modalConfirmation,
											showModal: true,
											type: MODAL_CONFIRMATION_TYPE.APPROVE,
										});
									}}
									className="disabled:opacity-50 flex items-center justify-center py-2.5 w-full border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
									<span className="icon-ico-check text-md mr-2" /> Approve
								</ButtonSubmit>
							</div>
						</div>
					)}
				</div>
				<UserListTable
					key={modalKey}
					columns={columns}
					dataSource={data?.data?.rows || []}
					loading={isLoading}
				/>
				{!isLoading && data?.data?.rows?.length === 0 && (
					<div className="h-[60vh] flex items-center justify-center">
						<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
					</div>
				)}
				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={selectedPage => {
							window.scrollTo(0, 0);
							setQueryFilter({ ...queryFilter, page: selectedPage });
						}}
						totalCount={data?.data?.total_result || 0}
					/>
				)}
			</div>

			<TableLimitModalComponent
				key={`modal-limit-${modalKey}`}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
				visible={tableFilterModal.showModalLimit}
				initialValue={queryFilter.limit}
				onChange={selected => {
					setQueryFilter({ ...queryFilter, limit: selected.limit, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				key={`modal-sort-${modalKey}`}
				initialValue={sortOptions[0]}
				options={sortOptions}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter({ ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
			/>

			<TableEventCategoryModal
				key={`modal-filter-${modalKey}`}
				label="Batch"
				title="Filter"
				initialValue={filterOptions?.[0]}
				options={filterOptions}
				onChange={handleOnFilterDataChange}
				visible={tableFilterModal.showModalDataFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }))}
			/>
			<ModalConfirmation
				buttons={[]}
				title={getModalConfirmationWording(modalConfirmation.type).title}
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleOnExecuteAction()}
				labelApprove={getModalConfirmationWording(modalConfirmation.type).buttonLabel}
				loading={isUpdating}>
				<div className="my-6">
					<div className="text-center ">
						<img src="/img/alert.png" alt="Alert" className="inline-block" />
					</div>
					<p className="text-center text-grey-60 text-sm my-5">
						{getModalConfirmationWording(modalConfirmation.type).description}
					</p>
				</div>
			</ModalConfirmation>

			<ModalContainer visible={showModalAddressDetails.show}>
				<div
					id="show-item"
					class={clsx(
						"rounded-2xl bg-white shadow-md p-4 w-[515px]",
						!showModalAddressDetails.show && "hidden",
					)}>
					<div className="relative text-center py-4">
						<div className="font-bold text-red-50">Detail Address</div>

						<button
							type="button"
							className="absolute top-0 right-0 border-0"
							onClick={() => {
								setShowModalAddressDetails(prevState => ({ ...prevState, show: false }));
							}}>
							<span className="icon-ico-close"></span>
						</button>
					</div>
					<p className="mt-2 mb-6 text-center text-main-grey">{showModalAddressDetails.data}</p>

					<div className=" px-2">
						<ButtonSubmit
							onClick={() => {
								setShowModalAddressDetails(prevState => ({ ...prevState, show: false }));
							}}
							title="Ok"
							className="block w-full px-8 py-2 text-white transition-all rounded-xl bg-red-50 hover:bg-red-70 disabled:border-red-10
			disabled:bg-red-10"
						/>
					</div>
				</div>
			</ModalContainer>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(false)}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
