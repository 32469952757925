import * as Yup from "yup";

/**
 * Form validation schema for /auth/login
 */

export const LoginValidationSchema = Yup.object().shape({
	email: Yup.string().email("Email must be valid email").required("Email is required"),
	password: Yup.string().required("Password is required"),
});
