export function RadioButtonComponent({
	containerClassName = "mb-4 ",
	inputClassName = "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-full bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
	labelClassName = "text-sm text-black font-semibold mb-3",
	onChange = () => {},
	label = "Label",
	value = "",
	options = [],
	name = "",
}) {
	return (
		<div class={containerClassName}>
			<div class={labelClassName}>{label}</div>
			<div className="flex h-[45px] items-center">
				{options.map(optionItem => (
					<div className="w-40">
						<div className="form-check">
							<input
								class={inputClassName}
								onChange={e => {
									onChange({ target: { name, value: e.target.value } });
								}}
								type="radio"
								value={optionItem.value}
								name={name}
								checked={value === optionItem.value}
								id={optionItem.value}
							/>
							<label
								className="form-check-label inline-block text-main-grey cursor-pointer"
								htmlFor={optionItem.value}>
								{optionItem?.label}
							</label>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
