import dayjs from "dayjs";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";

import Layout from "components/layout";
import { useListUser, useApproveUser, useExportDataUser } from "hooks/registration";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { ModalSocialMedia } from "components/modal/modal.social-media";
import { getInitialsName } from "utils/string";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { TableFilterModalComponent, ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { SelectComponent } from "components/select.component";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalAnswerComponent } from "./modal-answer.component";
import { UserListTable } from "./user-table.component";
const MODAL_CONFIRMATION_ACTION_TYPE = {
	APPROVE_MULTIPE_USER: "APPROVE_MULTIPE_USER",
	APPROVE_SINGLE_USER: "APPROVE_SINGLE_USER",
	REJECT_MULTIPE_USER: "REJECT_MULTIPE_USER",
	REJECT_SINGLE_USER: "REJECT_SINGLE_USER",
};

/**
 * It returns a string based on the action type and data passed to it
 * @param actionType - This is the type of action that you want to perform.
 * @param data - The data that will be passed to the action function.
 * @returns A string
 */
const getModalConfirmationMessage = (actionType, data) => {
	switch (actionType) {
		case MODAL_CONFIRMATION_ACTION_TYPE.APPROVE_MULTIPE_USER:
			return `Are you sure to approve ${data?.length} user(s)?`;
		case MODAL_CONFIRMATION_ACTION_TYPE.APPROVE_SINGLE_USER:
			return `Are you sure to approve ${data?.full_name}?`;
		case MODAL_CONFIRMATION_ACTION_TYPE.REJECT_MULTIPE_USER:
			return `Are you sure to reject ${data?.length} user(s)?`;
		case MODAL_CONFIRMATION_ACTION_TYPE.REJECT_SINGLE_USER:
			return `Are you sure to reject ${data?.full_name}?`;

		default:
			return "";
	}
};

/**
 * It returns the string "approve" if the actionType is either APPROVE_MULTIPE_USER or
 * APPROVE_SINGLE_USER, and it returns the string "reject" if the actionType is either
 * REJECT_MULTIPE_USER or REJECT_SINGLE_USER
 * @returns the string "approve" if the actionType is either APPROVE_MULTIPE_USER or
 * APPROVE_SINGLE_USER.
 * It returns the string "reject" if the actionType is either REJECT_MULTIPE_USER or
 * REJECT_SINGLE_USER.
 * Otherwise, it returns null.
 */
const getActionType = actionType => {
	const { APPROVE_MULTIPE_USER, APPROVE_SINGLE_USER, REJECT_MULTIPE_USER, REJECT_SINGLE_USER } =
		MODAL_CONFIRMATION_ACTION_TYPE;
	if ([APPROVE_MULTIPE_USER, APPROVE_SINGLE_USER].includes(actionType)) {
		return "approve";
	}
	if ([REJECT_MULTIPE_USER, REJECT_SINGLE_USER].includes(actionType)) {
		return "reject";
	}
	return null;
};

/**
 * It returns an array of objects that contain the value, iconClassName, and baseUrl for each social
 * media icon
 * @returns An array of objects.
 */
const getSocialMediaIcons = () => {
	return [
		{
			value: "facebook",
			iconClassName: "icon-ico-facebook",
			baseUrl: "https://www.facebook.com/",
		},
		{
			value: "twitter",
			iconClassName: "icon-ico-twitter",
			baseUrl: "https://www.twitter.com/",
		},
		{
			value: "tiktok",
			iconClassName: "icon-ico-tiktok",
			baseUrl: "https://www.tiktok.com/",
		},
		{
			value: "instagram",
			iconClassName: "icon-ico-instagram",
			baseUrl: "https://www.instagram.com/",
		},
		{
			value: "youtube",
			iconClassName: "icon-ico-youtube",
			baseUrl: "https://www.youtube.com/",
		},
	];
};

// Main Page Component
export function RegistrationUserPage() {
	const dispatch = useDispatch();

	// Selected user's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalSort: false,
		showModalDataFilter: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});
	// Modal Social Media State
	const [modalSocialMedia, setModalSocialMedia] = useState({
		showModal: false,
		data: [],
	});

	const [showModalResult, setShowModalResult] = useState(false);

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "last_registered_at",
		order: "desc",
		search: "",
	});
	// Data filter on body request
	const [dataFilter, setDataFilter] = useState({
		min_age: null,
		max_age: null,
		min_correct_answer: null,
		batch: null,
		gender: "",
		min_followers_instagram: "",
		min_followers_tiktok: "",
		min_followers_twitter: "",
	});

	const [selectedAction, setSelectedAction] = useState(null);
	const [examResultView, setExamResultView] = useState({ userId: null, showModal: false });

	const isDataFilterActive = useMemo(() => Object.values(dataFilter).some(item => item), [dataFilter]);

	// Hooks API Call
	const { data, mutate: getListUser, isLoading } = useListUser();
	const { mutate: approveUser, isLoading: isAprroving } = useApproveUser();
	const { mutate: exportDataUser, isLoading: isExporting } = useExportDataUser();

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	/**
	 * A function that is used to change the status of the user when user click
	 * Approve/reject on a user's row or when user click "Apply" button.
	 */
	const handleSubmitChangeStatusUser = () => {
		let formData = { status: getActionType(modalConfirmation.type) };
		if (modalConfirmation.type.indexOf("SINGLE") > -1) {
			formData = { ...formData, user_ids: [modalConfirmation.data.id] };
		} else {
			formData = { ...formData, user_ids: modalConfirmation.data };
		}

		approveUser(formData, {
			onSuccess: () => {
				getListUser({ queryFilter, dataFilter });
				setModalConfirmation({
					showModal: false,
					type: null,
					data: null,
				});
				setShowModalResult(true);
				resetCheckList();
			},
		});
	};

	const handleExportData = () => {
		exportDataUser(
			{
				filter: {
					...dataFilter,
					search: queryFilter.search,
					order: queryFilter.order,
					order_by: queryFilter.order_by,
				},
				formData: { user_ids: selectedIds },
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName(`UserList`, EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const [columns] = useState([
		{
			type: "checkbox",
			isRightBorderAvailable: true,
			render: record => {
				return (
					<div className="form-check px-2">
						<input
							className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
							type="checkbox"
							readOnly
						/>
					</div>
				);
			},
		},
		{
			name: "User",
			dataIndex: "full_name",
			className: "w-1/4 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
			render: (record, full_name) => (
				<div className="flex justify-center items-center overflow-hidden w-1/4">
					<div className="initial">{getInitialsName(full_name) || "A"}</div>
					<div className="w-4/5 pl-3 overflow-hidden">
						<span
							href={null}
							className="text-ellipsis mb-1 block text-sm overflow-hidden whitespace-nowrap text-grey-70 hover:text-red-50 font-semibold">
							{full_name}
						</span>

						<div className="text-ellipsis pr-4 text-sm block font-medium overflow-hidden whitespace-nowrap text-main-grey">
							{record.email}
						</div>
					</div>
				</div>
			),
		},
		{
			name: "Birth Date",
			className: "w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
			dataIndex: "birth_date",
			render: record => (
				<div className="overflow-hidden w-[15%]">
					<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
						{record.birth_date ? dayjs(record.birth_date, "YYYY-MM-DD").format("DD / MM / YYYY") : "N/A"}
					</div>
					<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap text-main-grey">
						{record.age} Years Old
					</div>
				</div>
			),
		},
		{
			name: "Social Media",
			className: "w-[11%] text-red-60 font-semibold overflow-hidden whitespace-nowrap text-ellipsis",
			dataIndex: "username_instagram",
			render: record => (
				<div className="w-[11%]">
					<ul className="flex flex-wrap mb-0">
						{getSocialMediaIcons().map(socialMedia => {
							const userSocialMedia = record?.social_media?.find(
								item => item?.name === socialMedia.value,
							);
							return (
								<li className="mb-2 mr-2" key={socialMedia.value}>
									<button
										href={`${socialMedia?.baseUrl}${userSocialMedia?.user_social_media?.username}`}
										disabled={!userSocialMedia}
										onClick={() => {
											window.open(
												`${socialMedia?.baseUrl}${userSocialMedia?.user_social_media?.username}`,
												"_blank",
											);
										}}
										class={clsx(
											"w-6.5 h-6.5 inline-block transition-all border border-solid border-grey-10 text-center hover:bg-red-50 hover:border-red-50 hover:text-white  rounded-full bg-white leading-7 disabled:bg-red-",
											!userSocialMedia &&
												"bg-grey-10 text-grey-40 hover:bg-grey-10 hover:text-grey-40 hover:border-grey-40",
										)}>
										<span class={clsx(socialMedia.iconClassName)}></span>
									</button>
								</li>
							);
						})}
						<li className="mr-2">
							<button
								className="w-6.5 h-6.5 inline-block transition-all border border-solid border-grey-10 text-center hover:bg-red-50 hover:border-red-50 hover:text-white text-black rounded-full bg-white leading-7"
								onClick={() => setModalSocialMedia({ showModal: true, data: record?.social_media })}>
								<span className="icon-ico-bullets"></span>
							</button>
						</li>
					</ul>
				</div>
			),
		},
		{
			name: "Correct Answer",
			className: "w-[20%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
			dataIndex: "username_instagram",
			render: record => (
				<div className="w-[20%]">
					<div className="text-sm font-medium block text-center mb-2 text-main-grey">{`${record.correct_answer}/${record.total_question}`}</div>
					<span
						className="cursor-pointer text-center text-sm font-bold block text-main-blue"
						onClick={() => setExamResultView({ userId: record?.id, showModal: true })}>
						SEE DATA
					</span>
				</div>
			),
		},
		{
			name: "Reg. Date",
			className: "w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
			dataIndex: "username_instagram",
			render: record => (
				<div className="overflow-hidden w-[13%]">
					<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
						{`Batch ${record.last_batch}`}
					</div>
					<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap text-main-grey">
						{dayjs(record.last_registered_at, "YYYY-MM-DD").format("DD / MM / YYYY")}
					</div>
				</div>
			),
		},

		{
			name: "Action",
			className: "w-1/5 text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
			dataIndex: "goddes_gang_session",
			render: record => (
				<div className="w-1/5">
					<ul className="flex link-action justify-center items-center">
						<li className="mr-2 flex items-center">
							<button
								className="text-main-green text-sm hover:text-red-60 transition-all font-bold uppercase"
								onClick={() => {
									setModalConfirmation({
										data: record,
										showModal: true,
										type: MODAL_CONFIRMATION_ACTION_TYPE.APPROVE_SINGLE_USER,
									});
								}}>
								Approve
							</button>
						</li>
						<li>
							<button
								className="text-red-50 text-sm font-bold hover:text-red-60 transition-all uppercase"
								onClick={() => {
									setModalConfirmation({
										data: record,
										showModal: true,
										type: MODAL_CONFIRMATION_ACTION_TYPE.REJECT_SINGLE_USER,
									});
								}}>
								Reject
							</button>
						</li>
					</ul>
				</div>
			),
		},
	]);

	useEffect(() => {
		if (!data) getListUser({ queryFilter, dataFilter });

		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter, dataFilter]);

	return (
		<Layout breadCumbTitle="User Register" breadCumbDesc="User Register List" buttonToTop>
			<div className="flex items-center justify-between mb-4">
				<div className="w-[70%] flex">
					<SearchFilterComponent
						containerClassName="relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2"
						onSearch={search => {
							setQueryFilter({ ...queryFilter, search: search.keyword });
							getListUser({
								queryFilter: { ...queryFilter, search: search.keyword, page: 1 },
								dataFilter,
							});
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={isDataFilterActive}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleExportData} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[28%] justify-end">
					<SelectComponent
						disabled={!selectedIds.length}
						title="Select Action"
						onChange={select => {
							if (select.value === "APPROVE")
								setSelectedAction(MODAL_CONFIRMATION_ACTION_TYPE.APPROVE_MULTIPE_USER);
							else setSelectedAction(MODAL_CONFIRMATION_ACTION_TYPE.REJECT_MULTIPE_USER);
						}}
						options={[
							{ label: "Approve", value: "APPROVE" },
							{ label: "Reject", value: "REJECT" },
						]}
					/>
					<div className="">
						<ButtonSubmit
							onClick={() =>
								setModalConfirmation({
									data: selectedIds,
									showModal: true,
									type: selectedAction,
								})
							}
							disabled={!selectedIds.length || !selectedAction}
							loading={isAprroving}
							className="flex
                items-center
                py-2.5
                px-7
                border
                border-solid
                border-red-50
                rounded-lg
                text-white
				disabled:border-red-10
				disabled:bg-red-10
                bg-red-50
			
                hover:bg-red-60
                hover:border-red-50
                transition-all
                font-semibold"
							title="Apply"
						/>
					</div>
				</div>
			</div>

			{!isLoading && (
				<div className="italic text-sm text-grey-60 mb-4">{`Total ${data?.data?.total_result || 0} Users`}</div>
			)}

			<UserListTable columns={columns} dataSource={data?.data?.rows || []} loading={isLoading} />

			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}
			{!isLoading && (
				<Pagination
					currentPage={queryFilter.page}
					pageSize={queryFilter.limit}
					siblingCount={1}
					onPageChange={selectedPage => {
						window.scrollTo(0, 0);
						getListUser({
							queryFilter: { ...queryFilter, page: selectedPage },
							dataFilter,
						});
						setQueryFilter({ ...queryFilter, page: selectedPage });
					}}
					totalCount={data?.data?.total_result || 0}
				/>
			)}

			{/* Modal/Pop Up  */}
			<ModalAnswerComponent
				visible={examResultView.showModal}
				userId={examResultView.userId}
				onClose={() => setExamResultView({ userId: null, showModal: false })}
			/>

			<ModalSocialMedia
				visible={modalSocialMedia.showModal}
				data={modalSocialMedia.data}
				onClose={() => {
					setModalSocialMedia({ data: [], showModal: false });
				}}
			/>

			<TableLimitModalComponent
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
				visible={tableFilterModal.showModalLimit}
				initialValue={20}
				onChange={selected => {
					getListUser({ queryFilter: { ...queryFilter, limit: selected.limit, page: 1 }, dataFilter });
					setQueryFilter({ ...queryFilter, limit: selected.limit, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableFilterModalComponent
				visible={tableFilterModal.showModalDataFilter}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
				}}
				onApply={filterData => {
					getListUser({
						queryFilter: { ...queryFilter, page: 1 },
						dataFilter: { ...dataFilter, ...filterData },
					});
					setDataFilter(filterData);
					setQueryFilter({ ...queryFilter, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
				}}
			/>
			<TableSortModalComponent
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					getListUser({
						queryFilter: { ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 },
						dataFilter,
					});
					setQueryFilter({ ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				message={getModalConfirmationMessage(modalConfirmation.type, modalConfirmation.data)}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleSubmitChangeStatusUser()}
				loading={isAprroving}
			/>
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Change user status successfully!"
			/>
		</Layout>
	);
}
