import * as Yup from "yup";

const websiteUrlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/g;

export const CreateEventSchema = Yup.object().shape({
	title: Yup.string().max(50, "Max character for title is 50").required("Title is required"),
	redirect_url: Yup.string().matches(websiteUrlRegExp, "URL is not valid").required("URL is required"),
	event_category_id: Yup.string().required("Event category is required"),
	image_buffer: Yup.mixed().required("Image is required"),
	event_date: Yup.string().required("Event date is required"),
});
export const EditEventSchema = Yup.object().shape({
	title: Yup.string().max(50, "Max character for title is 50").required("Title is required"),
	redirect_url: Yup.string().matches(websiteUrlRegExp, "URL is not valid").required("URL is required"),
	event_category_id: Yup.string().required("Event category is required"),
	event_date: Yup.string().required("Event date is required"),
});
