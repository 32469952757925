import { InputComponent } from "components/form/input.component";
import { AccordionWrapper } from "../accordion-wrapper.component";
import { UploadMultipeFiles } from "../upload-multiple-file.component";
import TextEditor from "components/text-editor";
import { IsJsonString } from "utils/string";

export function Challange({
	expanded,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	descriptionInitialValue = undefined,
	setFieldValue,
	initialValues = { example_uploaded_files: [] },
	sectionId = "challenge",
}) {
	return (
		<AccordionWrapper
			onToggleExpand={onToggleExpand}
			expaned={expanded}
			title="Challange"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="flex justify-between ">
					<div className="w-[48%]">
						<InputComponent
							label="Title"
							placeholder="Insert Title"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.title}
							name={`${sectionId}.title`}
							error={touched?.title && errors?.title}
						/>

						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(descriptionInitialValue) ? descriptionInitialValue : undefined
								}
								onChange={nodeValue => setFieldValue(`${sectionId}.description`, nodeValue)}
							/>
						</div>
						<InputComponent
							label="Point"
							placeholder="Masukan jumlah point"
							numberOnly
							onBlur={onBlur}
							onChange={onChange}
							value={values?.point}
							name={`${sectionId}.point`}
							error={touched?.point && errors?.point}
						/>
					</div>
					<div className="w-[48%]">
						<InputComponent
							label="URL / Link Attachment"
							placeholder="URL gdrive, dropbox, mediafire, dll"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.example_redirect_url}
							name={`${sectionId}.example_redirect_url`}
							error={touched?.example_redirect_url && errors?.example_redirect_url}
						/>
						<UploadMultipeFiles
							id={`${sectionId}.example_uploaded_files`}
							initialValues={initialValues.example_uploaded_files}
							onChange={files => {
								setFieldValue(`${sectionId}.example_uploaded_files`, files);
							}}
						/>

						<InputComponent
							label="Start Date & Time"
							type="datepicker-modal"
							placeholder="Start Date & Time"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.start_date}
							name={`${sectionId}.start_date`}
							error={touched?.start_date && errors?.start_date}
						/>
						<InputComponent
							label="End Date & Time"
							type="datepicker-modal"
							placeholder="End Date & Time"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.end_date}
							name={`${sectionId}.end_date`}
							error={touched?.end_date && errors?.end_date}
						/>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
