import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import _ from "lodash";
import { Formik } from "formik";
import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { ModalUploading } from "components/modal/modal.uploading";
import {
	useTopicCategory,
	useDeleteTopicCategory,
	useListBatch,
	useListProvince,
	useGetDiscussionTopicDetails,
	useEditDiscussionTopic,
} from "hooks/discussion";
import { ButtonSubmit } from "components/button/submit.button";
import { InputComponent } from "components/form/input.component";
import TextEditor from "components/text-editor";
import { Select } from "components/form/select.component";
import { UploadTopicMedia } from "./components/upload-media-topic";
import { BulkSelect } from "components/form/bulk-select.component";
import { ModalBulkSelectOptions } from "components/form/bulk-select-option.modal";
import { CreateDiscussionTopic } from "config/form/schema/discussion/create-topic";
import { useUploadImage, useUploadVideo } from "hooks/upload";
import { useNavigate } from "react-router-dom";
import { IsJsonString } from "utils/string";

const OPTIONS_BULK_MODAL_TYPE = {
	LIST_PROVINCES: "LIST_PROVINCES",
	LIST_BATCH: "LIST_BATCH",
};

const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
};

const formikInitialValues = {
	mediaFile: null,
	image_desktop: null,
	image_mobile: null,
	video_url: null,
	title: "",
	description: "",
	topic_category_id: null,
	province_ids: [],
	batch: [],
	is_pinned: false,
	publish_date: null,
};

export function DiscussionEditTopicPage() {
	const navigate = useNavigate();
	const formikRef = useRef(null);
	const { topicId } = useParams();

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);

	const [provinceOptions, setProvinceOptions] = useState([]);
	const [batchListOptions, setBatchListOptions] = useState([]);

	const [modalOptionsBulk, setModalOptionsBulk] = useState({
		visible: false,
		type: null,
		title: undefined,
	});

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	// Hooks API Call
	const { data: topicCategories } = useTopicCategory();
	const { data: topicDetails, isLoading } = useGetDiscussionTopicDetails(topicId);
	const { data: batchs } = useListBatch();
	const { data: provinces } = useListProvince();
	const { mutate: deleteCategoryById, isLoading: isDeleting } = useDeleteTopicCategory();
	const { mutateAsync: uploadImageAsync, isLoading: isUploadingImage } = useUploadImage();
	const { mutateAsync: uploadVideoAsync, isLoading: isUploadingVideo } = useUploadVideo();
	const { mutateAsync: editTopicAsync, isLoading: isCreatingTopic } = useEditDiscussionTopic();

	useEffect(() => {
		if (provinces?.data) {
			setProvinceOptions(
				provinces?.data?.map(province => ({
					...province,
					selected: false,
				})),
			);
		}
	}, [provinces]);
	useEffect(() => {
		if (batchs?.data) {
			setBatchListOptions(
				batchs?.data?.map(province => ({
					...province,
					selected: false,
				})),
			);
		}
	}, [batchs]);

	useEffect(() => {
		if (topicDetails?.data) {
			const { data } = topicDetails;
			formikRef.current.setValues({
				...formikRef.current.values,
				mediaFile: data?.video_url || data?.image_desktop,
				image_desktop: data?.image_desktop,
				image_mobile: data?.image_mobile,
				video_url: data?.video_url,
				title: data?.title,
				description: data?.description,
				topic_category_id: data?.topic_category?.id,
				province_ids: data?.provinces?.map(item => item?.id),
				batch: data?.topic_batches?.map(item => +item?.batch),
				is_pinned: Boolean(data?.is_pinned),
				publish_date: data?.publish_date,
			});

			setProvinceOptions(
				provinces?.data?.map(province => ({
					...province,
					selected: data?.provinces?.find(item => item?.id === province?.id) ? true : false,
				})),
			);

			setBatchListOptions(
				batchs?.data?.map(batch => ({
					...batch,
					selected: data?.topic_batches?.find(item => +item?.batch === batch?.id) ? true : false,
				})),
			);
		}
	}, [topicDetails, provinces, batchs]);

	const handleChangeBulkSelect = (selectedOptions, type) => {
		if (type === OPTIONS_BULK_MODAL_TYPE.LIST_PROVINCES) {
			const newProvinceOptions = provinceOptions.map(province => {
				if (selectedOptions?.id === province.id) {
					return {
						...province,
						selected: selectedOptions.selected,
					};
				}
				return province;
			});
			setProvinceOptions(newProvinceOptions);
			formikRef.current.setFieldValue(
				"province_ids",
				newProvinceOptions.filter(province => province.selected).map(province => province.id),
			);
		} else {
			const newBatchOptions = batchListOptions.map(batch => {
				if (selectedOptions?.id === batch.id) {
					return {
						...batch,
						selected: selectedOptions.selected,
					};
				}
				return batch;
			});
			setBatchListOptions(newBatchOptions);
			formikRef.current.setFieldValue(
				"batch",
				newBatchOptions.filter(batch => batch.selected).map(batch => batch.id),
			);
		}
	};

	const handleDeleteCategory = () => {
		deleteCategoryById(
			{ categoryId: modalConfirmation.data?.categoryId },
			{
				onSuccess: () => {
					setModalConfirmation({
						message: "",
						showModal: false,
						data: {},
					});
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: "Category has been deleted!",
					}));
				},
			},
		);
	};

	const handleSubmitForm = async (values, status) => {
		const mediaType = values.mediaFile?.type?.split("/")[0];
		const mediaFile = values.mediaFile;
		const payload = _.cloneDeep(values);
		if (mediaType === "video") {
			const formData = new FormData();
			formData.append("video", mediaFile);
			formData.append("type", "banner");
			const { data: uploadVideoResult } = await uploadVideoAsync({ formData });
			payload.video_url = uploadVideoResult.video_url;
		}
		if (mediaType === "image") {
			const formData = new FormData();
			formData.append("image", mediaFile);
			formData.append("type", "banner");

			const { data: uploadImageResult } = await uploadImageAsync({ formData });
			payload.image_desktop = uploadImageResult.image_desktop;
			payload.image_mobile = uploadImageResult.image_mobile;
		}
		const res = await editTopicAsync({ ...payload, status: status.toLowerCase(), topicId });
		if (res.code === 200) {
			setShowModalResult(prev => ({
				...prev,
				showModal: true,
				message: "Discussion topic has been updated!",
			}));
		}
	};

	return (
		<Layout breadCumbTitle="Discussion" breadCumbDesc="Edit Topic">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<Formik
					innerRef={formikRef}
					initialValues={formikInitialValues}
					validationSchema={CreateDiscussionTopic}>
					{({ handleBlur, handleChange, values, touched, errors, setFieldValue }) => (
						<div className="bg-white rounded-lg drop-shadow-main filter-none p-5">
							<div className="flex items-center justify-between mb-4">
								<div className="text-2xl text-grey-70 font-semibold">Edit Topic</div>
							</div>
							<div className="w-4/5">
								<UploadTopicMedia
									initialValue={{
										url: topicDetails?.data?.video_url || topicDetails?.data?.image_desktop,
										type: topicDetails?.data?.video_url ? "video" : "image",
									}}
									onChange={data => {
										setFieldValue("mediaFile", data?.fileBuffer);
									}}
								/>
								<InputComponent
									containerClassName="relative mb-4"
									label="Title"
									onBlur={handleBlur}
									error={touched.title && errors.title}
									value={values.title}
									name="title"
									onChange={handleChange}
									placeholder="Topic title"
								/>

								<div className="mb-4">
									<div className="text-sm text-black font-semibold mb-3">Description</div>
									<TextEditor
										initialValue={
											IsJsonString(topicDetails?.data?.description)
												? topicDetails?.data?.description
												: undefined
										}
										onChange={nodeValue => setFieldValue("description", nodeValue)}
									/>
								</div>

								<Select
									name={"topic_category_id"}
									onBlur={handleBlur}
									error={touched.topic_category_id && errors.topic_category_id}
									onChange={handleChange}
									value={values.topic_category_id}
									options={topicCategories?.data || []}
									label="Category"
									placeholder="Select Category"
								/>

								<BulkSelect
									key="batch-list"
									error={touched.batch && errors.batch}
									onClick={() => {
										setModalOptionsBulk({
											title: "Add Batch",
											visible: true,
											type: OPTIONS_BULK_MODAL_TYPE.LIST_BATCH,
										});
									}}
									label="Batch"
									placeholder="Cari batch..."
									onChange={selected =>
										handleChangeBulkSelect(selected, OPTIONS_BULK_MODAL_TYPE.LIST_BATCH)
									}
									selectedData={batchListOptions}
								/>

								<BulkSelect
									key="province-list"
									error={touched.province_ids && errors.province_ids}
									onClick={() => {
										setModalOptionsBulk({
											title: "Add Province Regional",
											visible: true,
											type: OPTIONS_BULK_MODAL_TYPE.LIST_PROVINCES,
										});
									}}
									label="Regional"
									placeholder="Cari nama provinsi..."
									onChange={selected =>
										handleChangeBulkSelect(selected, OPTIONS_BULK_MODAL_TYPE.LIST_PROVINCES)
									}
									selectedData={provinceOptions}
								/>

								<InputComponent
									label="Publish Date & Time"
									type="datepicker-modal"
									placeholder="Publish Date & Time"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values?.publish_date}
									name="publish_date"
									error={touched?.publish_date && errors?.publish_date}
								/>

								<div className="mb-4 mt-2">
									<input
										id="is_pinned"
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										checked={values.is_pinned}
										name="is_pinned"
										onChange={handleChange}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor="is_pinned">
										Pin This Topic
									</label>
								</div>
								<div className="flex items-center justify-center my-9">
									<div className="w-3/12 mx-1">
										<ButtonSubmit
											onClick={() => handleSubmitForm(values, "DRAFT")}
											disabled={
												!CreateDiscussionTopic.isValidSync(values) ||
												isUploadingVideo ||
												isUploadingImage ||
												isCreatingTopic
											}
											className="disabled:opacity-50 flex items-center justify-center py-2.5 px-3 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold w-full text-sm uppercase">
											<span className="pl-3">Save as Draft</span>
										</ButtonSubmit>
									</div>
									<div className="w-3/12 mx-1">
										<ButtonSubmit
											loading={isUploadingVideo | isUploadingImage | isCreatingTopic}
											onClick={() => handleSubmitForm(values, "PUBLISHED")}
											disabled={!CreateDiscussionTopic.isValidSync(values)}
											className="disabled:opacity-50 w-full text-sm uppercase block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
											title="Update Topic"
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</Formik>
			)}

			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteCategory()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus data ini?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>
			<ModalUploading visible={isUploadingVideo} message="Hang on mate, we are uploading your video..." />

			<ModalBulkSelectOptions
				title={modalOptionsBulk.title}
				visible={modalOptionsBulk.visible}
				onClose={() => {
					setModalOptionsBulk(prev => ({ ...prev, visible: false }));
					formikRef.current.setTouched({
						...formikRef.current.touched,
						[modalOptionsBulk.type === OPTIONS_BULK_MODAL_TYPE.LIST_PROVINCES
							? "province_ids"
							: "batch"]: true,
					});
				}}
				onChange={selected => handleChangeBulkSelect(selected, modalOptionsBulk.type)}
				options={
					modalOptionsBulk.type === OPTIONS_BULK_MODAL_TYPE.LIST_PROVINCES
						? provinceOptions
						: batchListOptions
				}
			/>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => {
					setShowModalResult(prev => ({ ...prev, showModal: false }));
					navigate("/discussion/list");
				}}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
