import clsx from "clsx";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { UserListTable } from "pages/registration/users/user-table.component";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { ButtonSubmit } from "components/button/submit.button";
import { useActivityResult, useExportActivityResult } from "hooks/activity";
import { convertToTitleCase, getInitialsName } from "utils/string";
import { getColorClassNameByExamStatus } from "config/static-mapping";
import { ModalResult } from "components/modal/modal.result";
import { Pagination } from "components/pagination.component";
import { ModalAnswerComponent } from "pages/registration/users/modal-answer.component";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalExamFilter } from "../../components/exam-filter.component";
import { ModalGivePoint } from "../../components/give-point.component";
import { urlSafeEncrypt } from "utils/encryption";

const sortOptions = [
	{ label: "Tanggal Submit Terbaru", sortType: "desc", sortBy: "exam_submitted_at" },
	{ label: "Tanggal Submit Terdahulu", sortType: "asc", sortBy: "exam_submitted_at" },
];

export function TabExam() {
	const dispatch = useDispatch();
	const { activityId } = useParams();
	// Selected record's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);
	const [examResultView, setExamResultView] = useState({ userId: null, showModal: false });

	const [modalResult, setModalResult] = useState({
		showModal: false,
		message: "",
	});
	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
		showModalPoint: false,
		key: new Date().getTime(),
	});

	// Query Params Filter
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		search: "",
		order: "desc",
		order_by: "exam_submitted_at",
	});

	// Body Request Filter
	const [bodyFilter, setBodyFilter] = useState({
		filter: {
			min_correct_answer: null,
			max_correct_answer: null,
			exam_status: [],
		},
	});

	const { data: activityResultRegistrations, isLoading } = useActivityResult(
		{ activityId, type: "exam" },
		{ queryFilter, bodyFilter },
	);
	const { mutate: exportRegistratioData, isLoading: isExporting } = useExportActivityResult();

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "Nama",
				dataIndex: "user",
				className:
					"w-1/3 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
				render: record => (
					<div className="w-1/3 overflow-hidden pr-3">
						<div className="pl-3 flex items-center overflow-hidden">
							{record?.user?.profile_picture ? (
								<img
									src={record?.user?.profile_picture}
									alt={record?.user?.full_name}
									className="rounded-full w-12 h-12 overflow-hidden object-cover object-center"
								/>
							) : (
								<div className="font-bold text-red-60 text-2xl">
									{getInitialsName(record?.user?.full_name)}
								</div>
							)}

							<div className="w-full pl-3 overflow-hidden">
								<a
									href="user-detail.html"
									className="text-ellipsis mb-1 block text-sm overflow-hidden whitespace-nowrap text-grey-70 hover:text-red-50 font-semibold">
									{record?.user?.full_name}
								</a>

								<div className="text-ellipsis pr-4 text-sm block font-medium overflow-hidden whitespace-nowrap text-main-grey">
									{record?.user?.email}
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				name: "Submitted Date",
				dataIndex: "exam_submitted_at_formatted",
				className: "w-1/6 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, exam_submitted_at_formatted) => (
					<div className="w-1/6">
						<div className="text-sm text-main-grey">{exam_submitted_at_formatted}</div>
					</div>
				),
			},
			{
				name: "Exam Result",
				dataIndex: "user",
				className: "w-[11%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis  font-semibold mr-[5%]",
				render: record => (
					<div className="overflow-hidden text-center w-[11%]  mr-[5%]">
						<div className="text-ellipsis text-sm font-semibold block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
							{`${record.correct_answer}/${record.total_question}`}
						</div>
						<span
							className="cursor-pointer text-center text-sm font-bold block text-main-blue"
							onClick={() => setExamResultView({ showModal: true, userId: urlSafeEncrypt(record?.id) })}>
							SEE DATA
						</span>
					</div>
				),
			},
			{
				name: "Point",
				dataIndex: "given_point",
				className: "w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, given_point) => (
					<div className="w-[13%]">
						<div className="font-medium text-main-grey text-sm">
							{given_point !== null ? `${given_point} Points` : "No Point Yet"}
						</div>
					</div>
				),
			},
			{
				name: "Status",
				dataIndex: "exam_status",
				className: "w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, exam_status) => (
					<div className="w-[13%]">
						<div class={clsx("font-semibold text-sm", getColorClassNameByExamStatus(exam_status))}>
							{convertToTitleCase(exam_status)}
						</div>
					</div>
				),
			},
		],
		[],
	);

	const resetCheckList = () => {
		if (selectedIds.length > 0) {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setBodyFilter({
			filter: {
				exam_status: selected.selected,
				min_correct_answer: selected.filterValue.min_correct_answer,
				max_correct_answer: selected.filterValue.max_correct_answer,
			},
		});

		setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
	};

	const handleOnExport = () => {
		exportRegistratioData(
			{
				activityId,
				type: "exam",
				bodyRequest: { ...bodyFilter, filter: { ...bodyFilter.filter, user_activity_ids: selectedIds } },
				filterParams: { ...queryFilter, order_by: "exam_submitted_at" },
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("Exam", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex items-center justify-between p-5">
				<div className="w-[70%] flex">
					<SearchFilterComponent
						containerClassName="relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2"
						onSearch={search => {
							resetCheckList();
							setQueryFilter({ ...queryFilter, search: search.keyword });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={
								bodyFilter.filter.exam_status.length > 0 ||
								bodyFilter.filter.min_correct_answer ||
								bodyFilter.filter.max_correct_answer
							}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport loading={isExporting} onClick={handleOnExport} />
					</div>
				</div>
				<div className="flex w-[28%] justify-end">
					<div className="">
						<ButtonSubmit
							disabled={!selectedIds.length}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalPoint: true }))
							}
							className="items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white disabled:border-red-10 disabled:bg-red-10 bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
							title="Give Point"
						/>
					</div>
				</div>
			</div>

			<div className="px-5">
				<UserListTable
					rowClassName="flex border border-solid border-grey-10 rounded-lg py-5 items-center mb-3"
					rowSelectedClassName="flex border border-solid bg-light-pink border-red-10 rounded-lg py-5 items-center mb-3"
					columns={columns}
					dataSource={activityResultRegistrations?.data?.rows || []}
					loading={isLoading}
				/>
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && !queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message="There's no records available here" />
					</div>
				)}
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message={`Your search "${queryFilter.search}" did not match any records`} />
					</div>
				)}
				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={selectedPage => {
							window.scrollTo(0, 0);

							setQueryFilter({ ...queryFilter, page: selectedPage });
						}}
						totalCount={activityResultRegistrations?.data?.total_result || 0}
					/>
				)}
			</div>
			<ModalExamFilter
				initialValue={bodyFilter.filter}
				onChange={handleOnFilterDataChange}
				visible={tableFilterModal.showModalFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
			/>
			<ModalResult
				visible={modalResult.showModal}
				key={`result-point-${tableFilterModal.key}`}
				title={false}
				onClose={() => setModalResult(prev => ({ ...prev, showModal: false }))}
				message={modalResult.message}
			/>
			<ModalGivePoint
				key={tableFilterModal.key}
				selectedIds={selectedIds}
				activityId={activityId}
				visible={tableFilterModal.showModalPoint}
				onCompleted={() => {
					resetCheckList();
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalPoint: false,
						key: new Date().getTime(),
					}));
					setModalResult({
						showModal: true,
						message: "Successfully change user status!",
					});
				}}
				onClose={() => {
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalPoint: false,
						key: new Date().getTime(),
					}));
				}}
			/>

			<ModalAnswerComponent
				visible={examResultView.showModal}
				userId={examResultView.userId}
				title="Exam Result"
				type="activity"
				onClose={() => setExamResultView({ userId: null, showModal: false })}
			/>

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				options={sortOptions}
				initialValue={sortOptions[0]}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</>
	);
}
