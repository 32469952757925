import clsx from "clsx";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setToolsReducer } from "store/reducer/tools.reducer";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { useActivityResult, useExportActivityResult } from "hooks/activity";
import { convertToTitleCase, getInitialsName, isUrlMedia } from "utils/string";

import { getColorClassNameByExamStatus } from "config/static-mapping";
import { ModalResult } from "components/modal/modal.result";

import { ModalFilterCheckBox } from "../../components/registration-filter.component";
import { ModalGivePoint } from "../../components/give-point.component";
import { ModalComment } from "../../components/modal-comment.component";
import { ModalChallangeResult } from "../../components/challange-result.component";
import { TableChallange } from "../components/TableChallange";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalContainer } from "components/modal/modal.container";
import { urlSafeEncrypt } from "utils/encryption";

const sortOptions = [
	{ label: "Tanggal Submit Terbaru", sortType: "desc", sortBy: "challenge_submitted_date" },
	{ label: "Tanggal Submit Terdahulu", sortType: "asc", sortBy: "challenge_submitted_date" },
];
const filterOptions = [
	{
		label: "Waiting Review",
		value: "waiting review",
	},
	{
		label: "Failed",
		value: "failed",
	},
	{
		label: "Passed",
		value: "passed",
	},
];
export function TabChallange() {
	const dispatch = useDispatch();
	const { activityId } = useParams();
	// Selected record's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	const [modalImagePreview, setModalImagePreview] = useState({
		visible: false,
		url: null,
	});

	const [expandedRowIds, setExpandedRowIds] = useState([]);
	const [challangeResult, setChallageResultView] = useState({ userActivityId: null, showModal: false });

	const [modalResult, setModalResult] = useState({
		showModal: false,
		message: "",
	});
	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
		showModalPoint: false,
		showModalComment: false,
		key: new Date().getTime(),
	});

	// Query Params Filter
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		search: "",
		order: "desc",
		order_by: "challenge_submitted_date",
	});

	// Body Request Filter
	const [bodyFilter, setBodyFilter] = useState({
		filter: {
			challenge_status: [],
		},
	});

	const { mutate: exportChallangeData, isLoading: isExporting } = useExportActivityResult();

	const { data: activityResultRegistrations, isLoading } = useActivityResult(
		{ activityId, type: "challenge" },
		{ queryFilter, bodyFilter },
	);

	useEffect(() => {
		if (expandedRowIds.length) setExpandedRowIds([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter, bodyFilter]);

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "Nama",
				dataIndex: "user",
				className:
					" w-1/4 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
				render: record => (
					<div className="flex justify-center items-center overflow-hidden w-1/4">
						<div className="w-12">
							{record?.user?.profile_picture ? (
								<img
									src={record?.user?.profile_picture}
									alt={record?.user?.full_name}
									className="rounded-full w-12 h-12 overflow-hidden object-cover object-center"
								/>
							) : (
								<div className="font-bold text-red-60 text-2xl">
									{getInitialsName(record?.user?.full_name)}
								</div>
							)}
						</div>
						<div className="w-4/5 pl-3 overflow-hidden">
							<a
								href="user-detail.html"
								className="text-ellipsis mb-1 block text-sm overflow-hidden whitespace-nowrap text-grey-70 hover:text-red-50 font-semibold">
								{record?.user?.full_name}
							</a>

							<div className="text-ellipsis pr-4 text-sm block font-medium overflow-hidden whitespace-nowrap text-main-grey">
								{record?.user?.email}
							</div>
						</div>
					</div>
				),
			},
			{
				name: "Submitted Date",
				dataIndex: "challenge_submitted_date_formatted",
				className: " w-1/6 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, challenge_submitted_date_formatted) => {
					if (!record.challenge_status) {
						return (
							<div className="w-1/6">
								<div className="text-sm text-main-grey">-</div>
							</div>
						);
					}
					return (
						<div className="w-1/6">
							<div className="text-sm text-main-grey">{challenge_submitted_date_formatted}</div>
						</div>
					);
				},
			},
			{
				name: "Challenge Result",
				dataIndex: "user",
				className: " w-1/6 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: record => {
					if (!record.challenge_status) {
						return (
							<div className="overflow-hidden w-1/6">
								<div class={clsx("font-semibold text-sm")}>-</div>
							</div>
						);
					}
					return (
						<div className="overflow-hidden w-1/6">
							<div className="flex items-center mb-2">
								{record.user_activity_challenge_results.map(item => {
									if (isUrlMedia(item.result_url).isImage) {
										return (
											<div className="mr-2 cursor-pointer">
												<img
													onClick={() => {
														setModalImagePreview({
															visible: true,
															url: item.result_url,
														});
													}}
													src={item.result_url}
													alt={item.result_url}
													className="rounded w-8 h-8 overflow-hidden object-cover object-center"
												/>
											</div>
										);
									}
									if (isUrlMedia(item.result_url).isVideo) {
										return (
											<div className="mr-2 relative">
												<video
													src={item.result_url}
													alt={item.result_url}
													preload="auto"
													className="rounded w-8 h-8 overflow-hidden object-cover object-center">
													<source src={item.result_url} type="video/mp4" />
													<source src={item.result_url} type="video/webm" />
												</video>
												<div
													onClick={() => {
														window.open(item.result_url, "_blank");
													}}
													className="cursor-pointer absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] z-10">
													<img src="/img/play.png" alt="Play" />
												</div>
											</div>
										);
									}
									return (
										<div className="mr-2">
											<div
												onClick={() => {
													window.open(item.result_url, "_blank");
												}}
												className="cursor-pointer  w-8 h-8 rounded border flex justify-center items-center border-solid border-main-pink bg-light-pink">
												<span className="icon-ico-link text-red-50 text-base"></span>
											</div>
										</div>
									);
								})}
							</div>
							<span
								onClick={() => setChallageResultView({ showModal: true, userActivityId: record?.id })}
								className="cursor-pointer uppercase text-sm font-bold block overflow-hidden
								whitespace-nowrap text-red-50 hover:text-red-60 transition-all">
								see detail
							</span>
						</div>
					);
				},
			},
			{
				name: "Status",
				dataIndex: "challenge_status",
				className: "w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, challenge_status) => (
					<div className="w-[15%]">
						<div class={clsx("font-semibold text-sm", getColorClassNameByExamStatus(challenge_status))}>
							{challenge_status ? convertToTitleCase(challenge_status) : "Not yet submitted"}
						</div>
					</div>
				),
			},
			{
				name: "Point",
				dataIndex: "given_point",
				className: "w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, given_point) => (
					<div className="w-[13%]">
						<div className="text-ellipsis text-sm font-semibold block overflow-hidden whitespace-nowrap text-main-grey">
							{given_point !== null ? `${given_point} Points` : "No Point Yet"}
						</div>
					</div>
				),
			},

			{
				name: "Comment",
				dataIndex: "challenge_status",
				className: "w-[11%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, challenge_status) => {
					if (!challenge_status) return null;
					return (
						<div className="w-[11%]">
							<div className="flex justify-center">
								<div className="mr-3 flex items-center">
									<span className="icon-ico-comment text-red-50 text-xl"></span>
									<span className="font-semibold text-grey-70 text-sm pl-1">
										{record.user_activity_admin_replies.length}
									</span>
								</div>

								{record.user_activity_admin_replies.length > 0 && (
									<button
										onClick={() => {
											if (expandedRowIds.includes(record.id)) {
												setExpandedRowIds(expandedRowIds.filter(id => id !== record.id));
											} else {
												setExpandedRowIds([...expandedRowIds, record.id]);
											}
										}}
										className="items-btn flex items-center bg-white w-10 h-10 rounded-full justify-center border text-sm border-solid border-grey-10  text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
										type="button">
										<span
											class={clsx(
												"text-red-50 text-base",
												expandedRowIds.includes(record.id)
													? "icon-ico-chev-down"
													: "icon-ico-chev-up",
											)}></span>
									</button>
								)}
							</div>
						</div>
					);
				},
			},
		],
		[expandedRowIds],
	);

	const resetCheckList = () => {
		if (selectedIds.length > 0) {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setBodyFilter({
			filter: {
				challenge_status: selected,
			},
		});

		setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
	};

	const handleOnDeleteCommentCompleted = () => {
		setModalResult({
			showModal: true,
			message: "Successfully deleted comment!",
		});
	};
	const handleOnExport = () => {
		exportChallangeData(
			{
				activityId,
				type: "challenge",
				bodyRequest: { ...bodyFilter, filter: { ...bodyFilter.filter, user_activity_ids: selectedIds } },
				filterParams: queryFilter,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("Challange", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};
	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex items-center justify-between p-5">
				<div className="w-[70%] flex">
					<SearchFilterComponent
						containerClassName="relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2"
						onSearch={search => {
							resetCheckList();
							setQueryFilter({ ...queryFilter, search: search.keyword });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={bodyFilter.filter.challenge_status.length > 0}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleOnExport} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[28%] justify-end">
					<div className="">
						<ButtonSubmit
							disabled={!selectedIds.length}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalPoint: true }))
							}
							className="items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white disabled:border-red-10 disabled:bg-red-10 bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
							title="Give Point"
						/>
						<ButtonSubmit
							disabled={!selectedIds.length}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalComment: true }))
							}
							className="items-center py-2.5 px-7 ml-2 border border-solid border-red-50 rounded-lg text-white disabled:border-red-10 disabled:bg-red-10 bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
							title="Comment"
						/>
					</div>
				</div>
			</div>

			<div className="px-5">
				<TableChallange
					onDeleteCommentItemCompleted={handleOnDeleteCommentCompleted}
					rowClassName="flex items-center py-4"
					rowSelectedClassName="flex  bg-light-pink  rounded-lg py-4 items-center "
					expandedRowIds={expandedRowIds}
					columns={columns}
					dataSource={activityResultRegistrations?.data?.rows || []}
					loading={isLoading}
				/>
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && !queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message="There's no records available here" />
					</div>
				)}
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message={`Your search "${queryFilter.search}" did not match any records`} />
					</div>
				)}
				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={selectedPage => {
							window.scrollTo(0, 0);

							setQueryFilter({ ...queryFilter, page: selectedPage });
						}}
						totalCount={activityResultRegistrations?.data?.total_result || 0}
					/>
				)}
			</div>
			<ModalFilterCheckBox
				initialValue={bodyFilter.filter.challenge_status}
				options={filterOptions}
				onChange={handleOnFilterDataChange}
				visible={tableFilterModal.showModalFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
			/>
			<ModalGivePoint
				showComment
				key={`give-point-${tableFilterModal.key}`}
				type="challenge"
				selectedIds={selectedIds}
				activityId={activityId}
				visible={tableFilterModal.showModalPoint}
				onCompleted={() => {
					resetCheckList();
					setModalResult({
						showModal: true,
						message: "Successfully change user status!",
					});
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalPoint: false,
						key: new Date().getTime(),
					}));
				}}
				onClose={() => {
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalPoint: false,
						key: new Date().getTime(),
					}));
				}}
			/>
			<ModalResult
				visible={modalResult.showModal}
				title={false}
				onClose={() => setModalResult(prev => ({ ...prev, showModal: false }))}
				message={modalResult.message}
			/>
			<ModalComment
				selectedIds={selectedIds}
				activityId={activityId}
				key={`comment-${tableFilterModal.key}`}
				visible={tableFilterModal.showModalComment}
				onCompleted={() => {
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalComment: false,
						key: new Date().getTime(),
					}));
					setModalResult({
						showModal: true,
						message: "Comment sent successfully!",
					});
					resetCheckList();
				}}
				onClose={() => {
					resetCheckList();
					setShowTableFilterModal(prevState => ({ ...prevState, showModalComment: false }));
				}}
			/>
			<ModalChallangeResult
				onPreviewImage={url => {
					setModalImagePreview({
						visible: true,
						url,
					});
				}}
				onClose={() => setChallageResultView({ showModal: false, userActivityId: null })}
				visible={challangeResult.showModal}
				userActivityId={urlSafeEncrypt(challangeResult.userActivityId)}
			/>

			<ModalContainer visible={modalImagePreview.visible}>
				<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-2 ")}>
					<div className="relative text-center py-4">
						<button
							type="button"
							className="absolute top-0 right-2 border-0"
							onClick={() => {
								setModalImagePreview(prevState => ({ ...prevState, visible: false }));
							}}>
							<span className="icon-ico-close"></span>
						</button>
					</div>
					<img className="w-[100%] h-[90vh] object-cover" src={modalImagePreview.url} alt="Preview" />
				</div>
			</ModalContainer>

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				options={sortOptions}
				initialValue={sortOptions[0]}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</>
	);
}
