import { useEffect, useState } from "react";

export function SearchFilterComponent({
	containerClassName = "relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2",
	onSearch,
	placeholder = "Cari User...",
	inputClassName = "block w-full pr-4 py-2.5 pl-10 text-base font-normal text-red-50 bg-white bg-clip-padding border border-solid border-red-50 placeholder:text-red-50 placeholder:italic rounded-lg form-control focus:text-red-50 focus:bg-white focus:border-red-50 focus:outline-none focus:shadow-none",
	controllerInput = false,
	initialValue,
	onChange = () => {},
	value = undefined,
}) {
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		if (initialValue) setKeyword(initialValue);
	}, [initialValue]);

	return (
		<div className={containerClassName}>
			<input
				type="text"
				className={inputClassName}
				value={controllerInput ? value : keyword}
				onChange={e => {
					if (controllerInput) {
						onChange(e);
					} else {
						setKeyword(e.target.value);
					}
				}}
				placeholder={placeholder}
				onKeyDown={e => {
					if (e.key === "Enter") {
						onSearch({ keyword });
					}
				}}
			/>
			<div
				className="absolute left-3 top-2 text-red-50"
				onClick={() => {
					onSearch({ keyword });
				}}>
				<span className="icon-ico-search text-xl cursor-pointer"></span>
			</div>
		</div>
	);
}
