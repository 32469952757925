import { TooltipComponent } from "components/tooltip/tooltip.component";
import { ImageWithZoom } from "components/image/image-zoom.component";
import clsx from "clsx";

export function QuestionItemComponent({
	question = "Disini adalah pertanyaan dengan multiple choice jawaban di bawah ini",
	questionId = null,
	sequence = 1,
	options = [],
	published = true,
	onMoveOrder = () => {},
	onMoveStatus = () => {},
	onDelete = () => {},
	onChangeOption = () => {},
	onEdit = () => {},
	disabled,
	disabledUpButton = false,
	disabledDownButton = false,
	image = null,
	type = "register",
}) {
	return (
		<div className="rounded-lg border border-solid border-grey-30 p-4 mb-6">
			<div className="flex">
				<div className="w-7 h-7 rounded-full font-bold text-white bg-red-50 text-center leading-7">
					{sequence}
				</div>
				<div className="w-[90%] ml-2">
					<div className="text-sm text-black font-semibold mb-6">{question}</div>
					{image && (
						<ImageWithZoom
							src={image}
							className="w-44 max-h-44   mb-4 rounded-md"
							loading="lazy"
							alt={question}
						/>
					)}
				</div>
			</div>
			{options?.map(option => {
				if (option.type === "text") {
					return (
						<div className="test-item py-4 relative" key={option.option_id}>
							<input
								disabled={disabled}
								id={`${questionId}-${option.option_id}`}
								type="radio"
								name={`answer-${questionId}`}
								className="hidden"
								onChange={e => onChangeOption(questionId, option)}
								checked={option.is_correct}
							/>
							<label
								className="flex items-center font-semibold text-black text-sm"
								htmlFor={`${questionId}-${option.option_id}`}>
								<span className="pl-4">{option.label}</span>
							</label>
						</div>
					);
				}
				if (option.type === "image") {
					return (
						<div className="w-2/6">
							<div className="test-item py-4 relative" key={option.option_id}>
								<input
									id={`${questionId}-${option.option_id}`}
									disabled={disabled}
									type="radio"
									name={`answer-${questionId}`}
									className="hidden"
									onChange={e => onChangeOption(questionId, option)}
									checked={option.is_correct}
								/>
								<label
									className="flex items-center font-semibold text-black text-sm"
									htmlFor={`${questionId}-${option.option_id}`}>
									<span className="pl-4">
										<ImageWithZoom
											src={option.label}
											className="w-36 max-h-36  rounded-md"
											alt={option.option_id}
											loading="lazy"
										/>
									</span>
								</label>
							</div>
						</div>
					);
				}
				return null;
			})}

			{published ? (
				<div className={clsx(type === "activity" && "flex justify-between")}>
					{type === "activity" && (
						<div className="flex items-center justify-end mt-8">
							<button
								className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
								type="button"
								disabled={disabled}
								onClick={onDelete}>
								<span className="icon-ico-delete text-red-50 mr-1 text-xl"></span>
								<span className="pl-2">Delete</span>
							</button>
							<button
								disabled={disabled}
								onClick={onEdit}
								className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
								type="button">
								<span className="icon-ico-edit text-red-50 mr-1 text-xl"></span>
								<span className="pl-2">Edit</span>
							</button>
						</div>
					)}

					<div className="flex items-center justify-end mt-8">
						{type === "register" && (
							<button
								className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
								type="button"
								disabled={disabled}
								onClick={() => onMoveStatus("unpublished")}>
								<span className="icon-ico-plane text-red-50 text-xl"></span>
								<span className="pl-2">Move To Unpublished</span>
							</button>
						)}

						<button
							className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
							type="button"
							disabled={disabledUpButton || disabled}
							onClick={() => {
								onMoveOrder(sequence, sequence - 1);
							}}>
							<span className="icon-ico-arrow-up text-red-50 text-xl"></span>
						</button>
						<button
							className="flex items-center py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
							type="button"
							disabled={disabledDownButton || disabled}
							onClick={() => {
								onMoveOrder(sequence, sequence + 1);
							}}>
							<span className="icon-ico-arrow-down text-red-50 text-xl"></span>
						</button>
					</div>
				</div>
			) : (
				<div className="flex items-center justify-end mt-8">
					<button
						className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
						type="button"
						disabled={disabled}
						onClick={onDelete}>
						<span className="icon-ico-delete text-red-50 mr-1 text-xl"></span>
						<span className="pl-2">Delete</span>
					</button>
					<button
						disabled={disabled}
						onClick={onEdit}
						className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:cursor-not-allowed disabled:opacity-50"
						type="button">
						<span className="icon-ico-edit text-red-50 mr-1 text-xl"></span>
						<span className="pl-2">Edit</span>
					</button>
					<TooltipComponent
						tooltipText="Cannot move item, please select the correct answer on the options"
						enabled={options.every(item => !item.is_correct)}>
						<button
							disabled={disabled || options.every(item => !item.is_correct)}
							className="flex items-center py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold  disabled:opacity-50"
							type="button"
							onClick={() => onMoveStatus("published")}>
							<span className="icon-ico-plane text-red-50 text-xl"></span>
							<span className="pl-2">Move To Published</span>
						</button>
					</TooltipComponent>
				</div>
			)}
		</div>
	);
}
