export function EmptyState({ message = "Data not found" }) {
	return (
		<div className="empty-state">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				width="150"
				height="150"
				viewBox="0 0 172 172"
				style={{ fill: "#000000" }}>
				<g
					fill="none"
					fill-rule="nonzero"
					stroke="none"
					stroke-width="1"
					stroke-linecap="butt"
					stroke-linejoin="miter"
					stroke-miterlimit="10"
					stroke-dasharray=""
					stroke-dashoffset="0"
					font-family="none"
					font-weight="none"
					font-size="none"
					text-anchor="none"
					style={{ mixBlendMode: "normal" }}>
					<path d="M0,172v-172h172v172z" fill="none"></path>
					<g fill="#cccccc">
						<path d="M39.56,6.88c-6.60889,0 -12.04,5.43111 -12.04,12.04v134.16c0,6.60889 5.43111,12.04 12.04,12.04h92.88c6.60889,0 12.04,-5.43111 12.04,-12.04v-102.90437l-43.29563,-43.29563zM39.56,13.76h56.76v29.24c0,6.60889 5.43111,12.04 12.04,12.04h29.24v98.04c0,2.89239 -2.26761,5.16 -5.16,5.16h-92.88c-2.89239,0 -5.16,-2.26761 -5.16,-5.16v-134.16c0,-2.89239 2.26761,-5.16 5.16,-5.16zM103.2,18.62438l29.53563,29.53562h-24.37563c-2.89239,0 -5.16,-2.26761 -5.16,-5.16zM63.64,84.28c-2.84979,0 -5.16,2.31021 -5.16,5.16c0,2.84979 2.31021,5.16 5.16,5.16c2.84979,0 5.16,-2.31021 5.16,-5.16c0,-2.84979 -2.31021,-5.16 -5.16,-5.16zM75.68,86v6.88h37.84v-6.88zM63.64,101.48c-2.84979,0 -5.16,2.31021 -5.16,5.16c0,2.84979 2.31021,5.16 5.16,5.16c2.84979,0 5.16,-2.31021 5.16,-5.16c0,-2.84979 -2.31021,-5.16 -5.16,-5.16zM75.68,103.2v6.88h37.84v-6.88zM63.64,118.68c-2.84979,0 -5.16,2.31021 -5.16,5.16c0,2.84979 2.31021,5.16 5.16,5.16c2.84979,0 5.16,-2.31021 5.16,-5.16c0,-2.84979 -2.31021,-5.16 -5.16,-5.16zM75.68,120.4v6.88h37.84v-6.88z"></path>
					</g>
				</g>
			</svg>
			<p className="mb-4 text-center text-sm text-grey-60">{message}</p>
		</div>
	);
}
