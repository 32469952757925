import clsx from "clsx";
import { useState, useRef } from "react";

import { useOnClickOutside } from "hooks/useOnClickOutside";

export function SkinTypeItem({ image, name, onEdit, onDelete }) {
	const optionsContainerRef = useRef(null);
	const [showOptionsMenu, setShowOptionsMenu] = useState(false);

	useOnClickOutside(optionsContainerRef, () => setShowOptionsMenu(false));

	return (
		<div className="skin-type relative mr-4 mb-4">
			<input type="radio" name="skin-type" id="st-1" className="hidden" checked="" />
			<label
				for="st-1"
				className="w-[98px] h-32 py-4 rounded-xl border flex flex-col items-center justify-center border-solid cursor-pointer border-grey-10">
				<div className="h-16">
					<img src={image} alt="Category" className="object-cover rounded-full w-12 h-12 overflow-hidden" />
				</div>
				<div className="h-10 flex items-center">
					<div className="text-black font-semibold text-sm text-center px-2 line-clamp-2  ">{name}</div>
				</div>

				<div className="dropdown absolute top-0 right-0" ref={optionsContainerRef}>
					<button
						className="leading-tight w-7 h-7 rounded-full border-grey-10 relative flex items-center justify-center text-left"
						type="button"
						onClick={() => setShowOptionsMenu(!showOptionsMenu)}>
						<span className="icon-ico-bullets text-sm"></span>
					</button>
					<ul
						class={clsx(
							"dropdown-menu min-w-max absolute hidden bg-white text-base z-50 py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none w-[150px]",
							showOptionsMenu && "show",
						)}
						aria-labelledby="drop-1">
						<li>
							<button
								onClick={() => {
									onEdit();
									setShowOptionsMenu(false);
								}}
								className=" dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 focus:bg-white font-medium  ">
								Edit
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									onDelete();
									setShowOptionsMenu(false);
								}}
								className=" dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 font-medium focus:bg-white ">
								Delete
							</button>
						</li>
					</ul>
				</div>
			</label>
		</div>
	);
}
