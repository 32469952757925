import { useState, useMemo } from "react";

import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";

import { UserListTable } from "pages/registration/users/user-table.component";
import { useTopicCategory, useDeleteTopicCategory } from "hooks/discussion";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalAddTopicCategory } from "./components/modals/modal.add-category";
import { urlSafeEncrypt } from "utils/encryption";

const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
};

export function DiscussionCategoryPage() {
	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	const [modalCategory, setModalCategory] = useState({
		visible: false,
		operation: "ADD",
		id: null,
	});

	// Hooks API Call
	const { data: topicCategories, isLoading, refetch: refetchData } = useTopicCategory();
	const { mutate: deleteCategoryById, isLoading: isDeleting } = useDeleteTopicCategory();

	const columns = useMemo(
		() => [
			{
				name: "Category Name",
				dataIndex: "name",
				className:
					"w-[30%] pl-3 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis  text-sm font-semibold",
				render: (_, name) => (
					<div className="w-[30%] pl-3 text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis  text-sm font-semibold">
						{name}
					</div>
				),
			},
			{
				name: "Description",
				dataIndex: "description",
				className: " w-[27%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				render: (record, description) => (
					<div className="w-[27%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm">
						{description}
					</div>
				),
			},
			{
				name: "Total Topic",
				dataIndex: "total_topic",
				className: " w-[20%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				render: (record, total_topic) => (
					<div className="w-[20%] ml-3 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						<span className="icon-ico-topic text-red-50"></span>
						<span className="ml-3">{total_topic} Topics</span>
					</div>
				),
			},
			{
				name: "Action",
				dataIndex: "action",
				className:
					" w-[20%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-center font-semibold",
				render: record => {
					return (
						<div className="w-[20%] text-sm text-center flex items-center justify-center font-semibold">
							<ButtonSubmit
								onClick={() => {
									setModalCategory({
										visible: true,
										operation: "EDIT",
										id: record.id,
									});
								}}
								className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
								<span className="icon-ico-edit text-xl text-red-50 mr-2"></span>
								<span className="pl-1">Edit</span>
							</ButtonSubmit>
							<ButtonSubmit
								onClick={() => {
									setModalConfirmation({
										message:
											"Apakah ingin menghapus data ini? Setelah dihapus tidak dapat dipulihkan",
										showModal: true,
										data: {
											categoryId: record.id,
										},
									});
								}}
								className="disabled:opacity-50 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
								<span className="icon-ico-delete text-xl text-red-50 mr-2"></span>
								<span className="pl-1">Delete</span>
							</ButtonSubmit>
						</div>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[topicCategories],
	);

	const handleDeleteCategory = () => {
		deleteCategoryById(
			{ categoryId: urlSafeEncrypt(modalConfirmation.data?.categoryId) },
			{
				onSuccess: () => {
					setModalConfirmation({
						message: "",
						showModal: false,
						data: {},
					});
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: "Category has been deleted!",
					}));
				},
			},
		);
	};

	return (
		<Layout breadCumbTitle="Discussion" breadCumbDesc="Category">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="bg-white rounded-lg drop-shadow-main filter-none p-5">
					<div className="flex items-center justify-between mb-4">
						<div className="text-2xl text-grey-70 font-semibold">Category</div>
						<ButtonSubmit
							onClick={() => {
								setModalCategory({
									visible: true,
									operation: "ADD",
								});
							}}
							disabled={isLoading}
							className="flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-circle-plus mr-2"></span> Create Category
						</ButtonSubmit>
					</div>
					<UserListTable
						rowSelectedClassName="flex items-center py-4 border-b border-solid border-grey-10"
						rowClassName="flex items-center py-4 border-b border-solid border-grey-10"
						loading={isLoading}
						dataSource={topicCategories?.data || []}
						columns={columns}
					/>
				</div>
			)}
			<ModalAddTopicCategory
				visible={modalCategory.visible}
				operation={modalCategory.operation}
				editId={urlSafeEncrypt(modalCategory?.id)}
				onSuccess={responseMessage => {
					setModalCategory(prev => ({ ...prev, visible: false }));
					refetchData();
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: responseMessage,
					}));
				}}
				onClose={() => setModalCategory(prev => ({ ...prev, visible: false }))}
			/>
			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteCategory()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus data ini?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
