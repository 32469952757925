import { Formik } from "formik";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "components/layout";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { useAllRewardItemsList, useCreateReward, useRewardBatchs, useRewardLevel } from "hooks/reward";
import { InputComponent } from "components/form/input.component";
import { Select } from "components/form/select.component";
import { ModalBulkSelectProduct } from "./components/modal-bulk-select-prodcut";
import { BulkSelect } from "components/form/bulk-select.component";
import { CreateRewardSchema } from "config/form/schema/reward/create-reward";

const formInitialValues = {
	title: "",
	hint: "",
	level_id: null,
	batch: null,
	item_ids: [],
	start_date: null,
	end_date: null,
};
const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
};

export function CreateRewardPage() {
	const navigate = useNavigate();
	const formikRef = useRef(null);

	const [productOptions, setProductOptions] = useState([]);
	const [modalBulkOptioons, setModalOptionsBulk] = useState({
		visible: false,
	});
	const [modalResult, setModalResult] = useState({
		visible: false,
		type: null,
		message: null,
	});
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);

	// Hooks API Call
	const { data: rewardItemOptions } = useAllRewardItemsList();
	const { data: rewardLevelOptions } = useRewardLevel();
	const { data: rewardBatchOptions } = useRewardBatchs();
	const { mutate: createReward, isLoading: isSubmitting } = useCreateReward();

	const handleChangeBulkSelect = selectedOptions => {
		const newOptionsData = productOptions.map(item => {
			if (selectedOptions?.id === item.id) {
				return {
					...item,
					selected: selectedOptions.selected,
				};
			}
			return item;
		});
		setProductOptions(newOptionsData);
		formikRef.current.setFieldValue(
			"item_ids",
			newOptionsData.filter(item => item.selected).map(item => item.id),
		);
	};

	const handleCreateReward = () => {
		const payload = formikRef.current.values;
		createReward(
			{
				...payload,
				level_id: Number(payload.level_id),
				batch: Number(payload.batch),
			},
			{
				onSuccess: () => {
					setModalConfirmation(modalConfirmationInitialState);
					setModalResult({
						visible: true,
						type: "success",
						message: "Reward has been created successfully",
					});
				},
			},
		);
	};

	useEffect(() => {
		if (rewardItemOptions) {
			setProductOptions(
				rewardItemOptions.data.map(item => ({
					...item,
					selected: false,
				})),
			);
		}
	}, [rewardItemOptions]);

	return (
		<Layout breadCumbTitle="Reward" breadCumbDesc="Create Reward" buttonToTop>
			<div className="bg-white rounded-lg drop-shadow-main filter-none p-5">
				<div className="text-2xl text-grey-70 font-semibold mb-5">Create Reward</div>
				<Formik
					innerRef={formikRef}
					initialValues={formInitialValues}
					onSubmit={handleCreateReward}
					validationSchema={CreateRewardSchema}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
						<div className="w-4/5">
							<InputComponent
								label="Title"
								placeholder="Reward Title"
								error={touched.title && errors.title}
								name="title"
								value={values.title}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<InputComponent
								label="Hint"
								error={touched.hint && errors.hint}
								placeholder="Input Hint"
								name="hint"
								value={values.hint}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Select
								name="level_id"
								onBlur={handleBlur}
								error={touched.level_id && errors.level_id}
								onChange={handleChange}
								value={values.level_id}
								options={rewardLevelOptions?.data || []}
								label="Level"
								placeholder="Select Level"
							/>
							<Select
								name="batch"
								onBlur={handleBlur}
								error={touched.batch && errors.batch}
								onChange={handleChange}
								value={values.batch}
								options={rewardBatchOptions?.data || []}
								label="Batch"
								placeholder="Select Batch"
							/>
							<BulkSelect
								key="item-reward-list"
								error={touched.province_ids && errors.province_ids}
								onClick={() => {
									setModalOptionsBulk({
										visible: true,
									});
								}}
								label="Add Reward"
								placeholder="Pilih item reward..."
								onChange={selected => handleChangeBulkSelect(selected)}
								selectedData={productOptions}>
								{({ data, onChange }) => {
									return (
										<div className="border border-solid border-grey-10 mt-[-1px] rounded-bl-lg rounded-br-lg p-4">
											<ul className="list-none p-0 flex-wrap flex justify-between">
												{data?.map(item => (
													<li className="w-[48%] flex items-center justify-between mb-5">
														<div className="w-9/12 flex items-center">
															<div className="w-[60px]">
																<img
																	alt={item.name}
																	src={item.image_desktop}
																	className="w-[60px] h-[60px] overflow-hidden object-cover object-center"
																/>
															</div>
															<div className="w-[calc(100%-60px)] pl-4 text-sm">
																{item.name}
															</div>
														</div>
														<div className="w-1/12">
															<button
																onClick={() => {
																	onChange({ ...item, selected: !item.selected });
																}}
																type="button"
																className="outline-none border-0 p-0 text-red-50">
																<span className="icon-ico-delete text-xl"></span>
															</button>
														</div>
													</li>
												))}
												{data?.length === 0 && (
													<div className="text-center text-sm text-grey-60 italic">
														No data selected
													</div>
												)}
											</ul>
										</div>
									);
								}}
							</BulkSelect>

							<InputComponent
								label="Start Date & Time"
								type="datepicker-modal"
								placeholder="Start Date & Time"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values?.start_date}
								name="start_date"
								error={touched?.start_date && errors?.start_date}
							/>
							<InputComponent
								label="End Date & Time"
								type="datepicker-modal"
								placeholder="End Date & Time"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values?.end_date}
								name="end_date"
								error={touched?.end_date && errors?.end_date}
							/>
							<div className="flex items-center justify-center my-9">
								<div className="w-1/3 mx-1">
									<ButtonSubmit
										disabled={!CreateRewardSchema.isValidSync(values)}
										title="CREATE REWARD"
										onClick={() => {
											setModalConfirmation({
												showModal: true,
											});
										}}
									/>
								</div>
							</div>
						</div>
					)}
				</Formik>
			</div>
			<ModalConfirmation
				buttons={[]}
				title="Create Reward"
				wrapperClasss=" w-[425px]"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleCreateReward()}
				labelApprove="Create"
				loading={isSubmitting}>
				<div className="my-6">
					<div className="text-center ">
						<img src="/img/alert.png" alt="Alert" className="inline-block" />
					</div>
					<p className="text-center text-grey-60 text-sm my-5">
						Apakah anda yakin semua data sudah benar dan ingin membuat reward?
					</p>
				</div>
			</ModalConfirmation>
			<ModalBulkSelectProduct
				visible={modalBulkOptioons.visible}
				onClose={() => {
					setModalOptionsBulk(prev => ({ ...prev, visible: false }));
					formikRef.current.setTouched({
						...formikRef.current.touched,
						item_ids: true,
					});
				}}
				options={productOptions || []}
				onChange={selected => handleChangeBulkSelect(selected)}
			/>
			<ModalResult
				visible={modalResult.visible}
				title={false}
				onClose={() => {
					setModalResult(prev => ({ ...prev, visible: false }));
					navigate("/reward/list");
				}}
				message={modalResult.message}
			/>
		</Layout>
	);
}
