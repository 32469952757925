import clsx from "clsx";
import { useEffect, useState } from "react";

import { ModalContainer } from "components/modal/modal.container";
import { convertToTitleCase } from "utils/string";
import { useListBatch, useListProvince, useTopicCategory } from "hooks/discussion";

export function ModalFilterDiscussion({ visible, onClose, onChange, initialValue }) {
	const { data: discussionBatch } = useListBatch();
	const { data: discussionTopicCategory } = useTopicCategory();
	const { data: listProvince } = useListProvince();

	const [selected, setSelected] = useState({
		topic_category_ids: [],
		province_ids: [],
		batches: [],
	});

	useEffect(() => {
		if (initialValue && visible) {
			setSelected(initialValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Filter</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="content-scroll">
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Category</label>
						{discussionTopicCategory?.data?.map(topicCategory => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										id={`topic_category_ids_${topicCategory.id}`}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										checked={selected.topic_category_ids.includes(topicCategory.id)}
										onChange={() => {
											const topic_category_ids = selected.topic_category_ids.includes(
												topicCategory.id,
											)
												? selected.topic_category_ids.filter(id => id !== topicCategory.id)
												: [...selected.topic_category_ids, topicCategory.id];
											setSelected({ ...selected, topic_category_ids });
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										for={`topic_category_ids_${topicCategory.id}`}>
										{topicCategory.name && convertToTitleCase(topicCategory.name)}
									</label>
								</div>
							</div>
						))}
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Province</label>
						{listProvince?.data?.map(province => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										id={`province_ids_${province.id}`}
										checked={selected.province_ids.includes(province.id)}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										onChange={e => {
											const province_ids = selected.province_ids.includes(province.id)
												? selected.province_ids.filter(id => id !== province.id)
												: [...selected.province_ids, province.id];
											setSelected({ ...selected, province_ids });
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										for={`province_ids_${province.id}`}>
										{province.name}
									</label>
								</div>
							</div>
						))}
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Batch</label>
						{discussionBatch?.data?.map(batch => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										id={`batch_ids_${batch.id}`}
										checked={selected.batches.includes(batch.id)}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										onChange={e => {
											const batches = selected.batches.includes(batch.id)
												? selected.batches.filter(id => id !== batch.id)
												: [...selected.batches, batch.id];
											setSelected({ ...selected, batches });
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										for={`batch_ids_${batch.id}`}>
										{batch.name}
									</label>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
