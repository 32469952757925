import clsx from "clsx";
import { ModalContainer } from "components/modal/modal.container";
import { ButtonSubmit } from "components/button/submit.button";

export function ModalConfirmation({
	title = "Confirmation Action",
	message = "Are you sure you want to perform this action?",
	hiddenButtons = false,
	visible = false,
	children,
	onClose = () => {},
	onApprove = () => {},
	onReject = () => {},
	loading,
	labelReject = "Cancel",
	labelApprove = "Yes",
	wrapperClasss = "",
}) {
	return (
		<ModalContainer visible={visible}>
			<div
				id="show-item"
				class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden", wrapperClasss)}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">{title}</div>

					<button
						disabled={loading}
						type="button"
						className="absolute top-0 right-0 border-0"
						onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="my-6">
					{children ? children : <p className="mb-6 text-center text-main-grey">{message}</p>}

					{!hiddenButtons && (
						<div className="flex justify-between align-item">
							<div className="w-2/4 px-2">
								<ButtonSubmit
									onClick={onReject}
									disabled={loading}
									title={labelReject}
									className="block w-full text-center items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
								/>
							</div>
							<div className="w-2/4 px-2">
								<ButtonSubmit
									onClick={onApprove}
									loading={loading}
									title={labelApprove}
									className="block w-full px-8 py-2 text-white transition-all rounded-xl bg-red-50 hover:bg-red-70 disabled:border-red-10
			disabled:bg-red-10"
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</ModalContainer>
	);
}
