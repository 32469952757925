import { InputComponent } from "components/form/input.component";
import { AccordionWrapper } from "../accordion-wrapper.component";
import TextEditor from "components/text-editor";
import { IsJsonString } from "utils/string";

export function Attendance({
	expanded,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	setFieldValue,
	descriptionInitialValue = undefined,
	sectionId = "attendance",
}) {
	return (
		<AccordionWrapper
			onToggleExpand={onToggleExpand}
			expaned={expanded}
			title="Presensi"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="flex justify-between ">
					<div className="w-[48%]">
						<InputComponent
							label="Title"
							placeholder="Insert Title"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.title}
							name={`${sectionId}.title`}
							error={touched?.title && errors?.title}
						/>

						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(descriptionInitialValue) ? descriptionInitialValue : undefined
								}
								onChange={nodeValue => setFieldValue(`${sectionId}.description`, nodeValue)}
							/>
						</div>
					</div>
					<div className="w-[48%]">
						<InputComponent
							label="Kode Presensi"
							placeholder="Insert kode presensi"
							onBlur={onBlur}
							onChange={e => setFieldValue("attendance.code", e.target.value.toUpperCase())}
							value={values?.code}
							name={`${sectionId}.code`}
							error={touched?.code && errors?.code}
						/>
						<InputComponent
							label="Point"
							placeholder="Masukan jumlah point"
							numberOnly
							onBlur={onBlur}
							onChange={onChange}
							value={values?.point}
							name={`${sectionId}.point`}
							error={touched?.point && errors?.point}
						/>
						<InputComponent
							label="Start Date & Time"
							type="datepicker-modal"
							placeholder="Start Date & Time"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.start_date}
							name={`${sectionId}.start_date`}
							error={touched?.start_date && errors?.start_date}
						/>
						<InputComponent
							label="End Date & Time"
							type="datepicker-modal"
							placeholder="End Date & Time"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.end_date}
							name={`${sectionId}.end_date`}
							error={touched?.end_date && errors?.end_date}
						/>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
