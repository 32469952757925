import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { loadStaticPublicAsset } from "utils/loader";

import { useUploadImage } from "hooks/upload";

export function UploadSocmedIcon({
	title = "Desktop Banner (Aspect Ration 16:8)",
	initialImage = null,
	name = "image",
	imageClassName = "w-full  object-cover object-center",
	showDeleteButton = true,
	onChange = () => {},
	silentUpload = false,
}) {
	const [imagePreviewURL, setImagePreviewURL] = useState(initialImage || null);
	const [imageBuffer, setImageBuffer] = useState(null);
	const { mutate: uploadImage } = useUploadImage();

	const handleUploadImage = e => {
		const file = e.target.files[0];
		if (/\.(jpe?g|png)$/i.test(file.name)) {
			// file size more than 4mb
			if (file.size > 4 * 1024 * 1024) {
				toast.error("Image size is too large, maximum file size is 4mb");
				return;
			}

			setImageBuffer(file);
			setImagePreviewURL(URL.createObjectURL(file));
			if (silentUpload) {
				const formData = new FormData();
				formData.append("image", file);
				formData.append("type", "banner");
				uploadImage(
					{ formData },
					{
						onSuccess: res => {
							onChange({ [name]: res.data });
						},
					},
				);
			} else {
				onChange({
					[name]: file,
				});
			}
		} else {
			toast.error("File type is not supported, only jpg, png, and jpeg are allowed");
		}
	};
	const handleDelete = () => {
		setImagePreviewURL(imageBuffer ? initialImage : null);
		setImageBuffer(null);
		onChange({
			[name]: null,
		});
	};

	useEffect(() => {
		if (initialImage) setImagePreviewURL(initialImage);
	}, [initialImage]);

	return (
		<>
			<div className="text-sm text-black font-semibold mb-3">Logo (Desktop) 16:10</div>
			<div className="relative w-40 h-40 bg-grey-30 flex justify-center items-center p-5 overflow-hidden rounded-lg">
				<img
					src={imagePreviewURL ? imagePreviewURL : loadStaticPublicAsset("img/no-image.png")}
					class={imageClassName}
					alt="Banner"
				/>

				{imagePreviewURL && showDeleteButton ? (
					<button
						onClick={handleDelete}
						className="absolute top-2 right-3 w-10 h-10 shadow-md leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
						<span className="icon-ico-delete"></span>
					</button>
				) : null}
			</div>

			<div className="flex justify-center mt-5 mb-5">
				<input type="file" className="hidden" id={`upload-${name}`} onChange={handleUploadImage} name={name} />
				<label
					className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
					htmlFor={`upload-${name}`}>
					<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
					<span className="pl-2">Upload</span>
				</label>
			</div>
		</>
	);
}
