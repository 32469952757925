import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { urlSafeEncrypt } from "utils/encryption";
import { convertToTitleCase } from "utils/string";

function getColorByLevelId(levelId) {
	switch (levelId) {
		case 1:
			return {
				textColor: "text-main-tosca",
				backgroundColor: "bg-light-tosca",
				backgourndColorMain: "bg-main-tosca",
			};
		case 2:
			return {
				textColor: "text-main-purple",
				backgroundColor: "bg-light-purple ",
				backgourndColorMain: "bg-main-purple",
			};
		case 3:
			return {
				textColor: "text-main-pink",
				backgroundColor: "bg-light-pink ",
				backgourndColorMain: "bg-main-pink",
			};
		case 4:
			return {
				textColor: "text-main-green",
				backgroundColor: "bg-light-green ",
				backgourndColorMain: "bg-main-green",
			};
		default:
			return "";
	}
}

function getIconByActivityCategoryId(categoryId) {
	switch (categoryId) {
		case 7:
			return "icon-ico-review text-xl";
		case 1:
			return "icon-ico-course text-xl";
		case 4:
			return "icon-ico-exam text-xl";
		case 2:
			return "icon-ico-challange text-xl";
		default:
			return "icon-ico-course text-xl";
	}
}

export function ActivityItem({ data, selected, onSelect, editable = true }) {
	const navigate = useNavigate();

	const handleOnEditClick = () => {
		navigate(`/activity/list/edit/${urlSafeEncrypt(data.id)}`);
	};

	const handleOnNavigateDetails = () => {
		navigate(`/activity/list/details/${urlSafeEncrypt(data.id)}`);
	};
	return (
		<div className="rounded-lg bg-white p-3 border border-solid border-grey-10">
			<div className="relative mb-3">
				<img
					alt={data?.title}
					src={data.image_desktop}
					loading="lazy"
					className="w-full h-36 xl:h-38 rounded-lg overflow-hidden object-cover object-center"
				/>

				<div className="absolute top-2 left-2">
					<div className="form-check">
						<input
							className="form-check-input appearance-none h-4 w-4 border border-red-40 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
							type="checkbox"
							value=""
							id={`activity-item-${data.id}`}
							checked={selected}
							onChange={e => onSelect({ isSelected: e.target.checked, id: data.id })}
						/>
					</div>
				</div>

				<div className="absolute bg-red-40 text-white top-2 left-8 italic inline-block px-4 py-1 font-semibold text-xs rounded-tl-md rounded-br-md">
					{data?.total_point} Points
				</div>

				{editable && (
					<div className="dropdown absolute top-2 right-2">
						<button
							onClick={handleOnEditClick}
							className="leading-tight w-9 h-9 rounded-full shadow-md bg-white relative flex items-center justify-center text-left"
							type="button">
							<span className="icon-ico-pencil-edit text-xl text-red-50"></span>
						</button>
					</div>
				)}
			</div>
			<div className="flex items-center mb-3">
				<div className="w-8 h-8 rounded-full flex justify-center items-center bg-red-40 text-white">
					<span class={getIconByActivityCategoryId(data?.activity_category?.id)}></span>
				</div>
				<div className="text-red-40 text-sm font-semibold ml-3">{data?.activity_category?.name}</div>
			</div>
			<span
				className="block text-sm text-grey-70 mb-3 font-semibold hover:text-red-50 transition-all line-clamp-2 h-10 cursor-pointer"
				onClick={handleOnNavigateDetails}>
				{convertToTitleCase(data?.title)}
			</span>
			<div className="text-red-40 text-xs font-semibold mb-2">{data?.start_date_formatted}</div>
			<div class={clsx("rounded-lg flex items-center p-4", getColorByLevelId(data?.level_id).backgroundColor)}>
				<div className="overflow-hidden -space-x-2 flex">
					{data?.user_activities.length ? (
						data.user_activities?.map((userActivity, index) => {
							if (userActivity?.user?.profile_picture) {
								return (
									<img
										src={userActivity?.user?.profile_picture}
										alt="User"
										className="w-8 h-8 rounded-full overflow-hidden object-cover object-center"></img>
								);
							}
							return (
								<div
									class={clsx(
										"w-8 h-8 rounded-full opacity-30",
										getColorByLevelId(data?.level_id).backgourndColorMain,
									)}></div>
							);
						})
					) : (
						<>
							<div
								class={clsx(
									"w-8 h-8 rounded-full",
									getColorByLevelId(data?.level_id).backgourndColorMain,
								)}></div>
							<div
								class={clsx(
									"w-8 h-8 rounded-full opacity-30",
									getColorByLevelId(data?.level_id).backgourndColorMain,
								)}></div>
						</>
					)}
				</div>
				<div className="ml-3">
					<div class={clsx("text-sm font-semibold ", getColorByLevelId(data?.level_id).textColor)}>
						{data?.level?.name && convertToTitleCase(data?.level?.name)}
					</div>
					<div className="text-xs font-medium text-main-grey italic">
						{data?.total_joined_user ? `${data?.total_joined_user} User Joined` : "No User Joined"}{" "}
					</div>
				</div>
			</div>
		</div>
	);
}
