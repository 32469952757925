import { useMemo } from "react";

export function BulkSelect({
	children,
	onChange,
	selectedData,
	name,
	onClick,
	emptyMessage = "No data selected",
	placeholder,
	error,
	label,
	labelClassName = "text-sm text-black font-semibold mb-3",
	containerClassName = "mb-4",
	inputParentClassName = "relative",
	inputClassName = "border-grey-10 w-full outline-none border placeholder:italic border-solid pl-11 pr-4 py-3 text-sm text-grey-60 rounded-tl-lg rounded-tr-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none",
}) {
	const data = useMemo(() => selectedData?.filter(item => item.selected), [selectedData]);

	return (
		<div class={containerClassName}>
			<div class={labelClassName}>{label}</div>
			<div className="relative">
				<input
					onClick={onClick}
					type="text"
					name={name}
					value=""
					class={inputClassName}
					placeholder={placeholder}
				/>

				<button type="button" className="absolute border-0 z-10 text-grey-50 text-xl w-7 h-7 top-2.5 left-3">
					<span className="icon-ico-search"></span>
				</button>
			</div>

			{!children && (
				<div className="border border-solid border-grey-10 mt-[-1px] rounded-bl-lg rounded-br-lg p-4">
					<ul className="list-none flex flex-wrap pl-0">
						{data?.map(item => (
							<li
								key={`${item.name}-${item.id}`}
								className="border mr-2 mb-2 border-solid border-red-10 bg-red-hover pl-4 pr-2 min-w-32 py-1 flex items-center justify-between rounded-full text-sm text-grey-70 font-semibold">
								<span className="mr-3">{item.name}</span>
								<button
									className="text-red-50 outline-none text-base leading-3"
									type="button"
									onClick={() => onChange({ ...item, selected: !item.selected })}>
									<span className="icon-ico-x-circle"></span>
								</button>
							</li>
						))}
						{data?.length === 0 && (
							<div className="text-center text-sm text-grey-60 italic">{emptyMessage}</div>
						)}
					</ul>
				</div>
			)}
			{children && typeof children === "function" && children({ data, onChange })}

			{error && <p className="form-input-error">{error}</p>}
		</div>
	);
}
