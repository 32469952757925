export function ButtonLoader({ className = "spinner" }) {
	return (
		<div className={className}>
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
}

export function ButtonSubmit({
	className = " mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold btn-submit disabled:border-red-10 disabled:bg-red-10",
	onClick = () => {},
	disabled = false,
	title = "Submit",
	loading = false,
	type = "submit",
	children,
}) {
	return (
		<button onClick={onClick} className={className} disabled={disabled || loading} type={type}>
			{loading ? <ButtonLoader /> : children ? children : title}
		</button>
	);
}
