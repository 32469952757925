import clsx from "clsx";
import { memo, useRef } from "react";
import { ModalDatePicker } from "components/modal/modal.datepicker";
import { useState } from "react";
import dayjs from "dayjs";
import { SketchPicker } from "react-color";
import { useOnClickOutside } from "hooks/useOnClickOutside";

export const InputComponent = memo(
	({
		onBlur,
		onChange,
		error,
		name,
		value,
		numberOnly = false,
		placeholder = "Placeholder",
		label,
		labelClass = "text-sm text-black font-semibold mb-3",
		containerClass = "mb-4",
		inputClass = "border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none",
		type = "text",
		presetColors = [],
		...props
	}) => {
		const [isOpen, setIsOpen] = useState(false);
		const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
		const colorPickerRef = useRef(null);

		useOnClickOutside(colorPickerRef, () => setIsOpenColorPicker(false));

		if (type === "datepicker-modal") {
			return (
				<>
					<div className={clsx(containerClass)}>
						<div className={labelClass}>{label}</div>
						<div className="relative">
							<input
								name={name}
								type={type}
								className={clsx(inputClass, error && "form-control-error")}
								onChange={onChange}
								onClick={() => setIsOpen(true)}
								onBlur={onBlur}
								value={value ? dayjs(value).format("HH:mm, DD MMMM YYYY") : ""}
								placeholder={placeholder}
								{...props}
							/>
							<button
								className="absolute top-3 right-3 hover:text-red-50"
								onClick={() => setIsOpen(true)}>
								<span className="icon-ico-events"></span>
							</button>
						</div>
						{error && <p className="form-input-error">{error}</p>}
					</div>
					<ModalDatePicker
						initialValue={value}
						visible={isOpen}
						onClose={() => setIsOpen(false)}
						onChange={date => {
							setIsOpen(false);
							onChange({ target: { name, value: date } });
						}}
					/>
				</>
			);
		}
		if (type === "color") {
			return (
				<div className={clsx(containerClass)}>
					<div className={labelClass}>{label}</div>

					<div className="flex items-center flex-row gap-2">
						<div
							className="color-prev h-12 w-12 rounded-lg border cursor-pointer"
							onClick={() => setIsOpenColorPicker(!isOpenColorPicker)}
							style={{ backgroundColor: value }}></div>

						<input
							name={name}
							type="text"
							className={clsx(inputClass, error && "form-control-error")}
							onChange={e => {
								if (!numberOnly) {
									onChange(e);
								} else {
									onChange({ target: { name, value: e.target.value.replace(/[^0-9]/g, "") } });
								}
							}}
							onClick={() => setIsOpenColorPicker(!isOpenColorPicker)}
							onBlur={onBlur}
							value={value}
							placeholder={placeholder}
							{...props}
						/>
					</div>
					{isOpenColorPicker && (
						<div
							className="pb-5 absolute bg-white p-5 h-auto w-auto shadow-lg border rounded-sm"
							ref={colorPickerRef}>
							<button
								type="button"
								className="absolute top-1 right-1 border-0"
								onClick={() => setIsOpenColorPicker(false)}>
								<span className="icon-ico-close"></span>
							</button>
							<SketchPicker
								color={value || "#000000"}
								styles={{
									default: {
										picker: {
											boxShadow: "none",
											backgroundColor: "transparent",
											cursor: "pointer",
										},
									},
								}}
								onChange={color => {
									onChange({ target: { name, value: color.hex } });
								}}
								disableAlpha
								presetColors={presetColors}
							/>
						</div>
					)}

					{error && <p className="form-input-error">{error}</p>}
				</div>
			);
		}
		return (
			<div className={containerClass}>
				<div className={labelClass}>{label}</div>
				<input
					name={name}
					type={type}
					className={clsx(inputClass, error && "form-control-error")}
					onChange={e => {
						if (!numberOnly) {
							onChange(e);
						} else {
							onChange({ target: { name, value: e.target.value.replace(/[^0-9]/g, "") } });
						}
					}}
					onBlur={onBlur}
					value={value}
					placeholder={placeholder}
					{...props}
				/>
				{error && <p className="form-input-error">{error}</p>}
			</div>
		);
	},
);

export function InputPasswordComponent({
	onBlur,
	onChange,
	onToggleShow = () => {},
	showPassword,
	error,
	name,
	value,
	placeholder,
	label,
	labelClass = "text-sm text-black font-semibold mb-3",
	containerClass = "mb-4",
	inputClass = "border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none",
	type = "text",
}) {
	return (
		<div className={containerClass}>
			<div className={labelClass}>{label}</div>
			<div className="relative">
				<input
					name={name}
					type={showPassword ? "text" : "password"}
					className={clsx(inputClass, error && "form-control-error")}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					placeholder={placeholder}
				/>
				<button
					className="bg-white text-lg text-red-50 absolute right-3 top-2.5"
					onClick={() => onToggleShow(!showPassword)}>
					<span className={clsx(showPassword ? "icon-ico-eye-close" : "icon-ico-eye")}></span>
				</button>
			</div>
			{error && <p className="form-input-error">{error}</p>}
		</div>
	);
}
