export function PreviewUploadFileGallery({ imageUrls, onDeleteFile = () => {} }) {
	return (
		<div className="grid grid-cols-4 gap-4 border p-3 rounded-lg h-80 overflow-y-scroll">
			{imageUrls.map((image, index) => (
				<div key={index} className="relative">
					<img
						src={image.url}
						alt="Preview"
						className="w-36 h-28 border object-cover object-center rounded-lg"
					/>
					<button
						className="absolute top-2 right-2 w-8 h-8 bg-white rounded-full flex items-center justify-center"
						onClick={() => onDeleteFile(index, image.id)}>
						<span className="icon-ico-delete text-red-50 text-xl"></span>
					</button>
				</div>
			))}
		</div>
	);
}
