import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";

import Layout from "components/layout";
import { useDeleteMember } from "hooks/member";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { Pagination } from "components/pagination.component";
import { serializeEvent } from "components/text-editor/serializer";
import { IsJsonString } from "utils/string";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { ModalSocialMedia } from "components/modal/modal.social-media";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { UserListTable } from "pages/registration/users/user-table.component";
import { useExportDiscussionTopic, useGetDiscussionList } from "hooks/discussion";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalFilterDiscussion } from "./components/modals/modal.filter-discussion";
import { numberAbbreviator } from "utils/number";
import { loadStaticPublicAsset } from "utils/loader";
import { urlSafeEncrypt } from "utils/encryption";

const sortOptions = [
	{ label: "Latest Created Time", sortType: "desc", sortBy: "created_at" },
	{ label: "Earliest Created Time", sortType: "asc", sortBy: "created_at" },
	{ label: "Most Liked", sortType: "desc", sortBy: "total_liked" },
	{ label: "Least Liked", sortType: "asc", sortBy: "total_liked" },
	{ label: "Most Commented", sortType: "desc", sortBy: "total_commented" },
	{ label: "Least Commented", sortType: "asc", sortBy: "total_commented" },
];
// Main Page Component
export function DiscussionListPage() {
	const { selectedRowKeys, selectedRows } = useSelector(state => state.toolsReducer);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Selected user's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	const [modalKey] = useState(0);

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalSort: false,
		showModalDataFilter: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});
	// Modal Social Media State
	const [modalSocialMedia, setModalSocialMedia] = useState({
		showModal: false,
		data: [],
	});

	const [showModalResult, setShowModalResult] = useState(false);

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "created_at",
		order: "desc",
		search: "",
	});
	// Data filter on body request
	const [dataFilter, setDataFilter] = useState({
		topic_category_ids: [],
		province_ids: [],
		batches: [],
	});

	const isDataFilterActive = useMemo(
		() =>
			Boolean(dataFilter.batches.length) ||
			Boolean(dataFilter.topic_category_ids.length) ||
			Boolean(dataFilter.province_ids.length),
		[dataFilter],
	);

	// Hooks API Call
	const { data, isLoading } = useGetDiscussionList({ filter: queryFilter, body: dataFilter });
	const { mutate: deleteMembers, isLoading: isDeleting } = useDeleteMember();
	const { mutate: exportDataUser, isLoading: isExporting } = useExportDiscussionTopic();

	const getRowKeys = data => {
		let rowKeys = [];
		data.forEach(i => {
			rowKeys.push(i.id);
		});

		return rowKeys;
	};

	const onCheckHandler = (e, value) => {
		let rows = [];

		if (e.target.checked) {
			rows = [...selectedRows, value];
		} else {
			rows = selectedRows.filter(item => item !== value);
		}

		let newSelectedRowKeys = getRowKeys(rows);

		dispatch(setToolsReducer({ selectedRowKeys: newSelectedRowKeys, selectedRows: rows }));
	};

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setDataFilter(prevState => ({
			...prevState,
			...selected,
		}));
		setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
	};

	const handleDeleteMembers = () => {
		const formData = { user_ids: selectedIds };

		deleteMembers(formData, {
			onSuccess: () => {
				setModalConfirmation({
					showModal: false,
					type: null,
					data: null,
				});
				setShowModalResult(true);
				resetCheckList();
			},
		});
	};

	const handleExportData = () => {
		exportDataUser(
			{
				filter: {
					...dataFilter,
					search: queryFilter.search,
					order: queryFilter.order,
					order_by: queryFilter.order_by,
				},
				formData: {
					topic_ids: selectedIds,
					...dataFilter,
				},
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("DiscussionList", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const columns = useMemo(
		() => [
			{
				type: "custom-checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="w-2/5 relative">
							<div className="form-check absolute top-2 bottom-auto left-1 px-2 z-10">
								<input
									className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
									type="checkbox"
									checked={selectedRowKeys.includes(record.id)}
									onChange={e => onCheckHandler(e, record)}
								/>
							</div>

							<div className="flex items-center ">
								<div className="w-28 relative z-0">
									{record?.video_url ? (
										<>
											<video
												src={record?.video_url}
												alt={record?.title}
												preload="auto"
												className="rounded-lg w-32 h-32 overflow-hidden object-cover object-center">
												<source src={record?.video_url} type="video/mp4" />
												<source src={record?.video_url} type="video/webm" />
											</video>
											{record?.status === "draft" && (
												<div className="border border-solid translate-x-[-50%] translate-y-[-50%] border-red-50 bg-red-hover absolute z-10 top-1/2 left-1/2 px-4 py-1 rounded-lg text-sm font-bold italic text-red-50">
													Draft
												</div>
											)}
										</>
									) : (
										<>
											<img
												alt={record?.title}
												src={
													record?.image_desktop || loadStaticPublicAsset("/img/no-image.png")
												}
												className="w-28 h-28 overflow-hidden rounded-lg object-cover object-center"
											/>
											{record?.status === "draft" && (
												<div className="border border-solid translate-x-[-50%] translate-y-[-50%] border-red-50 bg-red-hover absolute z-10 top-1/2 left-1/2 px-4 py-1 rounded-lg text-sm font-bold italic text-red-50">
													Draft
												</div>
											)}
										</>
									)}
								</div>
								<div className="w-[calc(100%-128px)]">
									<div className="px-3">
										<div className="text-sm text-grey-70 font-semibold mb-1 line-clamp-2">
											{record?.title}
										</div>

										{record.description &&
											IsJsonString(record.description) &&
											serializeEvent(
												JSON.parse(record.description),
												{
													paragraph: "text-ellipsis text-grey-60 text-sm mb-2 line-clamp-1",
												},
												{
													paragraph: 100,
												},
											)}
										{/* </p> */}
										<div className="text-sm text-grey-80 italic">
											Created at{" "}
											<span className="text-red-30">{record?.created_at_formatted}</span>
											{record?.status === "draft" && (
												<div className="italic font-bold text-red-50">Draft</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				},
			},

			{
				name: "Category",
				className: " w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "topic_category",
				render: (record, topic_category) => (
					<div className=" overflow-hidden w-[15%]">
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
							{topic_category?.name || "Uncategorized"}
						</div>
					</div>
				),
			},
			{
				name: "Type",
				className: " w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "username_instagram",
				render: record => (
					<div className="w-[15%]">
						<div className="text-sm font-semibold block mb-1 text-grey-70">
							{record?.topic_batches?.[0]?.batch && `Batch ${record?.topic_batches?.[0]?.batch}`}
							{!record?.topic_batches?.[0] && "General"}
							{record?.topic_batches?.length > 1 && (
								<>
									<br />
									dan {record?.topic_batches?.length - 1} batch lainnya...
								</>
							)}
						</div>
						<div className="text-sm block text-grey-60">
							{record?.provinces?.[0]?.name || "-"}
							{record?.provinces?.length > 1 && (
								<>
									<br />
									dan {record?.provinces?.length - 1} regional lainnya...
								</>
							)}
						</div>
					</div>
				),
			},

			{
				name: "Like Comments",
				className: " w-[18%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "username_instagram",
				render: record => (
					<div className="overflow-hidden w-[18%]">
						<div className="flex items-center mb-3">
							<span className="icon-ico-heart text-red-50"></span>
							<div className="text-ellipsis text-sm pl-3 font-medium block overflow-hidden whitespace-nowrap text-black">
								{numberAbbreviator(record?.total_liked)} Likes
							</div>
						</div>
						<div className="flex items-center">
							<span className="icon-ico-bubble text-red-50"></span>
							<div className="text-ellipsis text-sm pl-3 font-medium block overflow-hidden whitespace-nowrap text-black">
								{numberAbbreviator(record?.total_commented)} Comments
							</div>
						</div>
					</div>
				),
			},

			{
				name: "Action",
				className:
					" w-[10%] text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "-",
				render: record => (
					<div className="w-[10%]">
						<ButtonSubmit
							onClick={() => navigate(`/discussion/list/edit-topic/${urlSafeEncrypt(record?.id)}`)}
							className="flex items-center py-1 px-3 w-24 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all mb-2 font-semibold">
							<span className="icon-ico-edit text-red-50 text-xl"></span>
							<span className="pl-2 w-auto">Edit</span>
						</ButtonSubmit>

						<Link to={`/discussion/list/details/${urlSafeEncrypt(record?.id)}`}>
							<span className="uppercase block font-bold text-sm text-red-50 hover:text-red-70">
								See Detail
							</span>
						</Link>
					</div>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[navigate, selectedRowKeys],
	);

	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter, dataFilter]);

	return (
		<Layout breadCumbTitle="Discussion" breadCumbDesc="Discussion List" buttonToTop>
			<div className="flex items-center justify-between mb-4">
				<div className="w-[80%] flex">
					<SearchFilterComponent
						key={`search-filter-${modalKey}`}
						placeholder="Cari Topic..."
						containerClassName="relative md:w-2/5 lg:w-[40%] xl:w-2/5 mr-2"
						onSearch={search => {
							setQueryFilter({ ...queryFilter, search: search.keyword, page: 1 });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={isDataFilterActive}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleExportData} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[20%] justify-end">
					<div className="">
						<ButtonSubmit
							onClick={() => navigate("/discussion/create-topic")}
							className="disabled:opacity-50 flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
							<span className=" icon-ico-circle-plus mr-2"></span> Create Topic
						</ButtonSubmit>
					</div>
				</div>
			</div>

			{!isLoading && (
				<div className="italic text-sm text-grey-60 mb-4">{`Total ${
					data?.data?.total_result || 0
				} Discussions`}</div>
			)}

			<UserListTable key={modalKey} columns={columns} dataSource={data?.data?.rows || []} loading={isLoading} />

			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}
			{!isLoading && (
				<Pagination
					currentPage={queryFilter.page}
					pageSize={queryFilter.limit}
					siblingCount={1}
					onPageChange={selectedPage => {
						window.scrollTo(0, 0);
						setQueryFilter({ ...queryFilter, page: selectedPage });
					}}
					totalCount={data?.data?.total_result || 0}
				/>
			)}

			<ModalSocialMedia
				visible={modalSocialMedia.showModal}
				data={modalSocialMedia.data}
				onClose={() => {
					setModalSocialMedia({ data: [], showModal: false });
				}}
			/>

			<TableLimitModalComponent
				key={`limit-filter-${modalKey}`}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
				visible={tableFilterModal.showModalLimit}
				initialValue={20}
				onChange={selected => {
					setQueryFilter({ ...queryFilter, limit: selected.limit, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<ModalFilterDiscussion
				initialValue={dataFilter}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
					setQueryFilter({ ...queryFilter, page: 1 });
				}}
				visible={tableFilterModal.showModalDataFilter}
				onChange={handleOnFilterDataChange}
			/>

			<TableSortModalComponent
				key={`modal-sort-${modalKey}`}
				initialValue={sortOptions[0]}
				options={sortOptions}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter({ ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteMembers()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus data ini?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Successfully Deleted Members!"
			/>
		</Layout>
	);
}
