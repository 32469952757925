import { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableEventCategoryModal, ButtonEventCategoryFilter } from "components/table/event-category-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { EventItem } from "./component/event-item";

import { useEventList, useDeleteEvent, useExportEvent, useEventCategories } from "hooks/event";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";
import { urlSafeEncrypt } from "utils/encryption";

const sortOptions = [
	{ label: "Tanggal Event Terbaru", sortType: "desc", sortBy: "event_date" },
	{ label: "Tanggal Event Terdahulu", sortType: "asc", sortBy: "event_date" },
	{ label: "Tanggal Buat Event Terbaru", sortType: "desc", sortBy: "created_at" },
	{ label: "Tanggal Buat Event Terdahulu", sortType: "asc", sortBy: "created_at" },
];

// Main Page Component
export function EventListPage() {
	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState(false);

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "created_at",
		order: "desc",
		search: "",
	});
	const [bodyRequestFilter, setBodyRequestFilter] = useState({ event_category_id: null });

	// Hooks API Call
	const { data, refetch: refetchEvent, isLoading } = useEventList({ filter: queryFilter, body: bodyRequestFilter });
	const { data: eventCategories, isSuccess: isCategoriesAvailable } = useEventCategories();
	const { mutate: deleteEvent, isLoading: isDeleting } = useDeleteEvent();
	const { mutate: exportEvent, isLoading: isExporting } = useExportEvent();

	const handleDeleteEvent = () => {
		deleteEvent(
			{ eventId: urlSafeEncrypt(modalConfirmation.data.id) },
			{
				onSuccess: () => {
					refetchEvent();
					setModalConfirmation({ data: null, showModal: false });
					setShowModalResult(true);
				},
			},
		);
	};

	const handleExportData = () => {
		exportEvent(
			{
				order: queryFilter.order,
				order_by: queryFilter.order_by,
				search: queryFilter.search,
				event_category_id: bodyRequestFilter.event_category_id,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("EventList", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	return (
		<Layout breadCumbTitle="Event" breadCumbDesc="Event List" buttonToTop>
			<div className="flex items-center justify-between mb-7">
				<div className="sm:w-[75%] xl:w-[75%] flex">
					<SearchFilterComponent
						containerClassName="relative sm:w-1/3 md:w-[40%] xl:w-2/5 mr-2"
						placeholder="Cari Event..."
						onSearch={search => {
							setQueryFilter(prevState => ({ ...prevState, search: search.keyword }));
						}}
					/>

					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonEventCategoryFilter
							active={bodyRequestFilter.event_category_id !== null}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleExportData} loading={isExporting} />
					</div>
				</div>

				<div className="flex sm:w-[25%] lg:w-[19%] justify-end">
					<div className="">
						<Link to="/event/create">
							<button
								className="flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
								type="button">
								<span className="icon-ico-circle-plus mr-2"></span> Create Event
							</button>
						</Link>
					</div>
				</div>
			</div>

			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}

			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="grid grid-cols-3 xl:grid-cols-4 gap-4">
					{data?.data?.rows.map(item => (
						<EventItem
							key={item.id}
							{...item}
							onDelete={() => {
								setModalConfirmation({
									data: item,
									showModal: true,
								});
							}}
						/>
					))}
				</div>
			)}

			{isCategoriesAvailable && (
				<TableEventCategoryModal
					options={[{ id: null, name: "All Categories" }, ...eventCategories?.data]}
					visible={tableFilterModal.showModalFilter}
					onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
					onChange={({ selected }) => {
						setBodyRequestFilter({ event_category_id: selected?.id || null });
						setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
					}}
				/>
			)}

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				message={`Are you sure want to delete event "${modalConfirmation.data?.title}"?`}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteEvent()}
				loading={isDeleting}
			/>
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Delete event success!"
			/>
			<TableSortModalComponent
				options={sortOptions}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</Layout>
	);
}
