import { useState } from "react";

import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";

import { useDataBeautyProfile, useDeleteSkinItem, useDeleteSkinType } from "hooks/general-settings";

import { SkinType } from "./components/skin-type";
import { ModalAddTypeSkin } from "./components/modal-add-type";
import { ModalAddTypeSkinItem } from "./components/modal-add-skin";
import { urlSafeEncrypt } from "utils/encryption";

// Main Page Component
export function BeautyProfileSetting() {
	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		data: null,
	});

	// Modal Add New SkinType State
	const [modalAddSkinType, setModalAddSkinType] = useState({
		showModal: false,
		operation: "ADD",
		data: null,
	});

	// Modal Add New Skin Item State
	const [modalSkinItem, setModalSkinItem] = useState({
		showModal: false,
		operation: "ADD",
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	// Hooks API Call
	const { data, refetch: refetchBeautyProfile, isLoading } = useDataBeautyProfile();
	const { mutate: deleteSkinItem, isLoading: isDeleting } = useDeleteSkinItem();
	const { mutate: deleteSkinType, isLoading: isDeletingType } = useDeleteSkinType();

	const handleDelete = (deleteType, data) => {
		const onSuccessOperation = {
			onSuccess: () => {
				refetchBeautyProfile();
				setModalConfirmation({ data: null, showModal: false });
				setShowModalResult({ showModal: true, message: "Successfully deleted data!" });
			},
		};

		if (deleteType === "SKIN_ITEM") {
			deleteSkinItem({ skinItemId: urlSafeEncrypt(modalConfirmation.data.id) }, onSuccessOperation);
		}
		if (deleteType === "SKIN_TYPE") {
			deleteSkinType({ skinTypeId: urlSafeEncrypt(modalConfirmation.data.id) }, onSuccessOperation);
		}
	};

	return (
		<Layout breadCumbTitle="General Setting" breadCumbDesc="Beauty Profile">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="bg-white rounded-lg drop-shadow-main filter-none p-6">
					{data?.data?.map(skinType => (
						<SkinType
							key={skinType.name}
							{...skinType}
							onEditItem={({ skinItemId }) => {
								setModalSkinItem({ showModal: true, operation: "EDIT", data: { skinItemId } });
							}}
							onEdit={() => setModalAddSkinType({ showModal: true, operation: "EDIT", data: skinType })}
							onAddNewItem={() =>
								setModalSkinItem({
									showModal: true,
									operation: "ADD",
									data: { skinTypeId: skinType.id },
								})
							}
							onDelete={() =>
								setModalConfirmation({ showModal: true, data: skinType, type: "SKIN_TYPE" })
							}
							onDeleteItem={item =>
								setModalConfirmation({ showModal: true, data: item, type: "SKIN_ITEM" })
							}
						/>
					))}

					<div className="flex my-9">
						<div className="w-1/4">
							<button
								onClick={() =>
									setModalAddSkinType(prevData => ({
										...prevData,
										showModal: true,
										operation: "ADD",
									}))
								}
								className="
                    mt-5 w-full flex items-center justify-center py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
								type="button">
								<span className="icon-ico-circle-plus mr-2"></span> Create New Type
							</button>
						</div>
					</div>
				</div>
			)}

			<ModalAddTypeSkinItem
				visible={modalSkinItem.showModal}
				operation={modalSkinItem.operation}
				data={modalSkinItem.data}
				editId={urlSafeEncrypt(modalSkinItem.data?.skinItemId)}
				onSuccess={responseMessage => {
					refetchBeautyProfile();
					setModalSkinItem(prev => ({ ...prev, showModal: false }));
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: responseMessage,
					}));
				}}
				onClose={() => setModalSkinItem(prev => ({ ...prev, showModal: false }))}
			/>

			<ModalAddTypeSkin
				visible={modalAddSkinType.showModal}
				operation={modalAddSkinType.operation}
				data={{
					...modalAddSkinType.data,
					id: urlSafeEncrypt(modalAddSkinType.data?.id),
				}}
				onSuccess={responseMessage => {
					refetchBeautyProfile();
					setModalAddSkinType(prev => ({ ...prev, showModal: false }));
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: responseMessage,
					}));
				}}
				onClose={() => setModalAddSkinType(prev => ({ ...prev, showModal: false }))}
			/>

			<ModalConfirmation
				buttons={[]}
				message={`Are you sure want to delete "${modalConfirmation.data?.name}"?`}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDelete(modalConfirmation.type, modalConfirmation.data)}
				loading={isDeleting || isDeletingType}
			/>
			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
