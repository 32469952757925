import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";

import { PageSettingLayout } from "../layout";

import TextEditor from "components/text-editor";
import {
	ImageCompononentValidationSchema,
	TitlePageSettingValidationSchema,
	WEBSITE_URL_REG_EXP,
} from "config/form/schema/page-setting";
import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { InputComponent } from "components/form/input.component";

import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { UploadBannerComponent } from "../upload-banner.component";
import { IsJsonString } from "utils/string";

const formikInitialValues = {
	is_visible: false,
	title: "",
	description: undefined,
	image_desktop: null,
	image_desktop_file: null,
	image_mobile: null,
	image_mobile_file: null,
	section_redirect_title: "",
	section_redirect_url: null,
};

const PopBannerValidationSchema = Yup.object().shape({
	...TitlePageSettingValidationSchema.fields,
	...ImageCompononentValidationSchema.fields,
	section_redirect_url: Yup.string().nullable().matches(WEBSITE_URL_REG_EXP, "URL is not valid"),
});

export function PopupBannerSetting() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "pop-up-banner", slug: "pop-up-banner" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [imageFileBuffer, setImageFileBuffer] = useState({ image_desktop: null, image_mobile: null });
	const [showModalResult, setShowModalResult] = useState(false);

	useEffect(() => {
		Object.keys(formikInitialValues).forEach(key => {
			if (data?.data?.[key]) {
				if (data?.data?.[key] === "") {
					formikRef.current.setFieldValue(key, null);
				} else {
					formikRef.current.setFieldValue(key, data?.data?.[key]);
				}
			}
		});
	}, [data?.data]);

	const handleSubmitForm = async payloadData => {
		try {
			if (Object.values(imageFileBuffer).some(value => value)) {
				const images = Object.keys(imageFileBuffer)
					.filter(itemKey => imageFileBuffer[itemKey])
					.map(itemKey => ({
						version: itemKey.replace("image_", ""),
						file: imageFileBuffer[itemKey],
					}));

				const imagePromise = images.map(image => {
					const formData = new FormData();
					formData.append("image", image.file);
					formData.append("version", image.version);
					formData.append("type", "banner");
					return uploadImageAsync({ formData, uploadId: `image_${image.version}` });
				});

				const res = await Promise.all(imagePromise);

				payloadData.image_desktop =
					res.find(item => item.uploadId === "image_desktop")?.data?.image_desktop ||
					payloadData.image_desktop;
				payloadData.image_mobile =
					res.find(item => item.uploadId === "image_mobile")?.data?.image_mobile || payloadData.image_mobile;
			}

			updatePageSetting(
				{ page: "pop-up-banner", slug: "pop-up-banner", formData: payloadData },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setImageFileBuffer({ image_desktop: null, image_mobile: null });
					},
				},
			);
		} finally {
		}
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="BANNER"
			desc="Popup Banner">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik
						innerRef={formikRef}
						initialValues={formikInitialValues}
						onSubmit={handleSubmitForm}
						validationSchema={PopBannerValidationSchema}>
						{({
							values,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							errors,
							touched,
							setValues,
						}) => {
							return (
								<>
									<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
										<div className="flex items-center">
											<div className="text-2xl text-black font-semibold">Popup Banner</div>
											<div
												class={clsx(
													"text-base italic  ml-5",
													clsx(values.is_visible ? "text-main-green" : "text-red-50"),
												)}>
												{values.is_visible ? "Appear" : "Hide"} At Every Page
											</div>
										</div>
										<div className="flex flex-row-reverse items-center">
											<ToogleSwitchComponent
												inputClassName="toggle-checkbox"
												labelClassName="toggle enable"
												onChange={handleChange}
												name="is_visible"
												checked={values.is_visible}
											/>
										</div>
									</div>
									<div className="flex justify-between py-4 px-6">
										<div className="w-[47%]">
											<UploadBannerComponent
												initialImage={values?.image_desktop}
												name="image_desktop"
												onChange={data => {
													if (data.image_desktop) {
														setFieldValue("image_desktop_file", data.image_desktop);
														setImageFileBuffer(prevState => ({ ...prevState, ...data }));
													} else {
														setValues({
															...values,
															image_desktop: null,
															image_desktop_file: null,
														});
													}
												}}
												title="Desktop Banner (Aspect Ration 1:1)"
											/>
										</div>
										<div className="w-[47%]">
											<UploadBannerComponent
												initialImage={values?.image_mobile}
												name="image_mobile"
												onChange={data => {
													if (data.image_mobile) {
														setFieldValue("image_mobile_file", data.image_mobile);
														setImageFileBuffer(prevState => ({ ...prevState, ...data }));
													} else {
														setValues({
															...values,
															image_mobile: null,
															image_mobile_file: null,
														});
													}
												}}
												title="Mobile Banner (Aspect Ration 1:1)"
											/>
										</div>
									</div>
									<div className="flex justify-between py-4 px-6">
										<div className="w-[47%]">
											<InputComponent
												containerClass="mb-4 px-6"
												labelclassName="text-sm text-black font-semibold mb-3"
												label="Title"
												placeholder="insert title"
												name="title"
												value={values.title}
												onBlur={handleBlur}
												error={touched.title && errors.title}
												onChange={handleChange}
											/>
											<div className="mb-4 px-6">
												<div className="text-sm text-black font-semibold mb-3">Description</div>
												<TextEditor
													initialValue={
														IsJsonString(data?.data?.description)
															? data?.data?.description
															: undefined
													}
													onChange={nodeValue => setFieldValue("description", nodeValue)}
												/>
											</div>
										</div>
										<div className="w-[47%]">
											<InputComponent
												containerClass="mb-4 px-6"
												labelclassName="text-sm text-black font-semibold mb-3"
												label="Title Link / URL"
												placeholder="to describe the link"
												name="section_redirect_title"
												value={values.section_redirect_title}
												onChange={handleChange}
											/>
											<InputComponent
												containerClass="mb-4 px-6"
												labelclassName="text-sm text-black font-semibold mb-3"
												label="Link/URL"
												placeholder="Link to page / external url"
												name="section_redirect_url"
												onBlur={handleBlur}
												error={touched.section_redirect_url && errors.section_redirect_url}
												value={values.section_redirect_url}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="flex justify-center my-9">
										<div className="w-2/6">
											<ButtonSubmit
												disabled={!PopBannerValidationSchema.isValidSync(values)}
												onClick={handleSubmit}
												loading={isUploading || isUpdating}
												title="Save All Changes"
												className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
											/>
										</div>
									</div>
								</>
							);
						}}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
