import clsx from "clsx";
import { useState } from "react";

import { ModalContainer } from "components/modal/modal.container";

const limitOptions = [20, 50, 100, 250, 500];

export function TableLimitModalComponent({ visible, initialValue, onClose, onChange, value }) {
	const [selected, setSelected] = useState(initialValue || limitOptions[0]);

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Show Item</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				{limitOptions.map(limit => (
					<div className="popup-item mb-3 " key={limit}>
						<input
							type="radio"
							name="limit"
							className="hidden"
							checked={value ? limit === value : limit === selected}
						/>
						<label
							onClick={() => setSelected(limit)}
							className="flex relative cursor-pointer justify-between text-grey-60 text-sm font-semibold items-center p-4 border border-solid border-grey-10 rounded-lg">
							<span>{`Tampilkan ${limit} Item`}</span>
						</label>
					</div>
				))}

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ limit: selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}

export function ButtonLimitComponent({ value, disabled, onClick }) {
	return (
		<button
			className="disabled:opacity-50 flex items-center py-2 px-3 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold overflow-hidden whitespace-nowrap text-ellipsis"
			type="button"
			disabled={disabled}
			onClick={onClick}>
			<span className="icon-ico-eye text-red-50 text-xl"></span>
			<span
				className="pl-1 
                overflow-hidden
                whitespace-nowrap
                text-ellipsis">
				{`Show ${value} Items`}
			</span>
		</button>
	);
}
