import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "components/layout";
import TextEditor from "components/text-editor";
import { useGetAwsTokenForUploadS3, useUploadToS3 } from "hooks/upload";
import { ButtonSubmit } from "components/button/submit.button";

import { UploadBannerComponent } from "pages/page-settings/upload-banner.component";
import { useDropzone } from "react-dropzone";
import {
	generateUploadingStateData,
	ModalUploadingWithList,
	UPLOADING_STATE_STATUS,
} from "components/modal/modal.uploading";
import { CreateGallerySchema } from "config/form/schema/page-setting";
import { useEditGallery, useGetGalleryDetail } from "hooks/page-settings";
import { PreviewUploadFileGallery } from "./component/preview-image.component";
import { IsJsonString } from "utils/string";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalResult } from "components/modal/modal.result";

const formInitialValues = {
	title: "",
	thumbnail: null,
	images_urls: [],
	description: "",
	is_published: "",
	status: "",
};

const MAX_FILE_COMPRESSION_SIZE_MB = 2;

export function EditGallery() {
	const formikRef = useRef();

	const { galleryId } = useParams();
	const navigate = useNavigate();
	const [files, setFiles] = useState([]);
	const [mediaGallery, setMediaGallery] = useState([]);
	const [imageFileBuffer, setImageFileBuffer] = useState(null);
	const [uploadingStateData, setUploadingStateData] = useState([]);
	const [modalUploading, setModalUploading] = useState({
		visible: false,
		message: "",
	});

	const [modalResut, setModalResult] = useState({
		visible: false,
		message: "Gallery succesfully updated!",
	});

	const [imageIdToDelete, setImageIdToDelete] = useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		maxSize: 5 * 1024 * 1024,

		accept: {
			"image/jpeg": [],
			"image/png": [],
		},
		onDropAccepted: files => {
			setFiles(prev => {
				const existingFiles = prev.map(file => file.name);
				const newFiles = files.filter(file => !existingFiles.includes(file.name));
				return [...prev, ...newFiles];
			});

			const mediaPreview = files.map(file => {
				return {
					name: file.name,
					url: URL.createObjectURL(file),
					type: file.type?.split("/")[0],
				};
			});
			setMediaGallery(prev => {
				const existingPreviews = prev.map(media => media.name);
				const newPreviews = mediaPreview.filter(preview => !existingPreviews.includes(preview.name));
				return [...prev, ...newPreviews];
			});
		},
		onDropRejected: files => {
			const fileNameAndErrorMessage = files.map(
				errorFile => `${errorFile.file.name} - ${errorFile.errors[0].message}`,
			);
			toast.error(`${fileNameAndErrorMessage.join("\n\n ")}`);
		},
	});

	const { data: galleryDetail, isLoading } = useGetGalleryDetail(galleryId);
	const { mutate: editGallery, isLoading: isSubmitting } = useEditGallery();
	const { mutateAsync: getS3BucketToken } = useGetAwsTokenForUploadS3();
	const { mutateAsync: uplaodDataToS3, isLoading: isUploading } = useUploadToS3();

	const handleSubmitData = async payloadData => {
		try {
			if (imageFileBuffer || files.length > 0) {
				setModalUploading({
					visible: true,
					message: "Preparing to upload images...",
				});
			}
			if (imageFileBuffer) {
				const formData = new FormData();
				const fileName = imageFileBuffer.name;
				const thumbnailUploadToken = await getS3BucketToken({
					files: [
						{
							name: fileName,
							type: "thumbnail",
						},
					],
				});
				const thumbnailPreSignedURL = thumbnailUploadToken.data?.[0]?.fields;

				for (const key in thumbnailPreSignedURL) {
					formData.append(key, thumbnailPreSignedURL[key]);
				}

				const compressedFile = await imageCompression(imageFileBuffer, {
					maxSizeMB: MAX_FILE_COMPRESSION_SIZE_MB,
					maxIteration: 10,
					exifOrientation: 1,
				});

				formData.append("file", compressedFile);
				setModalUploading(prev => ({
					...prev,
					message: "Uploading thumbnail...",
				}));

				await uplaodDataToS3({ formData, url: thumbnailUploadToken.data?.[0]?.url });
				payloadData.thumbnail = {
					url: thumbnailUploadToken.data?.[0]?.url + thumbnailPreSignedURL.key,
				};
			}

			if (files.length > 0) {
				const uploadResultsWithFilenames = [];

				// Request to upload images
				const galleryImagesUploadToken = await getS3BucketToken({
					files: files.map(file => ({
						name: file.name,
						type: "ordinary",
					})),
				});
				const uploadingState = generateUploadingStateData(files);
				setModalUploading(prev => ({
					...prev,
					message: null,
				}));
				setUploadingStateData(uploadingState);

				const mapKeyGalleryImages = new Map();
				const mapKeyWithURL = new Map();
				for (const uploadToken of galleryImagesUploadToken.data) {
					const key = uploadToken.filename;
					mapKeyGalleryImages.set(key, uploadToken.fields);
					mapKeyWithURL.set(key, uploadToken.url);
				}

				for (const file of files) {
					const url = mapKeyWithURL.get(file.name);

					const signedFieldsData = mapKeyGalleryImages.get(file.name);
					const formData = new FormData();
					for (const key in signedFieldsData) {
						formData.append(key, signedFieldsData[key]);
					}
					const fileIndex = uploadingState.findIndex(state => state.fileName === file.name);

					setUploadingStateData(prev => {
						const updatedState = [...prev];
						updatedState[fileIndex].status = UPLOADING_STATE_STATUS.COMPRESSING;
						return updatedState;
					});
					const compressedGalleryFile = await imageCompression(file, {
						maxSizeMB: MAX_FILE_COMPRESSION_SIZE_MB,
						maxIteration: 10,
						exifOrientation: 1,
					});

					formData.append("file", compressedGalleryFile);

					setUploadingStateData(prev => {
						const updatedState = [...prev];
						updatedState[fileIndex].status = UPLOADING_STATE_STATUS.PROCESSING;
						return updatedState;
					});
					try {
						await uplaodDataToS3({
							formData,
							url,
						});

						const FINAL_URL = url + signedFieldsData.key;

						uploadResultsWithFilenames.push({
							url: FINAL_URL,
							fileName: file.name,
						});

						setUploadingStateData(prev => {
							const updatedState = [...prev];
							updatedState[fileIndex].status = UPLOADING_STATE_STATUS.COMPLETE;
							return updatedState;
						});
					} catch (error) {
						setUploadingStateData(prev => {
							const updatedState = [...prev];
							updatedState[fileIndex].status = UPLOADING_STATE_STATUS.ERROR;
							return updatedState;
						});
					}
				}

				const uploadResultURLs = uploadResultsWithFilenames.map(result => result.url);
				payloadData.images_urls = [
					...payloadData.images_urls,
					...uploadResultURLs.filter(Boolean).map(url => ({ url })),
				];
			}
			payloadData.remove_image_ids = imageIdToDelete;

			editGallery(
				{
					galleryId,
					body: payloadData,
				},
				{
					onSuccess: () => {
						if (files.length === 0 && !imageFileBuffer) {
							setModalResult(prev => ({ ...prev, visible: true }));
						}
					},
				},
			);
		} catch (error) {
			toast.error(error?.message || error.response?.data?.message || "Something went wrong");
		}
	};

	const handleOnModalUploadingClose = () => {
		setModalUploading(prev => ({
			...prev,
			visible: false,
		}));

		setUploadingStateData([]);
		setModalResult(prev => ({ ...prev, visible: true }));
	};

	const handleSubmitForm = eventStatus => {
		formikRef.current.setFieldValue("status", eventStatus);
		formikRef.current.handleSubmit();
	};

	const handleOnDeleteImage = (index, imageId) => {
		setFiles(files.filter((_, i) => i !== index));
		setMediaGallery(mediaGallery.filter((_, i) => i !== index));
		if (imageId) {
			setImageIdToDelete(prev => [...prev, imageId]);
			formikRef.current.setFieldValue(
				"images_urls",
				formikRef.current.values.images_urls.filter(image => image.id !== imageId),
			);
		}
	};

	useEffect(() => {
		if (galleryDetail) {
			const images = galleryDetail.data?.images
				.filter(image => !image.is_default)
				.map(image => ({ url: image.url, id: image.id }));

			const imageThumbnail = galleryDetail.data?.images.filter(image => image.is_default)?.[0];

			formikRef.current.setValues({
				title: galleryDetail.data?.title,
				description: galleryDetail.data?.description,
				thumbnail: imageThumbnail,
				images_urls: images,
				status: galleryDetail.data?.status,
			});
			setMediaGallery(images);
		}
	}, [galleryDetail]);

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-5"
			breadCumbTitle="Gallery"
			breadCumbDesc="Edit Gallery">
			<div className="text-title text-black font-semibold">Edit Gallery</div>
			<hr className="border-grey-10 mt-3 mb-7" />

			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<Formik
					validationSchema={CreateGallerySchema}
					innerRef={formikRef}
					initialValues={formInitialValues}
					onSubmit={handleSubmitData}>
					{({ values, handleBlur, handleChange, touched, errors, handleSubmit, setFieldValue }) => {
						return (
							<>
								<div className="mb-4">
									<div className="text-sm text-black font-semibold">Gallery Title</div>
									<div className="w-full">
										<input
											name="title"
											value={values.title}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											className="border-grey-10 outline-none w-full border-l-0 border-t-0 border-r-0 border-b text-2xl border-solid px-4 py-3 transition-all focus:bg-white focus:border-t-0 focus:border-r-0 focus:border-l-0 hover:border-t-0 hover:border-r-0 hover:border-l-0 focus:text-black focus:border-red-50 focus:outline-none focus:shadow-none"
											placeholder="Write gallery title here"
										/>
										{errors.title && touched.title && (
											<p className="form-input-error">{errors.title}</p>
										)}
									</div>
								</div>
								<div className="flex justify-between">
									<div className="w-[48%]">
										<UploadBannerComponent
											imageClassName="w-full h-96 object-cover object-center"
											name="image_desktop"
											initialImage={values.thumbnail?.url}
											onChange={imageFileBuffer => {
												setImageFileBuffer(imageFileBuffer["image_desktop"]);
												formikRef.current.setFieldValue(
													"image_buffer",
													imageFileBuffer["image_desktop"],
												);
											}}
											title="Thumbnail"
										/>

										<div className="text-sm text-black font-semibold mb-3">Description</div>
										<TextEditor
											initialValue={
												IsJsonString(values.description) ? JSON.parse(values.description) : null
											}
											onChange={nodeValue => setFieldValue("description", nodeValue)}
										/>
									</div>
									<div className="w-[48%]">
										<div className="mb-4">
											<div className="text-sm text-black font-semibold mb-3">Images</div>

											<button
												{...getRootProps({ className: "drag-item mb-4 cursor-pointer" })}
												type="button"
												className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
												<input {...getInputProps()} />
												<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>

												<span className="mt-2 block text-sm font-semibold text-gray-900">
													<span className="text-red-50">Click to upload </span>or drag and
													drop
												</span>
												<span className="mt-2 block text-sm font-semibold text-gray-900">
													JPG, JPEG, and PNG (MAX 5MB)
												</span>
											</button>
										</div>
										<div className="mb-4">
											<div className="text-sm text-black font-semibold mb-3">Gallery Images</div>
											{mediaGallery.length > 0 && (
												<PreviewUploadFileGallery
													imageUrls={mediaGallery}
													onDeleteFile={handleOnDeleteImage}
												/>
											)}
										</div>
									</div>
								</div>

								<div className="flex items-center justify-center my-9">
									<div className="w-3/12 mx-1">
										<ButtonSubmit
											loading={isUploading || isSubmitting}
											disabled={
												!CreateGallerySchema.isValidSync(values) ||
												!values.thumbnail ||
												!mediaGallery.length
											}
											onClick={() => handleSubmitForm("draft")}
											className="flex items-center justify-center w-full px-3 disabled:cursor-not-allowed py-2 font-semibold text-black transition-all border border-solid rounded-lg disabled:opacity-50 border-grey-10 hover:bg-red-hover hover:border-red-50"
											type="button">
											<span className="icon-ico-sort text-red-50 text-xl"></span>
											<span className="pl-3">Save as Draft</span>
										</ButtonSubmit>
									</div>
									<div className="w-3/12 mx-1">
										<ButtonSubmit
											disabled={
												!CreateGallerySchema.isValidSync(values) ||
												!values.thumbnail ||
												!mediaGallery.length
											}
											loading={isUploading || isSubmitting}
											onClick={() => handleSubmitForm("publish")}
											className="disabled:opacity-50 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
											type="button">
											<span className="icon-ico-save mr-2"></span> Update Gallery
										</ButtonSubmit>
									</div>
								</div>
							</>
						);
					}}
				</Formik>
			)}

			<ModalResult
				visible={modalResut.visible}
				title={false}
				message={modalResut.message}
				onClose={() => {
					setModalResult(prev => ({ ...prev, visible: false }));
					navigate("/page-setting/gallery");
				}}
			/>

			<ModalUploadingWithList
				title="Updating Gallery"
				visible={modalUploading.visible}
				message={modalUploading.message}
				uploadingData={uploadingStateData}
				onClose={handleOnModalUploadingClose}
			/>
		</Layout>
	);
}
