import { useState } from "react";
import { Player } from "video-react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { ImageWithZoom } from "components/image/image-zoom.component";

const getDropzoneErrorByCode = code => {
	switch (code) {
		case "file-invalid-type":
			return "File type is not supported, only images and videos are allowed";
		case "file-too-large":
			return "File size is too large, maximum file size is 100MB";
		case "too-many-files":
			return "Too many files, only one file is allowed";

		case "file-too-small":
			return "File size is too small";
		default:
			return `Only one file and only image/video file is allowed`;
	}
};

export function UploadTopicMedia({ onChange, initialValue }) {
	const [mediaPreview, setMediaPreview] = useState(initialValue || { url: null, type: null });
	const [error, setError] = useState(null);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		multiple: false,
		maxSize: 100000000,

		accept: {
			"image/jpeg": [],
			"image/png": [],
			"video/*": [],
		},
		onDropAccepted: files => {
			onChange({
				url: URL.createObjectURL(files[0]),
				fileBuffer: files[0],
			});
			setMediaPreview({
				url: URL.createObjectURL(files[0]),
				type: files[0].type?.split("/")[0],
			});
			setError(null);
		},
		onDropRejected: files => {
			setError(getDropzoneErrorByCode(files[0].errors[0].code));
		},
	});

	const handleOnDeleteFile = () => {
		onChange(null);
		setMediaPreview({ url: null, type: null });
		acceptedFiles.length = 0;
	};

	return (
		<>
			<div className="text-sm text-grey-70 font-semibold mb-3">Upload Media</div>
			{mediaPreview?.url ? (
				<div
					class={clsx(
						"relative h-auto overflow-hidden rounded-xl mb-4",
						mediaPreview.type === "video" ? "w-2/3" : "w-80",
					)}>
					{mediaPreview.type === "video" ? (
						<Player playsInline src={mediaPreview.url} aspectRatio="16:9" />
					) : (
						<ImageWithZoom src={mediaPreview.url} className="max-w-full" alt="Media Preview" />
					)}

					<button
						onClick={handleOnDeleteFile}
						className="absolute shadow-md top-2 right-3 w-10 h-10 leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
						<span className="icon-ico-delete"></span>
					</button>
				</div>
			) : (
				<div {...getRootProps({ className: "drag-item mb-4 cursor-pointer" })}>
					<input {...getInputProps()} />
					<label
						for="upload-file"
						className="border border-solid border-grey-10 rounded-lg min-h-[200px] py-10 text-center items-center flex justify-center flex-col">
						<div className="w-auto mb-4">
							<div className="text-center">
								<img src="/img/drag-icon.png" className="block" alt="Drag media" />
							</div>
						</div>
						<div className="text-center text-sm text-grey-70 font-semibold">
							Drag and drop image or video file
						</div>
						<div className="flex justify-center mt-5 mb-5">
							<label
								className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
								for="upload">
								<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
								<span className="pl-2">Upload</span>
							</label>
						</div>
					</label>
					{error && <p className="form-input-error">{error}</p>}
				</div>
			)}
		</>
	);
}
