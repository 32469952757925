import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { loadStaticPublicAsset } from "utils/loader";
import pjson from "../../package.json";

export const SidebarComponent = () => {
	const location = useLocation();
	const { pathname } = location;

	const [sidebarItems, setSidebarItems] = useState([
		{
			menuLabel: "User Register",
			key: "user-registration",
			isExpand: false,
			parentPath: "/registration",
			iconClassName: "icon-ico-user-register text-lg mr-3",
			child: [
				{
					label: "USER REG. LIST",
					key: "user-reg-list",
					path: "/registration/users",
				},
				{
					label: "REGISTRATION TEST",
					key: "user-reg-test",
					path: "/registration/test",
				},
				{
					label: "REG. SETTING",
					key: "user-reg-setting",
					path: "/registration/setting",
				},
			],
		},
		{
			menuLabel: "Activity",
			key: "activity",
			iconClassName: "icon-ico-activity-menu text-lg mr-3",
			isExpand: false,
			path: "/",
			parentPath: "/activity",
			child: [
				{
					label: "activity list",
					key: "activity-list",
					path: "/activity/list",
					childParentPath: "/activity/list",
				},
				{
					label: "create activity",
					key: "activity-create",
					path: "/activity/create",
				},
			],
		},
		{
			menuLabel: "User",
			key: "menu-user",
			iconClassName: "icon-ico-user text-lg mr-3",
			isExpand: false,
			parentPath: "/member",
			path: "/member",
			child: [],
		},
		{
			menuLabel: "Event",
			key: "menu-event",
			iconClassName: "icon-ico-events text-lg mr-3",
			isExpand: false,
			parentPath: "/event",
			path: "/event/list",
			child: [],
		},
		{
			menuLabel: "Discussion",
			key: "discussion",
			iconClassName: "icon-ico-bubble text-lg mr-3",
			isExpand: false,
			path: "/",
			parentPath: "/discussion",
			child: [
				{
					label: "Discussion List",
					key: "disucssion-list",
					path: "/discussion/list",
					childParentPath: "/discussion/list",
				},
				{
					label: "Create topic",
					key: "discussion-create-topic",
					path: "/discussion/create-topic",
				},
				{
					label: "Category",
					key: "discussion-category",
					path: "/discussion/category",
				},
			],
		},
		{
			menuLabel: "Reward",
			key: "reward",
			iconClassName: "icon-ico-reward text-lg mr-3",
			isExpand: false,
			path: "/",
			parentPath: "/reward",
			child: [
				{
					label: "Reward List",
					key: "rewards-list",
					path: "/reward/list",
					childParentPath: "/reward/list",
				},
				{
					label: "Item Reward",
					key: "item-reward",
					path: "/reward/item-reward",
				},
				{
					label: "claim reward",
					key: "claim-reward",
					path: "/reward/claim",
				},
			],
		},
		{
			menuLabel: "Page Setting",
			key: "menu-page-setting",
			iconClassName: "icon-ico-page text-lg mr-3",
			parentPath: "/page-setting",
			isExpand: false,
			child: [
				{
					label: "HOME",
					key: "page-setting-home",
					childParentPath: "/page-setting/home",
					path: "/page-setting/home/running-text",
				},
				{
					label: "ABOUT",
					key: "page-setting-about",
					childParentPath: "/page-setting/about",
					path: "/page-setting/about/rubi-about",
				},

				{
					label: "rubi ambassador",
					key: "page-setting-rubi-ambassador",
					childParentPath: "/page-setting/ambassador",
					path: "/page-setting/ambassador/community",
				},
				{
					label: "banner",
					key: "page-setting-banner",
					childParentPath: "/page-setting/banner",
					path: "/page-setting/banner/popup-banner",
				},
				{
					label: "gallery",
					key: "page-setting-gallery",
					childParentPath: "/page-setting/gallery",
					path: "/page-setting/gallery",
				},
			],
		},
		{
			menuLabel: "General Setting",
			key: "menu-general-setting",
			iconClassName: "icon-ico-setting text-lg mr-3",
			isExpand: false,
			parentPath: "/general-setting",
			path: "/",
			child: [
				{
					label: "beauty profile",
					key: "page-general-setting",
					path: "/general-setting/beauty-profile",
				},
				{
					label: "level & batch",
					key: "page-general-setting-level-batch",
					path: "/general-setting/level-batch",
				},
				{
					label: "user level point",
					key: "page-general-setting-user-level-point",
					path: "/general-setting/user-level-point",
				},
				{
					label: "footer",
					key: "page-general-setting-user-footer",
					path: "/footer/logo",
				},
			],
		},
	]);

	const handleExpandSidebar = key => {
		const newSidebarItems = [...sidebarItems];
		const index = newSidebarItems.findIndex(item => item.key === key);
		newSidebarItems[index].isExpand = !newSidebarItems[index].isExpand;
		setSidebarItems(newSidebarItems);
	};

	return (
		<div className="h-full w-60">
			<div
				className="h-full fixed z-50 w-[240px] bg-cover"
				style={{ backgroundImage: `url(${loadStaticPublicAsset("img/bg-nav.jpg")})` }}>
				<div className="mx-auto w-36 p-4">
					<img src={loadStaticPublicAsset("img/logo/logo-white.png")} alt="logo" />
				</div>
				<p className="text-ellipsis text-xs mb-4 text-center font-medium overflow-hidden text-white  whitespace-nowrap ">
					App version: {pjson.version}
				</p>

				<div className="h-[80vh] scroll-menu overflow-y-auto">
					{sidebarItems.map((item, index) => {
						if (item.child.length) {
							return (
								<Fragment key={item.key}>
									<input
										hidden
										id={item.key}
										className="menu-item-checkbox"
										type="checkbox"
										onChange={e => handleExpandSidebar(item.key)}
										key={item.key}
										defaultChecked={pathname.includes(item?.parentPath)}
									/>
									<label htmlFor={item.key}>
										<span
											key={item.key}
											className={clsx(
												"cursor-pointer flex items-center py-4 px-8 tracking-wide text-white  font-bold text-sm uppercase transition duration-150 ease-in-out",
												{ "bg-[rgba(0,0,0,0.2)]": pathname.includes(item?.parentPath) },
											)}>
											<span className={item.iconClassName}></span> {item.menuLabel}
										</span>
									</label>
									<div
										className={clsx(
											" transition-all ease-out duration-300 overflow-hidden",
											pathname.includes(item?.parentPath) || item.isExpand ? "h-auto" : "h-0",
										)}>
										<ul className="pl-0 mt-2 mb-3">
											{item.child.map(childItem => (
												<li
													key={childItem.key}
													className={clsx(
														"block text-left",
														pathname === childItem.path && "relative",
														pathname.includes(childItem.childParentPath) && "relative",
													)}>
													<Link
														to={childItem.path}
														className={clsx(
															"cursor-pointer px-8 py-2 block hover:text-white hover:bg-[rgba(0,0,0,0.1)] tracking-wide uppercase text-sm font-medium",
															pathname === childItem.path &&
																"text-white relative menu-active",
															pathname.includes(childItem.childParentPath) &&
																"text-white relative menu-active",
															pathname !== childItem.path &&
																!pathname.includes(childItem.childParentPath) &&
																"text-red-menu",
														)}>
														{childItem.label}
													</Link>
												</li>
											))}
										</ul>
									</div>
								</Fragment>
							);
						} else {
							return (
								<Link
									to={item.path}
									key={item.key}
									href={null}
									className={clsx(
										"cursor-pointer flex items-center py-4 px-8 tracking-wide text-white  font-bold text-sm uppercase transition duration-150 ease-in-out",
										{ "bg-[rgba(0,0,0,0.2)]": pathname.includes(item?.parentPath) },
									)}>
									<span className={item.iconClassName}></span> {item.menuLabel}
								</Link>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
