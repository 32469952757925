import clsx from "clsx";
import { useState } from "react";

import { useEffect } from 'react'
import { ModalContainer } from "components/modal/modal.container";
import { useActivityLevels } from "hooks/activity";
import { convertToTitleCase } from "utils/string";
import { InputComponent } from "components/form/input.component";

export function ModalFilterMember({ visible, initialValue, onClose, onChange }) {
	const { data: activityLevels } = useActivityLevels();

	const [selected, setSelected] = useState(
		initialValue || {
			levels: [],
			batch: null,
		},
	);
	useEffect(() => {
		if (initialValue && visible) {
			setSelected(initialValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Filter</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="content-scroll">
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Level</label>
						{activityLevels?.data?.map(itemLevel => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										id={`level-${itemLevel.id}`}
										checked={selected.levels.includes(itemLevel.name)}
										onChange={e => {
											if (e.target.checked) {
												setSelected({
													...selected,
													levels: [...selected.levels, itemLevel.name],
												});
											} else {
												setSelected({
													...selected,
													levels: selected.levels.filter(level => level !== itemLevel.name),
												});
											}
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor={`level-${itemLevel.id}`}>
										{itemLevel.name && convertToTitleCase(itemLevel.name)}
									</label>
								</div>
							</div>
						))}
					</div>
					<div className="mb-6">
						<InputComponent
							label="Batch Pendaftaran"
							numberOnly
							value={selected.batch}
							onChange={e => {
								const batch = e.target.value;
								setSelected({ ...selected, batch });
							}}
							placeholder="Masukan nomor batch pendaftaran"
						/>
					</div>
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
