import axios from "axios";
import CONFIG from "config/env";
import { logout, renewAccessToken } from "store/reducer/auth.reducer";
import { store } from "../store";

const BASE_URL = CONFIG.endpoint.API_ENDPOINT;
let instance = null;

function makeInstance() {
	if (instance) {
		return instance;
	}
	instance = axios.create({
		baseURL: BASE_URL,
		timeout: +CONFIG.timeout.REQUEST || 60000,
	});

	return instance;
}

const axiosInstance = makeInstance();

const LOGIN_PATH = "/api/v1/auth/login-admin";

const includesRedirectHome = ["401-A001", "401-A002", "401-A003", "401-A004"];
axiosInstance.interceptors.response.use(
	res => {
		if (res.headers?.["x-access-token"] && res.config.url !== LOGIN_PATH) {
			store.dispatch(renewAccessToken(res.headers?.["x-access-token"]));
			axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.headers?.["x-access-token"]}`;
		}
		return res;
	},
	err => {
		if (includesRedirectHome.includes(err.response.data?.message)) {
			store.dispatch(logout());
		}
		return Promise.reject(err);
	},
);

export const setAuthHeader = (accessToken, refreshToken) => {
	axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	axiosInstance.defaults.headers.common["x-refresh-token"] = refreshToken;
};

export const resetAuthHeader = () => {
	delete axiosInstance.defaults.headers.common.Authorization;
	delete axiosInstance.defaults.headers.common["x-refresh-token"];
};

export default axiosInstance;
