import { convertToTitleCase } from "utils/string";

export function Select({
	onChange,
	onBlur,
	value,
	name,
	defaultValue = "0",
	options,
	placeholder,
	error,
	label,
	labelClassName = "text-sm text-black font-semibold mb-3",
	containerClassName = "mb-4",
	inputParentClassName = "relative",
	inputClassName = "appearance-none border-grey-10 italic w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none",
}) {
	return (
		<div class={containerClassName}>
			<div class={labelClassName}>{label}</div>
			<div class={inputParentClassName}>
				<select
					defaultValue={defaultValue}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					className={inputClassName}>
					<option value="">{placeholder}</option>
					{options?.map(item => (
						<option value={item.id}>{convertToTitleCase(item.name)}</option>
					))}
				</select>

				<div className="absolute top-3 right-3">
					<span className="icon-ico-down"></span>
				</div>
			</div>
			{error && <p className="form-input-error">{error}</p>}
		</div>
	);
}
