import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";

import Layout from "components/layout";
import { UpdateRegSettingValidaitonSchema } from "config/form/schema/registration/settings";
import { useRegistrationSettings, useUpdateRegSettings } from "hooks/registration";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { InputComponent } from "components/form/input.component";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

const formInitialValues = {
	is_registration_visible: false,
	batch_label: "",
	redirect_url: "",
	image_desktop: "",
	image_mobile: "",
	is_graduate_allowed: false,
};

export function RegistrationSettingPage() {
	const formikRef = useRef();

	const [imagePreviewURL, setImagePreviewURL] = useState("");
	const [imageBuffer, setImageBuffer] = useState(null);
	const [showModalResult, setShowModalResult] = useState(false);

	const { data, isLoading } = useRegistrationSettings();
	const { mutate: updateRegistrationSetting, isLoading: isSubmitting } = useUpdateRegSettings();
	const { mutate: uploadImage, isLoading: isUploadingImage } = useUploadImage();

	useEffect(() => {
		if (data) {
			Object.keys(formInitialValues).forEach(key => {
				formikRef.current.setFieldValue(key, data?.data[key]);
			});
			setImagePreviewURL(data?.data?.image_desktop === "" ? null : data?.data?.image_desktop);
		}
	}, [data]);

	const handleSubmitForm = formValue => {
		// imageBuffer state is indicator whether the image thumbnail is replaced or not
		// if imageBuffer is null, then the image is not replaced, otherwise the image is being replaced with new image
		// if image being replaced, the first things to do is upload image to server, then update registration setting
		if (imageBuffer) {
			const formData = new FormData();
			formData.append("image", imageBuffer);
			formData.append("type", "register");
			uploadImage(
				{ formData },
				{
					onSuccess: responseUpload => {
						updateRegistrationSetting(
							{
								...formValue,
								image_desktop: responseUpload?.data?.image_desktop,
								image_mobile: responseUpload?.data?.image_mobile,
							},
							{
								onSuccess: responseUpdate => {
									setShowModalResult(true);
								},
							},
						);
					},
				},
			);
		} else {
			updateRegistrationSetting(formValue, {
				onSuccess: response => {
					setShowModalResult(true);
				},
			});
		}
	};

	const handleSelectImage = e => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = e => {
			setImagePreviewURL(e.target.result);
		};
		reader.readAsDataURL(file);
		setImageBuffer(file);
	};

	return (
		<Layout
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-9"
			breadCumbTitle="User Register"
			breadCumbDesc="Registration Setting">
			<div className="text-2xl text-black font-semibold mb-4">Registration Settings</div>
			<p className="text-main-grey mb-6">
				Pengaturan ini digunakan untuk mengaktifkan fitur registrasi yang ada di website front. Pengunjung dapat
				masuk ke registrasi bergantung pada pengaturan ini{" "}
			</p>

			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<Formik
					validationSchema={UpdateRegSettingValidaitonSchema}
					innerRef={formikRef}
					initialValues={formInitialValues}
					onSubmit={handleSubmitForm}>
					{({ handleBlur, handleChange, handleSubmit, values, errors, touched }) => (
						<>
							<div className="flex justify-between py-4 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6">
								<div className="text-base text-grey-50 font-semibold">Batch Registration Popup</div>
								<div className="flex flex-row-reverse items-center">
									<ToogleSwitchComponent
										inputClassName="toggle-checkbox"
										onChange={handleChange}
										name="is_registration_visible"
										checked={values.is_registration_visible}
									/>
								</div>
							</div>
							<div className="flex justify-between">
								<div className="w-[45%]">
									{/* <InputComponent
										containerclassName="mb-4"
										label="Label Batch"
										name="batch_label"
										value={values.batch_label}
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder="Beri nama batch"
										error={touched.batch_label && errors.batch_label}
									/> */}
									<InputComponent
										containerclassName="mb-4"
										label="Link / URL"
										name="redirect_url"
										value={values.redirect_url}
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder="Registration link / google form"
										error={touched.redirect_url && errors.redirect_url}
									/>

									<div className="mb-4">
										<div className="text-sm text-black font-semibold mb-3">
											Allow for graduate user?
										</div>
										<div className="flex w-40 flex-row-reverse items-center">
											<div className="">
												<ToogleSwitchComponent
													onChange={handleChange}
													name="is_graduate_allowed"
													inputClassName="toggle-checkbox"
													labelClassName="toggle allow"
													checked={values.is_graduate_allowed}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="w-[45%]">
									<div className="w-72">
										<div className="text-sm text-black font-semibold mb-3">Thumbnail</div>
										<div className="relative overflow-hidden rounded-lg">
											{imagePreviewURL && (
												<>
													<img
														src={imagePreviewURL}
														className="max-w-full"
														alt="thumbnail batch label"
													/>
													<button
														onClick={() => setImagePreviewURL(null)}
														className="absolute top-2 right-3 w-10 h-10 leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
														<span className="icon-ico-delete"></span>
													</button>
												</>
											)}
											{imagePreviewURL === null && (
												<p className="mb-4 text-sm text-grey-60">No image selected</p>
											)}
										</div>
										<div className="flex justify-center mt-5">
											<input
												type="file"
												className="hidden"
												id="upload"
												onChange={handleSelectImage}
											/>
											<label
												className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
												for="upload">
												<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
												<span className="pl-2">Upload</span>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="flex justify-center my-9">
								<div className="w-2/6">
									<ButtonSubmit
										disabled={!UpdateRegSettingValidaitonSchema.isValidSync(values)}
										loading={isUploadingImage || isSubmitting}
										onClick={handleSubmit}
										title="Save All Changes"
										className=" mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold disabled:border-red-10 disabled:bg-red-10"
									/>
								</div>
							</div>
						</>
					)}
				</Formik>
			)}
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Successfully saved changes!"
			/>
		</Layout>
	);
}
