import { BounceLoading } from "components/loading/bounce.loading";

export function ButtonExport({ onClick, disabled, loading }) {
	return (
		<button
			className="disabled:opacity-50 flex items-center py-2 px-3 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
			onClick={onClick}
			disabled={disabled || loading}
			type="button">
			{loading ? (
				<BounceLoading color="#90011f" />
			) : (
				<>
					<span className="icon-ico-export text-red-50 text-xl"></span>
					<span className="pl-1">Export</span>
				</>
			)}
		</button>
	);
}
