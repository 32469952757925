import * as Yup from "yup";

export const CreateRewardSchema = Yup.object().shape({
	title: Yup.string().required("Title is required"),
	hint: Yup.string().required("Hint is required"),
	level_id: Yup.string().required("Level is required").nullable(),
	batch: Yup.string().required("Batch is required").nullable(),
	item_ids: Yup.array().min(1, "Item is required").required("Item is required"),
	start_date: Yup.string().required("Start Date is required"),
	end_date: Yup.string().required("End Date is required"),
});
