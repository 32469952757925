import { useParams } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";

import { convertToSlug, getInitialsName } from "utils/string";
import { useGetMemberProfileById, useGetMemberBeautyProfofile, useUpdateMemberBeautyProfile } from "hooks/member";
import { BounceLoading } from "components/loading/bounce.loading";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalResult } from "components/modal/modal.result";

import { MemberProfileLayout } from "../../components/profile.layout";
import { ModalConfirmation } from "components/modal/modal.confirmation";

export function MemberBeautyProfilePage() {
	const { userId, userStatus } = useParams();

	const [beautyProfileData, setBeautyProfileData] = useState([]);
	const [modalConfirmation, setModalConfirmation] = useState(false);
	const [showModalResult, setShowModalResult] = useState(false);
	const { data: memberProfileDetails } = useGetMemberProfileById({ userId, type: userStatus });
	const { data: memberBeautyProfileDetails, isLoading: isLoadingBeautyProfile } = useGetMemberBeautyProfofile({
		userId,
	});
	const { mutate: updateMemberBeautyProfile, isLoading: isUpdating } = useUpdateMemberBeautyProfile({ userId });

	const handleChange = (sectionItemId, selectedItem) => {
		const newBeautyProfileData = beautyProfileData.map(item => {
			if (item.name === sectionItemId.name) {
				const isSelected = item.selectedIds.has(selectedItem.id);
				if (isSelected) {
					item.selectedIds.delete(selectedItem.id);
				} else {
					item.selectedIds.add(selectedItem.id);
				}
			}
			return item;
		});
		setBeautyProfileData(newBeautyProfileData);
	};

	useEffect(() => {
		if (memberBeautyProfileDetails?.data) {
			if (memberBeautyProfileDetails) {
				const mappedData = memberBeautyProfileDetails?.data.map(skinCategory => {
					return {
						...skinCategory,
						selectedItem: skinCategory.skin_items.find(item => {
							return item.selected;
						}),
						selectedIds: new Set(
							skinCategory.skin_items.filter(item => item.selected).map(item => item.id),
						),
					};
				});

				setBeautyProfileData(mappedData);
			}
		}
	}, [memberBeautyProfileDetails]);

	const handleOnUpdate = () => {
		const selectedIDS = beautyProfileData.map(item => {
			return Array.from(item.selectedIds);
		});
		const selectedItems = selectedIDS.flat();
		updateMemberBeautyProfile(
			{ item_ids: selectedItems },
			{
				onSuccess: () => {
					setModalConfirmation(false);
					setShowModalResult(true);
					window.scroll({
						top: 0,
						behavior: "smooth",
					});
				},
			},
		);
	};

	return (
		<MemberProfileLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="User"
			desc="User Detail">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoadingBeautyProfile ? (
					<div className="h-[40vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<div className="mb-14 py-2 p-3">
						<div className="flex justify-between items-center mb-8">
							<div className="text-title font-semibold text-grey-70">Beauty Profile</div>
							<div className="rounded-xl border min-w-[280px] py-2 px-3 overflow-hidden flex items-center border-solid border-grey-10">
								<div className="mr-4">
									{memberProfileDetails?.data?.profile_picture ? (
										<img
											src={memberProfileDetails?.data?.profile_picture}
											alt="profile"
											className="w-12 h-12 overflow-hidden rounded-full object-cover object-center"
										/>
									) : (
										<div className="initial">
											{getInitialsName(memberProfileDetails?.data?.full_name) || "A"}
										</div>
									)}
								</div>
								<div className="w-auto">
									<div className="text-sm font-bold">{memberProfileDetails?.data?.full_name}</div>
									<div className="text-sm text-grey-40 italic">
										{memberProfileDetails?.data?.email}
									</div>
								</div>
							</div>
						</div>
						{beautyProfileData.map((skinCategory, index) => {
							return (
								<Fragment key={convertToSlug(skinCategory.name)}>
									<div className="text-sm text-black font-semibold mb-5">{skinCategory.name}</div>
									<div className="flex mb-5 flex-wrap">
										{skinCategory.skin_items.map((skinItem, index) => {
											const isSelected = skinCategory.selectedIds.has(skinItem.id);
											return (
												<div className="skin-type mr-4 mb-4" key={skinItem.id}>
													<input
														type="checkbox"
														name={convertToSlug(skinCategory.name)}
														id={`${skinItem?.name}-${skinItem.id}`}
														className="hidden"
														checked={isSelected}
													/>
													<label
														aria-disabled={isUpdating}
														onClick={() => handleChange(skinCategory, skinItem)}
														htmlFor={`${skinItem?.name}-${skinItem.id}`}
														className="w-[98px] h-32 py-4 rounded-xl border flex flex-col items-center justify-center border-solid cursor-pointer border-grey-10">
														<div className="h-16">
															<img
																src={skinItem.image}
																className="rounded-full w-12 h-12 overflow-hidden"
																alt="Item"
															/>
														</div>
														<div className="h-10 flex items-center">
															<div className="text-black font-semibold text-sm text-center px-2">
																{skinItem.name}
															</div>
														</div>
													</label>
												</div>
											);
										})}
									</div>
								</Fragment>
							);
						})}

						<div className="flex justify-center my-9">
							<div className="w-2/6">
								<ButtonSubmit
									disabled={
										beautyProfileData?.filter(item => item?.selectedItem)?.length !==
										beautyProfileData?.length
									}
									onClick={() => setModalConfirmation(true)}>
									<span className="icon-ico-save mr-2"></span> Save All Changes
								</ButtonSubmit>
							</div>
						</div>
					</div>
				)}
			</div>
			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
			<ModalConfirmation
				visible={modalConfirmation}
				title="Simpan Perubahan"
				labelReject="Cancel"
				loading={isUpdating}
				labelApprove="Save"
				onApprove={handleOnUpdate}
				onReject={() => setModalConfirmation(false)}
				onClose={() => setModalConfirmation(false)}>
				<div className="my-6">
					<img src="/img/icons/save.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">Apakah ingin menyimpan perubahan ?</p>
				</div>
			</ModalConfirmation>
		</MemberProfileLayout>
	);
}
