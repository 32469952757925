import axios from "api/http.api";
import { useQuery } from "react-query";
import qs from "qs";

export function useGetRewardCategoryList() {
	return useQuery(["REWARD_CATEGORY_LIST"], () => axios.get(`/api/v1/admin/item/categories`).then(res => res.data));
}

export function useGetRewardItemList({ filter }) {
	return useQuery(["REWARD_ITEM_LIST", { filter }], () =>
		axios.get(`/api/v1/admin/item?${qs.stringify(filter)}`).then(res => res.data),
	);
}
export function useGetRewardItemDetails(itemId) {
	return useQuery(
		["REWARD_ITEM_DETAILS", { itemId }],
		() => axios.get(`/api/v1/admin/item/detail/${itemId}`).then(res => res.data),
		{ enabled: !!itemId },
	);
}

export function useGetRewardList() {
	return useQuery(["REWARD_LIST"], () => axios.get(`/api/v1/admin/reward`).then(res => res.data));
}

export function useRewardLevel() {
	return useQuery(["REWARD_LEVEL"], () => axios.get(`/api/v1/admin/reward/levels`).then(res => res.data));
}

export function useRewardBatchs() {
	return useQuery(["LIST_BATCH_REWARD"], () =>
		axios.get(`/api/v1/admin/reward/batches`).then(res => {
			return { ...res.data, data: res.data.data.map(batch => ({ id: batch, name: `Batch ${batch}` })) };
		}),
	);
}

export function useAllRewardItemsList() {
	return useQuery(["ALL_REWARD_ITEMS_LIST"], () => axios.get(`/api/v1/admin/reward/items`).then(res => res.data));
}

export function useRewardDetails(rewardId) {
	return useQuery(["REWARD_DETAILS", { rewardId }], () =>
		axios.get(`/api/v1/admin/reward/detail/${rewardId}`).then(res => res.data),
	);
}

export function useClaimRewardList({ queryFilter, bodyFilter }, status) {
	return useQuery(["CLAIM_REWARD_LIST", { queryFilter, bodyFilter, status }], () =>
		axios
			.post(`/api/v1/admin/reward/claims/list/${status}?${qs.stringify(queryFilter)}`, bodyFilter)
			.then(res => res.data),
	);
}
