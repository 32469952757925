import { ButtonSubmit } from "components/button/submit.button";
import { InputComponent } from "components/form/input.component";
import TextEditor from "components/text-editor";

import { AccordionWrapper } from "../accordion-wrapper.component";
import { IsJsonString } from "utils/string";
export function Exam({
	expanded,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	sectionId = "exam",
	buttonExamDisabled,
	buttonExamLoading = false,
	onClickButtonExam,
	descriptionInitialValue = undefined,
	setFieldValue,
}) {
	return (
		<AccordionWrapper
			expaned={expanded}
			onToggleExpand={onToggleExpand}
			title="Exam"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="flex justify-between ">
					<div className="w-[48%]">
						<InputComponent
							label="Title"
							placeholder="Insert Title"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.title}
							name={`${sectionId}.title`}
							error={touched?.title && errors?.title}
						/>

						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(descriptionInitialValue) ? descriptionInitialValue : undefined
								}
								onChange={nodeValue => setFieldValue(`${sectionId}.description`, nodeValue)}
							/>
						</div>
					</div>
					<div className="w-[48%]">
						<InputComponent
							label="Point"
							placeholder="Masukan jumlah point"
							numberOnly
							onBlur={onBlur}
							onChange={onChange}
							value={values?.point}
							name={`${sectionId}.point`}
							error={touched?.point && errors?.point}
						/>
						<InputComponent
							label="Duration"
							placeholder="Durasi pengerjaan ujian"
							onBlur={onBlur}
							onChange={onChange}
							inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none without_ampm"
							value={values?.duration}
							type="time"
							name={`${sectionId}.duration`}
							error={touched?.duration && errors?.duration}
							step={1}
						/>

						<InputComponent
							label="Start Date & Time"
							type="datepicker-modal"
							placeholder="Start Date & Time"
							onChange={onChange}
							onBlur={onBlur}
							name={`${sectionId}.start_date`}
							value={values?.start_date}
							error={touched?.start_date && errors?.start_date}
							min={new Date().toISOString().split("T")[0]}
						/>
						<InputComponent
							label="End Date & Time"
							type="datepicker-modal"
							placeholder="End Date & Time"
							onChange={onChange}
							onBlur={onBlur}
							name={`${sectionId}.end_date`}
							value={values?.end_date}
							error={touched?.end_date && errors?.end_date}
							min={new Date().toISOString().split("T")[0]}
						/>
						<div className="flex mb-5">
							<div className="w-1/2">
								<ButtonSubmit
									disabled={buttonExamDisabled}
									loading={buttonExamLoading}
									onClick={onClickButtonExam}
									className="disabled:opacity-50 mt-5 w-full flex justify-center py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
									<span className="mr-2">
										<img src="/img/icons/exam.svg" className="max-w-full" alt="Exam Button" />
									</span>
									<span>Create Exams</span>
								</ButtonSubmit>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
