import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { isTokenExpired } from "api/utils-auth.api";
import { setAuthHeader, resetAuthHeader } from "api/http.api";
import authSlice, { logout } from "./reducer/auth.reducer";
import toolSlice from "./reducer/tools.reducer";

const rootReducers = combineReducers({
	auth: authSlice,
	toolsReducer: toolSlice,
});

const persistConfig = {
	key: "APPLICATION_STATE",
	storage,
	whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore(
	{
		reducer: persistedReducer,
	},
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export const persistor = persistStore(store, {}, () => {
	const state = store.getState();
	if (isTokenExpired(state)) {
		store.dispatch(logout());
		resetAuthHeader();
		localStorage.clear();
	} else {
		setAuthHeader(state.auth.accessToken, state.auth.refreshToken);
	}
});
