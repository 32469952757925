import clsx from "clsx";
import { useState, useRef } from "react";

import { serializeEvent } from "components/text-editor/serializer";
import { IsJsonString } from "utils/string";
import { useOnClickOutside } from "hooks/useOnClickOutside";

export function GalleryItem({ onDelete = () => {}, onEdit = () => {}, ...props }) {
	const menuRef = useRef();
	const [showMenu, setShowMenu] = useState(false);

	useOnClickOutside(menuRef, () => {
		setShowMenu(false);
	});

	return (
		<div className="rounded-lg bg-white p-3 border border-solid border-grey-10">
			<div className="relative mb-3">
				<img
					src={props?.images?.[0]?.url}
					alt={props?.title}
					className="w-full h-52 xl:h-72 rounded-lg overflow-hidden object-cover object-center"
				/>

				{props.status === "draft" && (
					<div className="absolute bg-red-50 text-white top-2 left-2 italic inline-block px-5 py-1 font-semibold text-sm rounded-tl-md rounded-br-md">
						Draft
					</div>
				)}

				<div className="dropdown absolute top-2 right-2">
					<button
						className=" leading-tight w-10 h-10 rounded-full bg-white relative flex items-center justify-center text-left"
						type="button"
						onClick={() => setShowMenu(!showMenu)}>
						<span className="icon-ico-bullets text-2xl text-red-50"></span>
					</button>
					<ul
						ref={menuRef}
						class={clsx(
							"dropdown-menu item-right min-w-max absolute hidden bg-white text-base z-50 py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none w-[150px] right-0",
							showMenu && "show",
						)}>
						<li>
							<button
								onClick={() => {
									setShowMenu(false);
									onEdit();
								}}
								className="dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 focus:bg-white font-medium">
								Edit
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									onDelete();
									setShowMenu(false);
								}}
								className="dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 font-medium focus:bg-white">
								Delete
							</button>
						</li>
					</ul>
				</div>
			</div>
			<div className="text-sm text-grey-70 mb-3 font-semibold">{props?.title}</div>

			{props.description &&
				IsJsonString(props.description) &&
				serializeEvent(JSON.parse(props.description), undefined, { paragraph: 50 })}
		</div>
	);
}
