import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";
import { toast } from "react-hot-toast";

import { PageSettingLayout } from "../layout";

import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";

import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";

import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { SliderBannerComponent } from "../component/slider-feature.component";

const formikInitialValues = {
	is_visible: false,
	features: [],
};

export function FeatureSetting() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "about", slug: "feature" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [showModalResult, setShowModalResult] = useState(false);
	const [dataBanner, setDataBanner] = useState([
		{
			id: "banner-0",
			isExpand: false,
			name: null,
			image_desktop: null,
			image_mobile: null,
			title: "",
			description: "",
		},
	]);
	const [filesBuffer, setFilesBuffer] = useState([]);

	useEffect(() => {
		if (data?.data) {
			Object.keys(formikInitialValues).forEach(key => {
				if (data?.data?.[key]) {
					if (key === "features") {
						formikRef.current.setFieldValue(
							key,
							data?.data?.features.map((item, index) => ({
								...item,
								id: `features-${index + 1}`,
							})),
						);
					} else {
						formikRef.current.setFieldValue(key, data?.data?.[key]);
					}
				}
			});
			setDataBanner(
				data?.data?.features.map((item, index) => ({
					...item,
					isExpand: index === 0,
					id: `features-${index + 1}`,
					initialDescription: item.description,
				})),
			);
		}
	}, [data?.data]);

	const handleSubmitForm = async payloadData => {
		try {
			const generateBodyRequestWithNewImage = () => {
				return new Promise(async (resolve, reject) => {
					const newPayloadData = _.cloneDeep(payloadData);
					let cloneBannerData = _.cloneDeep(dataBanner);
					const filteredFileBuffer = filesBuffer.filter(item => !!item);
					if (!!filteredFileBuffer.length) {
						const processFileBuffers = async () => {
							for (const fileImageBuffer of filteredFileBuffer) {
								const images = Object.keys(fileImageBuffer.file)
									.filter(itemKey => fileImageBuffer.file[itemKey])
									.map(itemKey => ({
										version: itemKey.replace("image_", ""),
										file: fileImageBuffer.file[itemKey],
									}));

								const imagePromises = images.map(image => {
									const formData = new FormData();
									formData.append("image", image.file);
									formData.append("version", image.version);
									formData.append("type", "banner");
									return uploadImageAsync({
										formData,
										uploadId: `image_${image.version}_${fileImageBuffer.id}`,
									});
								});

								const response = await Promise.all(imagePromises);
								const responseDataUrls = response.map(item => item.data);
								cloneBannerData = cloneBannerData.map(item => {
									if (item.id === fileImageBuffer.id) {
										return { ...item, ...Object.assign({}, ...responseDataUrls) };
									}
									return item;
								});
							}
							newPayloadData.features = cloneBannerData;
						};
						await processFileBuffers();
						resolve(newPayloadData);
					} else {
						newPayloadData.features = cloneBannerData;
						resolve(newPayloadData);
					}
				});
			};

			const newBodyRequest = await generateBodyRequestWithNewImage();

			updatePageSetting(
				{ page: "about", slug: "feature", formData: newBodyRequest },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setFilesBuffer([]);
						window.scrollTo({ top: 0, behavior: "smooth" });
					},
				},
			);
		} catch (error) {
			toast.error(error?.response?.message || "Oops, something went wrong");
		}
	};

	const handleChangeImage = (bannerId, data, index) => {
		const newFilesBuffer = [...filesBuffer];
		const newData = _.mapKeys(data, (value, key) => key.replace(`-${bannerId}`, ""));
		const itemByBannerId = newFilesBuffer?.find(item => item?.id === bannerId);
		const itemByBannerIdIndex = newFilesBuffer?.findIndex(item => item?.id === bannerId);
		if (itemByBannerId) {
			itemByBannerId.file[Object.keys(newData)[0]] = newData[Object.keys(newData)[0]];

			newFilesBuffer[itemByBannerIdIndex] = itemByBannerId;
		} else {
			newFilesBuffer.push({
				id: bannerId,
				file: {
					image_desktop: null,
					image_mobile: null,
					...newData,
				},
			});
		}
		setFilesBuffer(newFilesBuffer);
	};

	const handleExpand = index => {
		const newData = [...dataBanner];
		newData[index].isExpand = !newData[index].isExpand;
		setDataBanner(newData);
	};

	const handleChangeInput = (data, index) => {
		const newData = [...dataBanner];
		newData[index].title = data;
		setDataBanner(newData);
	};
	const handleChangeDesc = (data, index) => {
		const newData = [...dataBanner];
		newData[index].description = data;
		setDataBanner(newData);
	};

	const handleAddNewItem = () => {
		const newData = [...dataBanner];
		newData.push({
			id: `banner-${newData.length + 1}`,
			isExpand: true,
			name: null,
			image_desktop: null,
			image_mobile: null,
			redirect_url: "",
		});
		setDataBanner(newData);
	};

	const handleDelete = index => {
		const newData = [...dataBanner];
		newData.splice(index, 1);
		newData[0].isExpand = true;
		setDataBanner(newData);
	};

	const handleMoveOrder = (direction, index) => {
		const newData = [...dataBanner];
		const item = newData[index];
		newData.splice(index, 1);
		if (direction === "UP") {
			newData.splice(index - 1, 0, item);
		} else {
			newData.splice(index + 1, 0, item);
		}

		setDataBanner(newData);
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="ABOUT"
			desc="About RUBI Page">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik innerRef={formikRef} initialValues={formikInitialValues} onSubmit={handleSubmitForm}>
						{({ values, handleChange, handleSubmit }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">Feature</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} From Home Page
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>
								{dataBanner.map((bannerItem, index) => (
									<SliderBannerComponent
										bannerTitle={{
											desktop: "Desktop Banner (Aspect Ration 16:4)",
											mobile: "Mobile Banner (Aspect Ration 16:8)",
										}}
										data={bannerItem}
										key={index}
										title={`Item ${index + 1}`}
										expand={bannerItem.isExpand}
										onExpand={() => handleExpand(index)}
										onDelete={() => handleDelete(index)}
										onChange={data => handleChangeImage(bannerItem.id, data, index)}
										onChangeInput={data => handleChangeInput(data, index)}
										onChangeDesc={data => handleChangeDesc(data, index)}
										disabledDelete={dataBanner.length === 1}
										disabledUpButton={index === 0}
										disabledDownButton={index === dataBanner.length - 1}
										onMove={direction => handleMoveOrder(direction, index)}
									/>
								))}

								<button
									onClick={handleAddNewItem}
									className="flex items-center py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
									type="button">
									<span className="icon-ico-circle-plus text-red-50 text-xl"></span>
									<span className="pl-2">Add New Item</span>
								</button>

								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											onClick={handleSubmit}
											loading={isUploading || isUpdating}
											title="Save All Changes"
											className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
