import _ from "lodash";
import dayjs from "dayjs";
import dayjsPluginDuration from "dayjs/plugin/duration";
import { useEffect, useRef, useState, useMemo } from "react";
import { Formik } from "formik";

import Layout from "components/layout";
import TextEditor from "components/text-editor";
import { UpdateRegTestValidationSchema } from "config/form/schema/registration/settings";
import { useRegTestData, useUpdateRegTestData } from "hooks/registration";
import { useStateCallback } from "hooks/useStateCallback";
import { convertTimeToSeconds } from "utils/date";
import { BounceLoading } from "components/loading/bounce.loading";
import { TostComponent } from "components/toast/toast.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalResult } from "components/modal/modal.result";
import { CreateQuestionComponent } from "./create-question.component";
import { QuestionItemComponent } from "./question.component";
import { IsJsonString } from "utils/string";

dayjs.extend(dayjsPluginDuration);

const formInitialValues = {
	title: "",
	description: "",
	exam_duration: "",
};

export function TestSettingPage() {
	const formikRef = useRef();
	const bottomDivRef = useRef();

	const { data, isLoading, refetch } = useRegTestData();
	const { mutate: updateData, isLoading: isSubmitting } = useUpdateRegTestData();

	const [showModalResult, setShowModalResult] = useState(false);
	const [dataQuestions, setDataQuestions] = useStateCallback({
		published: [],
		unpublished: [],
	});

	/**
	 * this function takes the current sequence and the direction sequence, finds the current question and the
	 * direction question, finds the current question index and the direction question index, sets the
	 * current question to the direction sequence and the direction question to the current sequence, and
	 * then sorts the published questions by sequence
	 * @param currentSequence - The sequence of the question that is being moved.
	 * @param directionSequence - The sequence of the question that the current question is moving to.
	 */
	const handleMovePublishedQuestionOrder = (currentSequence, directionSequence) => {
		const newDataQuestions = { ...dataQuestions };
		const currentQuestion = _.find(newDataQuestions.published, { sequence: currentSequence });
		const directionQuestion = _.find(newDataQuestions.published, { sequence: directionSequence });
		const currentQuestionIndex = newDataQuestions.published.indexOf(currentQuestion);
		const directionQuestionIndex = newDataQuestions.published.indexOf(directionQuestion);
		newDataQuestions.published[currentQuestionIndex] = { ...currentQuestion, sequence: directionSequence };
		newDataQuestions.published[directionQuestionIndex] = { ...directionQuestion, sequence: currentSequence };
		newDataQuestions.published = _.sortBy(newDataQuestions.published, "sequence");
		setDataQuestions(newDataQuestions);
	};

	/**
	 * This function removes the question from the current array, adds it to the other array, and then sorts the array
	 * by sequence
	 * @param questionItemObject - The question object that is being moved.
	 * @param statusDirection - This is the direction of the status change. It can be either published or
	 * unpublished.
	 */

	const handleMoveStatusQuestion = (questionItemObject, statusDirection) => {
		const sourceDirection = statusDirection === "published" ? "unpublished" : "published";
		const newDataQuestions = { ...dataQuestions };
		// lodash find and remove item from array
		_.remove(newDataQuestions[sourceDirection], {
			id: questionItemObject.id,
		});
		// add item to other array
		newDataQuestions[statusDirection].push({
			...questionItemObject,
			sequence: newDataQuestions[statusDirection].length + 1,
		});
		// sort array by sequence
		newDataQuestions[statusDirection] = _.sortBy(newDataQuestions[statusDirection], "sequence");

		//recalculate sequence number
		newDataQuestions[sourceDirection] = newDataQuestions[sourceDirection].map((item, index) => ({
			...item,
			sequence: index + 1,
		}));

		setDataQuestions(newDataQuestions);
	};

	/**
	 * The function takes a question object as an argument, finds the question in the unpublished array,
	 * removes it, sorts the array, and then sets the state with the new array.
	 * The function only work for unpublished questions.
	 *
	 * @param questionItemObject - The question object that is being deleted.
	 *
	 */
	const handleDeleteQuestion = questionItemObject => {
		const newDataQuestions = { ...dataQuestions };
		// lodash find and remove item from array
		_.remove(newDataQuestions.unpublished, {
			id: questionItemObject.id,
		});
		// sort array by sequence
		newDataQuestions.unpublished = _.sortBy(newDataQuestions.unpublished, "sequence").map((item, index) => {
			return { ...item, sequence: index + 1 };
		});
		setDataQuestions(newDataQuestions);
	};

	/**
	 * This function will handle once user click change option on a question.
	 * @param questionId - The id of the question that you want to change the option for.
	 * @param selectedOptionObject - The option object that was selected.
	 * @param questionStatus - This is the status of the question. It can be either published or
	 * unpublished.
	 */

	const handleChangeOption = (questionId, selectedOptionObject, questionStatus) => {
		const newDataQuestions = { ...dataQuestions };
		const questionItemObject = _.find(newDataQuestions[questionStatus], { id: questionId });
		const newQuestionItemObject = { ...questionItemObject };
		const newOptions = [...newQuestionItemObject.options].map(item => ({ ...item, is_correct: false }));
		const selectedOptionIndex = newQuestionItemObject.options.indexOf(selectedOptionObject);
		newOptions[selectedOptionIndex] = { ...selectedOptionObject, is_correct: !selectedOptionObject.is_correct };
		newQuestionItemObject.options = newOptions;
		newDataQuestions[questionStatus] = _.map(newDataQuestions[questionStatus], questionItem => {
			if (questionItem.id === questionId) {
				return newQuestionItemObject;
			}
			return questionItem;
		});
		setDataQuestions(newDataQuestions);
	};

	/**
	 * This function takes in a new question, adds a sequence number to it, and then adds it to the list of
	 * unpublished questions
	 */
	const handleAddNewQuestion = newQuestionData => {
		const newDataQuestion = { ...newQuestionData, sequence: dataQuestions.unpublished.length + 1 };

		/* Creating a new object with the data from the form and the questions. */
		const data = formikRef.current?.values;
		const bodyRequest = {
			...data,
			image_desktop: "",
			image_mobile: "",
			exam_duration: convertTimeToSeconds(data.exam_duration),
			published_questions: dataQuestions.published.map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
			unpublished_questions: [newDataQuestion, ...dataQuestions.unpublished].map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
		};

		updateData(bodyRequest, {
			onSuccess: () => {
				setShowModalResult(true);
				setDataQuestions({
					...dataQuestions,
					unpublished: [newDataQuestion, ...dataQuestions.unpublished],
				});
				refetch();
			},
		});
	};

	const handleSubmitData = data => {
		/* Creating a new object with the data from the form and the questions. */
		const bodyRequest = {
			...data,
			image_desktop: "",
			image_mobile: "",
			exam_duration: convertTimeToSeconds(data.exam_duration),
			published_questions: dataQuestions.published.map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
			unpublished_questions: dataQuestions.unpublished.map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
		};

		updateData(bodyRequest, {
			onSuccess: () => {
				setShowModalResult(true);
			},
		});
	};

	const handleEditQuestion = (currentItem, editedData) => {
		const newUnpublishedQuestions = [...dataQuestions.unpublished];
		const dataedited = {
			...currentItem,
			id: editedData.id,
			isEditing: false,
			image_desktop: editedData.image_desktop,
			image_mobile: editedData.image_mobile,
			options: editedData.options,
			question: editedData.question,
		};
		// find by id and replace in newUnplublishedQuestions
		const index = _.findIndex(newUnpublishedQuestions, { id: editedData.id });
		newUnpublishedQuestions[index] = dataedited;
		setDataQuestions({
			...dataQuestions,
			unpublished: newUnpublishedQuestions,
		});
	};

	const isEditingModeOn = useMemo(() => {
		return dataQuestions.unpublished.some(item => item.isEditing);
	}, [dataQuestions]);

	useEffect(() => {
		/* Setting the initial values of the formik form. */
		if (data) {
			Object.keys(formInitialValues).forEach(key => {
				if (data?.data[key]) {
					if (key === "exam_duration") {
						formikRef.current.setFieldValue(
							key,
							dayjs.duration(data.data[key], "seconds").format("HH:mm:ss"),
						);
					} else {
						formikRef.current.setFieldValue(key, data.data[key]);
					}
				}
			});
			setDataQuestions({
				published: data.data.published_questions?.map(item => ({ ...item, isEditing: false })),
				unpublished: data.data.unpublished_questions.map((item, index) => ({
					...item,
					isEditing: false,
					sequence: index + 1,
				})),
			});
		}
	}, [data, setDataQuestions]);

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-5"
			breadCumbTitle="User Register"
			breadCumbDesc="Create Registration Test">
			<div className="text-title text-black font-semibold">Introduction</div>
			<hr className="border-grey-10 mt-3 mb-7" />
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<Formik
					validationSchema={UpdateRegTestValidationSchema}
					innerRef={formikRef}
					initialValues={formInitialValues}
					onSubmit={handleSubmitData}>
					{({ values, handleBlur, handleChange, touched, errors, handleSubmit, setFieldValue }) => {
						return (
							<>
								<div className="mb-5">
									<div className="text-sm text-black font-semibold">Registration Question Title</div>
									<div className="w-full">
										<input
											name="title"
											value={values.title}
											onChange={handleChange}
											onBlur={handleBlur}
											type="text"
											className="border-grey-10 outline-none w-full border-l-0 border-t-0 border-r-0 border-b text-2xl border-solid px-4 py-3 text-red-50 transition-all focus:bg-white focus:border-t-0 focus:border-r-0 focus:border-l-0 hover:border-t-0 hover:border-r-0 hover:border-l-0 focus:text-red-50 focus:border-red-50 focus:outline-none focus:shadow-none"
											placeholder="Exam title"
										/>
										{errors.title && touched.title && (
											<p className="form-input-error">{errors.title}</p>
										)}
									</div>
								</div>
								<div className="flex justify-between mb-8">
									<div className="w-[48%]">
										<div className="text-sm text-black font-semibold mb-3">Description</div>
										{/* <img src="img/text-input.png" className="max-w-full" /> */}
										<TextEditor
											initialValue={
												IsJsonString(data?.data?.description)
													? data?.data?.description
													: undefined
											}
											onChange={nodeValue => setFieldValue("description", nodeValue)}
										/>
										{/* <textarea
											value={values.description}
											onChange={handleChange}
											name="description"
											className="border-grey-10 outline-none w-full border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
											rows="6"
											placeholder="type description"></textarea> */}
									</div>
									<div className="w-[48%]">
										<div className="text-sm text-black font-semibold mb-3">Total Time</div>
										<div className="relative">
											<input
												name="exam_duration"
												value={values.exam_duration}
												onChange={handleChange}
												type="time"
												step={1}
												className="border-grey-10 w-full outline-none border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
												placeholder="hh : mm : ss"
											/>
										</div>
									</div>
								</div>
								<div className="flex border-t border-grey-10">
									<div className="w-1/2 border-r border-grey-10 pt-4 pr-4">
										<div className="w-[95%]">
											<div className="text-title text-black font-semibold mb-4">
												Unpublished Questions
											</div>
											<CreateQuestionComponent
												onSubmit={handleAddNewQuestion}
												disabled={isEditingModeOn}
											/>
											{dataQuestions.unpublished?.map((item, index) => {
												if (item.isEditing) {
													return (
														<CreateQuestionComponent
															onCancel={() => {
																const newUnpublishedQuestions =
																	dataQuestions.unpublished;
																newUnpublishedQuestions[index].isEditing = false;

																setDataQuestions({
																	...dataQuestions,
																	unpublished: newUnpublishedQuestions,
																});
															}}
															editingMode
															editId={item.id}
															onSubmitEditing={editedData =>
																handleEditQuestion(item, editedData)
															}
															initialImage={{
																image_desktop: item.image_desktop,
																image_mobile: item.image_mobile,
															}}
															intialQuestion={item.question}
															initialOptions={item.options}
														/>
													);
												}
												return (
													<QuestionItemComponent
														disabled={isEditingModeOn}
														key={index}
														image={item.image_desktop}
														questionId={item.id}
														sequence={item.sequence}
														question={item.question}
														onEdit={() => {
															const newUnpublishedQuestions = [
																...dataQuestions.unpublished,
															];
															newUnpublishedQuestions[index].isEditing = true;
															setDataQuestions({
																...dataQuestions,
																unpublished: newUnpublishedQuestions,
															});
														}}
														onChangeOption={(questionId, selectedOptionObject) =>
															handleChangeOption(
																questionId,
																selectedOptionObject,
																"unpublished",
															)
														}
														onDelete={() => handleDeleteQuestion(item)}
														onMoveStatus={statusDirection =>
															handleMoveStatusQuestion(item, statusDirection)
														}
														options={item.options}
														published={false}
														data={item}
													/>
												);
											})}
										</div>
									</div>
									<div className="w-1/2 pt-4 pl-4">
										<div className="flex justify-end">
											<div className="w-[95%]">
												<div className="text-title text-black font-semibold mb-4">
													Published Questions
												</div>
												{dataQuestions.published?.map((item, index) => {
													return (
														<QuestionItemComponent
															disabled={isEditingModeOn}
															key={index}
															image={item.image_desktop}
															published
															onChangeOption={(questionId, selectedOptionObject) =>
																handleChangeOption(
																	questionId,
																	selectedOptionObject,
																	"published",
																)
															}
															disabledDownButton={
																dataQuestions.published?.length === index + 1
															}
															disabledUpButton={index === 0}
															questionId={item.id}
															sequence={item.sequence}
															onMoveOrder={handleMovePublishedQuestionOrder}
															onMoveStatus={statusDirection =>
																handleMoveStatusQuestion(item, statusDirection)
															}
															question={item.question}
															options={item.options}
														/>
													);
												})}
											</div>
										</div>
									</div>
								</div>
								<div className="flex justify-center my-9" ref={bottomDivRef}>
									<div className="w-2/6">
										<ButtonSubmit
											disabled={
												isEditingModeOn || !UpdateRegTestValidationSchema.isValidSync(values)
											}
											loading={isSubmitting}
											type="submit"
											onClick={handleSubmit}
											title="Save All Changes"
											className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold disabled:cursor-not-allowed disabled:opacity-50"
										/>
									</div>
								</div>
							</>
						);
					}}
				</Formik>
			)}

			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Successfully saved changes!"
			/>
			<TostComponent
				visible={isEditingModeOn}
				title="Editing Mode On"
				message="You are not be able to delete, upload, and move any item until you finish with your current editing."
			/>
		</Layout>
	);
}
