import clsx from "clsx";
import { InputComponent } from "components/form/input.component";

import TextEditor from "components/text-editor";
import { UploadBannerComponent } from "../upload-banner.component";
import { IsJsonString } from "utils/string";

export function SliderBannerComponent({
	bannerTitle = { desktop: "Desktop Banner Title", mobile: "Mobile Banner Title" },
	data,
	onExpand,
	onDelete,
	onMove,
	onChange,
	onChangeInput,
	onChangeDesc,
	title,
	expand,
	disabledDelete,
	disabledUpButton,
	disabledDownButton,
}) {
	const handleChangeInput = e => {
		onChangeInput(e.target.value);
	};

	return (
		<div className="overflow-hidden rounded-lg border border-solid border-grey-10 mb-4">
			<div
				class={clsx(
					"items-tab flex items-center justify-between py-2.5 px-4 bg-[#F2F2F2] tracking-wide hover:bg-[rgba(0,0,0,0.1)] transition duration-150 ease-in-out ",
					expand && " collapsed",
				)}>
				<div className="text-black text-base font-bold">{title}</div>
				<div className="flex">
					<button
						disabled={disabledUpButton}
						className="disabled:opacity-30 flex items-center bg-white w-10 h-10 mr-2 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("UP")}>
						<span className="icon-ico-arrow-up text-red-50 text-xl"></span>
					</button>
					<button
						disabled={disabledDownButton}
						className="disabled:opacity-30  flex items-center bg-white w-10 h-10 mr-5 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("DOWN")}>
						<span className="icon-ico-arrow-down text-red-50 text-xl"></span>
					</button>

					<button
						onClick={onExpand}
						className="items-btn flex items-center bg-white w-10 h-10 rounded-full justify-center border text-sm border-solid border-grey-10  text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span class={clsx("text-red-50 text-base", expand ? "icon-ico-down" : "icon-ico-up")}></span>
					</button>
				</div>
			</div>
			<div class={clsx("collapse", expand && "show")} id="items-1">
				<div className="flex justify-between p-4">
					<div className="w-[48%]">
						<UploadBannerComponent
							showDeleteButton={false}
							initialImage={data.image_desktop}
							name={`image_desktop-${data.id}`}
							onChange={imageFileBuffer => {
								onChange(imageFileBuffer);
							}}
							title={bannerTitle.desktop}
						/>
						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Title</div>
							<InputComponent
								value={data.title}
								onChange={handleChangeInput}
								placeholder="link to page / external url"
								inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
							/>
						</div>
					</div>
					<div className="w-[48%]">
						<div className="mb-4">
							<UploadBannerComponent
								showDeleteButton={false}
								initialImage={data.image_mobile}
								name={`image_mobile-${data.id}`}
								onChange={imageFileBuffer => {
									onChange(imageFileBuffer);
								}}
								title={bannerTitle.mobile}
							/>
							<TextEditor
								initialValue={
									IsJsonString(data.initialDescription) ? data.initialDescription : undefined
								}
								onChange={nodesValue => onChangeDesc(nodesValue)}
							/>
						</div>
					</div>
				</div>
				<div className="border-t border-grey-10 py-3 px-4 flex justify-end">
					<button
						onClick={onDelete}
						disabled={disabledDelete}
						className="disabled:opacity-30 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span className="icon-ico-delete text-red-50 text-xl"></span>
						<span className="pl-2">Delete</span>
					</button>
				</div>
			</div>
		</div>
	);
}
