import clsx from "clsx";
import _ from "lodash";
import { Player } from "video-react";
import { useState, useRef } from "react";
import { getRelativeTime } from "utils/date";
import { isUrlMedia } from "utils/string";
import { ModalContainer } from "components/modal/modal.container";
import { useUploadImage, useUploadVideo } from "hooks/upload";
import { ButtonSubmit } from "components/button/submit.button";
import { ImagePreview } from "./image-preview.component";
import { ImageWithZoom } from "components/image/image-zoom.component";
import { VideoPreview } from "./video-preview.component";
import { ModalCamera } from "components/modal/modal.camera";
import { EmojiPicker } from "components/emooji-picker.component";

export default function ModalReply({ visible, onClose, data, onSubmit }) {
	const playerRef = useRef(null);
	const textAreaRef = useRef(null);

	const [attachment, setAttachment] = useState(null);
	const [comment, setComment] = useState("");
	const [showModalCamera, setShowModalCamera] = useState(false);

	const { mutateAsync: uploadImageAsync, isLoading: isUploadingImage } = useUploadImage();
	const { mutateAsync: uploadVideoAsync, isLoading: isUploadingVideo } = useUploadVideo();

	const handleOnSubmit = async () => {
		const values = {
			parent: data?.parent || null,
			recipient_id: data?.commentator?.id,
			comment,
			threadId: data?.parent ? data?.topic_thread_id : data?.id,
		};
		const mediaType = attachment?.type?.split("/")[0];
		const mediaFile = attachment;
		const payload = _.cloneDeep(values);
		if (mediaType === "video") {
			const formData = new FormData();
			formData.append("video", mediaFile);
			formData.append("type", "discussion");
			const { data: uploadVideoResult } = await uploadVideoAsync({ formData });
			payload.attachment = uploadVideoResult.video_url;
		}
		if (mediaType === "image") {
			const formData = new FormData();
			formData.append("image", mediaFile);
			formData.append("type", "discussion");

			const { data: uploadImageResult } = await uploadImageAsync({ formData });
			payload.attachment = uploadImageResult.image_desktop;
		}

		onSubmit(payload);
	};

	const insertEmojiAtPost = value => {
		const { current: taRef } = textAreaRef;
		setComment(taRef.value + value.native);
	};

	return (
		<ModalContainer visible={visible}>
			<div id="comments" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[550px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-grey-70">Reply</div>

					<button
						type="button"
						className="absolute top-0 right-0 border-0"
						onClick={() => {
							onClose();
							setAttachment(null);
						}}>
						<span className="icon-ico-close"></span>
					</button>
				</div>
				<ul className="list-comment reply list-none">
					<li>
						<div className="w-[50px]">
							<img
								alt="user"
								src={data?.commentator?.profile_picture || "/img/default-user.png"}
								className="overflow-hidden w-[50px] h-[50px] object-cover object-center rounded-full"
							/>
						</div>
						<div className="w-[calc(100%-50px)] pl-3">
							<div className="flex items-center justify-between mb-2">
								<div className="w-[70%]">
									<div className="flex items-center">
										<div className="text-ellipsis w-auto whitespace-nowrap overflow-hidden text-sm font-semibold">
											{data?.commentator?.full_name}
										</div>
										<div className="text-grey-60 pl-2 text-sm whitespace-nowrap block">
											<span className="italic ">Replying to</span>
											<strong className="ml-2 text-bold inline-block text-grey-70">
												Main Topic
											</strong>
										</div>
									</div>
								</div>
								<div className="w-[30%]">
									<div className="text-right text-grey-60 text-sm italic">
										{getRelativeTime(data?.created_at)}
									</div>
								</div>
							</div>

							{data?.attachment && (
								<>
									{!isUrlMedia(data?.attachment).isImage ? (
										<div className="relative h-auto overflow-hidden rounded-xl mb-4 w-full">
											<Player
												ref={playerRef}
												playsInline
												src={data?.attachment}
												aspectRatio="16:9"
												key={`video-player-replies-${data?.id}`}
											/>
										</div>
									) : (
										<div className="rounded-xl mb-4 w-full h-[300px] overflow-hidden object-cover object-center">
											<ImageWithZoom
												src={data?.attachment}
												className="w-full rounded-lg overflow-hidden mb-4"
											/>
										</div>
									)}
								</>
							)}

							<div className="flex mb-2">
								<div className="w-full text-sm text-grey-70">{data?.comment}</div>
							</div>

							<div className="flex items-center">
								<div className="like">
									<input type="checkbox" id="like-6" className="hidden" checked={data?.is_liked} />
									<label for="like-6" className="cursor-pointer">
										<span className="text-grey-70 ml-2 text-sm">{data?.total_liked}</span>
									</label>
								</div>
								<div className="text-sm mx-5">
									<span className="icon-ico-bubble text-grey-40"></span>{" "}
									<span className="text-grey-70 ml-2">{data?.total_commented}</span>
								</div>
								<div className="text-sm">
									<span className="icon-ico-flag text-grey-40"></span>{" "}
									<span className="text-grey-70 ml-2">{data?.total_reported}</span>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div className="w-[50px]">
							<img
								src="/img/default-user.png"
								alt="Admin"
								className="overflow-hidden w-[50px] h-[50px] object-cover object-center rounded-full"
							/>
						</div>
						<div className="w-[calc(100%-50px)] pl-3">
							<div className="flex flex-col">
								<textarea
									ref={textAreaRef}
									onChange={e => setComment(e.target.value.trimStart())}
									value={comment}
									placeholder="Tulis apa yang kamu ingin sampaikan.."
									rows="5"
									className="w-full italic text-sm border-b border-solid border-green-10 outline-none focus:border-red-50"
								/>
							</div>

							<div className="flex items-center justify-between mt-2">
								<div className="w-auto flex items-center">
									{attachment && (
										<div
											style={{
												background: "#fbfbfb",
											}}>
											{attachment?.type?.split("/")[0] === "image" ? (
												<ImagePreview file={attachment} onRemove={() => setAttachment(null)} />
											) : (
												<VideoPreview file={attachment} onRemove={() => setAttachment(null)} />
											)}
										</div>
									)}
									{!attachment && (
										<>
											<EmojiPicker onSelect={insertEmojiAtPost} />
											<div className="upload-file">
												<input
													type="file"
													accept="image/*"
													id="file-1"
													className="hidden"
													onChange={e => setAttachment(e.target.files[0])}
												/>
												<label
													for="file-1"
													className="cursor-pointer transition-all rounded-md border border-solid border-grey-10 w-9 h-9 bg-white flex items-center justify-center hover:bg-red-hover hover:border-red-50">
													<img
														src="/img/icons/ico-img.svg"
														className="max-w-full"
														alt="Select Gallery"
													/>
												</label>
											</div>
											<div className="upload-file mx-2">
												<input
													type="file"
													accept="video/*"
													id="file-2"
													className="hidden"
													onChange={e => setAttachment(e.target.files[0])}
												/>
												<label
													for="file-2"
													className="cursor-pointer transition-all rounded-md border border-solid border-grey-10 w-9 h-9 bg-white flex items-center justify-center hover:bg-red-hover hover:border-red-50">
													<img
														src="/img/icons/ico-video.svg"
														className="max-w-full"
														alt="Select Video"
													/>
												</label>
											</div>
											<div className="upload-file">
												<label
													onClick={() => setShowModalCamera(true)}
													for="camera-modal"
													className="cursor-pointer transition-all rounded-md border border-solid border-grey-10 w-9 h-9 bg-white flex items-center justify-center hover:bg-red-hover hover:border-red-50">
													<img
														src="/img/icons/ico-camera.svg"
														alt="Open Camera"
														className="max-w-full"
													/>
												</label>
											</div>
										</>
									)}
								</div>
								<ButtonSubmit
									disabled={!comment}
									loading={isUploadingImage || isUploadingVideo}
									onClick={handleOnSubmit}
									className="disabled:opacity-40 rounded-lg bg-red-50 text-white px-5 font-semibold hover:bg-red-70 transition-all py-2">
									<span className="icon-ico-plane"></span> Reply
								</ButtonSubmit>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<ModalCamera
				visible={showModalCamera}
				onClose={() => setShowModalCamera(false)}
				onChange={({ file }) => setAttachment(file)}
			/>
		</ModalContainer>
	);
}
