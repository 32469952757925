import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { catchErrorMessage } from "utils/string";
import axios from "api/http.api";

export const ACTION_TYPE = {
	THREAD: "thread",
	THREAD_REPLY: "thread_reply",
};

export function useLikeThreadReply() {
	const queryClient = useQueryClient();
	const ACTION_LOG_QUERY = ["ACTIONS_LOG"];
	return useMutation(
		({ like, threadCommentId }) =>
			axios.post(`/api/v1/admin/thread/comment/like/${threadCommentId}`, { like }).then(res => res.data),
		{
			// handle optimistically update
			onMutate: async ({ threadId, like, threadCommentId }) => {
				await queryClient.cancelQueries(["THREAD_REPLY", { threadId }]);
				const previousThread = queryClient.getQueryData(["THREAD_REPLY", { threadId }]);
				queryClient.setQueryData(["THREAD_REPLY", { threadId }], old => {
					const newData = {
						...old,
						pages: old.pages.map(page => {
							return {
								...page,
								rows: page.rows.map(thread => {
									if (thread.id === threadCommentId) {
										return {
											...thread,
											is_liked: like,
											total_liked: like ? thread.total_liked + 1 : thread.total_liked - 1,
										};
									}
									return thread;
								}),
							};
						}),
					};
					return newData;
				});
				return { previousThread };
			},
			// handle error
			onError: (error, { threadId }, context) => {
				queryClient.setQueryData(["THREAD_REPLY", { threadId }], context.previousThread);
				toast.error(
					catchErrorMessage(
						error?.response?.data?.message ||
							error?.message ||
							"Something went wrong, please try again later.",
					),
				);
			},
			onSuccess: () => {
				queryClient.invalidateQueries(ACTION_LOG_QUERY);
			},
		},
	);
}

export function useLikeThread(topicId, filter) {
	const queryClient = useQueryClient();
	const THREAD_LIST_QUERY = ["THREAD_TOPIC", { topicId, filter }];
	const ACTION_LOG_QUERY = ["ACTIONS_LOG", { topicId }];
	return useMutation(
		({ threadId, like }) => axios.post(`/api/v1/admin/thread/like/${threadId}`, { like }).then(res => res.data),
		{
			// handle optimistically update
			onMutate: async ({ threadId, like }) => {
				await queryClient.cancelQueries(["THREAD_TOPIC", { topicId }]);
				const previousThread = queryClient.getQueryData(THREAD_LIST_QUERY);
				queryClient.setQueryData(THREAD_LIST_QUERY, old => {
					const newData = {
						...old,
						pages: old.pages.map(page => {
							return {
								...page,
								rows: page.rows.map(thread => {
									if (thread.id === threadId) {
										return {
											...thread,
											is_liked: like,
											total_liked: like ? thread.total_liked + 1 : thread.total_liked - 1,
										};
									}
									return thread;
								}),
							};
						}),
					};
					return newData;
				});
				return { previousThread };
			},
			// handle error
			onError: (error, { threadId }, context) => {
				queryClient.setQueryData(THREAD_LIST_QUERY, context.previousThread);
				toast.error(
					catchErrorMessage(
						error?.response?.data?.message ||
							error?.message ||
							"Something went wrong, please try again later.",
					),
				);
			},
			// handle success
			onSuccess: (data, { threadId }) => {
				queryClient.invalidateQueries(ACTION_LOG_QUERY);
			},
		},
	);
}

export function useDeleteThread(topicId, filter) {
	const queryClient = useQueryClient();
	const THREAD_LIST_QUERY = ["THREAD_TOPIC", { topicId, filter }];

	const ACTION_LOG_QUERY = ["ACTIONS_LOG", { topicId }];
	return useMutation(
		({ threadId, ...body }) =>
			axios.delete(`/api/v1/admin/thread/delete/${threadId}`, { data: body }).then(res => res.data),
		{
			// handle optimistically update
			onMutate: async ({ threadId, source, parentThreadId }) => {
				if (source === "topic_thread") {
					await queryClient.cancelQueries(["THREAD_TOPIC", { topicId }]);
					const previousThread = queryClient.getQueryData(THREAD_LIST_QUERY);
					queryClient.setQueryData(THREAD_LIST_QUERY, old => {
						const newData = {
							...old,
							pages: old.pages.map(page => {
								return {
									...page,
									rows: page.rows.filter(thread => thread.id !== threadId),
								};
							}),
						};
						return newData;
					});
					return { previousThread };
				} else {
					await queryClient.cancelQueries(["THREAD_REPLY", { threadId: parentThreadId }]);
					const previousThread = queryClient.getQueryData(["THREAD_REPLY", { threadId: parentThreadId }]);
					//  Remove reply from parent thread
					queryClient.setQueryData(["THREAD_REPLY", { threadId: parentThreadId }], old => {
						const newData = {
							...old,
							pages: old.pages.map(page => {
								return {
									...page,
									rows: page.rows.filter(thread => thread.id !== threadId),
								};
							}),
						};
						return newData;
					});

					queryClient.setQueryData(THREAD_LIST_QUERY, old => {
						const newData = {
							...old,
							pages: old.pages.map(page => {
								return {
									...page,
									rows: page.rows.map(thread => {
										if (thread.id === parentThreadId) {
											return {
												...thread,
												total_commented: thread.total_commented - 1,
											};
										}
										return thread;
									}),
								};
							}),
						};
						return newData;
					});
					return { previousThread };
				}
			},
			// handle error
			onError: (error, { threadId }, context) => {
				queryClient.setQueryData(THREAD_LIST_QUERY, context.previousThread);
				toast.error(
					catchErrorMessage(
						error?.response?.data?.message ||
							error?.message ||
							"Something went wrong, please try again later.",
					),
				);
			},
			onSuccess: (data, { parentThreadId }) => {
				queryClient.invalidateQueries(ACTION_LOG_QUERY);
				queryClient.invalidateQueries(["DISCUSSION_LIST", { topicId }]);
			},
		},
	);
}

export function useReplyThread(topicId, filter) {
	const queryClient = useQueryClient();
	const THREAD_LIST_QUERY = ["THREAD_TOPIC", { topicId, filter }];
	const ACTION_LOG_QUERY = ["ACTIONS_LOG", { topicId }];
	return useMutation(
		({ threadId, ...body }) => axios.post(`/api/v1/admin/thread/reply/${threadId}`, body).then(res => res.data),
		{
			// handle optimistically update
			onMutate: async ({ threadId, ...body }) => {
				await queryClient.cancelQueries(["THREAD_TOPIC", { topicId }]);
				const previousThread = queryClient.getQueryData(THREAD_LIST_QUERY);
				queryClient.setQueryData(THREAD_LIST_QUERY, old => {
					const newData = {
						...old,
						pages: old.pages.map(page => {
							return {
								...page,
								rows: page.rows.map(thread => {
									if (thread.id === threadId) {
										return {
											...thread,
											total_commented: thread.total_commented + 1,
										};
									}
									return thread;
								}),
							};
						}),
					};
					return newData;
				});
				return { previousThread };
			},
			// handle error
			onError: (error, { threadId }, context) => {
				queryClient.setQueryData(THREAD_LIST_QUERY, context.previousThread);
				toast.error(
					catchErrorMessage(
						error?.response?.data?.message ||
							error?.message ||
							"Something went wrong, please try again later.",
					),
				);
			},
			onSuccess: (data, { threadId }) => {
				queryClient.removeQueries(["THREAD_REPLY", { threadId }]);
				queryClient.invalidateQueries(ACTION_LOG_QUERY);
				queryClient.invalidateQueries(["DISCUSSION_LIST", { topicId }]);
			},
		},
	);
}
