import { useState } from "react";

export function CheckBoxComponent({
	containerClassName = "form-check ",
	inputClassName = "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer",
	labelClassName = "text-sm text-grey-70 font-semibold pl-3",
	onChange = () => {},
	label = "Label",
	initialValue = false,
	name = "",
}) {
	const [checked, setChecked] = useState(initialValue || false);
	return (
		<div
			className={containerClassName}
			onClick={() => {
				onChange(!checked);
				setChecked(!checked);
			}}>
			<input className={inputClassName} type="checkbox" name={name} checked={checked} readOnly />
			<label className={labelClassName}>{label}</label>
		</div>
	);
}
