import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useMemo } from "react";

import Layout from "components/layout";
import { useStateCallback } from "hooks/useStateCallback";
import { BounceLoading } from "components/loading/bounce.loading";
import { TostComponent } from "components/toast/toast.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalResult } from "components/modal/modal.result";
import { CreateQuestionComponent } from "pages/registration/test/create-question.component";
import { QuestionItemComponent } from "pages/registration/test/question.component";
import { useCreateQuizActivty, useGetQuizActivityByID } from "hooks/activity";

export function ActivityQuizPage() {
	const bottomDivRef = useRef();

	const { activityId } = useParams();
	const navigate = useNavigate();

	const [showFormAddQuestion, setShowFormAddQuestion] = useState(false);
	const { mutate: updateActivityQuestion, isLoading: isSubmitting } = useCreateQuizActivty();
	const { data: quizData, isLoading, refetch } = useGetQuizActivityByID({ activity_exam_id: activityId });

	const [showModalResult, setShowModalResult] = useState({ show: false, message: "", source: null });
	const [dataQuestions, setDataQuestions] = useStateCallback([]);

	/**
	 * The function takes a question object as an argument, finds the question in the unpublished array,
	 * removes it, sorts the array, and then sets the state with the new array.
	 * The function only work for unpublished questions.
	 *
	 * @param questionItemObject - The question object that is being deleted.
	 *
	 */
	const handleDeleteQuestion = questionItemObject => {
		let newDataQuestions = [...dataQuestions];
		// lodash find and remove item from array
		_.remove(newDataQuestions, {
			id: questionItemObject.id,
		});
		// sort array by sequence
		newDataQuestions = _.sortBy(newDataQuestions, "sequence").map((item, index) => {
			return { ...item, sequence: index + 1 };
		});

		setDataQuestions(newDataQuestions);
	};

	/**
	 * This function will handle once user click change option on a question.
	 * @param questionId - The id of the question that you want to change the option for.
	 * @param selectedOptionObject - The option object that was selected.
	 * @param questionStatus - This is the status of the question. It can be either published or
	 * unpublished.
	 */

	const handleChangeOption = (questionId, selectedOptionObject) => {
		let newDataQuestions = [...dataQuestions];
		const questionItemObject = _.find(newDataQuestions, { id: questionId });
		const newQuestionItemObject = { ...questionItemObject };
		const newOptions = [...newQuestionItemObject.options].map(item => ({ ...item, is_correct: false }));
		const selectedOptionIndex = newQuestionItemObject.options.indexOf(selectedOptionObject);
		newOptions[selectedOptionIndex] = { ...selectedOptionObject, is_correct: !selectedOptionObject.is_correct };
		newQuestionItemObject.options = newOptions;
		newDataQuestions = _.map(newDataQuestions, questionItem => {
			if (questionItem.id === questionId) {
				return newQuestionItemObject;
			}
			return questionItem;
		});
		setDataQuestions(newDataQuestions);
	};

	/**
	 * This function takes in a new question, adds a sequence number to it, and then adds it to the list of
	 * unpublished questions
	 */
	const handleAddNewQuestion = newQuestionData => {
		const newDataQuestion = { ...newQuestionData, sequence: dataQuestions.length + 1 };

		const bodyRequest = {
			activity_exam_id: activityId,
			exam_details: [...dataQuestions, newDataQuestion].map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
		};

		updateActivityQuestion(bodyRequest, {
			onSuccess: () => {
				setShowModalResult({
					show: true,
					message: "New question successfully added!",
					source: "CREATE_QUESTION",
				});
				setDataQuestions([...dataQuestions, newDataQuestion]);
				setShowFormAddQuestion(false);
				refetch();
			},
		});
	};

	const handleMoveQuestionOrder = (currentSequence, directionSequence) => {
		let newDataQuestions = [...dataQuestions];
		const currentQuestion = _.find(newDataQuestions, { sequence: currentSequence });
		const directionQuestion = _.find(newDataQuestions, { sequence: directionSequence });
		const currentQuestionIndex = newDataQuestions.indexOf(currentQuestion);
		const directionQuestionIndex = newDataQuestions.indexOf(directionQuestion);
		newDataQuestions[currentQuestionIndex] = { ...currentQuestion, sequence: directionSequence };
		newDataQuestions[directionQuestionIndex] = { ...directionQuestion, sequence: currentSequence };
		newDataQuestions = _.sortBy(newDataQuestions, "sequence");
		setDataQuestions(newDataQuestions);
	};

	const handleSubmitData = data => {
		/* Creating a new object with the data from the form and the questions. */
		const bodyRequest = {
			activity_exam_id: activityId,
			exam_details: dataQuestions.map(
				({ id, exam_registration_id, sequence, createdAt, deletedAt, is_published, updatedAt, ...item }) => ({
					...item,
					options: item.options.map(({ id, option_id, ...optionItem }) => optionItem),
				}),
			),
		};

		updateActivityQuestion(bodyRequest, {
			onSuccess: () => {
				setShowModalResult({ show: true, message: "Changes saved successfully!", source: "SAVE_BUTTON" });
				setShowFormAddQuestion(false);
			},
		});
	};

	const handleEditQuestion = (currentItem, editedData) => {
		const newDataQuestion = [...dataQuestions];
		const dataedited = {
			...currentItem,
			id: editedData.id,
			isEditing: false,
			image_desktop: editedData.image_desktop,
			image_mobile: editedData.image_mobile,
			options: editedData.options,
			question: editedData.question,
		};
		// find by id and replace in newUnplublishedQuestions
		const index = _.findIndex(newDataQuestion, { id: editedData.id });
		newDataQuestion[index] = dataedited;
		setDataQuestions(newDataQuestion);
	};

	const handleOnCloseModalResult = () => {
		if (showModalResult.source === "SAVE_BUTTON") {
			navigate("/activity/list");
		} else {
			setShowModalResult({ show: false, message: "" });
		}
	};

	const isEditingModeOn = useMemo(() => {
		return dataQuestions?.some(item => item.isEditing);
	}, [dataQuestions]);

	useEffect(() => {
		/* Setting the initial values of the formik form. */

		if (quizData) {
			setDataQuestions(
				quizData.data?.exam_details.map((item, index) => ({
					...item,
					isEditing: false,
					sequence: index + 1,
				})),
			);
		}
	}, [quizData, setDataQuestions]);

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-5"
			breadCumbTitle="Activity"
			breadCumbDescChild="Create Exam"
			breadCumbDesc="Create Activity">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<>
					<div className="flex">
						<div className="w-[65%] xl:w-1/2">
							<div className="text-title text-black font-semibold mb-4">Create Exam</div>

							{dataQuestions?.map((item, index) => {
								if (item.isEditing) {
									return (
										<CreateQuestionComponent
											onCancel={() => {
												const newUnpublishedQuestions = [...dataQuestions];
												newUnpublishedQuestions[index].isEditing = false;
												setDataQuestions(newUnpublishedQuestions);
											}}
											editingMode
											editId={item.id}
											onSubmitEditing={editedData => handleEditQuestion(item, editedData)}
											initialImage={{
												image_desktop: item.image_desktop,
												image_mobile: item.image_mobile,
											}}
											intialQuestion={item.question}
											initialOptions={item.options}
										/>
									);
								}
								return (
									<QuestionItemComponent
										disabled={isEditingModeOn}
										type="activity"
										disabledDownButton={dataQuestions?.length === index + 1}
										disabledUpButton={index === 0}
										key={index}
										image={item.image_desktop}
										questionId={item.id}
										sequence={item.sequence}
										question={item.question}
										onMoveOrder={handleMoveQuestionOrder}
										onEdit={() => {
											const newUnpublishedQuestions = [...dataQuestions];
											newUnpublishedQuestions[index].isEditing = true;
											setDataQuestions(newUnpublishedQuestions);
										}}
										onChangeOption={(questionId, selectedOptionObject) =>
											handleChangeOption(questionId, selectedOptionObject, "unpublished")
										}
										onDelete={() => handleDeleteQuestion(item)}
										options={item.options}
										published
										data={item}
									/>
								);
							})}
							{showFormAddQuestion && (
								<CreateQuestionComponent onSubmit={handleAddNewQuestion} disabled={isEditingModeOn} />
							)}
							<ButtonSubmit
								disabled={showFormAddQuestion || isEditingModeOn}
								onClick={() => setShowFormAddQuestion(true)}
								className="disabled:opacity-50 inline-block uppercase py-2.5 px-7 border text-center w-60 border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
								<span className="icon-ico-circle-plus mr-2"></span>
								Add New Field
							</ButtonSubmit>
						</div>
					</div>
					<div className="flex justify-center my-9" ref={bottomDivRef}>
						<div className="w-2/6">
							<ButtonSubmit
								disabled={isEditingModeOn || !dataQuestions.length}
								loading={isSubmitting}
								type="submit"
								onClick={handleSubmitData}
								title="Save All Changes"
								className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold disabled:cursor-not-allowed disabled:opacity-50"
							/>
						</div>
					</div>
				</>
			)}

			<ModalResult
				visible={showModalResult.show}
				title={false}
				onClose={handleOnCloseModalResult}
				message={showModalResult.message}
			/>
			<TostComponent
				visible={isEditingModeOn}
				title="Editing Mode On"
				message="You are not be able to delete, upload, and move any item until you finish with your current editing."
			/>
		</Layout>
	);
}
