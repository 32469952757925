import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import { useRef, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Layout from "components/layout";
import TextEditor, { DEFAULT_EDITOR_EMPTY_VALUE } from "components/text-editor";
import { useGetAwsTokenForUploadS3, useUploadToS3 } from "hooks/upload";
import { ButtonSubmit } from "components/button/submit.button";

import { UploadBannerComponent } from "pages/page-settings/upload-banner.component";
import { useDropzone } from "react-dropzone";
import {
	generateUploadingStateData,
	ModalUploadingWithList,
	UPLOADING_STATE_STATUS,
} from "components/modal/modal.uploading";
import { CreateGallerySchema } from "config/form/schema/page-setting";
import { useAddNewGallery } from "hooks/page-settings";
import { PreviewUploadFileGallery } from "./component/preview-image.component";
import { ModalResult } from "components/modal/modal.result";

const formInitialValues = {
	title: "",
	thumbnail: null,
	images_urls: [],
	description: JSON.stringify(DEFAULT_EDITOR_EMPTY_VALUE),
	is_published: "",
	status: "",
};

const MAX_FILE_COMPRESSION_SIZE_MB = 2;

export function CreateGallery() {
	const formikRef = useRef();
	const navigate = useNavigate();
	const [files, setFiles] = useState([]);
	const [mediaGallery, setMediaGallery] = useState([]);
	const [imageFileBuffer, setImageFileBuffer] = useState(null);
	const [uploadingStateData, setUploadingStateData] = useState([]);
	const [modalUploading, setModalUploading] = useState({
		visible: false,
		message: "",
	});

	const [modalResut, setModalResult] = useState({
		visible: false,
		message: "Gallery succesfully created!",
	});

	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		maxSize: 5 * 1024 * 1024,

		accept: {
			"image/jpeg": [],
			"image/png": [],
		},
		onDropAccepted: files => {
			setFiles(prev => {
				const existingFiles = prev.map(file => file.name);
				const newFiles = files.filter(file => !existingFiles.includes(file.name));
				return [...prev, ...newFiles];
			});

			const mediaPreview = files.map(file => {
				return {
					name: file.name,
					url: URL.createObjectURL(file),
					type: file.type?.split("/")[0],
				};
			});
			setMediaGallery(prev => {
				const existingPreviews = prev.map(media => media.name);
				const newPreviews = mediaPreview.filter(preview => !existingPreviews.includes(preview.name));
				return [...prev, ...newPreviews];
			});
		},
		onDropRejected: files => {
			const fileNameAndErrorMessage = files.map(
				errorFile => `${errorFile.file.name} - ${errorFile.errors[0].message}`,
			);
			toast.error(`${fileNameAndErrorMessage.join("\n\n ")}`);
		},
	});

	const { mutate: createNewGallery, isLoading: isSubmitting } = useAddNewGallery();
	const { mutateAsync: getS3BucketToken } = useGetAwsTokenForUploadS3();
	const { mutateAsync: uplaodDataToS3, isUploading } = useUploadToS3();

	const handleSubmitData = async payloadData => {
		setModalUploading({
			visible: true,
			message: "Preparing to upload images...",
		});
		try {
			if (imageFileBuffer) {
				const formData = new FormData();
				const fileName = imageFileBuffer.name;
				const thumbnailUploadToken = await getS3BucketToken({
					files: [
						{
							name: fileName,
							type: "thumbnail",
						},
					],
				});
				const thumbnailPreSignedURL = thumbnailUploadToken.data?.[0]?.fields;

				for (const key in thumbnailPreSignedURL) {
					formData.append(key, thumbnailPreSignedURL[key]);
				}

				const compressedFile = await imageCompression(imageFileBuffer, {
					maxSizeMB: MAX_FILE_COMPRESSION_SIZE_MB,
					maxIteration: 10,
					exifOrientation: 1,
				});
				formData.append("file", compressedFile);
				setModalUploading(prev => ({
					...prev,
					message: "Uploading thumbnail...",
				}));
				await uplaodDataToS3({ formData, url: thumbnailUploadToken.data?.[0]?.url });
				payloadData.thumbnail = {
					url: thumbnailUploadToken.data?.[0]?.url + thumbnailPreSignedURL.key,
				};
			}

			const uploadResultsWithFilenames = [];

			const uploadingState = generateUploadingStateData(files);
			setModalUploading(prev => ({
				...prev,
				message: null,
			}));
			setUploadingStateData(uploadingState);

			// Request to upload images
			const galleryImagesUploadToken = await getS3BucketToken({
				files: files.map(file => ({
					name: file.name,
					type: "ordinary",
				})),
			});

			const mapKeyGalleryImages = new Map();
			const mapKeyWithURL = new Map();
			for (const uploadToken of galleryImagesUploadToken.data) {
				const key = uploadToken.filename;
				mapKeyGalleryImages.set(key, uploadToken.fields);
				mapKeyWithURL.set(key, uploadToken.url);
			}

			for (const file of files) {
				const url = mapKeyWithURL.get(file.name);

				const signedFieldsData = mapKeyGalleryImages.get(file.name);
				const formData = new FormData();

				const fileIndex = uploadingState.findIndex(state => state.fileName === file.name);

				for (const key in signedFieldsData) {
					formData.append(key, signedFieldsData[key]);
				}

				setUploadingStateData(prev => {
					const updatedState = [...prev];
					updatedState[fileIndex].status = UPLOADING_STATE_STATUS.COMPRESSING;
					return updatedState;
				});
				const compressedGalleryFile = await imageCompression(file, {
					maxSizeMB: MAX_FILE_COMPRESSION_SIZE_MB,
					maxIteration: 10,
					exifOrientation: 1,
				});

				formData.append("file", compressedGalleryFile);

				try {
					setUploadingStateData(prev => {
						const updatedState = [...prev];
						updatedState[fileIndex].status = UPLOADING_STATE_STATUS.PROCESSING;
						return updatedState;
					});
					await uplaodDataToS3({
						formData,
						url,
					});

					const FINAL_URL = url + signedFieldsData.key;

					uploadResultsWithFilenames.push({
						url: FINAL_URL,
						fileName: file.name,
					});

					setUploadingStateData(prev => {
						const updatedState = [...prev];
						updatedState[fileIndex].status = UPLOADING_STATE_STATUS.COMPLETE;
						return updatedState;
					});
				} catch (error) {
					setUploadingStateData(prev => {
						const updatedState = [...prev];
						updatedState[fileIndex].status = UPLOADING_STATE_STATUS.ERROR;
						return updatedState;
					});
				}
			}
			const uploadResultURLs = uploadResultsWithFilenames.map(result => result.url);
			payloadData.images_urls = uploadResultURLs.filter(Boolean).map(url => ({ url }));

			createNewGallery(payloadData);
		} catch (error) {
			toast.error(error?.message || error.response?.data?.message || "Something went wrong");
		}
	};

	const handleOnModalUploadingClose = () => {
		setModalUploading(prev => ({
			...prev,
			visible: false,
		}));

		setUploadingStateData([]);
		setModalResult(prev => ({ ...prev, visible: true }));
	};

	const handleSubmitForm = eventStatus => {
		formikRef.current.setFieldValue("status", eventStatus);
		formikRef.current.handleSubmit();
	};

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-5"
			breadCumbTitle="Gallery"
			breadCumbDesc="Create Gallery">
			<div className="text-title text-black font-semibold">Create Gallery</div>
			<hr className="border-grey-10 mt-3 mb-7" />

			<Formik
				validationSchema={CreateGallerySchema}
				innerRef={formikRef}
				initialValues={formInitialValues}
				onSubmit={handleSubmitData}>
				{({ values, handleBlur, handleChange, touched, errors, handleSubmit, setFieldValue }) => {
					return (
						<>
							<div className="mb-4">
								<div className="text-sm text-black font-semibold">Gallery Title</div>
								<div className="w-full">
									<input
										name="title"
										value={values.title}
										onChange={handleChange}
										onBlur={handleBlur}
										type="text"
										className="border-grey-10 outline-none w-full border-l-0 border-t-0 border-r-0 border-b text-2xl border-solid px-4 py-3 text-black transition-all focus:bg-white focus:border-t-0 focus:border-r-0 focus:border-l-0 hover:border-t-0 hover:border-r-0 hover:border-l-0 focus:text-black focus:border-red-50 focus:outline-none focus:shadow-none"
										placeholder="Write gallery title here"
									/>
									{errors.title && touched.title && (
										<p className="form-input-error">{errors.title}</p>
									)}
								</div>
							</div>
							<div className="flex justify-between">
								<div className="w-[48%]">
									<UploadBannerComponent
										imageClassName="w-full h-96 object-cover object-center"
										name="image_desktop"
										onChange={imageFileBuffer => {
											setImageFileBuffer(imageFileBuffer["image_desktop"]);
											formikRef.current.setFieldValue(
												"image_buffer",
												imageFileBuffer["image_desktop"],
											);
										}}
										title="Thumbnail"
									/>

									<div className="text-sm text-black font-semibold mb-3">Description</div>
									<TextEditor
										onChange={nodeValue => setFieldValue("description", nodeValue)}
										value={values.description && JSON.parse(values.description)}
									/>
								</div>
								<div className="w-[48%]">
									<div className="mb-4">
										<div className="text-sm text-black font-semibold mb-3">Images</div>

										<button
											{...getRootProps({ className: "drag-item mb-4 cursor-pointer" })}
											type="button"
											className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
											<input {...getInputProps()} />
											<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>

											<span className="mt-2 block text-sm font-semibold text-gray-900">
												<span className="text-red-50">Click to upload </span>or drag and drop
											</span>
											<span className="mt-2 block text-sm font-semibold text-gray-900">
												JPG, JPEG, or PNG (MAX 5MB)
											</span>
										</button>
									</div>
									<div className="mb-4">
										<div className="text-sm text-black font-semibold mb-3">Gallery Images</div>
										{mediaGallery.length > 0 && (
											<PreviewUploadFileGallery
												imageUrls={mediaGallery}
												onDeleteFile={index => {
													setFiles(files.filter((_, i) => i !== index));
													setMediaGallery(mediaGallery.filter((_, i) => i !== index));
												}}
											/>
										)}
									</div>
								</div>
							</div>

							<div className="flex items-center justify-center my-9">
								<div className="w-3/12 mx-1">
									<ButtonSubmit
										loading={isUploading || isSubmitting}
										disabled={
											!CreateGallerySchema.isValidSync(values) ||
											!imageFileBuffer ||
											!mediaGallery.length
										}
										onClick={() => handleSubmitForm("draft")}
										className="flex items-center justify-center disabled:cursor-not-allowed w-full px-3 py-2 font-semibold text-black transition-all border border-solid rounded-lg disabled:opacity-50 border-grey-10 hover:bg-red-hover hover:border-red-50"
										type="button">
										<span className="icon-ico-sort text-red-50 text-xl"></span>
										<span className="pl-3">Save as Draft</span>
									</ButtonSubmit>
								</div>
								<div className="w-3/12 mx-1">
									<ButtonSubmit
										disabled={
											!CreateGallerySchema.isValidSync(values) ||
											!imageFileBuffer ||
											!mediaGallery.length
										}
										loading={isUploading || isSubmitting}
										onClick={() => handleSubmitForm("publish")}
										className="disabled:opacity-50 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										type="button">
										<span className="icon-ico-save mr-2"></span> Create Gallery
									</ButtonSubmit>
								</div>
							</div>
						</>
					);
				}}
			</Formik>

			<ModalResult
				visible={modalResut.visible}
				title={false}
				message={modalResut.message}
				onClose={() => {
					setModalResult(prev => ({ ...prev, visible: false }));
					navigate("/page-setting/gallery");
				}}
			/>

			<ModalUploadingWithList
				title="Creating Gallery"
				visible={modalUploading.visible}
				message={modalUploading.message}
				uploadingData={uploadingStateData}
				onClose={handleOnModalUploadingClose}
			/>
		</Layout>
	);
}
