import * as Yup from "yup";
import dayjs from "dayjs";

import { websiteUrlRegExp } from "../regexValidation";

/**
 * Form validation schema for /activity/crete
 *
 */

const REDIRECT_URL_VALIDATION_SCHEMA = Yup.array()
	.of(
		Yup.object().shape({
			title: Yup.string().required("URL Title is required"), // these constraints take precedence
			url: Yup.string().matches(websiteUrlRegExp, "URL is not valid").required("URL is required"), // these constraints take precedence
		}),
	)
	.required("Must have at lease one url") // these constraints are shown if and only if inner constraints are satisfied
	.min(1, "Must have at lease one url");

export const MultipleLinkValidationSchema = Yup.object().shape({
	redirect_urls: REDIRECT_URL_VALIDATION_SCHEMA,
});
export const CreateActivityValidatioSchema = Yup.object()
	.shape({
		introduction: Yup.object({
			title: Yup.string().required("Title is required"),
			activity_category_id: Yup.string().required("Activity category is required"),
			total_point: Yup.string().required("Total point  is required"),
			level_id: Yup.string().required("Level is required"),
			start_date: Yup.string().required("Start Date is required"),
			end_date: Yup.string()
				.test("end_date", "End Date must be greater than Start Date", function (value) {
					return dayjs(value).isAfter(this.parent.start_date);
				})
				.required("End Date is required"),
		}),
		class_material: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required"),
				})
				.nullable(),
		}),
		class_video_conference: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required"),
				})
				.nullable(),
		}),
		registration: Yup.object({
			is_active: Yup.boolean(),
			start_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Start Date is required"),
				})
				.nullable(),
			end_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.test("end_date", "End Date must be greater than Start Date", function (value) {
							return dayjs(value).isAfter(this.parent.start_date);
						})
						.required("End Date is required"),
				})
				.nullable(),
		}),
		attendance: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required"),
				})
				.nullable(),
			point: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Point is required"),
				})
				.nullable(),
			code: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Presence Code is required"),
				})
				.nullable(),
			start_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Start Date is required"),
				})
				.nullable(),
			end_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.test("end_date", "End Date must be greater than Start Date", function (value) {
							return dayjs(value).isAfter(this.parent.start_date);
						})
						.required("End Date is required"),
				})
				.nullable(),
		}),
		challenge: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required").nullable(),
				})
				.nullable(),
			point: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Point is required"),
				})
				.nullable(),
			example_redirect_url: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.matches(websiteUrlRegExp, "URL is not valid")

						.nullable(),
				})
				.nullable(),
			start_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Start Date is required"),
				})
				.nullable(),
			end_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.test("end_date", "End Date must be greater than Start Date", function (value) {
							return dayjs(value).isAfter(this.parent.start_date);
						})
						.required("End Date is required"),
				})
				.nullable(),
		}),
		review: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required"),
				})
				.nullable(),
			point: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Point is required"),
				})
				.nullable(),
			start_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Start Date is required"),
				})
				.nullable(),
			end_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.test("end_date", "End Date must be greater than Start Date", function (value) {
							return dayjs(value).isAfter(this.parent.start_date);
						})
						.required("End Date is required"),
				})
				.nullable(),
		}),
		exam: Yup.object({
			is_active: Yup.boolean(),
			title: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Title is required"),
				})
				.nullable(),
			point: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Point is required"),
				})
				.nullable(),
			duration: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Duration is required"),
				})
				.nullable(),
			start_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string().required("Start Date is required"),
				})
				.nullable(),
			end_date: Yup.string()
				.when("is_active", {
					is: true,
					then: Yup.string()
						.test("end_date", "End Date must be greater than Start Date", function (value) {
							return dayjs(value).isAfter(this.parent.start_date);
						})
						.required("End Date is required"),
				})
				.nullable(),
		}),
	})
	.test({
		name: "atLeastOneWorkRequired",
		test: function (values) {
			const isValid = [
				"class_material",
				"class_video_conference",
				"registration",
				"attendance",
				"review",
				"exam",
				"challenge",
			].some(field => values[field]?.is_active);

			if (isValid) return true;
			return this.createError({
				path: "class_material.is_active | class_video_conference.is_active | registration.is_active | attendance.is_active | review.is_active | exam.is_active",
				message: "One field must be set",
			});
		},
	});
