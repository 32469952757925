import { useRef } from "react";

export function TooltipComponent({ children, tooltipText, enabled }) {
	const tipRef = useRef(null);
	function handleMouseEnter() {
		tipRef.current.style.opacity = 1;
	}
	function handleMouseLeave() {
		tipRef.current.style.opacity = 0;
	}

	if (!enabled) return children;

	return (
		<div className="relative flex items-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div
				className="mb-4 absolute whitespace-no-wrap bg-black text-white px-4 py-2 rounded flex items-center transition-all duration-150"
				style={{ bottom: "100%", opacity: 0 }}
				ref={tipRef}>
				<div className="bg-black h-3 w-3 absolute" style={{ bottom: "-6px", transform: "rotate(45deg)" }} />
				<p className="font-medium text-xs">{tooltipText}</p>
			</div>
			{children}
		</div>
	);
}
