import { useState, useMemo } from "react";
import { useParams } from "react-router";

import Layout from "components/layout";

import { SectionSidebar } from "pages/page-settings/section-sidebar.component";

export function MemberProfileLayout({ children, sidebarMenu = "PROFILE", title, desc }) {
	const { userId, userStatus } = useParams();

	const ROUTES = useMemo(
		() => [
			{
				id: "member-profile",
				label: "Profile",
				path: `/member/profile-details/${userStatus}/${userId}`,
			},
			{
				id: "member-beauty-profile",
				label: "Beauty Profile",
				path: `/member/beauty-profile/${userStatus}/${userId}`,
			},
			{
				id: "member-point-history",
				label: "Point History",
				path: `/member/point-history/${userStatus}/${userId}`,
			},
			{
				id: "member-activity-history",
				label: "Activity History",
				path: `/member/activity-history/${userStatus}/${userId}`,
			},
			{
				id: "member-account",
				label: "Account",
				path: `/member/account/${userStatus}/${userId}`,
			},
		],
		[userId, userStatus],
	);
	const [currentSection, setCurrentSection] = useState(ROUTES[0].id);

	return (
		<Layout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			breadCumbTitle={title}
			breadCumbDesc={desc}>
			<SectionSidebar
				menu={ROUTES}
				currentSection={currentSection}
				onChange={sectionId => setCurrentSection(sectionId)}
			/>
			{children}
		</Layout>
	);
}
