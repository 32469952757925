import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { GalleryItem } from "./component/gallery-item";

import { urlSafeEncrypt } from "utils/encryption";
import { useDeleteGallery, useGetGalleryList } from "hooks/page-settings";
import { Pagination } from "components/pagination.component";

const sortOptions = [
	{ label: "Tanggal Buat Gallery Terbaru", sortType: "desc", sortBy: "created_at" },
	{ label: "Tanggal Buat Gallery Terdahulu", sortType: "asc", sortBy: "created_at" },
];

// Main Page Component
export function GallerySetting() {
	const navigate = useNavigate();
	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState(false);

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "created_at",
		order: "desc",
		search: "",
	});

	// Hooks API Call
	const { data, refetch: refetchGalleries, isLoading } = useGetGalleryList({ filter: queryFilter });
	const { mutate: deleteGallery, isLoading: isDeleting } = useDeleteGallery();

	const handleDeleteEvent = () => {
		deleteGallery(
			{ galleryId: urlSafeEncrypt(modalConfirmation.data.id) },
			{
				onSuccess: () => {
					refetchGalleries();
					setModalConfirmation({ data: null, showModal: false });
					setShowModalResult(true);
				},
			},
		);
	};

	return (
		<Layout breadCumbTitle="Gallery" breadCumbDesc="Gallery List" buttonToTop>
			<div className="flex items-center justify-between mb-7">
				<div className="sm:w-[75%] xl:w-[75%] flex">
					<SearchFilterComponent
						containerClassName="relative sm:w-1/3 md:w-[40%] xl:w-2/5 mr-2"
						placeholder="Cari Gallery..."
						onSearch={search => {
							setQueryFilter(prevState => ({ ...prevState, search: search.keyword }));
						}}
					/>

					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
				</div>

				<div className="flex sm:w-[25%] lg:w-[19%] justify-end">
					<div className="">
						<Link to="/page-setting/gallery/create">
							<button
								className="flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
								type="button">
								<span className="icon-ico-circle-plus mr-2"></span> Create Gallery
							</button>
						</Link>
					</div>
				</div>
			</div>

			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}

			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="grid grid-cols-3 xl:grid-cols-4 gap-4">
					{data?.data?.rows.map(item => (
						<GalleryItem
							key={item.id}
							{...item}
							onEdit={() => {
								navigate(`/page-setting/gallery/edit/${urlSafeEncrypt(item.id)}`);
							}}
							onDelete={() => {
								setModalConfirmation({
									data: item,
									showModal: true,
								});
							}}
						/>
					))}
				</div>
			)}

			<Pagination
				currentPage={queryFilter.page}
				pageSize={queryFilter.limit}
				siblingCount={1}
				onPageChange={selectedPage => {
					window.scrollTo(0, 0);

					setQueryFilter({ ...queryFilter, page: selectedPage });
				}}
				totalCount={data?.data?.total_result || 0}
			/>

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				message={`Are you sure want to delete gallery "${modalConfirmation.data?.title}"?`}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteEvent()}
				loading={isDeleting}
			/>
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Delete gallery success!"
			/>
			<TableSortModalComponent
				options={sortOptions}
				initialValue={sortOptions[0]}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</Layout>
	);
}
