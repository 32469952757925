import axios from "api/http.api";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setBasicProfile } from "store/reducer/auth.reducer";

export function useProfileData() {
	const dispatch = useDispatch();
	return useQuery(["PROFILE_USER"], () => axios.get(`/api/v1/admin/account/profile`).then(res => res.data), {
		onSuccess: data => {
			dispatch(setBasicProfile(data?.data));
		},
	});
}
