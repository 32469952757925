export function getMemberActivityStatusColorStyle(status) {
	switch (status) {
		case "SEDANG BERLANGSUNG": {
			return {
				textClassName: "text-main-blue",
				bgClassName: "bg-light-blue",
			};
		}
		case "SUDAH DISELESAIKAN": {
			return {
				textClassName: "text-main-green",
				bgClassName: "bg-light-green",
			};
		}
		case "TIDAK DISELESAIKAN": {
			return {
				textClassName: "text-red-60",
				bgClassName: "bg-light-pink",
			};
		}
		default: {
			return {
				textClassName: "text-main-blue",
				bgClassName: "bg-light-blue",
			};
		}
	}
}

export function getColorStyleByLevel(level) {
	switch (level) {
		case "beginner": {
			return "bg-light-tosca text-main-tosca";
		}
		case "intermediate": {
			return "bg-light-purple text-main-purple";
		}
		case "advance": {
			return "bg-light-pink text-main-pink";
		}
		case "graduate": {
			return "bg-light-green text-main-green";
		}
		default: {
			return "bg-light-tosca text-main-tosca";
		}
	}
}
