import clsx from "clsx";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLogout, useProfileData } from "hooks/auth";
import { getInitialsName, convertToTitleCase } from "utils/string";
import { logout } from "store/reducer/auth.reducer";

export function HeaderComponent({ breadCumbTitle = "Title", breadCumbDesc = "Description", breadCumbDesc2 = null }) {
	const dispatch = useDispatch();
	const [showDropdown, setShowDropdown] = useState(false);
	const { profile } = useSelector(state => state.auth);
	const { mutate: destroySession } = useLogout();
	useProfileData();

	const handleOnLogout = () => {
		dispatch(logout());
		localStorage.clear();
		destroySession();
	};

	return (
		<div className="flex justify-between items-center mb-8">
			<ul className="flex items-center breadcrumd">
				<li className="flex pr-4 items-center">
					<h2 className="text-red-50 font-bold ">{breadCumbTitle}</h2>
				</li>
				<li className="flex pr-4">
					<div className="text-red-50 font-medium">{breadCumbDesc}</div>
				</li>
				{breadCumbDesc2 && (
					<li className="flex">
						<div className="text-red-50 font-medium">{breadCumbDesc2}</div>
					</li>
				)}
			</ul>

			<div className="dropdown relative">
				<button
					className={clsx(
						"leading-tight rounded-lg profile px-3 py-2 relative flex items-center profile-shadow text-left ",
						showDropdown && "show",
					)}
					type="button"
					onClick={() => setShowDropdown(!showDropdown)}>
					<div className="flex items-center">
						<div className="initial">{getInitialsName(profile?.full_name)}</div>
						<div className="w-48 overflow-hidden pl-3">
							<div className="text-red-50 font-semibold text-sm text-ellipsis whitespace-nowrap">
								{profile.full_name}
							</div>
							<div className="text-red-50 text-sm italic">{convertToTitleCase(profile?.role.name)}</div>
						</div>
					</div>
				</button>
				<ul
					className={clsx(
						`
              dropdown-menu
              min-w-max
              absolute
              hidden
              bg-white
              text-base
              z-50
              float-left
              py-2
              list-none
              text-left
              rounded-lg
              shadow-lg
              mt-1
              m-0
              bg-clip-padding
              border-none
              w-[280px]
          `,
						showDropdown && "show",
					)}
					aria-labelledby="drop-1">
					<li>
						<button
							className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  text-center
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-red-menu
                  hover:text-white
                  font-medium
                ">
							Profile
						</button>
					</li>
					<li>
						<button
							onClick={handleOnLogout}
							className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  block
                  text-center
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-gray-700
                  hover:bg-red-menu
                  hover:text-white
                  font-medium
                ">
							Log Out
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
}
