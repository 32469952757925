import clsx from "clsx";
import { InputComponent } from "components/form/input.component";

import TextEditor from "components/text-editor";
import { UploadBannerComponent } from "../upload-banner.component";
import { IsJsonString } from "utils/string";

export function SliderBenefitComponent({
	bannerTitle = { desktop: "Desktop Banner Title", mobile: "Mobile Banner Title" },
	data,
	onExpand,
	onDelete,
	onMove,
	errors,
	touched,
	onChange,
	handleOnBlur,
	onChangeName,
	onChangeInput,
	onChangeDesc,
	title,
	expand,
	disabledDelete,
	disabledUpButton,
	disabledDownButton,
}) {
	const handleChangeInputColor = e => {
		onChangeInput(e.target.value);
	};

	return (
		<div className="overflow-hidden rounded-lg border border-solid border-grey-10 mb-4">
			<div
				class={clsx(
					"items-tab flex items-center justify-between py-2.5 px-4 bg-[#F2F2F2] tracking-wide hover:bg-[rgba(0,0,0,0.1)] transition duration-150 ease-in-out ",
					expand && " collapsed",
				)}>
				<div className="text-black text-base font-bold">{title}</div>
				<div className="flex">
					<button
						disabled={disabledUpButton}
						className="disabled:opacity-30 flex items-center bg-white w-10 h-10 mr-2 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("UP")}>
						<span className="icon-ico-arrow-up text-red-50 text-xl"></span>
					</button>
					<button
						disabled={disabledDownButton}
						className="disabled:opacity-30  flex items-center bg-white w-10 h-10 mr-5 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("DOWN")}>
						<span className="icon-ico-arrow-down text-red-50 text-xl"></span>
					</button>

					<button
						onClick={onExpand}
						className="items-btn flex items-center bg-white w-10 h-10 rounded-full justify-center border text-sm border-solid border-grey-10  text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span class={clsx("text-red-50 text-base", expand ? "icon-ico-down" : "icon-ico-up")}></span>
					</button>
				</div>
			</div>
			<div class={clsx("collapse", expand && "show")} id="items-1">
				<div className="flex justify-between p-4">
					<div className="w-[48%]">
						<UploadBannerComponent
							title="Desktop Icon  (Aspect Ration 1:1)"
							showDeleteButton={false}
							imageClassName="w-auto mx-auto h-44 object-cover object-center"
							initialImage={data.image_desktop}
							name={`image_desktop-${data.id}`}
							onChange={imageFileBuffer => {
								onChange(imageFileBuffer);
							}}
						/>
						<UploadBannerComponent
							title="Mobile Icon  (Aspect Ration 1:1)"
							imageClassName="w-auto mx-auto h-44 object-cover object-center"
							showDeleteButton={false}
							initialImage={data.image_mobile}
							name={`image_mobile-${data.id}`}
							onChange={imageFileBuffer => {
								onChange(imageFileBuffer);
							}}
						/>
					</div>
					<div className="w-[48%]">
						{onChangeName && (
							<InputComponent
								error={touched?.name && errors?.name}
								containerClass="mb-4"
								labelclassName="text-sm text-black font-semibold mb-3"
								label="Name"
								placeholder="Insert Name"
								onBlur={handleOnBlur}
								name="name"
								value={data.name}
								onChange={onChangeName}
							/>
						)}

						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(data.initialDescription) ? data.initialDescription : undefined
								}
								onChange={nodesValue => onChangeDesc(nodesValue)}
							/>
						</div>
						{onChangeInput && (
							<div className="mb-4">
								<div className="text-sm text-black font-semibold mb-3">Background Color</div>
								<InputComponent
									type="color"
									value={data.background_color}
									onChange={handleChangeInputColor}
									onBlur={handleOnBlur}
									name="background_color"
									error={touched?.background_color && errors?.background_color}
									placeholder="click to select color"
									presetColors={[
										"#e985cf",
										"#7fb2e5",
										"#fc86a6",
										"#b9c697",
										"#ee9fab",
										"#f4c78b",
										"#88c4d8",
									]}
									inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="border-t border-grey-10 py-3 px-4 flex justify-end">
					<button
						onClick={onDelete}
						disabled={disabledDelete}
						className="disabled:opacity-30 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span className="icon-ico-delete text-red-50 text-xl"></span>
						<span className="pl-2">Delete</span>
					</button>
				</div>
			</div>
		</div>
	);
}
