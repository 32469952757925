import dayjs from "dayjs";
import dayjsPluginDuration from "dayjs/plugin/duration";
import { Formik } from "formik";
import { useState, useRef, useEffect } from "react";
import { Player } from "video-react";
import { toast } from "react-hot-toast";

import { isSelfHostedVideo } from "utils/string";
import { InputComponent } from "components/form/input.component";

dayjs.extend(dayjsPluginDuration);

const options = [
	{
		id: "video-url",
		label: "Video URL",
	},
	{
		id: "video-file",
		label: "Video File",
	},
];

const MAX_BYTES_VIDEO = 100000000; // 100 MB

export function UploadVideoComponent({ onChange, initialValue, name, showDeleteButton = false }) {
	const formikRef = useRef(null);
	const [selectedOptionId, setSelectedOptionId] = useState(
		isSelfHostedVideo(initialValue) ? options[1].id : options[0].id,
	);
	const [videoUrl, setVideoUrl] = useState(isSelfHostedVideo(initialValue) ? initialValue : null);

	const [showLimitFileMessage, setShowLimitFileMessage] = useState(false);

	const handleUploadVideo = async e => {
		const file = e.target.files[0];

		if (/\.(mp4)$/i.test(file.name)) {
			// 100 MB

			if (file.size > MAX_BYTES_VIDEO) {
				setShowLimitFileMessage(true);
				return;
			} else {
				setShowLimitFileMessage(false);
			}

			setVideoUrl(URL.createObjectURL(file));
			onChange({
				[name]: {
					type: selectedOptionId,
					data: file,
				},
			});
		} else {
			toast.error("Video type is not supported, only mp4 are allowed");
		}
	};
	const handleChangeFormInput = e => {
		onChange({
			[name]: {
				type: selectedOptionId,
				data: e.target.value,
			},
		});
	};

	const handleChangeVideoTypeOption = itemOption => {
		setSelectedOptionId(itemOption.id);
		onChange({
			[name]: {
				type: itemOption.id,
				data: null,
			},
		});
	};

	useEffect(() => {
		if (!isSelfHostedVideo(initialValue)) {
			formikRef.current.setFieldValue("redirect_url_video", initialValue);
		}
	}, [initialValue]);

	return (
		<Formik innerRef={formikRef} initialValues={{ redirect_url_video: "" }}>
			{({ handleChange, handleBlur, values }) => (
				<div className="mb-4">
					<div className="text-sm text-black font-semibold mb-3">Input Video</div>
					<div className="flex items-center mb-5">
						{options.map(itemOption => (
							<div className="w-2/5">
								<div className="test-item py-3 relative">
									<input
										onChange={() => handleChangeVideoTypeOption(itemOption)}
										type="radio"
										name="video"
										className="hidden"
										id={itemOption.id}
										checked={itemOption.id === selectedOptionId}
									/>
									<label
										className="cursor-pointer flex items-center font-semibold text-black text-sm"
										htmlFor={itemOption.id}>
										<span className="pl-4">{itemOption.label}</span>
									</label>
								</div>
							</div>
						))}
					</div>

					{selectedOptionId === "video-url" && (
						<div className="mb-4">
							<InputComponent
								label="Video URL"
								name="redirect_url_video"
								placeholder="Example: https://www.youtube.com/watch?v=blablabla"
								onChange={e => {
									handleChange(e);
									handleChangeFormInput(e);
								}}
								onBlur={handleBlur}
								labelclassName="text-sm text-black font-semibold mb-3"
								value={values.redirect_url_video}
							/>
						</div>
					)}

					{showLimitFileMessage && (
						<p className="form-input-error">Video file size must be less than 100MB</p>
					)}

					{selectedOptionId === "video-file" && (
						<>
							<div className="rounded-md">
								{isSelfHostedVideo(videoUrl) || videoUrl ? (
									<Player playsInline src={videoUrl} aspectRatio="16:9" />
								) : null}
							</div>
							<div className="flex justify-center mt-5 mb-5">
								<input
									type="file"
									className="hidden"
									id="upload-video-file"
									onChange={handleUploadVideo}
									name="video"
								/>
								<label
									className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
									htmlFor="upload-video-file">
									<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
									<span className="pl-2">Upload Video</span>
								</label>
								{showDeleteButton && videoUrl && (
									<label
										onClick={() => {
											setVideoUrl(null);
											onChange({
												[name]: {
													type: selectedOptionId,
													data: null,
												},
											});
										}}
										className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
										<span className="icon-ico-delete text-red-50 mr-1 text-xl"></span>
										<span className="pl-2">Remove Video</span>
									</label>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</Formik>
	);
}
