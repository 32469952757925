import clsx from "clsx";
import { useState, useEffect } from "react";

import { ModalContainer } from "components/modal/modal.container";
import { convertToTitleCase } from "utils/string";

const OPTIONS_STATUS = [
	{
		label: "Waiting Review",
		value: "waiting review",
	},
	{
		label: "Failed",
		value: "failed",
	},
	{
		label: "Passed",
		value: "passed",
	},
];

export function ModalExamFilter({
	visible,
	initialValue,
	onClose,
	options = OPTIONS_STATUS,
	onChange,
	filtersInitialValue,
}) {
	const [filterValue, setFilterValue] = useState(
		filtersInitialValue || {
			min_correct_answer: null,
			max_correct_answer: null,
		},
	);
	const [selected, setSelected] = useState([]);

	const handleValueChange = e => {
		const { name, value } = e.target;
		setFilterValue({ ...filterValue, [name]: value ? +value.replace(/\D/g, "") : null });
	};

	useEffect(() => {
		if (initialValue && visible) {
			setSelected(initialValue?.exam_status);

			setFilterValue({
				min_correct_answer: initialValue?.min_correct_answer,
				max_correct_answer: initialValue?.max_correct_answer,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Filter By Status</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="content-scroll">
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Jumlah Jawaban Benar</label>
						<div className="flex items-center">
							<div className="w-1/2">
								<input
									name="min_correct_answer"
									onChange={handleValueChange}
									value={filterValue.min_correct_answer || ""}
									type="text"
									className="border-grey-10 w-full outline-none border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
									placeholder="Min"
								/>
							</div>
							<div className="mx-3">
								<div className="font-bold text-2xl">-</div>
							</div>
							<div className="w-1/2">
								<input
									onChange={handleValueChange}
									name="max_correct_answer"
									type="text"
									value={filterValue.max_correct_answer || ""}
									className="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
									placeholder="Max"
								/>
							</div>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Status</label>
						{options.map(optionStatus => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										id={`status-${optionStatus.value}`}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										checked={selected.includes(optionStatus.value)}
										onChange={e => {
											if (e.target.checked) {
												setSelected([...selected, optionStatus.value]);
											} else {
												setSelected(selected.filter(item => item !== optionStatus.value));
											}
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor={`status-${optionStatus.value}`}>
										{optionStatus.label && convertToTitleCase(optionStatus.label)}
									</label>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ selected: { filterValue, selected } });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
