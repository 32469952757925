import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useRef, useState } from "react";

export function EmojiPicker({ onSelect, style }) {
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);

	const emojiContainerRef = useRef(null);

	useOnClickOutside(emojiContainerRef, () => setShowEmojiPicker(false));

	return (
		<div className="upload-file" ref={emojiContainerRef}>
			<label
				onClick={() => setShowEmojiPicker(!showEmojiPicker)}
				className="cursor-pointer transition-all rounded-md border border-solid border-grey-10 w-9 h-9 bg-white flex items-center justify-center hover:bg-red-hover hover:border-red-50">
				<img src="/img/icons/smile.svg" className="m-6 h-6" alt="Pick Emoji" />
			</label>
			<div
				style={{
					position: "absolute",
					bottom: 30,
					zIndex: 129,
					display: showEmojiPicker ? "block" : "none",
					transitions: "all 0.8s ease",
					...style,
				}}>
				<Picker theme="light" icons="solid" data={data} onEmojiSelect={onSelect} previewPosition="none" />
			</div>
		</div>
	);
}
