import { useNavigate, useLocation } from "react-router-dom";

export function SectionSidebar({ menu = {}, currentSection, onChange }) {
	const location = useLocation();
	const { pathname } = location;

	const navigate = useNavigate();

	const activeClass =
		"side-item side-active block relative py-4 px-7 text-sm uppercase text-red-50 font-bold transition-all hover:text-red-50 hover:font-bold";
	const inactiveClass =
		"side-item block relative py-4 px-7 text-sm uppercase text-main-grey font-medium transition-all hover:text-red-50 hover:font-bold";
	return (
		<div className="basis-[200px] border-r border-grey-10">
			{menu.map(item => (
				<button
					key={item.id}
					onClick={() => {
						onChange(item.id);
						navigate(item.path);
					}}
					class={item.path === pathname ? activeClass : inactiveClass}>
					{item.label}
				</button>
			))}
		</div>
	);
}
