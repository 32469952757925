import * as Yup from "yup";

/**
 * Form validation schema for /registration/setting
 */

const websiteUrlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/g;

export const UpdateRegTestValidationSchema = Yup.object().shape({
	title: Yup.string().max(50, "Max character for title is 50").required("Title is required"),
});

export const UpdateRegSettingValidaitonSchema = Yup.object().shape({
	redirect_url: Yup.string().matches(websiteUrlRegExp, "URL is not valid").required("URL is required"),
	batch_label: Yup.string().required("Batch label is required"),
});
