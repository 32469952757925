import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

/**
 *
 * @param {*} time in format HH:mm:ss
 * @returns {number}
 *
 * Example:
 * convertTimeToSeconds('00:02:00') => 120
 * convertTimeToSeconds('24:00:00') => 86400
 *
 */

export function convertTimeToSeconds(time) {
	if (!time) return null;
	const timeArray = time.split(":");
	const seconds = parseInt(timeArray[0]) * 60 * 60 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
	return seconds;
}

/**
 *
 * @param {*} miliseconds
 * @returns {number}
 *
 * Example:
 * convertMilisecondsToTime(120000) => '00:02:00'
 *
 */
export const convertMilisecondsToTime = (miliseconds, stringify = false) => {
	let hours = Math.floor((miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let minutes = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
	let seconds = Math.floor((miliseconds % (1000 * 60)) / 1000);

	if (hours < 10) hours = `0${hours}`;
	if (minutes < 10) minutes = `0${minutes}`;
	if (seconds < 10) seconds = `0${seconds}`;

	if (stringify) return `${hours}:${minutes}:${seconds}`;
	return { hours, minutes, seconds };
};

export function getRelativeTime(isoTime) {
	if (!isoTime) return null;
	return dayjs(isoTime).fromNow();
}
