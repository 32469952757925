import escapeHtml from "escape-html";
import { Text } from "slate";
import parse from "html-react-parser";
import { generateUniqueId, urlify } from "utils/string";

export function trimText(text, length) {
	if (text.length > length) {
		return text.substring(0, length) + "...";
	}
	return text;
}

function formatToHtml(node, className, trim) {
	if (Text.isText(node)) {
		let string = escapeHtml(node.text);
		if (node.bold) {
			string = `<strong>${string}</strong>`;
		}
		if (node.italic) {
			string = `<i>${string}</i>`;
		}
		return string;
	}
	const children = node?.children?.map(n => formatToHtml(n)).join("");
	switch (node?.type) {
		case "quote":
			return `<blockquote><p>${children}</p></blockquote>`;
		case "paragraph":
			return `<p class='${className[node?.type]}' key=${generateUniqueId()}>${
				trim?.[node?.type] ? trimText(children, trim?.[node?.type]) : urlify(children)
			}</p>`;
		case "link":
			return `<a key=${generateUniqueId()} href="${escapeHtml(node?.url)}">${children}</a>`;
		case "list-item":
			return `<li key=${generateUniqueId()}>${children}</li>`;
		case "bulleted-list":
			return `<ul key=${generateUniqueId()} class='jd-list mb50'>${children}</ul>`;
		case "numbered-list":
			return `<ol key=${generateUniqueId()} >${children}</ol>`;
		default:
			return children;
	}
}

export const serializeEvent = (
	node,
	className = { paragraph: "text-sm text-grey-50 line-clamp-2 mb-3" },
	trim = { paragraph: null },
) => {
	if (trim.paragraph) {
		//copy first item on array on variabel node
		const nodeCopy = [node[0]];
		return nodeCopy.map(element => formatToHtml(element, className, trim)).map(el => parse(el));
	}
	return node.map(element => formatToHtml(element, className, trim)).map(el => parse(el));
};

function formatToHTMLInterviewNote(node, index) {
	if (Text.isText(node)) {
		let string = escapeHtml(node.text);
		if (node.bold) {
			string = `<strong>${string}</strong>`;
		}
		if (node.italic) {
			string = `<i>${string}</i>`;
		}
		return string;
	}
	const children = node?.children?.map((n, idx) => formatToHTMLInterviewNote(n, idx)).join("");

	switch (node?.type) {
		case "bulleted-list":
			return `<ul key=${generateUniqueId()} class='jd-list mb50' id='bullet-list-interview-note'>${children}</ul>`;
		case "list-item":
			return `<div className="list-note">
      <div className="number-list">${typeof index === "number" && index + 1}</div>
      <div className="text-list">${children}</div>
    </div>`;
		case "paragraph":
			return `<dipv>${urlify(children)}</div>`;
		default:
			return children;
	}
}

export const serializeInterviewNote = node =>
	node.map((element, index) => formatToHTMLInterviewNote(element, index)).map(el => parse(el));
