import qs from "query-string";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Layout from "components/layout";
import { useActivityResultDetails } from "hooks/activity";
import { BounceLoading } from "components/loading/bounce.loading";
import { setToolsReducer } from "store/reducer/tools.reducer";

import { ActivityDescription } from "./components/ActivityDescrtiption";
import { TabRegistration } from "./tab-sections/Registration";
import { TabPresence } from "./tab-sections/Presence";
import { TabExam } from "./tab-sections/Exam";
import { TabChallange } from "./tab-sections/Challange";
import { TabReview } from "./tab-sections/Review";

const TAB_SECTIONS_CONSTANT = {
	REGISTRATION: {
		id: "REGISTRATION",
		label: "Registration",
	},
	PRESENCE: {
		id: "PRESENCE",
		label: "Presensi",
	},
	REVIEW: {
		id: "REVIEW",
		label: "Review",
	},
	CHALLANGE: {
		id: "CHALLENGE",
		label: "Challenge",
	},
	EXAM: {
		id: "EXAM",
		label: "Exam",
	},
};

const TAB_SECTIONS = Object.values(TAB_SECTIONS_CONSTANT);

export function ActivityDetailsPage() {
	const dispatch = useDispatch();
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);
	const navigate = useNavigate();
	const { search } = useLocation();

	const [activeTab, setActiveTab] = useState(
		(qs.parse(search).tab && { id: qs.parse(search).tab, label: "" }) || TAB_SECTIONS[0],
	);
	const { activityId } = useParams();

	const { data: activityDetails, isLoading } = useActivityResultDetails(activityId);

	const renderSection = useCallback(() => {
		switch (activeTab.id) {
			case TAB_SECTIONS_CONSTANT.REGISTRATION.id:
				return <TabRegistration />;
			case TAB_SECTIONS_CONSTANT.PRESENCE.id:
				return <TabPresence />;
			case TAB_SECTIONS_CONSTANT.EXAM.id:
				return <TabExam />;
			case TAB_SECTIONS_CONSTANT.CHALLANGE.id:
				return <TabChallange />;
			case TAB_SECTIONS_CONSTANT.REVIEW.id:
				return <TabReview />;
			default:
				return null;
		}
	}, [activeTab]);

	useEffect(() => {
		if (activeTab.id === TAB_SECTIONS_CONSTANT.REGISTRATION.id) {
			navigate(`/activity/list/details/${activityId}`, { replace: true });
		} else {
			navigate(`/activity/list/details/${activityId}?tab=${activeTab?.id}`, { replace: true });
		}
		if (selectedIds.length > 0) {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	if (isLoading) {
		return (
			<Layout
				buttonToTop
				containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none py-4"
				breadCumbTitle="Activity"
				breadCumbDesc="Activity Details">
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none py-4"
			breadCumbTitle="Activity"
			breadCumbDesc="Activity Details">
			<ActivityDescription data={activityDetails?.data} activityId={activityId} />

			<div className="border-b flex border-solid border-grey-10 px-5">
				{TAB_SECTIONS.map(sectionTab => (
					<div className="inline-block mr-4">
						<span
							onClick={() => setActiveTab(sectionTab)}
							className={clsx(
								activeTab.id === sectionTab.id
									? "cursor-pointer rounded-tl-lg rounded-tr-lg uppercase text-sm font-semibold text-red-50  transition-all inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-grey-10"
									: "cursor-pointer rounded-tl-lg rounded-tr-lg uppercase text-sm hover:text-red-50 transition-all font-semibold text-black inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-transparent",
							)}>
							{sectionTab.label}
						</span>
					</div>
				))}
			</div>

			{renderSection(activeTab)}
		</Layout>
	);
}
