import { useNavigate } from "react-router-dom";
import { ButtonSubmit } from "components/button/submit.button";
import { convertToTitleCase, IsJsonString } from "utils/string";
import { serializeEvent } from "components/text-editor/serializer";
import { loadStaticPublicAsset } from "utils/loader";

export function ActivityDescription(props) {
	const { data = null, activityId } = props;
	const navigate = useNavigate();

	return (
		<div className="flex justify-between mb-8 px-5">
			<div className="w-3/4 flex">
				<img
					src={data?.image_desktop}
					className="w-2/4 h-52 rounded-lg overflow-hidden object-cover object-center"
					alt={data?.title}
				/>

				<div className="w-3/5 pl-5">
					<div className="flex items-center mb-3">
						<div className="flex items-center mr-4">
							<div className="w-8 h-8 rounded-full flex justify-center items-center bg-red-40 text-white">
								<span className="icon-ico-challange text-xl"></span>
							</div>
							<div className="text-red-40 text-sm font-semibold ml-3">
								{convertToTitleCase(data?.activity_category?.name)}
							</div>
						</div>
						<div className="py-1 px-2 rounded-lg bg-light-tosca text-sm font-semibold inline-block text-main-tosca">
							{convertToTitleCase(data?.level?.name)}
						</div>
					</div>
					<div className="lg:text-base sm:text-sm text-grey-70 font-bold mb-3">{data?.title}</div>
					<p className="text-sm text-main-grey mb-3">
						{data.description &&
							IsJsonString(data.description) &&
							serializeEvent(JSON.parse(data.description), undefined, { paragraph: 50 })}
					</p>
					<div className="text-xs text-grey-60 font-semibold italic">{data?.start_date_formatted}</div>
				</div>
			</div>

			<div className="w-auto">
				<div className="flex items-center justify-end mb-4">
					<div className="overflow-hidden -space-x-2 flex">
						{data?.user_activities?.map(item => {
							return (
								<img
									key={item?.id}
									alt={item?.id}
									src={item.user?.profile_picture || loadStaticPublicAsset("/img/default-user.png")}
									className="w-8 h-8 rounded-full overflow-hidden object-cover object-center"
								/>
							);
						})}
					</div>
					<div className="ml-3">
						<div className="text-xs font-medium text-main-grey italic">
							{data?.total_joined_user} Users Are Joined
						</div>
					</div>
				</div>

				<div className="flex items-center justify-end">
					<ButtonSubmit
						onClick={() => {
							navigate(`/activity/list/edit/${activityId}`);
						}}
						className="flex items-center justify-center py-2 uppercase px-7 w-52 border border-solid border-red-50 rounded-lg text-sm text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold mb-4">
						<span className="icon-ico-edit text-xl mr-2"></span> Edit Activity
					</ButtonSubmit>
				</div>

				<div className="flex items-center justify-end">
					<ButtonSubmit className="flex items-center justify-center py-2 uppercase px-7 w-52 border border-solid border-grey-10 rounded-lg text-sm text-grey-70 bg-white hover:bg-grey-10 transition-all font-semibold mb-4">
						<span className="icon-ico-eye text-red-50 text-xl mr-2"></span> Preview
					</ButtonSubmit>
				</div>

				<div className="flex items-center justify-end"></div>
			</div>
		</div>
	);
}
