import _ from "lodash";
import axios from "api/http.api";
import { useQuery } from "react-query";
import qs from "qs";

export function useTopicCategory() {
	return useQuery(["TOPIC_CATEGORIES"], () => axios.get(`/api/v1/admin/topic-category/list`).then(res => res.data));
}

export function useTopicCategoryDetails(categoryId) {
	return useQuery(
		["TOPIC_CATEGORIES", categoryId],
		() => axios.get(`/api/v1/admin/topic-category/detail/${categoryId}`).then(res => res.data),
		{
			enabled: !!categoryId,
		},
	);
}

export function useListBatch() {
	return useQuery(["LIST_BATCH"], () =>
		axios.get(`/api/v1/admin/topic/batch/list`).then(res => {
			return { ...res.data, data: res.data.data.map(batch => ({ id: batch, name: `Batch ${batch}` })) };
		}),
	);
}

export function useListProvince() {
	return useQuery(["LIST_PROVINCE"], () =>
		axios.get(`/api/v1/master-data/province/all`).then(res => {
			return { ...res.data, data: _.orderBy(res.data.data, ["name"], ["asc"]) };
		}),
	);
}
export function useGetDiscussionList({ filter, body }) {
	return useQuery(["DISCUSSION_LIST", { filter, body }], () =>
		axios.post(`/api/v1/admin/topic/list?${qs.stringify(filter)}`, body).then(res => res.data),
	);
}

export function useGetDiscussionTopicDetails(topicId) {
	return useQuery(
		["DISCUSSION_LIST", { topicId }],
		() => axios.get(`/api/v1/admin/topic/detail/${topicId}`).then(res => res.data),
		{
			enabled: !!topicId,
		},
	);
}

export function useGetActionLog(topicId) {
	return useQuery(
		["ACTIONS_LOG", { topicId }],
		() => axios.get(`/api/v1/admin/topic/action-log/${topicId}`).then(res => res.data),
		{
			enabled: !!topicId,
		},
	);
}
