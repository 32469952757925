import axios from "api/http.api";
import { useMutation } from "react-query";

export function useLogin() {
	return useMutation(values =>
		axios.post("/api/v1/auth/login-admin", values).then(res => {
			return {
				...res.data,
				token: res.headers?.["x-access-token"],
				refreshToken: res.headers?.["x-refresh-token"],
			};
		}),
	);
}

export function useLogout() {
	return useMutation(values =>
		axios.delete("/api/v1/auth/destroy").then(res => {
			return res;
		}),
	);
}

export function useVerifyCaptcha() {
	return useMutation(values =>
		axios.post("/api/v1/auth/verify-captcha", values).then(res => {
			return res;
		}),
	);
}

export function useForgotPassword() {
	return useMutation(values => axios.post("/api/v1/auth/forgot-password-admin", values).then(res => res.data));
}
