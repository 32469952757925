import { useParams } from "react-router-dom";
import { useGetActionLog } from "hooks/discussion";
import { getRelativeTime } from "utils/date";
import { BounceLoading } from "components/loading/bounce.loading";

const getActionLogText = actionLogType => {
	switch (actionLogType) {
		case "like":
			return "Like to";
		case "unlike":
			return "Unlike to";
		case "reply":
			return "Reply to";
		default:
			return "Unknown";
	}
};

export function ActionLog() {
	const { topicId } = useParams();
	const { data, isLoading } = useGetActionLog(topicId);

	return (
		<>
			<div className="text-2xl font-bold text-red-50 mb-4">Action Log</div>
			<div className="scroll max-h-[35vh] pr-1">
				{isLoading && (
					<div className="h-[40vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				)}
				{data?.data?.rows?.length === 0 && !isLoading && (
					<div className="flex justify-center mb-4">
						<div className="text-center">
							<img src="/img/log-empty.png" className="max-w-full" alt="data not found" />
							<div className="block text-black font-semibold mt-3">No Log</div>
						</div>
					</div>
				)}
				{data?.data?.rows?.map(item => (
					<div className="flex w-full relative mb-4" key={`action-log-${item?.id}`}>
						<div className="flex mb-4">
							<div className="w-8">
								<img
									src={item?.actor?.profile_picture || "/img/default-user.png"}
									className="w-8 h-8 overflow-hidden rounded-full object-cover object-center"
									alt={item?.actor?.full_name}
								/>
							</div>
							<div className="w-full pl-3">
								<div className="flex justify-between items-center ">
									<div className="w-full">
										<div className="text-ellipsis whitespace-nowrap overflow-hidden font-semibold text-sm">
											{item?.actor?.full_name}
										</div>
									</div>
								</div>
								<div className="w-full flex mb-2">
									{item?.action === "delete" ? (
										<div className="text-red-50 text-sm whitespace-nowrap block">
											<span className="italic ">Deleting</span>
											<strong className="text-bold inline-block text-grey-70 ml-2">
												{item?.target?.full_name}
											</strong>
											<span className="italic text-grey-60"> Comment</span>
										</div>
									) : (
										<div className="text-grey-60 text-sm whitespace-nowrap block">
											<span className="italic ">{getActionLogText(item?.action)}</span>
											<strong className="text-bold inline-block text-grey-70 ml-2">
												{item?.target?.full_name}
											</strong>
										</div>
									)}

									<div className="text-right pl-2 text-grey-60 text-ellipsis overflow-hidden whitespace-nowrap text-sm italic">
										· {getRelativeTime(item?.created_at)}
									</div>
								</div>
								<p className="text-sm text-grey-70">{item?.comment}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}
