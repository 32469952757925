import { useState } from "react";

import Layout from "components/layout";

import { SectionSidebar } from "../section-sidebar.component";

export const PAGE_SETTINGS_ROUTE_SECTIONS = {
	HOME: [
		{
			id: "running-text",
			label: "Running Text",
			path: "/page-setting/home/running-text",
		},
		{
			id: "slider-banner",
			label: "Slider Banner",
			path: "/page-setting/home/slider-banner",
		},
		{
			id: "about-rubi",
			label: "About Rubi",
			path: "/page-setting/home/about-rubi",
		},
		{
			id: "rubi-safespace",
			label: "Ambassador",
			path: "/page-setting/home/rubi-safespace",
		},
		{
			id: "article",
			label: "Article",
			path: "/page-setting/home/article",
		},
	],
	ABOUT: [
		{
			id: "about-rubi",
			label: "about rubi",
			path: "/page-setting/about/rubi-about",
		},
		{
			id: "about-benefits",
			label: "bennefits",
			path: "/page-setting/about/benefit",
		},

		{
			id: "about-feature",
			label: "feature",
			path: "/page-setting/about/feature",
		},
		{
			id: "community-ambassador",
			label: "gallery",
			path: "/page-setting/about/gallery-preview",
		},
	],
	VIMI: [
		{
			id: "full-banner",
			label: "full banner",
			path: "/page-setting/visi-misi/full-banner",
		},
		{
			id: "misi",
			label: "misi",
			path: "/page-setting/visi-misi/misi",
		},
		{
			id: "visi",
			label: "visi",
			path: "/page-setting/visi-misi/visi",
		},
	],
	AMBASSADOR: [
		{
			id: "community-ambassador",
			label: "community amb.",
			path: "/page-setting/ambassador/community",
		},

		{
			id: "ambassador-pilar",
			label: "Rubi pilar",
			path: "/page-setting/ambassador/rubi-pilar",
		},
		{
			id: "ambassador-benefits",
			label: "benefits",
			path: "/page-setting/ambassador/benefits",
		},
	],
	BANNER: [
		{
			id: "banner-setting",
			label: "Popup Banner",
			path: "/page-setting/banner/popup-banner",
		},
	],
	GALLERY: [
		{
			id: "gallery-setting",
			label: "Gallery Setting",
			path: "/page-setting/galler/gallery-setting",
		},
	],
};

export function PageSettingLayout({ children, sidebarMenu, title, desc, routes = PAGE_SETTINGS_ROUTE_SECTIONS }) {
	const [currentSection, setCurrentSection] = useState(routes[sidebarMenu][0].id);

	return (
		<Layout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			breadCumbTitle={title}
			breadCumbDesc={desc}>
			<SectionSidebar
				menu={routes[sidebarMenu]}
				currentSection={currentSection}
				onChange={sectionId => setCurrentSection(sectionId)}
			/>
			{children}
		</Layout>
	);
}
