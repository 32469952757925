import axios from "api/http.api";
import { useQuery } from "react-query";

// Service API for /registration/setting [REGISTRATION SETTINGS]
export function useRegistrationSettings() {
	return useQuery(["REGISTRATION_SETTINGS"], () =>
		axios.get(`/api/v1/admin/settings/registration/info`).then(res => res.data),
	);
}

// Service API for /registration/setting [REGISTRATION TEST]
// to get the test settings data
export function useRegTestData() {
	return useQuery(["REGISTRATION_TEST_DATA"], () => axios.get(`/api/v1/admin/exam/detail`).then(res => res.data), {
		refetchInterval: false,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
}

export function useExamResult({ userId, type }) {
	return useQuery(
		["EXAM_RESULT", { userId, type }],
		() => {
			if (type === "registration") {
				return axios.post(`/api/v1/admin/exam/result`, { user_id: userId }).then(res => res.data);
			}
			if (type === "activity") {
				return axios.get(`/api/v1/admin/activity-result/exam-result/${userId}`).then(res => res.data);
			}
		},
		{
			enabled: !!userId,
		},
	);
}
