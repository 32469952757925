export function TostComponent({ type = "info", message = "", visible, title }) {
	if (!visible) return null;
	return (
		<div className="fixed inline-block p-3 bg-white text-grey font-medium text-xs leading-tight border border-solid border-red-50 rounded-sm shadow-md  transition duration-150 ease-in-out bottom-20 right-5">
			<div className="flex items-start justify-between flex-col">
				<div className="flex justify-center items-center">
					<span className="icon-ico-flag"></span>
					<p className="ml-2">{title}</p>
				</div>
				<div>
					<p className="mt-2 italic text-[10px] w-60 text-grey-40">{message}</p>
				</div>
			</div>
		</div>
	);
}
