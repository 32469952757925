import { ButtonSubmit } from "components/button/submit.button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loadStaticPublicAsset } from "utils/loader";

export function PageNotFound() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const countBack = +searchParams.get("countBack") || 1;

	return (
		<div className="bg-fixed main-bg">
			<div className="relative flex items-center justify-center h-screen">
				<div className="w-[500px] bg-white py-10 px-6 drop-shadow-main-red rounded-lg relative">
					<div className="text-center text-[32px] text-red-50 font-bold mb-8">404</div>
					<p className="mb-5 form-input-error bg">Opps, the page you are looking for is not found</p>
					<ButtonSubmit title="Back to Home" onClick={() => navigate(-countBack)} />
					<div className="flex justify-center flex-wrap translate-x-[-50%] left-1/2 top-[-30%] lg:top-[-35%] absolute w-24">
						<div className="w-20">
							<img
								src={loadStaticPublicAsset("img/logo/logo.png")}
								className="max-w-full"
								alt="Logo Avo"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
