import axios from "api/http.api";
import qs from "query-string";
import { useQuery } from "react-query";

// Service API for /registration/setting [REGISTRATION TEST]
// to get the test settings data
export function usePageSettingsData({ page = null, slug = null }) {
	return useQuery(
		["PAGE_SETTING", { page, slug }],
		() => axios.get(`/api/v1/admin/settings/${page}/info/${slug}`).then(res => res.data),
		{
			refetchInterval: false,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		},
	);
}

export function useGetGalleryList({ filter, body }) {
	return useQuery(["GALLERY_LIST", { filter, body }], () =>
		axios.get(`/api/v1/admin/gallery?${qs.stringify(filter)}`, body).then(res => res.data),
	);
}

export function useGetGalleryDetail(galleryId) {
	return useQuery(
		["GALLERY_DETAILS", galleryId],
		() => axios.get(`/api/v1/admin/gallery/${galleryId}`).then(res => res.data),
		{
			disabled: !!galleryId,
		},
	);
}
