import qs from "query-string";
import { useMutation, useQueryClient } from "react-query";
import axios from "api/http.api";

export function useAddNewTopicCategry() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/topic-category/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(["TOPIC_CATEGORIES"]);
			queryClient.removeQueries(["TOPIC_CATEGORIES"]);
		},
	});
}

export function useEditTopicCategory() {
	const queryClient = useQueryClient();
	return useMutation(
		({ categoryId, ...values }) =>
			axios.post(`/api/v1/admin/topic-category/update/${categoryId}`, values).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["TOPIC_CATEGORIES"]);
				queryClient.removeQueries(["TOPIC_CATEGORIES"]);
			},
		},
	);
}

export function useDeleteTopicCategory() {
	const queryClient = useQueryClient();
	return useMutation(
		({ categoryId }) => axios.delete(`/api/v1/admin/topic-category/delete/${categoryId}`).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["TOPIC_CATEGORIES"]);
				queryClient.removeQueries(["TOPIC_CATEGORIES"]);
			},
		},
	);
}

export function useAddNewDiscussionTopic() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/topic/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.refetchQueries(["TOPIC_CATEGORIES"]);
			queryClient.removeQueries(["DISCUSSION_LIST"]);
		},
	});
}

export function useEditDiscussionTopic() {
	const queryClient = useQueryClient();
	return useMutation(
		({ topicId, ...values }) => axios.post(`/api/v1/admin/topic/update/${topicId}`, values).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.removeQueries(["DISCUSSION_LIST"]);
			},
		},
	);
}

export function useExportDiscussionTopic() {
	return useMutation(({ filter, formData }) =>
		axios
			.post(`/api/v2/admin/topic/export?${qs.stringify(filter)}`, formData, {
				responseType: "blob",
			})
			.then(res => res),
	);
}
