import dayjs from "dayjs";
import clsx from "clsx";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { UserListTable } from "pages/registration/users/user-table.component";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { Pagination } from "components/pagination.component";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { SelectComponent } from "components/select.component";
import { ButtonSubmit } from "components/button/submit.button";
import { useActivityResult, useChangeStatusUserActivityResult, useExportActivityResult } from "hooks/activity";
import { convertToTitleCase, getInitialsName } from "utils/string";
import { ModalResult } from "components/modal/modal.result";
import { getColorClassNameByStatus } from "config/static-mapping";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalFilterCheckBox } from "../../components/registration-filter.component";

const sortOptions = [
	{ label: "Tanggal Registrasi Terbaru", sortType: "desc", sortBy: "registration_date" },
	{ label: "Tanggal Registrasi Terdahulu", sortType: "asc", sortBy: "registration_date" },
];

const getModalConfirmationMessage = (actionType, data) => {
	switch (actionType) {
		case "APPROVED":
			return `Are you sure to approve ${data?.length} user(s)?`;
		case "REJECTED":
			return `Are you sure to reject ${data?.length} user(s)?`;
		default:
			return "";
	}
};

export function TabRegistration() {
	const dispatch = useDispatch();
	const { activityId } = useParams();
	// Selected record's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	const [selectedAction, setSelectedAction] = useState(null);

	const [modalResult, setModalResult] = useState({
		showModal: false,
		message: "",
	});

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});

	// Query Params Filter
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		search: "",
		order: "desc",
		order_by: "registration_date",
	});

	// Body Request Filter
	const [bodyFilter, setBodyFilter] = useState({
		filter: {
			registration_status: [],
		},
	});

	const { data: activityResultRegistrations, isLoading } = useActivityResult(
		{ activityId, type: "registration" },
		{ queryFilter, bodyFilter },
	);

	const { mutate: exportRegistratioData, isLoading: isExporting } = useExportActivityResult();

	const { mutate: changeStatus, isLoading: isUpdating } = useChangeStatusUserActivityResult();

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "Nama",
				dataIndex: "user",
				className:
					"w-1/4 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
				render: record => (
					<div className="w-1/4 overflow-hidden pr-3">
						<div className="pl-3 flex items-center overflow-hidden">
							{record?.user?.profile_picture ? (
								<img
									src={record?.user?.profile_picture}
									alt={record?.user?.full_name}
									className="rounded-full w-12 h-12 overflow-hidden object-cover object-center"
								/>
							) : (
								<div className="font-bold text-red-60 text-2xl">
									{getInitialsName(record?.user?.full_name)}
								</div>
							)}

							<div className="font-bold overflow-hidden text-ellipsis whitespace-nowrap text-grey-70 text-sm ml-4">
								{record?.user?.full_name}
							</div>
						</div>
					</div>
				),
			},
			{
				name: "Email",
				dataIndex: "user",
				className: "w-1/3 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, full_name) => (
					<div className="w-1/3">
						<div className="text-sm text-main-grey"> {record?.user?.email}</div>
					</div>
				),
			},
			{
				name: "Registration Date",
				dataIndex: "user",
				className: "w-1/4 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis  font-semibold mr-[5%]",
				render: record => (
					<div className="w-1/4  mr-[5%]">
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
							{dayjs(record?.registration_date).format("DD / MM / YYYY")}
						</div>
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap text-main-grey">
							{`Batch ${record?.batch}`}
						</div>
					</div>
				),
			},
			{
				name: "Status",
				dataIndex: "registration_status",
				className: "w-1/5 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, registration_status) => (
					<div className="w-1/5">
						<div class={clsx("font-semibold text-sm", getColorClassNameByStatus(registration_status))}>
							{convertToTitleCase(registration_status)}
						</div>
					</div>
				),
			},
		],
		[],
	);

	const resetCheckList = () => {
		if (selectedIds.length > 0) {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setBodyFilter({
			filter: {
				registration_status: selected,
			},
		});
		setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
	};

	const handleOnSelectedActionChange = selected => {
		setSelectedAction(selected.value);
	};

	const handleOnApplyAction = () => {
		setModalConfirmation({
			showModal: true,
			type: selectedAction,
			data: selectedIds,
		});
	};

	const handleOnSuccessChangeStatus = () => {
		setModalConfirmation({
			showModal: false,
			type: null,
			data: null,
		});
		setModalResult({
			showModal: true,
			message: "Successfully change user status!",
		});

		resetCheckList();
	};

	const handleChangeStatus = () => {
		changeStatus(
			{
				filter: {
					registration_status: bodyFilter.filter.registration_status,
					user_activity_ids: selectedIds,
				},
				update_status: selectedAction?.toLowerCase(),
				activityId,
				type: "registration",
			},
			{
				onSuccess: handleOnSuccessChangeStatus,
			},
		);
	};

	const handleOnExport = () => {
		exportRegistratioData(
			{
				activityId,
				type: "registration",
				bodyRequest: { ...bodyFilter, filter: { ...bodyFilter.filter, user_activity_ids: selectedIds } },
				filterParams: queryFilter,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("Registration", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	useEffect(() => {
		return () => {
			resetCheckList();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex items-center justify-between p-5">
				<div className="w-[70%] flex">
					<SearchFilterComponent
						containerClassName="relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2"
						onSearch={search => {
							resetCheckList();
							setQueryFilter({ ...queryFilter, search: search.keyword });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={bodyFilter.filter.registration_status.length > 0}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport loading={isExporting} onClick={handleOnExport} />
					</div>
				</div>
				<div className="flex w-[28%] justify-end">
					<SelectComponent
						disabled={!selectedIds.length}
						title="Select Action"
						onChange={handleOnSelectedActionChange}
						options={[
							{ label: "Approve", value: "APPROVED" },
							{ label: "Reject", value: "REJECTED" },
						]}
					/>
					<div className="">
						<ButtonSubmit
							disabled={!selectedIds.length || !selectedAction}
							onClick={handleOnApplyAction}
							className="items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white disabled:border-red-10 disabled:bg-red-10 bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
							title="Apply"
						/>
					</div>
				</div>
			</div>

			<div className="px-5">
				<UserListTable
					rowClassName="flex border border-solid border-grey-10 rounded-lg py-5 items-center mb-3"
					rowSelectedClassName="flex border border-solid bg-light-pink border-red-10 rounded-lg py-5 items-center mb-3"
					columns={columns}
					dataSource={activityResultRegistrations?.data?.rows || []}
					loading={isLoading}
				/>
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && !queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message="There's no records available here" />
					</div>
				)}
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message={`Your search "${queryFilter.search}" did not match any records`} />
					</div>
				)}
				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={selectedPage => {
							window.scrollTo(0, 0);

							setQueryFilter({ ...queryFilter, page: selectedPage });
						}}
						totalCount={activityResultRegistrations?.data?.total_result || 0}
					/>
				)}
			</div>
			<ModalFilterCheckBox
				initialValue={bodyFilter.filter.registration_status}
				onChange={handleOnFilterDataChange}
				visible={tableFilterModal.showModalFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
			/>
			<ModalConfirmation
				buttons={[]}
				message={getModalConfirmationMessage(modalConfirmation.type, modalConfirmation.data)}
				loading={isUpdating}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={handleChangeStatus}
			/>
			<ModalResult
				visible={modalResult.showModal}
				title={false}
				onClose={() => setModalResult(prev => ({ ...prev, showModal: false }))}
				message={modalResult.message}
			/>

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				options={sortOptions}
				initialValue={sortOptions[0]}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</>
	);
}
