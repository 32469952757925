import qs from "query-string";
import { useMutation, useQueryClient } from "react-query";
import axios from "api/http.api";

/**
 *
 * Mutation For Reward Item
 */
export function useDeleteRewardItem() {
	const queryClient = useQueryClient();
	return useMutation(({ itemId }) => axios.delete(`/api/v1/admin/item/delete/${itemId}`).then(res => res.data), {
		onSuccess: () => {
			queryClient.removeQueries(["REWARD_ITEM_LIST"]);
			queryClient.removeQueries(["ALL_REWARD_ITEMS_LIST"]);
		},
	});
}

export function useEditRewardItem() {
	const queryClient = useQueryClient();
	return useMutation(
		({ itemId, formData }) => axios.post(`/api/v1/admin/item/update/${itemId}`, formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.removeQueries(["REWARD_ITEM_LIST"]);
				queryClient.removeQueries(["ALL_REWARD_ITEMS_LIST"]);
			},
		},
	);
}

export function useAddNewRewardItem() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/item/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.removeQueries(["REWARD_ITEM_LIST"]);
			queryClient.removeQueries(["ALL_REWARD_ITEMS_LIST"]);
		},
	});
}

export function useExportRewardItem() {
	return useMutation(({ filter, bodyFilter }) =>
		axios
			.post(`/api/v2/admin/item/export?${qs.stringify(filter)}`, bodyFilter, {
				responseType: "blob",
			})
			.then(res => res),
	);
}

export function useExportDiscussionTopic() {
	return useMutation(({ filter, formData }) =>
		axios.post(`/api/v1/admin/topic/export?${qs.stringify(filter)}`, formData).then(res => res.data),
	);
}

/**
 *
 * Mutation For Reward
 */

export function useCreateReward() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/reward/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.removeQueries(["REWARD_LIST"]);
		},
	});
}

export function useEditReward() {
	const queryClient = useQueryClient();
	return useMutation(
		({ id, ...bodyRequest }) => axios.post(`/api/v1/admin/reward/update/${id}`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.removeQueries(["REWARD_LIST"]);
				queryClient.invalidateQueries(["REWARD_DETAILS"]);
			},
		},
	);
}
export function useDeleteReward() {
	const queryClient = useQueryClient();
	return useMutation(({ id }) => axios.delete(`/api/v1/admin/reward/delete/${id}`).then(res => res.data), {
		onSuccess: () => {
			queryClient.removeQueries(["REWARD_LIST"]);
		},
	});
}

export function useApproveRejectClaimReward() {
	const queryClient = useQueryClient();
	return useMutation(
		({ type, ...bodyRequest }) =>
			axios.post(`/api/v1/admin/reward/claims/${type}`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["CLAIM_REWARD_LIST"]);
			},
		},
	);
}

export function useExportClaimReward(queryFilter) {
	return useMutation(
		({ status, ...bodyRequest }) =>
			axios
				.post(`/api/v2/admin/reward/claims/export/${status}?${qs.stringify(queryFilter)}`, bodyRequest, {
					responseType: "blob",
				})
				.then(res => res),
		{},
	);
}
