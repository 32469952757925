import CryptoJS from "crypto-js";
import CONFIG from "config/env";

const CUSTOM_IV_VALUE = CONFIG.key.APP_IV_VALUE;
const APP_ENCRYPTION_KEY = CONFIG.key.APP_ENCRYPTION_KEY;

export const urlSafeEncrypt = id => {
	if (!id) return null;

	const iv = CryptoJS.enc.Hex.parse(CUSTOM_IV_VALUE);
	const key = CryptoJS.enc.Utf8.parse(APP_ENCRYPTION_KEY);
	const hashedKey = CryptoJS.SHA256(key);
	const encrypted = CryptoJS.AES.encrypt(id.toString(), hashedKey, {
		iv: iv,
	});

	const urlSafeEncrypted = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
		.replaceAll("+", "-")
		.replaceAll("/", "_")
		.replaceAll("=", "");


	return urlSafeEncrypted;
};
export const urlSafeDecrypt = urlSafeEncrypted => {
	if (!urlSafeEncrypted) return null;

	const iv = CryptoJS.enc.Hex.parse(CUSTOM_IV_VALUE);
	const key = CryptoJS.enc.Utf8.parse(APP_ENCRYPTION_KEY);
	const hashedKey = CryptoJS.SHA256(key);

	const decodedUrlSafe = urlSafeEncrypted.replaceAll("-", "+").replaceAll("_", "/");

	const decryptedData = CryptoJS.AES.decrypt(
		{
			ciphertext: CryptoJS.enc.Base64.parse(decodedUrlSafe),
		},
		hashedKey,
		{ iv: iv },
	).toString(CryptoJS.enc.Utf8);

	return decryptedData;
};
