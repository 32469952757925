import clsx from "clsx";

import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

export function AccordionWrapper({
	onChange,
	values,
	sectionId = "title",
	children,
	expaned = false,
	title = "Component Section title",
	withToggle = true,
	onToggleExpand = () => {},
}) {
	return (
		<>
			<div className="mb-6 flex items-center border-b border-solid border-grey-10 justify-between py-2.5 tracking-wide ">
				<div className="text-title text-grey-70 font-semibold">{title}</div>
				<div className="flex">
					{withToggle && (
						<div className="flex flex-row-reverse items-center mr-3">
							<ToogleSwitchComponent
								inputClassName="toggle-checkbox"
								onChange={e => {
									onChange(e);
									if (e.target.checked && !expaned) {
										onToggleExpand();
									}
								}}
								name={`${sectionId}.is_active`}
								checked={values.is_active}
							/>
						</div>
					)}

					<button onClick={onToggleExpand} className="items-tab transition duration-150 ease-in-out">
						<button
							className="items-btn flex items-center bg-white w-10 h-10 rounded-full justify-center border text-sm border-solid border-grey-10  text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
							type="button">
							<span
								class={clsx(
									"text-red-50 text-base",
									!expaned ? "icon-ico-down" : "icon-ico-up",
								)}></span>
						</button>
					</button>
				</div>
			</div>
			<div class={clsx("collapse", expaned && "show")}>{children}</div>
		</>
	);
}
