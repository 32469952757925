import clsx from "clsx";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { ModalContainer } from "components/modal/modal.container";
import { SearchFilterComponent } from "components/table/search-filter.component";

export function ModalBulkSelectProduct({ visible, options, onChange, onClose, title = "Select options title" }) {
	const [searchKeyword, setSearchKeyword] = useState("");

	return (
		<ModalContainer visible={visible}>
			<div className="rounded-2xl bg-white shadow-md p-4 w-[480px]">
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Select Product Item</div>

					<button
						type="button"
						className="absolute top-0 right-0 border-0"
						onClick={() => {
							onClose();
							setSearchKeyword("");
						}}>
						<span className="icon-ico-close"></span>
					</button>
				</div>
				<SearchFilterComponent
					placeholder="Search..."
					controllerInput
					value={searchKeyword}
					containerClassName="relative w-[100%] mr-2"
					onChange={e => setSearchKeyword(e.target?.value)}
				/>
				<div className="scroll content-scroll mt-3">
					{matchSorter(options, searchKeyword, { keys: ["name"] }).map(optionItem => (
						<div className="item-regional mb-3">
							<input type="checkbox" className="hidden" id="regional-1" />
							<label
								onClick={() => {
									onChange({ ...optionItem, selected: !optionItem?.selected });
								}}
								class={clsx(
									"flex justify-between items-center  px-2 rounded-lg cursor-pointer",
									optionItem.selected && "border-red-50 border-solid text-red transition-all",
								)}>
								<div className="w-9/12 flex items-center">
									<div className="w-[60px]">
										<img
											src={optionItem.image_desktop}
											alt={optionItem.name}
											className="w-[60px] h-[60px] overflow-hidden object-cover object-center"
										/>
									</div>
									<div className="w-[calc(100%-60px)] pl-4 text-sm">{optionItem.name}</div>
								</div>
								<span
									onClick={() => {
										onChange({ ...optionItem, selected: !optionItem?.selected });
									}}
									class={clsx(
										"text-red-50",
										optionItem?.selected ? "icon-ico-circle-check" : "icon-ico-circle-plus ",
									)}></span>
							</label>
						</div>
					))}
				</div>
			</div>
		</ModalContainer>
	);
}
