import { InputComponent } from "components/form/input.component";
import { AccordionWrapper } from "../accordion-wrapper.component";

export function RegistrationDate({
	expanded,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	sectionId = "registration",
}) {
	return (
		<AccordionWrapper
			onToggleExpand={onToggleExpand}
			expaned={expanded}
			title="Pendaftaran"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="flex justify-between ">
					<div className="w-[48%]">
						<InputComponent
							label="Start Date & Time"
							type="datepicker-modal"
							placeholder="Start Date & Time"
							onChange={onChange}
							onBlur={onBlur}
							name={`${sectionId}.start_date`}
							value={values?.start_date}
							error={touched?.start_date && errors?.start_date}
							min={new Date().toISOString().split("T")[0]}
						/>
					</div>
					<div className="w-[48%]">
						<InputComponent
							label="End Date & Time"
							type="datepicker-modal"
							placeholder="End Date & Time"
							onChange={onChange}
							onBlur={onBlur}
							name={`${sectionId}.end_date`}
							value={values?.end_date}
							error={touched?.end_date && errors?.end_date}
							min={new Date().toISOString().split("T")[0]}
						/>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
