import clsx from "clsx";
import { ModalContainer } from "components/modal/modal.container";
import { useState, useCallback, useRef } from "react";
import Webcam from "react-webcam";
const videoConstraints = {
	width: 1280,
	height: 720,
	facingMode: "user",
};

function getCameraErrorMessage(errorName) {
	switch (errorName) {
		case "NotAllowedError":
			return "Anda tidak memiliki izin untuk mengakses kamera";
		case "NotFoundError":
			return "Kamera tidak ditemukan";
		case "NotReadableError":
			return "Kamera tidak dapat dibaca";
		case "OverconstrainedError":
			return "Kamera tidak dapat dibaca";
		case "AbortError":
			return "Kamera tidak dapat dibaca";
		case "SecurityError":
			return "Anda tidak memiliki izin untuk mengakses kamera";
		case "TypeError":
			return "Kamera tidak dapat dibaca";
		default:
			return "Kamera tidak dapat dibaca";
	}
}

export function ModalCamera({
	title = "Camera",

	visible = false,

	onClose = () => {},
	onChange = () => {},
}) {
	const webcamRef = useRef(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [imageBlob, setImageBlob] = useState(null);
	const [cameraError, setCameraError] = useState(null);

	const handleOnClose = () => {
		onClose();
		setImagePreview(null);
	};

	const capture = useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot();
		const blobResult = await (await fetch(imageSrc)).blob();
		const objectUrl = URL.createObjectURL(blobResult);
		setImagePreview(objectUrl);
		setImageBlob(blobResult);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webcamRef, visible]);

	const handleOnRecapture = () => {
		setImagePreview(null);
		setImageBlob(null);
	};
	const handleCameraError = error => {
		setCameraError(error);
	};

	const handleOnUpload = () => {
		onChange({
			file: imageBlob,
		});
		setImagePreview(null);
		onClose();
	};
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					{title && <div className="font-bold text-red-50">{title}</div>}

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				{!cameraError && (
					<div className="flex-row d-flex justify-content-between">
						{imagePreview ? (
							<img src={imagePreview} className="img-fluid camera-img-preview" alt="Preview" />
						) : (
							<>
								{visible && (
									<Webcam
										audio={false}
										mirrored
										style={{
											textAlign: "center",
											height: "50vh",
											width: "100%",
											objectFit: "cover",
										}}
										height={720}
										ref={webcamRef}
										screenshotFormat="image/jpeg"
										width={720}
										videoConstraints={videoConstraints}
										onUserMediaError={handleCameraError}
									/>
								)}
							</>
						)}
					</div>
				)}
				{cameraError && (
					<div className="text-center color-red caption-1 mb-3">
						{getCameraErrorMessage(cameraError.name)}
					</div>
				)}

				{cameraError && (
					<div className="d-flex justify-content-center align-items-center my-3">
						<button type="button" className="btn btn-primary px-5 r-16 mr-2 " onClick={handleOnClose}>
							Close
						</button>
					</div>
				)}

				{!cameraError && (
					<div className="d-flex justify-content-center align-items-center my-3">
						{!imagePreview ? (
							<button
								type="button"
								className="disabled:opacity-50 block w-full rounded-xl font-semibold bg-red-50 text-white py-2 px-8 uppercase hover:bg-red-70 transition-all "
								onClick={capture}>
								Capture
							</button>
						) : (
							<div className="my-6 flex justify-center">
								<div className="w-1/2 px-2">
									<button
										type="button"
										className=" w-full rounded-xl bg-white font-semibold text-grey-70 border border-solid border-grey-10 py-2 px-8 uppercase hover:bg-grey-10 hover:text-red-50 transition-all"
										onClick={handleOnRecapture}>
										Retake
									</button>
								</div>
								<div className="w-1/2 px-2">
									<button
										type="button"
										className="disabled:opacity-50 block w-full rounded-xl font-semibold bg-red-50 text-white py-2 px-8 uppercase hover:bg-red-70 transition-all "
										onClick={handleOnUpload}>
										Upload
									</button>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</ModalContainer>
	);
}
