import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";
import { toast } from "react-hot-toast";
import { PageSettingLayout } from "pages/page-settings/layout";

import { TitlePageSettingValidationSchema } from "config/form/schema/page-setting";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { InputComponent } from "components/form/input.component";

import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { UploadBannerComponent } from "pages/page-settings/upload-banner.component";
import { useUpdateSettingFooter, useGetFooterSetting } from "hooks/general-settings";

const formikInitialValues = {
	is_visible: false,
	title: "",
	subtitle: undefined,
	image_desktop: null,
	image_mobile: null,
};

export const GENERAL_SETTING = {
	FOOTER: [
		{
			id: "general-setting-footer",
			label: "Logo",
			path: "/footer/logo",
		},
		{
			id: "general-setting-footer-social-media",
			label: "Social Media",
			path: "/footer/social-media",
		},
	],
};

export function GeneralSettingLogo() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchFooterSettingData,
	} = useGetFooterSetting({ page: "footer", slug: "logo" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updateFooterSetting, isLoading: isUpdating } = useUpdateSettingFooter();

	const [imageFileBuffer, setImageFileBuffer] = useState({ image_desktop: null, image_mobile: null });
	const [showModalResult, setShowModalResult] = useState(false);

	useEffect(() => {
		Object.keys(formikInitialValues).forEach(key => {
			if (data?.data?.[key]) {
				formikRef.current.setFieldValue(key, data?.data?.[key]);
			}
		});
	}, [data]);

	const handleSubmitForm = async payloadData => {
		try {
			if (Object.values(imageFileBuffer).some(value => value)) {
				const images = Object.keys(imageFileBuffer)
					.filter(itemKey => imageFileBuffer[itemKey])
					.map(itemKey => ({
						version: itemKey.replace("image_", ""),
						file: imageFileBuffer[itemKey],
					}));

				const imagePromise = images.map(image => {
					const formData = new FormData();
					formData.append("image", image.file);
					formData.append("version", image.version);
					formData.append("type", "banner");
					return uploadImageAsync({ formData, uploadId: `image_${image.version}` });
				});

				const res = await Promise.all(imagePromise);

				payloadData.image_desktop =
					res.find(item => item.uploadId === "image_desktop")?.data?.image_desktop ||
					payloadData.image_desktop;
				payloadData.image_mobile =
					res.find(item => item.uploadId === "image_mobile")?.data?.image_mobile || payloadData.image_mobile;
			}

			updateFooterSetting(
				{ page: "footer", slug: "logo", formData: payloadData },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchFooterSettingData();
						setImageFileBuffer({ image_desktop: null, image_mobile: null });
					},
				},
			);
		} catch (error) {
			toast.error(error?.message);
		}
	};
	return (
		<PageSettingLayout
			routes={GENERAL_SETTING}
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="General Setting"
			sidebarMenu="FOOTER"
			desc="Footer">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik
						innerRef={formikRef}
						initialValues={formikInitialValues}
						onSubmit={handleSubmitForm}
						validationSchema={TitlePageSettingValidationSchema}>
						{({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">Logo</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} At All Pages Footer
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>
								<div className="flex justify-between py-4 px-6">
									<div className="w-[47%]">
										<UploadBannerComponent
											initialImage={data?.data?.image_desktop}
											name="image_desktop"
											onChange={imageFileBuffer => {
												setImageFileBuffer(prevState => ({ ...prevState, ...imageFileBuffer }));
											}}
											title="Desktop Banner (Aspect Ration 16:8)"
										/>
									</div>
									<div className="w-[47%]">
										<UploadBannerComponent
											initialImage={data?.data?.image_mobile}
											name="image_mobile"
											onChange={imageFileBuffer => {
												setImageFileBuffer(prevState => ({ ...prevState, ...imageFileBuffer }));
											}}
											title="Mobile Banner (Aspect Ration 16:7)"
										/>
									</div>
								</div>
								<InputComponent
									containerclassName="mb-4 px-6"
									labelclassName="text-sm text-black font-semibold mb-3"
									label="Title"
									placeholder="Title under the logo"
									name="title"
									onBlur={handleBlur}
									error={touched.title && errors.title}
									value={values.title}
									onChange={handleChange}
								/>
								<div className="mb-4 px-6">
									<div className="text-sm text-black font-semibold mb-3">Description</div>
									<textarea
										onBlur={handleBlur}
										error={touched.subtitle && errors.subtitle}
										value={values.subtitle}
										name="subtitle"
										onChange={handleChange}
										className="border-grey-10 outline-none w-full border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
										rows="6"
										placeholder="Subtitle under the title"></textarea>
								</div>
								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											disabled={!TitlePageSettingValidationSchema.isValidSync(values)}
											onClick={handleSubmit}
											loading={isUploading || isUpdating}
											title="Save All Changes"
											className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
