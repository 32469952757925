import * as Yup from "yup";

/**
 * Form validation schema for /my-account change password
 */
export const ChangePasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.min(6, "Password baru terlalu singkat - harus lebih dari 6 karakter.")
		.required("Password baru wajib diisi")
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!$@%])/,
			"Password baru harus menyertakan kombinasi angka, huruf, dan karakter khusus (contoh: !$@% dsb)",
		),
	confirmationPassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Konfirmasi password tidak cocok")
		.required("Password wajib diisi"),
});
