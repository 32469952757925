import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	accessToken: null,
	refreshToken: null,
	rememberMe: false,
	expiredDate: null,
	profile: {
		id: null,
		full_name: "",
		email: null,
		phone_number: null,
		role: {
			id: null,
			name: "",
		},
	},
};

export const authSlice = createSlice({
	name: "@AUTHENTICATION",
	initialState,
	reducers: {
		setAccessToken: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.rememberMe = action.payload.rememberMe;
			state.expiredDate = action.payload.expiredDate;
		},
		setBasicProfile: (state, action) => {
			state.profile = action.payload;
		},
		logout: state => {
			state.accessToken = null;
			state.rememberMe = false;
			state.expiredDate = null;
			state.refreshToken = null;
		},
		renewAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
	},
});

export const { setBasicProfile, setAccessToken, logout, renewAccessToken } = authSlice.actions;

export default authSlice.reducer;
