import dayjs from "dayjs";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import { loadStaticPublicAsset } from "utils/loader";
import { setAuthHeader } from "api/http.api";
import { setAccessToken } from "store/reducer/auth.reducer";
import { LoginValidationSchema } from "config/form/schema/auth/login";
import { useLogin, useVerifyCaptcha } from "hooks/auth";
import { InputComponent, InputPasswordComponent } from "components/form/input.component";
import { ButtonSubmit } from "components/button/submit.button";
import { CheckBoxComponent } from "components/form/checkbox.component";
import CONFIG from "config/env";

const formInitialValues = {
	email: "",
	password: "",
};

export function LoginPage() {
	const navigate = useNavigate();
	const recaptchaRef = useRef();
	const dispatch = useDispatch();
	const [rememberMe, setRememberMe] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [captchaValue, setCaptchaValue] = useState(null);

	const { mutate: login, isLoading: isLoadingLogin, error } = useLogin();

	const { mutate: verifyCaptca, isLoading: isSubmittingCaptcha } = useVerifyCaptcha();

	const isLoading = isLoadingLogin || isSubmittingCaptcha;
	const handleOnCaptchaChange = value => {
		setCaptchaValue(value);
	};

	const handleOnCaptchaExpired = () => {
		setCaptchaValue(null);
		recaptchaRef.current.reset();
	};

	const handleSubmitForm = formData => {
		verifyCaptca(
			{
				recaptcha_token: captchaValue,
			},
			{
				onSuccess: resCaptcha => {
					const loginPayload = {
						...formData,
						token: resCaptcha.data.token,
						remember: rememberMe,
					};
					login(loginPayload, {
						onSuccess: res => {
							if (res.token) {
								setAuthHeader(res.token, res.refreshToken);
								dispatch(
									setAccessToken({
										accessToken: res.token,
										refreshToken: res.refreshToken,
										rememberMe: rememberMe,
										expiredDate: !rememberMe
											? dayjs().add(24, "hours").toISOString()
											: dayjs().add(30, "days").toISOString(),
									}),
								);
								navigate("/");
							}
						},
						onError: handleOnCaptchaExpired,
					});
				},
				onError: handleOnCaptchaExpired,
			},
		);
	};

	return (
		<div className="bg-fixed main-bg h-screen">
			<div className="relative flex items-center justify-center  h-full overflow-auto">
				<div className="flex flex-col">
					<div className="flex flex-col items-center justify-center my-7">
						<div className="w-20 flex justify-center">
							<img src={loadStaticPublicAsset("img/logo/logo.png")} className="w-20" alt="Logo Avo" />
						</div>
						<div className="mt-2 text-sm font-semibold text-center text-black">DASHBOARD</div>
					</div>

					<div className="w-[500px] bg-white py-10 px-6 drop-shadow-main-red rounded-lg relative">
						<div className="text-center text-[32px] text-red-50 font-bold mb-8">Login</div>

						{error && (
							<p className="mb-5 form-input-error bg">
								{error?.response?.data?.message || "Opps, something went wrong please try again later"}
							</p>
						)}
						<Formik
							initialValues={formInitialValues}
							validationSchema={LoginValidationSchema}
							onSubmit={handleSubmitForm}>
							{({ handleSubmit, handleBlur, handleChange, values, touched, errors }) => (
								<>
									<InputComponent
										placeholder="Input Username/Email"
										label="Username/Email"
										value={values.email}
										name="email"
										error={touched.email && errors.email}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									<InputPasswordComponent
										placeholder="Input Password"
										label="Password"
										name="password"
										showPassword={showPassword}
										onToggleShow={value => setShowPassword(value)}
										value={values.password}
										error={touched.password && errors.password}
										onBlur={handleBlur}
										onChange={handleChange}
									/>

									<div className="flex items-center justify-between mb-8">
										<CheckBoxComponent
											containerClassName="form-check flex items-center"
											inputClassName="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
											labelClassName="text-sm text-grey-70 font-semibold pl-3"
											label="Remember Me?"
											initialValue={rememberMe}
											onChange={() => setRememberMe(!rememberMe)}
										/>
										<Link
											to="/auth/forgot-password"
											className="inline-block text-sm font-bold transition-all text-grey-70 hover:text-red-50">
											Forgot Password?
										</Link>
									</div>
									<div className="mb-2">
										<ReCAPTCHA
											ref={recaptchaRef}
											onExpired={handleOnCaptchaExpired}
											sitekey={CONFIG.key.GOOGLE_RECAPTCHA}
											onChange={handleOnCaptchaChange}
										/>
									</div>
									<ButtonSubmit
										loading={isLoading}
										disabled={!LoginValidationSchema.isValidSync(values) || !captchaValue}
										onClick={handleSubmit}
									/>
								</>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
}
