import clsx from "clsx";
import { useState } from "react";
import { ModalContainer } from "components/modal/modal.container";

const GENDER_OPTIONS = [
	{
		label: "Female",
		value: "female",
	},
	{
		label: "Male",
		value: "male",
	},
];

const filtersInitialValue = {
	min_age: "",
	max_age: "",
	min_correct_answer: "",
	batch: "",
	gender: "",
	min_followers_instagram: "",
	min_followers_tiktok: "",
	min_followers_twitter: "",
};

export function TableFilterModalComponent({ visible, initialValue, onClose, onApply }) {
	const [filterValue, setFilterValue] = useState(filtersInitialValue);
	const [isActive, setIsActive] = useState(false);

	const handleValueChange = e => {
		const { name, value } = e.target;
		setFilterValue({ ...filterValue, [name]: value ? +value.replace(/\D/g, "") : null });
	};

	const handleClose = () => {
		if (!isActive) setFilterValue(filtersInitialValue);
		onClose();
	};

	const handleApply = () => {
		onApply(filterValue);
		setIsActive(true);
	};

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" className={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Filter</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={handleClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>
				<div className="max-h-[400px] overflow-y-auto scroll">
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Usia User</label>
						<div className="flex items-center">
							<div className="w-1/2">
								<input
									onChange={handleValueChange}
									name="min_age"
									value={filterValue.min_age}
									type="text"
									className="border-grey-10 w-full outline-none border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
									placeholder="Min"
								/>
							</div>
							<div className="mx-3">
								<div className="font-bold text-2xl">-</div>
							</div>
							<div className="w-1/2">
								<input
									onChange={handleValueChange}
									name="max_age"
									value={filterValue.max_age}
									type="text"
									className="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
									placeholder="Max"
								/>
							</div>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Minimal Jawaban Benar</label>
						<div className="flex items-center">
							<input
								onChange={handleValueChange}
								name="min_correct_answer"
								value={filterValue.min_correct_answer}
								type="text"
								className="border-grey-10 w-full placeholder:italic border border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								placeholder="jumlah minimal"
							/>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Batch Pendaftaran</label>
						<div className="flex items-center">
							<input
								onChange={handleValueChange}
								name="batch"
								value={filterValue.batch}
								type="text"
								className="border-grey-10 w-full placeholder:italic border border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								placeholder="masukkan nomor batch"
							/>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Minimal Follower TikTok</label>
						<div className="flex items-center">
							<input
								onChange={handleValueChange}
								name="min_followers_tiktok"
								value={filterValue.min_followers_tiktok}
								type="text"
								className="border-grey-10 w-full placeholder:italic border border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								placeholder="masukkan minimal follower tiktok"
							/>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">
							Minimal Follower Instagram
						</label>
						<div className="flex items-center">
							<input
								onChange={handleValueChange}
								name="min_followers_instagram"
								value={filterValue.min_followers_instagram}
								type="text"
								className="border-grey-10 w-full placeholder:italic border border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								placeholder="masukkan minimal follower instagram"
							/>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">
							Minimal Follower Twitter
						</label>
						<div className="flex items-center">
							<input
								onChange={handleValueChange}
								name="min_followers_twitter"
								value={filterValue.min_followers_twitter}
								type="text"
								className="border-grey-10 w-full placeholder:italic border border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								placeholder="masukkan minimal follower twitter"
							/>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Gender</label>
						{GENDER_OPTIONS.map(itemLevel => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										id={`level-${itemLevel.value}`}
										checked={itemLevel.value === filterValue.gender}
										onChange={e => {
											if (e.target.checked) {
												setFilterValue(prev => ({ ...prev, gender: itemLevel.value }));
											} else {
												setFilterValue(prev => ({ ...prev, gender: "" }));
											}
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor={`level-${itemLevel.id}`}>
										{itemLevel.label}
									</label>
								</div>
							</div>
						))}
					</div>

					<div className="my-6 flex justify-center">
						<div className="w-2/4">
							<button
								className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
								type="button"
								onClick={handleApply}>
								<span className="icon-ico-user-register"></span>
								<span>Apply</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}

export function ButtonDataFilterComponent({ disabled, onClick, active }) {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className="disabled:opacity-50 flex items-center px-3 py-2 font-semibold text-black transition-all border border-solid rounded-lg border-grey-10 hover:bg-red-hover hover:border-red-50"
			type="button">
			<span className="icon-ico-filter text-red-50 text-xl"></span>
			<span className="pl-1">Filter</span>
			{active && <span className="icon-ico-circle-check text-red-50 ml-2"></span>}
		</button>
	);
}
