import * as Yup from "yup";

/**
 * Form validation schema for /registration/setting
 */

export const WEBSITE_URL_REG_EXP =
	/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/g;

export const TitlePageSettingValidationSchema = Yup.object().shape({
	title: Yup.string().required("Title is required").max(200, "Max character for title is 200").trim(),
});

export const ImageCompononentValidationSchema = Yup.object().shape({
	image_mobile: Yup.mixed().test(
		"image-mobile-condition",
		"Image mobile or image mobile file is required",
		function (value) {
			const { image_mobile_file } = this.parent;
			const result = Boolean(value) || Boolean(image_mobile_file);
			return result;
		},
	),
	image_desktop: Yup.mixed().test(
		"image-desktop-condition",
		"Image desktop or image desktop file is required",
		function (value) {
			const { image_desktop_file } = this.parent;
			return value || image_desktop_file;
		},
	),
});

export const RubiSpaceValidationSchema = Yup.object().shape({
	title: Yup.string().max(40, "Max character for title is 40").required("Title is required").trim(),
	redirect_selengkapnya: Yup.string().matches(WEBSITE_URL_REG_EXP, "URL is not valid"),
	redirect_register_now: Yup.string().matches(WEBSITE_URL_REG_EXP, "URL is not valid"),
});

export const UpdateRegSettingValidaitonSchema = Yup.object().shape({
	redirect_url: Yup.string().matches(WEBSITE_URL_REG_EXP, "URL is not valid").required("URL is required"),
	batch_label: Yup.string().required("Batch label is required"),
});

export const CreateGallerySchema = Yup.object().shape({
	title: Yup.string().max(50, "Max character for title is 50").required("Title is required"),
});

export const BenefitSectionSchema = Yup.object().shape({
	banners: Yup.array()
		.of(
			Yup.object().shape({
				background_color: Yup.string().required("Background color is required"),
				description: Yup.string().required("Description is required"),
				...ImageCompononentValidationSchema.fields,
			}),
		)
		.required(),
});

export const BenefitAmbassadorSchema = Yup.object().shape({
	title: Yup.string().max(40, "Max character for title is 40").required("Title is required").trim(),
	description: Yup.string().required("Description is required"),
	...BenefitSectionSchema.fields,
});

export const AboutFeatureSectionSchema = Yup.object().shape({
	features: Yup.array()
		.of(
			Yup.object().shape({
				title: Yup.string().required("Title is required"),
				description: Yup.string().required("Description is required"),
				...ImageCompononentValidationSchema.fields,
			}),
		)
		.required(),
});

export const RubiPilarValidationSchema = Yup.object().shape({
	title: Yup.string().max(40, "Max character for title is 40").required("Title is required").trim(),
	banners: Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().required("Name is required"),
				description: Yup.string().required("Description is required"),
				...ImageCompononentValidationSchema.fields,
			}),
		)
		.required(),
});
