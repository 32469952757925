import clsx from "clsx";
import React, { useState, useRef } from "react";

import { useOnClickOutside } from "hooks/useOnClickOutside";

export const SelectComponent = ({ title = "Select Option", options, onChange = () => {}, disabled }) => {
	const selectContainerRef = useRef();

	const [showOptions, setShowOptions] = useState(false);
	const [value, setValue] = useState(title);

	const setSelectValue = item => {
		setValue(item.label);
		onChange(item);
		setShowOptions(false);
	};

	useOnClickOutside(selectContainerRef, () => setShowOptions(false));

	return (
		<div className="mr-2 w-3/4 relative" ref={selectContainerRef}>
			<button
				disabled={disabled}
				className="
				disabled:opacity-50
                for-status
                flex
                justify-between
                items-center
                py-2
                px-3
                w-full
                border
                border-solid
                border-grey-10
                rounded-lg
                text-black
                hover:bg-red-hover
                hover:border-red-50
				
                transition-all
                font-semibold
              "
				onClick={() => setShowOptions(!showOptions)}
				type="button">
				<span className="pl-1">{value}</span>
			</button>

			<div
				class={clsx(
					"absolute bg-white py-4 px-2 xl:px-4 xl:py-8 w-full rounded-2xl shadow-md",
					!showOptions && "hidden",
				)}>
				{options.map(option => (
					<span
						onClick={() => setSelectValue(option)}
						className="
                   block
                   p-3
                   text-center
                   border
                   border-solid
                   border-grey-10
                   rounded-md
                   mb-2
                   font-semibold
                   hover:text-red-50
                   cursor-pointer
                 ">
						{option.label}
					</span>
				))}
			</div>
		</div>
	);
};
