export function getColorClassNameByStatus(status) {
	switch (status) {
		case "rejected":
			return "text-main-pink";
		case "approved":
			return "text-main-green";
		case "waiting approval":
			return "text-main-orange";
		default:
			return "gray";
	}
}

export function getColorClassNameByExamStatus(status) {
	switch (status) {
		case "failed":
			return "text-main-pink";
		case "passed":
			return "text-main-green";
		case "waiting review":
			return "text-main-orange";
		default:
			return "gray";
	}
}
