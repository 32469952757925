import * as Yup from "yup";

export const UserLevelPointSchema = Yup.object().shape({
	welcome_point: Yup.object({
		beginner: Yup.string().required("Beginner point is required"),
		intermediate: Yup.string().required("Intermediate point is required"),
		advance: Yup.string().required("Advance point is required"),
	}),
	min_exam_access_point: Yup.object({
		beginner: Yup.string().required("Beginner point is required"),
		intermediate: Yup.string().required("Intermediate point is required"),
		advance: Yup.string().required("Advance point is required"),
	}),
});
