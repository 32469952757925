import clsx from "clsx";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalContainer } from "components/modal/modal.container";
import { useEffect, useMemo, useRef, useState } from "react";

export const UPLOADING_STATE_STATUS = {
	PENDING: "Pending",
	PROCESSING: "Uploading",
	COMPRESSING: "Compressing",
	COMPLETE: "Complete",
	ERROR: "Failed",
};

export const getUploadStatusColor = status => {
	switch (status) {
		case UPLOADING_STATE_STATUS.PENDING:
			return "bg-gray-100 text-gray-600";
		case UPLOADING_STATE_STATUS.PROCESSING:
			return "bg-blue-100 text-blue-600";
		case UPLOADING_STATE_STATUS.COMPLETE:
			return "bg-green-50 text-green-600";
		case UPLOADING_STATE_STATUS.ERROR:
			return "bg-red-100 text-red-600";
		default:
			return "bg-gray-50 text-gray-600";
	}
};

export function generateUploadingStateData(files) {
	return files.map(file => {
		return {
			fileName: file.name,
			fileSize: file.size,
			status: UPLOADING_STATE_STATUS.PENDING,
			previewURL: URL.createObjectURL(file),
		};
	});
}

/**
 * It's a modal that displays a success or error message
 * @returns A modal component that is used to display a result of an operation message to the user.
 */
export function ModalUploading({
	title,
	message = "Change succesfully updated!",
	visible = false,
	onClose = () => {},
}) {
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					{title && <div className="font-bold text-red-50">{title}</div>}
				</div>

				<div className="my-6">
					<div className="text-center">
						<span className="loader"></span>
					</div>
				</div>
				<p className="mt-2 mb-6 text-center  text-red-50">{message}</p>
			</div>
		</ModalContainer>
	);
}

export function ModalUploadingWithList({ title, uploadingData = [], visible = false, onClose = () => {}, message }) {
	const [isComplete, setIsComplete] = useState(false);
	const ulRef = useRef(null);
	const itemRefs = useRef([]);
	const totalStatus = useMemo(() => {
		if (uploadingData.length === 0) return null;
		const complete = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.COMPLETE).length;
		const error = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.ERROR).length;

		return {
			complete,
			error,
		};
	}, [uploadingData]);

	const processMessage = useMemo(() => {
		if (message) return message;
		if (uploadingData.length === 0) return null;
		const complete = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.COMPLETE).length;
		const error = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.ERROR).length;

		return `Uploading ${complete + error + 1} of ${uploadingData.length} files.`;
	}, [uploadingData, message]);

	useEffect(() => {
		if (uploadingData.length === 0) return;

		const complete = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.COMPLETE).length;
		const error = uploadingData.filter(item => item.status === UPLOADING_STATE_STATUS.ERROR).length;

		if (complete + error === uploadingData.length) {
			setIsComplete(true);
		}
	}, [uploadingData]);

	useEffect(() => {
		const uploadingIndex = uploadingData.findIndex(item => item.status === UPLOADING_STATE_STATUS.PROCESSING);
		if (uploadingIndex !== -1 && itemRefs.current[uploadingIndex] && ulRef.current) {
			itemRefs.current[uploadingIndex].scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	}, [uploadingData]);

	const handleOnClose = () => {
		setIsComplete(false);
		onClose();
	};
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					{title && <div className="font-bold text-red-50">{title}</div>}
				</div>

				{!isComplete && (
					<div className="my-6">
						<div className="text-center">
							<span className="loader"></span>
						</div>
					</div>
				)}

				{!isComplete && processMessage && (
					<p className="mt-2 mb-6 text-center  text-red-50">{processMessage}</p>
				)}

				<ul
					ref={ulRef}
					class="divide-y divide-gray-100 overflow-y-scroll max-h-72 overflow-x-hidden scroll-menu px-2 pb-3">
					{uploadingData.map((item, index) => (
						<li
							class="flex items-center justify-between rounded-md border my-2 px-2"
							ref={el => (itemRefs.current[index] = el)}>
							<div class="min-w-0 flex-row flex gap-x-3 py-2 px-2">
								<img src={item.previewURL || ""} alt={item.fileName} class="w-10 h-10 rounded-md" />
								<div class="flex items-start gap-x-3 justify-between flex-row">
									<p class="text-sm font-semibold leading-6 text-gray-900">{item.fileName}</p>
								</div>
							</div>
							<p
								class={clsx(
									"mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium",
									getUploadStatusColor(item.status),
								)}>
								{item.status}
							</p>
						</li>
					))}
				</ul>

				{isComplete && (
					<div class="flex justify-center mt-6 flex-col">
						<span className="mt-2 block text-sm font-semibold text-center text-gray-900 ">
							{`Upload complete with  ${totalStatus?.complete} success and ${totalStatus?.error} error`}
						</span>
						<ButtonSubmit title="Close" onClick={handleOnClose} />
					</div>
				)}
			</div>
		</ModalContainer>
	);
}
