import axios from "api/http.api";
import { useMutation, useQueryClient } from "react-query";
import qs from "query-string";

export function useUpdateProfileMember({ type, userId }) {
	const queryClient = useQueryClient();
	return useMutation(
		values => axios.post(`/api/v1/admin/member/profile/update/${type}/${userId}`, values).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("MEMBER_PROFILE");
			},
		},
	);
}

export function useUpdateMemberBeautyProfile({ userId }) {
	const queryClient = useQueryClient();
	return useMutation(
		values => axios.post(`/api/v1/admin/member/beauty-profile/update/${userId}`, values).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("MEMBER_BEAUTY_PROFILE");
			},
		},
	);
}

export function useDeleteMember() {
	const queryClient = useQueryClient();
	return useMutation(userId => axios.post(`/api/v1/admin/member/active/bulk-delete`, userId).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("MEMBERS");
		},
	});
}

export function useChangePasswordMember() {
	return useMutation(({ type, userId, new_password, password_confirmation }) =>
		axios
			.post(`/api/v1/admin/member/account/generate-new-password/${type}/${userId}`, {
				new_password,
				password_confirmation,
			})
			.then(res => res.data),
	);
}

export function useDeleteMemberById() {
	const queryClient = useQueryClient();
	return useMutation(
		({ userId }) => axios.delete(`/api/v1/admin/member/account/delete/${userId}`).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("MEMBERS");
			},
		},
	);
}

export function useExportDataMember() {
	return useMutation(({ filter, formData = {}, type }) =>
		axios
			.post(`/api/v2/admin/member/export/${type}?${qs.stringify(filter)}`, formData, {
				responseType: "blob",
			})
			.then(res => res),
	);
}
