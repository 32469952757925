import axios from "api/http.api";
import { useInfiniteQuery } from "react-query";
import qs from "qs";
import { urlSafeEncrypt } from "utils/encryption";

export function useTopicThread(topicId, filter) {
	return useInfiniteQuery(
		["THREAD_TOPIC", { topicId, filter }],
		({ pageParam = 1 }) =>
			axios
				.get(`/api/v1/admin/thread/list/${topicId}?${qs.stringify({ ...filter, page: pageParam })}`)
				.then(res => {
					return {
						...res?.data?.data,
						rows: res?.data?.data?.rows.map(item => ({
							...item,
							id: urlSafeEncrypt(item.id),
							topic_id: urlSafeEncrypt(item.topic_id),
						})),
					};
				}),
		{
			enabled: !!topicId,
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage?.current_page < lastPage?.total_page ? lastPage?.current_page + 1 : undefined;

				return nextPage;
			},
		},
	);
}

export function useGetThreadReply(threadId) {
	return useInfiniteQuery(
		["THREAD_REPLY", { threadId }],
		({ pageParam = { page: 1, limit: 2 } }) => {
			return axios
				.get(
					`/api/v1/admin/thread/comment/list/${threadId}?${qs.stringify({
						page: pageParam?.page,
						limit: pageParam?.limit,
					})}`,
				)
				.then(res => {
					return {
						...res?.data?.data,
						rows: res?.data?.data?.rows.map(item => ({
							...item,
							id: urlSafeEncrypt(item.id),
							topic_thread_id: urlSafeEncrypt(item.topic_thread_id),
						})),
					};
				});
		},

		{
			enabled: !!threadId,
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage?.current_page < lastPage?.total_page ? lastPage?.current_page + 1 : undefined;

				return nextPage;
			},
		},
	);
}
