import { BounceLoading } from "components/loading/bounce.loading";

export function ButtonUpload({
	className = "flex cursor-pointer uppercase items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold",
	onClick = () => {},
	disabled = false,
	title = "Submit",
	loading = false,
	type = "button",
	children,
	htmlFor = "",
}) {
	return (
		<button disabled={disabled || loading} type={type}>
			<label class={className} htmlFor={htmlFor} onClick={onClick}>
				{loading ? <BounceLoading color="#90011f" /> : children ? children : title}
			</label>
		</button>
	);
}
