import {Route, Routes} from "react-router-dom";

import {appRoutes} from "./declaration.routes";
import {RouteWrapper} from "./private-routes";
import {PageNotFound} from "pages/error/404";

export default function RoutePage() {
	return (
		<Routes>
			{appRoutes.map(item => {
				return (
					<Route
						key={item.id}
						path={item.path}
						element={<RouteWrapper isPrivate={item.isPrivate}>{item.component}</RouteWrapper>}
					/>
				);
			})}
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}
