import { useMemo, useState } from "react";
import { Select } from "components/form/select.component";
import { InputComponent } from "components/form/input.component";
import { UploadBannerComponent } from "pages/page-settings/upload-banner.component";
import { useActivityCategories, useActivityLevels, useGetUsersActivityByLevel } from "hooks/activity";
import TextEditor from "components/text-editor";

import { AccordionWrapper } from "../accordion-wrapper.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { IsJsonString } from "utils/string";
export function Introduction({
	expanded,
	selectedUsers,
	setSelectedUsers,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	setFieldValue,
	sectionId = "introduction",
	descriptionInitialValue = undefined,
}) {
	const [searchKeyword, setSearchKeyword] = useState("");
	const { data: activityCategories } = useActivityCategories();
	const { data: activityLevels } = useActivityLevels();

	const selectedUsersId = useMemo(() => {
		if (!selectedUsers) return [];
		return selectedUsers.map(user => user.id);
	}, [selectedUsers]);

	const selectedLevel = activityLevels?.data?.find(level => level.id === +values?.level_id);

	const { data: usersList, isLoading: isLoadingUser } = useGetUsersActivityByLevel({
		level: selectedLevel?.name || undefined,
		search: searchKeyword,
	});

	return (
		<AccordionWrapper
			expaned={expanded}
			onToggleExpand={onToggleExpand}
			withToggle={false}
			title="Introduction"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="text-sm text-black font-semibold mb-3">Activity Title</div>
				<input
					name={`${sectionId}.title`}
					onChange={onChange}
					onBlur={onBlur}
					value={values?.title}
					type="text"
					className="border-grey-10 w-full border-b outline-none placeholder:italic border-solid px-0 py-3 text-3xl placeholder:text-grey-30  focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
					placeholder="write the title"
				/>
				{errors?.title && touched?.title && <p className="form-input-error">{errors?.title}</p>}
				<div className="flex justify-between py-7">
					<div className="w-[48%]">
						<Select
							name={`${sectionId}.activity_category_id`}
							onBlur={onBlur}
							onChange={onChange}
							value={values?.activity_category_id}
							options={activityCategories?.data || []}
							label="Category"
							placeholder="Select Category"
							error={touched?.activity_category_id && errors?.activity_category_id}
						/>
						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(descriptionInitialValue) ? descriptionInitialValue : undefined
								}
								contentValue={values?.description}
								onChange={nodeValue => setFieldValue(`${sectionId}.description`, nodeValue)}
							/>
						</div>
						<InputComponent
							label="Total Point"
							placeholder="Insert Point"
							numberOnly
							onBlur={onBlur}
							onChange={onChange}
							value={values?.total_point}
							name={`${sectionId}.total_point`}
							error={touched?.total_point && errors?.total_point}
						/>
						<Select
							label="Level"
							placeholder="Select Level"
							options={activityLevels?.data || []}
							name={`${sectionId}.level_id`}
							onBlur={onBlur}
							onChange={onChange}
							value={values?.level_id}
							error={touched?.level_id && errors?.level_id}
						/>

						{values?.level_id && (
							<div className="mb-4">
								<div class="flex items-center py-2  rounded-t-md bg-red-10 ">
									<div class="form-check px-2"></div>
									<div class="w-7/12 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis  font-semibold">
										Users
									</div>
									<SearchFilterComponent
										inputClassName="block w-full pr-4 py-2 pl-10 text-base font-normal text-red-50 bg-white bg-clip-padding border border-solid border-red-50 placeholder:text-red-50 placeholder:italic rounded-lg form-control focus:text-red-50 focus:bg-white focus:border-red-50 focus:outline-none focus:shadow-none"
										containerClassName="relative  mr-2"
										onSearch={({ keyword }) => {
											setSearchKeyword(keyword);
										}}
									/>
								</div>
								<div className="scroll h-44 border rounded-b-md border-gray-200 ">
									{isLoadingUser ? (
										<div className="flex justify-center content-center mt-10">
											<p className="text-base font-medium text-grey-40">Loading...</p>
										</div>
									) : (
										usersList?.data?.map(user => (
											<div class="flex items-center py-2 border border-b-1 border-solid  border-gray-100">
												<div class="form-check px-2">
													<input
														checked={selectedUsersId.includes(user.id)}
														class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
														type="checkbox"
														onChange={e => {
															if (e.target.checked) {
																setSelectedUsers([...selectedUsers, user]);
															} else {
																setSelectedUsers(
																	selectedUsers.filter(u => u.id !== user.id),
																);
															}
														}}
													/>
												</div>
												<div class="w-1/4 text-grey-50 text-sm font-medium">{user?.email}</div>
											</div>
										))
									)}
									{!isLoadingUser && usersList?.data?.length === 0 && (
										<div className="flex justify-center content-center mt-10">
											<p className="text-base font-medium text-grey-40">No User Found</p>
										</div>
									)}
								</div>

								<div className="scroll h-32 border border-solid border-grey-10 mt-3 rounded-md rounded-br-lg p-4">
									<p className="mb-2 font-medium text-sm text-gray-400">
										{selectedUsers?.length} users selected
									</p>
									<ul className="list-none flex flex-wrap pl-0">
										{selectedUsers?.map(item => (
											<li
												key={`${item.name}-${item.id}`}
												className="border mr-2 mb-2 border-solid border-red-10 bg-red-hover pl-4 pr-2 min-w-32 py-1 flex items-center justify-between rounded-full text-sm text-grey-70 font-semibold">
												<span className="mr-3">{item.email}</span>
												<button
													className="text-red-50 outline-none text-base leading-3"
													type="button"
													onClick={() => {
														setSelectedUsers(selectedUsers.filter(u => u.id !== item.id));
													}}>
													<span className="icon-ico-x-circle"></span>
												</button>
											</li>
										))}
									</ul>
								</div>
							</div>
						)}
					</div>
					<div className="w-[48%]">
						<UploadBannerComponent
							initialImage={values?.thumbnail_desktop}
							name="thumbnail"
							title="Thumbnail"
							silentUpload
							onChange={data => {
								if (data.thumbnail) {
									setFieldValue(`${sectionId}.thumbnail_desktop`, data.thumbnail.image_desktop);
									setFieldValue(`${sectionId}.thumbnail_mobile`, data.thumbnail.image_mobile);
								}
							}}
						/>

						<div className="mb-4">
							<InputComponent
								label="Start Date & Time"
								placeholder="Select Start Date & Time"
								type="datepicker-modal"
								onChange={onChange}
								onBlur={onBlur}
								name={`${sectionId}.start_date`}
								value={values?.start_date}
								error={touched?.start_date && errors?.start_date}
								min={new Date().toISOString().split("T")[0]}
							/>
						</div>

						<div className="mb-4">
							<InputComponent
								label="End Date & Time"
								type="datepicker-modal"
								placeholder="Select End Date & Time"
								onChange={onChange}
								onBlur={onBlur}
								name={`${sectionId}.end_date`}
								value={values?.end_date}
								error={touched?.end_date && errors?.end_date}
								min={new Date().toISOString().split("T")[0]}
							/>
						</div>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
