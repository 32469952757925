import clsx from "clsx";

import { ModalContainer } from "components/modal/modal.container";
import { BounceLoading } from "components/loading/bounce.loading";
import { useExamResult } from "hooks/registration";

export function ModalAnswerComponent({
	visible,
	userId,
	onClose,
	type = "registration",
	title = "Registration Test Result",
}) {
	const { data: examResultData, isLoading } = useExamResult({ userId, type });

	return (
		<ModalContainer visible={visible}>
			<div id="data" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden")}>
				<div className="relative text-center py-4 mb-4">
					<div className="font-bold text-red-50">{title}</div>

					<button
						type="button"
						className="absolute top-0 right-0 border-0"
						onClick={onClose}
						disabled={isLoading}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				{isLoading ? (
					<div className="h-[60vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<>
						<div className="border flex justify-between items-center py-4 px-6 rounded-lg border-solid border-green-border mb-7 bg-green-10">
							<div className="w-1/2">
								<div className="flex items-center">
									<div className="bg-main-green w-6 h-6 rounded-full text-white text-center leading-7">
										<span className="icon-ico-check"></span>
									</div>
									<div className="pl-3 font-bold text-main-green text-sm">Correct Answers</div>
								</div>
							</div>
							<div className="w-1/2">
								<div className="flex justify-end">
									<div className="pl-3 font-bold text-main-green text-md">
										<span className="text-xl">{examResultData?.data?.correct_answer}</span>/
										{examResultData?.data?.total_question}
									</div>
								</div>
							</div>
						</div>

						<div className="content-scroll">
							{examResultData?.data?.[
								type === "registration" ? "exam_result" : "activity_exam_result"
							]?.map((item, index) => {
								return (
									<div className="mb-7">
										<div className="flex">
											<div className="w-7 h-7 rounded-full font-bold text-white bg-red-50 text-center leading-7">
												{item.sequence}
											</div>
											<div className="w-5/6 ml-4">
												<div className="text-sm text-main-grey font-semibold mb-6">
													{item.question}
												</div>

												<div className="text-xs text-grey-50 italic font-semibold mb-2">
													Jawab:
												</div>
												{item?.answer?.type === "image" ? (
													<img
														src={item?.answer?.label}
														className="max-w-full mb-4"
														alt="Item Answer"
													/>
												) : (
													<div className="text-sm text-main-grey font-semibold mb-3">
														{item.answer?.label}
													</div>
												)}
												<div className="flex items-center">
													<div
														class={clsx(
															"w-6 h-6 rounded-full text-white text-center leading-7",
															item?.is_correct ? "bg-main-green" : "bg-red-50",
														)}>
														<span
															class={clsx(
																item?.is_correct ? "icon-ico-check" : "icon-ico-close",
															)}></span>
													</div>
													<div
														class={clsx(
															"pl-3 font-bold text-sm",
															item?.is_correct ? "text-main-green" : "text-red-50",
														)}>
														{item?.is_correct ? "Correct" : "Incorrect"}
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</ModalContainer>
	);
}
