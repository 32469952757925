import clsx from "clsx";
import * as Yup from "yup";
import { useState, useMemo } from "react";

import { ModalContainer } from "components/modal/modal.container";
import { convertToTitleCase, IsJsonString } from "utils/string";
import { useActivityResultDetails } from "hooks/activity";
import { InputComponent } from "components/form/input.component";
import TextEditor from "components/text-editor";

import { useChangeStatusUserActivityResult } from "hooks/activity";
import { ButtonSubmit } from "components/button/submit.button";

const OPTIONS_STATUS = [
	{
		label: "Failed",
		value: "failed",
	},
	{
		label: "Passed",
		value: "passed",
	},
];

const textEditorInitValue = [
	{
		type: "paragraph",
		align: "left",
		children: [{ text: "" }],
	},
];

export function ModalGivePoint({
	visible,
	onClose,
	onCompleted,
	showComment,
	selectedIds,
	activityId,
	type = "exam",
	usersIdWithPoint = [],
}) {
	const [filterValue, setFilterValue] = useState({
		point: "",
		comment: JSON.stringify(textEditorInitValue),
	});
	const [selected, setSelected] = useState("");

	const { mutate: changeStatusUserActivityResult, isLoading } = useChangeStatusUserActivityResult();
	const { data: activityDetails } = useActivityResultDetails(activityId);

	const handleValueChange = e => {
		const { name, value } = e.target;
		setFilterValue({ ...filterValue, [name]: value ? +value.replace(/\D/g, "") : "" });
	};

	const inputPointSchema = useMemo(() => {
		return Yup.object().shape({
			point: Yup.string().test(
				"point",
				`Point must be equal or less than ${activityDetails?.data?.max_points?.[type]}`,
				value => {
					if (value === "") return true;
					if (value) return value <= activityDetails?.data?.max_points?.[type];
				},
			),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleOnApplyButtonClick = () => {
		const payloadRequest = {
			activityId,
			type,
			filter: {
				user_activity_ids: selectedIds,
			},
			update_status: selected,
			given_point: filterValue.point,
		};
		if (showComment) {
			const isCommentEmpty = filterValue.comment === JSON.stringify(textEditorInitValue);
			if (!isCommentEmpty) payloadRequest.comment = filterValue.comment;
		}
		changeStatusUserActivityResult(payloadRequest, {
			onSuccess: () => {
				setFilterValue({ point: "", comment: JSON.stringify(textEditorInitValue) });
				setSelected("");
				onCompleted();
			},
		});
	};

	function getPointValidation() {
		try {
			const result = inputPointSchema.validateSync(filterValue);
			return { isValid: true, ...result, error: null };
		} catch (error) {
			return { isValid: false, error: error.message };
		}
	}

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Give Point</div>

					<button
						type="button"
						className="absolute top-0 right-0 border-0"
						disabled={isLoading}
						onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="content-scroll">
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Jumlah Point</label>
						<div className="flex items-center">
							<InputComponent
								inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								containerclassName="w-3/4"
								numberOnly
								name="point"
								value={filterValue.point}
								onChange={handleValueChange}
								error={getPointValidation().error}
								placeholder="Masukkan point"
							/>
							<div className="w-1/4">
								<div className="text-sm text-main-grey font-semibold pl-3">
									/{activityDetails?.data?.max_points?.[type]} Point
								</div>
							</div>
						</div>
					</div>
					<div className="mb-6">
						<label className="text-grey-60 font-semibold text-sm mb-3 block">Status</label>
						{OPTIONS_STATUS.map(optionStatus => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										id={`status-${optionStatus.value}`}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										checked={selected === optionStatus.value}
										onChange={e => {
											if (e.target.checked) {
												setSelected(optionStatus.value);
											} else {
												setSelected("");
											}
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor={`status-${optionStatus.value}`}>
										{optionStatus.label && convertToTitleCase(optionStatus.label)}
									</label>
								</div>
							</div>
						))}
					</div>
					{showComment && (
						<div className="mb-4">
							<label className="text-grey-60 font-semibold text-sm mb-3 block">Komentar</label>
							<TextEditor
								placeholder="Masukkan komentar Anda"
								initialValue={IsJsonString(filterValue.comment) ? filterValue.comment : undefined}
								onChange={nodeValue => setFilterValue(prev => ({ ...prev, comment: nodeValue }))}
							/>
						</div>
					)}
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<ButtonSubmit
							className="disabled:opacity-50 block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							loading={isLoading}
							disabled={!getPointValidation().isValid || !selected || filterValue.point === ""}
							onClick={handleOnApplyButtonClick}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</ButtonSubmit>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
