import axios from "api/http.api";
import { useQuery } from "react-query";
import qs from "qs";

export function useActivityList({ filter, body }) {
	return useQuery(["ACTIVITY_LIST", { filter, body }, body], () =>
		axios.post(`/api/v1/admin/activity?${qs.stringify(filter)}`, body).then(res => res.data),
	);
}

export function useActivityCategories() {
	return useQuery(["ACTIVITY_CATEGORIES_LIST"], () =>
		axios.get(`/api/v1/admin/activity/categories`).then(res => res.data),
	);
}

export function useActivityLevels() {
	return useQuery(["ACTIVITY_LEVELS_LIST"], () => axios.get(`/api/v1/admin/activity/levels`).then(res => res.data));
}

export function useActivityDetails(activityId) {
	return useQuery(
		["ACTIVITY_DETAILS", { activityId }],
		() => axios.get(`/api/v1/admin/activity/detail/${activityId}`).then(res => res.data),
		{ enabled: !!activityId },
	);
}

export function useGetQuizActivityByID({ activity_exam_id = null }) {
	return useQuery(
		["ACTIVITY_QUIZ_BY_ID", { activity_exam_id }],
		() => axios.post(`/api/v1/admin/activity-exam/detail`, { activity_exam_id }).then(res => res.data),
		{ enabled: !!activity_exam_id },
	);
}

// For Activity Details

export function useActivityResultDetails(activityId) {
	return useQuery(
		["ACTIVITY_RESULT_DETAILS", { activityId }],
		() => axios.get(`/api/v1/admin/activity-result/detail/${activityId}`).then(res => res.data),
		{ enabled: !!activityId },
	);
}

export function useActivityResult({ type, activityId }, { queryFilter, bodyFilter }) {
	return useQuery(
		["ACTIVITY_RESULT_DETAILS", { activityId, type, queryFilter, bodyFilter }],
		() =>
			axios
				.post(
					`/api/v1/admin/activity-result/list/${activityId}/${type}?${qs.stringify(queryFilter)}`,
					bodyFilter,
				)
				.then(res => res.data),
		{ enabled: !!activityId },
	);
}

export function useActivityChallangeResult(userActivityId, modalVisible) {
	return useQuery(
		["ACTIVITY_CHALLANGE_RESULT", userActivityId],
		() => axios.get(`/api/v1/admin/activity-result/challenge-result/${userActivityId}`).then(res => res.data),
		{
			enabled: !!userActivityId && modalVisible,
		},
	);
}

export function useGetUsersActivityByLevel({ level, search }) {
	return useQuery(
		["GET_USERS_ACTIVITY_BY_LEVEL", { level, search }],
		() =>
			axios
				.get(
					`/api/v1/admin/activity/users?${qs.stringify({
						level,
						search,
					})}`,
				)
				.then(res => res.data),
		{ enabled: !!level },
	);
}
