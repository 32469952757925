import axios from "api/http.api";
import { useQuery } from "react-query";
import qs from "qs";

export function useEventList({ filter, body }) {
	return useQuery(["EVENT_LIST", { filter, body }], () =>
		axios.post(`/api/v1/admin/event?${qs.stringify(filter)}`, body).then(res => res.data),
	);
}

export function useEventCategories() {
	return useQuery(["EVENT_CATEGORIES"], () => axios.get(`/api/v1/admin/event/categories`).then(res => res.data));
}

export function useEventDetails(eventId) {
	return useQuery(
		["EVENT_DETAILS", eventId],
		() => axios.get(`/api/v1/admin/event/info/${eventId}`).then(res => res.data),
		{
			disabled: !!eventId,
		},
	);
}
