// The environment to use when building the project
const CONFIG = {
	endpoint: {
		APP_ENDPOINT: process.env.REACT_APP_URL,
		API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
	},
	timeout: {
		REQUEST: process.env.REACT_APP_MAX_TIMEOUT_REQUEST,
	},
	key: {
		GOOGLE_RECAPTCHA: process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY,
		APP_ENCRYPTION_KEY: process.env.REACT_APP_DATA_ENCRYPTION_KEY,
		APP_IV_VALUE: process.env.REACT_APP_DATA_ENCRYPTION_IV_VALUE,
	},
};

export default CONFIG;
