import clsx from "clsx";
import { ModalContainer } from "components/modal/modal.container";
import { useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";

import "assets/css/react-datepicker.css";
import dayjs from "dayjs";

export function ModalDatePicker({ initialValue, visible = false, onClose = () => {}, onChange = () => {} }) {
	const [startDate, setStartDate] = useState(initialValue ? new Date(initialValue) : new Date());
	const [time, setTime] = useState(dayjs().format("HH:mm"));
	const onCalendarChange = date => {
		setStartDate(date);
	};

	useEffect(() => {
		setStartDate(initialValue ? new Date(initialValue) : new Date());
		const initialTime = initialValue ? dayjs(initialValue).format("HH:mm") : dayjs().format("HH:mm");
		setTime(initialTime);
	}, [initialValue]);

	const handleApplyClick = () => {
		onChange(dayjs(startDate).format("YYYY-MM-DD") + " " + time);
	};

	const MyContainer = ({ className, children }) => {
		return <CalendarContainer className="react-datepicker-main-container">{children}</CalendarContainer>;
	};

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[429px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="mb-6  border-b-2 border-gray-200">
					<DatePicker
						className="custom-datepicker-modal-container"
						selected={startDate}
						onChange={onCalendarChange}
						startDate={startDate}
						minDate={new Date()}
						inline
						calendarContainer={MyContainer}
					/>
					<div className="my-6">
						<div className="flex justify-center">
							<div className="w-4/4 px-2">
								<p className="text-center text-main-grey text-sm mb-2 font-bold">Time</p>
								<input
									type="time"
									value={time}
									onChange={e => setTime(e.target.value)}
									className="block w-full px-2 py-2 text-center text-gray-500 transition-all rounded-xl border border-gray-300 hover:border-gray-400 disabled:border-red-10 focus-visible:border-red-40 focus:border-red-40 focus:outline-none"
								/>
							</div>
						</div>
					</div>
					<div className="my-6 flex justify-center">
						<div className="w-2/4">
							<button
								onClick={handleApplyClick}
								className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
								type="button">
								<span className="icon-ico-user-register"></span>
								<span>Apply</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
