import clsx from "clsx";
import { Formik } from "formik";
import { useState, useRef } from "react";

import { ChangePasswordValidationSchema } from "config/form/schema/members/membe-account";
import { ModalContainer } from "components/modal/modal.container";
import { InputPasswordComponent } from "components/form/input.component";
import { ButtonSubmit } from "components/button/submit.button";
import { useChangePasswordMember } from "hooks/member";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { useParams } from "react-router-dom";

export function ModalChangePassword({ visible, onClose, onSuccess }) {
	const formikRef = useRef();
	const { userId } = useParams();
	const [modalConfirmation, setModalConfirmation] = useState(false);
	const [showPassword, setShowPassword] = useState({
		showPassword: false,
		showConfirmPassword: false,
	});

	const { mutate: changePassword, isLoading: isUpdating } = useChangePasswordMember();

	const handleCloseModal = () => {
		formikRef.current.resetForm();
		onClose();
	};

	const handleOnSubmitClick = () => {
		setModalConfirmation(true);
	};

	const handleOnSubmit = () => {
		const formData = formikRef.current.values;

		changePassword(
			{
				userId,
				type: "active",
				new_password: formData.password,
				password_confirmation: formData.confirmationPassword,
			},
			{
				onSuccess: () => {
					onSuccess();
					setModalConfirmation(false);
					handleCloseModal();
				},
			},
		);
	};

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Change Account Password</div>

					<button
						type="button"
						disabled={isUpdating}
						className="absolute top-0 right-0 border-0"
						onClick={handleCloseModal}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<Formik
					onSubmit={handleOnSubmitClick}
					innerRef={formikRef}
					validationSchema={ChangePasswordValidationSchema}
					initialValues={{
						password: "",
						confirmationPassword: "",
					}}>
					{({ values, touched, handleBlur, handleChange, handleSubmit, errors }) => (
						<>
							<div className="content-scroll">
								<div className="mb-6">
									<InputPasswordComponent
										placeholder="Input Password Baru"
										label="Password"
										name="password"
										showPassword={showPassword.showPassword}
										onToggleShow={value =>
											setShowPassword({ ...showPassword, showPassword: value })
										}
										value={values.password}
										error={touched.password && errors.password}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								</div>
								<div className="mb-6">
									<InputPasswordComponent
										placeholder="Konfirmasi Password Baru"
										label="Konfirmasi Password"
										name="confirmationPassword"
										showPassword={showPassword.showConfirmPassword}
										onToggleShow={value =>
											setShowPassword({ ...showPassword, showConfirmPassword: value })
										}
										value={values.confirmationPassword}
										error={touched.confirmationPassword && errors.confirmationPassword}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								</div>
							</div>

							<div className="my-6 flex justify-center">
								<div className="w-2/4">
									<ButtonSubmit
										loading={isUpdating}
										onClick={handleSubmit}
										disabled={!ChangePasswordValidationSchema.isValidSync(values)}
										className="block disabled:opacity-50 w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all">
										<span className="icon-ico-user-register"></span>
										<span> Update </span>
									</ButtonSubmit>
								</div>
							</div>
						</>
					)}
				</Formik>
				<ModalConfirmation
					visible={modalConfirmation}
					labelReject="Cancel"
					loading={isUpdating}
					labelApprove="Save"
					onApprove={handleOnSubmit}
					onReject={() => setModalConfirmation(false)}
					onClose={() => setModalConfirmation(false)}>
					<div className="my-6">
						<img src="/img/icons/save.svg" alt="Delete" className="mx-auto" />
						<p className="my-6 text-center text-main-grey">Apakah ingin menyimpan perubahan ?</p>
					</div>
				</ModalConfirmation>
			</div>
		</ModalContainer>
	);
}
