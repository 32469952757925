import qs from "query-string";
import dayjs from "dayjs";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Layout from "components/layout";
import { useGetMemberList, useDeleteMember, useExportDataMember } from "hooks/member";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { ModalSocialMedia } from "components/modal/modal.social-media";
import { convertToTitleCase, getInitialsName } from "utils/string";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { ModalFilterMember } from "../components/member-filter.modal";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { UserListTable } from "pages/registration/users/user-table.component";
import { getColorStyleByLevel } from "constant/statusStyleColor";
import { urlSafeEncrypt } from "utils/encryption";

/**
 * It returns an array of objects that contain the value, iconClassName, and baseUrl for each social
 * media icon
 * @returns An array of objects.
 */
const getSocialMediaIcons = () => {
	return [
		{
			value: "facebook",
			iconClassName: "icon-ico-facebook",
			baseUrl: "https://www.facebook.com/",
		},
		{
			value: "twitter",
			iconClassName: "icon-ico-twitter",
			baseUrl: "https://www.twitter.com/",
		},
		{
			value: "tiktok",
			iconClassName: "icon-ico-tiktok",
			baseUrl: "https://www.tiktok.com/",
		},
		{
			value: "instagram",
			iconClassName: "icon-ico-instagram",
			baseUrl: "https://www.instagram.com/",
		},
		{
			value: "youtube",
			iconClassName: "icon-ico-youtube",
			baseUrl: "https://www.youtube.com/",
		},
	];
};

const TAB_LIST = [
	{
		label: "ACTIVE",
		id: "active",
	},
	{
		label: "DELETED",
		id: "deleted",
	},
];

// Main Page Component
export function UserListPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	const queryParamsValue = {
		Status: searchParams.get("status") || undefined,
		Limit: searchParams.get("limit") || 20,
		Page: searchParams.get("page") || 1,
		Sort: searchParams.get("order") || "asc",
		SortBy: searchParams.get("order_by") || "last_registered_at",
		Search: searchParams.get("search") || undefined,
		Batch: searchParams.get("batch") || undefined,
		Levels: searchParams.get("levels") || [],
	};

	// Selected user's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	// Tab State
	const [activeTab, setActiveTab] = useState(queryParamsValue.Status || TAB_LIST[0].id);
	const [modalKey, setModalKey] = useState(0);

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalSort: false,
		showModalDataFilter: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});
	// Modal Social Media State
	const [modalSocialMedia, setModalSocialMedia] = useState({
		showModal: false,
		data: [],
	});

	const [showModalResult, setShowModalResult] = useState(false);

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: queryParamsValue.Page,
		limit: queryParamsValue.Limit,
		order_by: queryParamsValue.SortBy,
		order: queryParamsValue.Sort,
		search: queryParamsValue.Search,
	});
	// Data filter on body request
	const [dataFilter, setDataFilter] = useState({
		levels: queryParamsValue.Levels,
		batch: queryParamsValue.Batch,
	});

	const isDataFilterActive = useMemo(
		() => Boolean(dataFilter.batch) || Boolean(dataFilter.levels.length),
		[dataFilter],
	);

	// Hooks API Call
	const { data, isLoading } = useGetMemberList({ filter: queryFilter, body: dataFilter, type: activeTab });
	const { mutate: deleteMembers, isLoading: isDeleting } = useDeleteMember();
	const { mutate: exportDataUser, isLoading: isExporting } = useExportDataMember();

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	const handleDeleteMembers = () => {
		const formData = { user_ids: selectedIds };

		deleteMembers(formData, {
			onSuccess: () => {
				setModalConfirmation({
					showModal: false,
					type: null,
					data: null,
				});
				setShowModalResult(true);
				resetCheckList();
			},
		});
	};

	const handleExportData = () => {
		exportDataUser(
			{
				filter: {
					...dataFilter,
					search: queryFilter.search,
					order: queryFilter.order,
					order_by: queryFilter.order_by,
				},
				formData: {
					filter: {
						user_ids: selectedIds,
						...dataFilter,
					},
				},
				type: activeTab,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName(`MemberList`, EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const handleOnTabChange = statusItem => {
		setActiveTab(statusItem.id);
		resetCheckList();
		setModalKey(modalKey + 1);
		// Reset data filter
		setDataFilter({
			levels: [],
			batch: null,
		});
		// Reset Query filter page and search
		setQueryFilter(prevState => ({
			...prevState,
			search: "",
			page: 1,
			limit: 20,
			order_by: "last_registered_at",
			order: "asc",
		}));
		navigate({
			search: `?status=${statusItem.id}`,
		});
	};

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "User",
				dataIndex: "full_name",
				className:
					"w-1/4 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center font-semibold",
				render: (record, full_name) => (
					<div className="flex justify-center items-center overflow-hidden w-1/4">
						{record?.profile_picture ? (
							<div className="w-12">
								<img
									src={record?.profile_picture}
									loading="lazy"
									alt={full_name}
									className="rounded-full w-12 h-12 overflow-hidden"
								/>
							</div>
						) : (
							<div className="initial">{getInitialsName(full_name) || "A"}</div>
						)}

						<div className="w-4/5 pl-3 overflow-hidden">
							<span
								href={null}
								className="text-ellipsis mb-1 block text-sm overflow-hidden whitespace-nowrap text-grey-70 hover:text-red-50 font-semibold">
								{full_name}
							</span>

							<div className="text-ellipsis pr-4 text-sm block font-medium overflow-hidden whitespace-nowrap text-main-grey">
								{record.email}
							</div>
						</div>
					</div>
				),
			},
			{
				name: "Birth Date",
				className: "w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "birth_date",
				render: record => (
					<div className="overflow-hidden w-[13%]">
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
							{record.birth_date
								? dayjs(record.birth_date, "YYYY-MM-DD").format("DD / MM / YYYY")
								: "N/A"}
						</div>
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap text-main-grey">
							{record.age} Years Old
						</div>
					</div>
				),
			},
			{
				name: "Social Media",
				className: "w-[11%] text-red-60 font-semibold overflow-hidden whitespace-nowrap text-ellipsis",
				dataIndex: "username_instagram",
				render: record => (
					<div className="w-[11%]">
						<ul className="flex flex-wrap mb-0">
							{getSocialMediaIcons().map(socialMedia => {
								const userSocialMedia = record?.social_media?.find(
									item => item?.name === socialMedia.value,
								);
								return (
									<li className="mb-2 mr-2" key={socialMedia.value}>
										<button
											href={`${socialMedia?.baseUrl}${userSocialMedia?.user_social_media?.username}`}
											disabled={!userSocialMedia}
											onClick={() => {
												window.open(
													`${socialMedia?.baseUrl}${userSocialMedia?.user_social_media?.username}`,
													"_blank",
												);
											}}
											class={clsx(
												"w-6.5 h-6.5 inline-block transition-all border border-solid border-grey-10 text-center hover:bg-red-50 hover:border-red-50 hover:text-white  rounded-full bg-white leading-7 disabled:bg-red-",
												!userSocialMedia &&
													"bg-grey-10 text-grey-40 hover:bg-grey-10 hover:text-grey-40 hover:border-grey-40",
											)}>
											<span class={clsx(socialMedia.iconClassName)}></span>
										</button>
									</li>
								);
							})}
							<li className="mr-2">
								<button
									className="w-6.5 h-6.5 inline-block transition-all border border-solid border-grey-10 text-center hover:bg-red-50 hover:border-red-50 hover:text-white text-black rounded-full bg-white leading-7"
									onClick={() =>
										setModalSocialMedia({ showModal: true, data: record?.social_media })
									}>
									<span className="icon-ico-bullets"></span>
								</button>
							</li>
						</ul>
					</div>
				),
			},

			{
				name: "Reg. Date",
				className: " w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "username_instagram",
				render: record => (
					<div className="overflow-hidden w-[13%]">
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap mb-2 text-main-grey">
							{`Batch ${record.last_batch}`}
						</div>
						<div className="text-ellipsis text-sm font-medium block overflow-hidden whitespace-nowrap text-main-grey">
							{dayjs(record.last_registered_at, "YYYY-MM-DD").format("DD / MM / YYYY")}
						</div>
					</div>
				),
			},
			{
				name: "Level",
				className: " w-[13%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "level",
				render: (_, level) => (
					<div className="w-[13%]">
						<div
							class={clsx(
								"text-ellipsis whitespace-nowrap overflow-hidden md:overflow-visible py-1 px-2 rounded-lg text-sm font-semibold inline-block ",
								getColorStyleByLevel(level),
							)}>
							{convertToTitleCase(level)}
						</div>
					</div>
				),
			},
			{
				name: "Status",
				className: " w-[9%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "status",
				render: (_, status) => (
					<div className="w-[9%]">
						<div
							class={clsx(
								"text-ellipsis text-sm font-semibold block overflow-hidden whitespace-nowrap",
								status === "approved" ? "text-main-blue" : "text-main-orange",
							)}>
							{convertToTitleCase(status)}
						</div>
					</div>
				),
			},

			{
				name: "Action",
				className:
					" w-[11%] text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "-",
				render: record => (
					<div className="flex justify-center">
						<ButtonSubmit
							onClick={() => navigate(`/member/profile-details/${activeTab}/${urlSafeEncrypt(record.id)}`)}
							className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-edit text-red-50 mr-1 text-xl"></span>
							<span className="pl-1">Edit</span>
						</ButtonSubmit>
					</div>
				),
			},
		],
		[activeTab, navigate],
	);

	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter, dataFilter]);

	return (
		<Layout breadCumbTitle="User" breadCumbDesc="User List" buttonToTop>
			<div className="flex items-center justify-between mb-4">
				<div className="w-[80%] flex">
					<SearchFilterComponent
						key={`search-filter-${modalKey}`}
						initialValue={queryParamsValue.Search}
						containerClassName="relative md:w-2/5 lg:w-[40%] xl:w-2/5 mr-2"
						onSearch={search => {
							setQueryFilter({ ...queryFilter, search: search.keyword, page: 1 });
							navigate({
								search: `?${qs.stringify({
									...queryFilter,
									status: activeTab,
									search: search.keyword,
								})}`,
							});
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={isDataFilterActive}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleExportData} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[20%] justify-end">
					{activeTab !== "deleted" && (
						<div className="">
							<ButtonSubmit
								disabled={selectedIds.length === 0}
								onClick={() =>
									setModalConfirmation(prevState => ({
										...prevState,
										showModal: true,
										message: `Are you sure want to delete selected data? ${selectedIds.length} data selected.`,
									}))
								}
								className="disabled:opacity-50 flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
								<span className="icon-ico-delete mr-2"></span> Delete
							</ButtonSubmit>
						</div>
					)}
				</div>
			</div>
			<div className="border-b flex border-solid border-grey-10 mb-3 ">
				{TAB_LIST.map(statusItem => (
					<div className="inline-block mr-4">
						<button
							onClick={() => handleOnTabChange(statusItem)}
							class={clsx(
								statusItem.id === activeTab
									? "rounded-tl-lg rounded-tr-lg uppercase text-sm font-semibold text-red-50  transition-all inline-block px-8 py-3 border border-solid mb-[-1px] border-b-transparent border-grey-10"
									: "rounded-tl-lg rounded-tr-lg uppercase text-sm hover:text-red-50 transition-all font-semibold text-black inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-transparent",
							)}>
							{statusItem.label}
						</button>
					</div>
				))}
			</div>

			{!isLoading && (
				<div className="italic text-sm text-grey-60 mb-4">{`Total ${data?.data?.total_result || 0} Users`}</div>
			)}

			<UserListTable key={modalKey} columns={columns} dataSource={data?.data?.rows || []} loading={isLoading} />

			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}
			{!isLoading && (
				<Pagination
					currentPage={Number(queryFilter.page)}
					pageSize={queryFilter.limit}
					siblingCount={1}
					onPageChange={selectedPage => {
						window.scrollTo(0, 0);
						setQueryFilter({ ...queryFilter, page: selectedPage });
						navigate({
							search: `?${qs.stringify({
								...queryFilter,
								status: activeTab,
								page: selectedPage,
							})}`,
						});
					}}
					totalCount={data?.data?.total_result || 0}
				/>
			)}

			<ModalSocialMedia
				visible={modalSocialMedia.showModal}
				data={modalSocialMedia.data}
				onClose={() => {
					setModalSocialMedia({ data: [], showModal: false });
				}}
			/>

			<TableLimitModalComponent
				key={`limit-filter-${activeTab}`}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
				visible={tableFilterModal.showModalLimit}
				initialValue={Number(queryFilter.limit)}
				onChange={selected => {
					setQueryFilter({ ...queryFilter, limit: selected.limit, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
					navigate({
						search: `?${qs.stringify({
							...queryFilter,
							status: activeTab,
							limit: selected.limit,
							page: 1,
						})}`,
					});
				}}
			/>
			<ModalFilterMember
				key={`modal-filter-member-${activeTab}`}
				initialValue={dataFilter}
				visible={tableFilterModal.showModalDataFilter}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
				}}
				onChange={({ selected }) => {
					setDataFilter(selected);
					setQueryFilter({ ...queryFilter, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalDataFilter: false }));
				}}
			/>
			<TableSortModalComponent
				key={`modal-sort-${activeTab}`}
				initialValue={{
					label: "Tanggal Reg. Terdahulu - Terbaru",
					sortType: "asc",
					sortBy: "last_registered_at",
				}}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter({ ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
					navigate({
						search: `?${qs.stringify({
							...queryFilter,
							status: activeTab,
							page: 1,
							order_by: selected.sortBy,
							order: selected.sortType,
						})}`,
					});
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteMembers()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus data ini?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>
			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => setShowModalResult(false)}
				message="Successfully Deleted Members!"
			/>
		</Layout>
	);
}
