export function downLoadFileFromUrl(url) {
	const link = document.createElement("a");
	link.href = url;
	document.body.appendChild(link);
	link.click();
}

export function downloadFileFromURLBloob(url, filename) {
	var link = document.createElement("a");
	link.href = url;
	if (filename) link.download = filename;
	link.click();
	link.remove();
}

export function getExportFileName(fileName, fileType) {
	if (!fileType) return fileName;
	if (EXPORT_FILE_TYPE.EXCEL === fileType) {
		return `${fileName}_${Math.round(new Date().getTime() / 1000)}.xlsx`;
	}
	if (EXPORT_FILE_TYPE.CSV === fileType) {
		return `${fileName}_${Math.round(new Date().getTime() / 1000)}.csv`;
	}
	return `${Math.round(new Date().getTime() / 1000)}.xlsx`;
}

export function getFileNameFromContentDisposition(header, defaultName) {
	if (!header) return defaultName;

	let result = header.split(";")?.[1]?.trim()?.split("=")[1];
	if (!result) return defaultName;

	if (result && result.charAt(0) === '"' && result.charAt(result.length - 1) === '"') {
		result = result.substring(1, result.length - 1);
	}

	return result;
}
export const EXPORT_FILE_TYPE = {
	EXCEL: "excel",
	CSV: "csv",
};
