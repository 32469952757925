import clsx from "clsx";
import { useRef, useEffect } from "react";

import { InputComponent } from "components/form/input.component";
import { ModalContainer } from "components/modal/modal.container";
import { Formik } from "formik";
import { loadStaticPublicAsset } from "utils/loader";
import { CreateSkinTypeSchema } from "config/form/schema/general-setting/beauty-profile";
import { ButtonSubmit } from "components/button/submit.button";

import { useAddNewSkinType, useSkinTypeDetails, useEditSkinType } from "hooks/general-settings";

const formInitialValues = { name: "", description: "" };

export function ModalAddTypeSkin({ visible, onSuccess, onClose, operation = "ADD", data = null }) {
	const formikRef = useRef(null);
	const { mutate: addNewSkinType, isLoading: isSubmitting } = useAddNewSkinType();
	const { mutate: edtSkinTypeById, isLoading: isUpdating } = useEditSkinType();
	const { data: skinTypeDetails } = useSkinTypeDetails(data?.id);
	const handleSubmitForm = formData => {
		if (operation === "ADD") {
			addNewSkinType(formData, {
				onSuccess: () => {
					onSuccess("Successfully created new data!");
					formikRef.current.resetForm();
				},
			});
		} else {
			edtSkinTypeById(
				{ skinTypeId: data.id, formData },
				{
					onSuccess: () => {
						onSuccess("Successfully updated data!");
						formikRef.current.resetForm();
					},
				},
			);
		}
	};

	useEffect(() => {
		if (operation === "EDIT" && skinTypeDetails && visible) {
			Object.keys(formInitialValues).forEach(key => {
				formikRef.current.setFieldValue(key, skinTypeDetails?.data?.[key]);
			});
		}
	}, [data, operation, skinTypeDetails, visible]);

	return (
		<ModalContainer visible={visible}>
			<Formik
				innerRef={formikRef}
				onSubmit={handleSubmitForm}
				initialValues={formInitialValues}
				validationSchema={CreateSkinTypeSchema}>
				{({ values, handleBlur, handleChange, handleSubmit, errors, touched, resetForm }) => (
					<div
						id="new-type"
						class={clsx("rounded-2xl  bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
						<div className="relative text-center py-4 mb-4">
							<div className="font-bold text-red-50">
								{operation === "ADD" ? "Create New Type" : "Edit Type"}
							</div>

							<button
								type="button"
								className="absolute top-0 right-0 border-0"
								onClick={() => {
									onClose();
									resetForm();
								}}>
								<span className="icon-ico-close"></span>
							</button>
						</div>
						<div className="mb-4 px-2">
							<InputComponent
								label="Type Name"
								name="name"
								placeholder="Contoh: Berminyak"
								value={values.name}
								onBlur={handleBlur}
								onChange={handleChange}
								error={touched.name && errors.name}
							/>
						</div>
						<div className="mb-4 px-2">
							<div className="text-sm text-black font-semibold mb-3">Type Description</div>
							<textarea
								name="description"
								value={values.description}
								onBlur={handleBlur}
								onChange={handleChange}
								className="border-grey-10 outline-none w-full border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
								rows="6"
								placeholder="Short description"></textarea>
							{touched.description && errors.description && (
								<p className="form-input-error">{errors.description}</p>
							)}
						</div>

						<div className="my-6 flex justify-center">
							<div className="w-1/2 px-2">
								<button
									onClick={() => {
										onClose();
										resetForm();
									}}
									className="flex items-center w-full rounded-xl bg-white font-semibold text-grey-70 border border-solid border-grey-10 py-2 px-8 uppercase hover:bg-grey-10 hover:text-red-50 transition-all"
									type="button">
									<img
										src={loadStaticPublicAsset("img/icons/x-circle.svg")}
										className="max-w-full w-5"
										alt="Cancel Operation"
									/>
									<span className="ml-2">Cancel</span>
								</button>
							</div>
							<div className="w-1/2 px-2">
								<ButtonSubmit
									loading={isSubmitting || isUpdating}
									onClick={handleSubmit}
									disabled={!CreateSkinTypeSchema.isValidSync(values)}
									className="disabled:opacity-50 block w-full rounded-xl font-semibold bg-red-50 text-white py-2 px-8 uppercase hover:bg-red-70 transition-all">
									<span className="icon-ico-user-register"></span>
									<span className="ml-2">Save</span>
								</ButtonSubmit>
							</div>
						</div>
					</div>
				)}
			</Formik>
		</ModalContainer>
	);
}
