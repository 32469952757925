import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setToolsReducer } from "store/reducer/tools.reducer";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { useActivityResult, useExportActivityResult } from "hooks/activity";
import { ModalResult } from "components/modal/modal.result";
import { getRelativeTime } from "utils/date";
import { getInitialsName } from "utils/string";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalComment } from "../../components/modal-comment.component";
import { ModalFilterRating } from "../../components/modal-filter-rating";
import { TableChallange } from "../components/TableChallange";

const sortOptions = [
	{ label: "Tanggal Submit Review Terbaru", sortType: "desc", sortBy: "review_date" },
	{ label: "Tanggal Submit Review Terdahulu", sortType: "asc", sortBy: "review_date" },
	{ label: "Jumlah Rating Terbesar", sortType: "desc", sortBy: "review_rating" },
	{ label: "Jumlah Rating Terkecil", sortType: "asc", sortBy: "review_rating" },
];

export function TabReview() {
	const dispatch = useDispatch();
	const { activityId } = useParams();
	// Selected record's id
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);

	const [singleCommentId, setSingleCommentId] = useState([]);

	const [modalResult, setModalResult] = useState({
		showModal: false,
		message: "",
	});

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
		showModalPoint: false,
		showModalComment: false,
		key: new Date().getTime(),
	});

	// Query Params Filter
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		search: "",
		order: "desc",
		order_by: "review_date",
	});

	// Body Request Filter
	const [bodyFilter, setBodyFilter] = useState({
		filter: {
			rating: [],
		},
	});

	const { mutate: exportChallangeData, isLoading: isExporting } = useExportActivityResult();
	const { data: activityResultRegistrations, isLoading } = useActivityResult(
		{ activityId, type: "review" },
		{ queryFilter, bodyFilter },
	);

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "Review",
				dataIndex: "user",
				className: "w-9/12 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis pl-14 font-semibold",
				render: (record, review_message) => {
					return (
						<div className="w-9/12 overflow-hidden pr-5">
							<div className="pl-3 flex items-center overflow-hidden mb-3">
								<div className="font-bold text-red-60 text-sm">
									{getInitialsName(record.user.full_name)}
								</div>
								<div className="font-bold overflow-hidden text-ellipsis whitespace-nowrap text-grey-70 text-sm ml-4">
									{record.user.full_name}
								</div>
								<div className="italic overflow-hidden text-ellipsis whitespace-nowrap text-main-grey text-sm ml-4">
									{record.user.email}
								</div>
							</div>
							<p className="text-sm text-main-grey pl-3">{record.review_message}</p>
						</div>
					);
				},
			},
			{
				name: "Rating",
				dataIndex: "challenge_submitted_date_formatted",
				className: " w-1/5 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, review_rating) => {
					return (
						<div className="w-1/5 flex items-center px-2">
							<img src="/img/icons/rate.svg" alt="Rating" />
							<div className="font-bold text-grey-70 text-lg ml-3">{record.review_rating}</div>
						</div>
					);
				},
			},
			{
				name: "Action",
				dataIndex: "review_date",
				className: " w-1/6 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				render: (record, review_date) => {
					return (
						<div className="w-1/6 pr-6">
							<div className="flex justify-end mb-3">
								<div className="text-main-grey text-sm">{getRelativeTime(review_date)}</div>
							</div>
							<div className="flex justify-end">
								<button
									onClick={() => {
										setSingleCommentId([record.id]);
										setShowTableFilterModal({
											...tableFilterModal,
											showModalComment: true,
											key: new Date().getTime(),
										});
									}}
									className="flex items-center justify-center py-1 w-32 px-5 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
									type="button">
									<span className="icon-ico-comment text-red-50 text-xl"></span>
									<span className="pl-3">Reply</span>
								</button>
							</div>
						</div>
					);
				},
			},
		],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const resetCheckList = () => {
		if (selectedIds.length > 0) {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setBodyFilter({
			filter: {
				rating: selected,
			},
		});

		setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
	};

	const handleOnExport = () => {
		exportChallangeData(
			{
				activityId,
				type: "review",
				bodyRequest: { ...bodyFilter, filter: { ...bodyFilter.filter, user_activity_ids: selectedIds } },
				filterParams: queryFilter,
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("Review", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const handleOnDeleteCommentCompleted = () => {
		setModalResult({
			showModal: true,
			message: "Successfully deleted reply!",
		});
	};
	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex items-center justify-between p-5">
				<div className="w-[70%] flex">
					<SearchFilterComponent
						containerClassName="relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2"
						onSearch={search => {
							resetCheckList();
							setQueryFilter({ ...queryFilter, search: search.keyword });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							active={bodyFilter.filter.rating.length > 0}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleOnExport} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[28%] justify-end">
					<ButtonSubmit
						disabled={!selectedIds.length}
						onClick={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalComment: true }))}
						className="items-center py-2.5 px-7 ml-2 border border-solid border-red-50 rounded-lg text-white disabled:border-red-10 disabled:bg-red-10 bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
						title="Reply"
					/>
				</div>
			</div>

			<div className="px-5">
				<TableChallange
					onDeleteCommentItemCompleted={handleOnDeleteCommentCompleted}
					autoExpandAllRows
					rowClassName="flex items-center py-4"
					rowSelectedClassName="flex  bg-light-pink  rounded-lg py-4 items-center "
					columns={columns}
					dataSource={activityResultRegistrations?.data?.rows || []}
					loading={isLoading}
				/>
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && !queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message="There's no records available here" />
					</div>
				)}
				{!isLoading && activityResultRegistrations?.data?.rows?.length === 0 && queryFilter.search && (
					<div className="flex justify-center items-center h-[300px]">
						<EmptyState message={`Your search "${queryFilter.search}" did not match any records`} />
					</div>
				)}
				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={selectedPage => {
							window.scrollTo(0, 0);

							setQueryFilter({ ...queryFilter, page: selectedPage });
						}}
						totalCount={activityResultRegistrations?.data?.total_result || 0}
					/>
				)}
			</div>
			<ModalFilterRating
				initialValue={bodyFilter.filter.rating}
				onChange={handleOnFilterDataChange}
				visible={tableFilterModal.showModalFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
			/>
			<ModalResult
				visible={modalResult.showModal}
				title={false}
				onClose={() => setModalResult(prev => ({ ...prev, showModal: false }))}
				message={modalResult.message}
			/>

			<ModalComment
				type="review"
				key={tableFilterModal.key}
				selectedIds={singleCommentId.length > 0 ? singleCommentId : selectedIds}
				activityId={activityId}
				visible={tableFilterModal.showModalComment}
				onCompleted={() => {
					setShowTableFilterModal(prevState => ({
						...prevState,
						showModalComment: false,
						key: new Date().getTime(),
					}));
					setModalResult({
						showModal: true,
						message: "Reply has been sent!",
					});
					resetCheckList();
				}}
				onClose={() => {
					if (singleCommentId.length) {
						setSingleCommentId([]);
					}
					resetCheckList();
					setShowTableFilterModal(prevState => ({ ...prevState, showModalComment: false }));
				}}
			/>

			<TableLimitModalComponent
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={({ limit }) => {
					setQueryFilter(prevState => ({
						...prevState,
						limit,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				options={sortOptions}
				initialValue={sortOptions[0]}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter(prevState => ({
						...prevState,
						order_by: selected.sortBy,
						order: selected.sortType,
					}));
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
		</>
	);
}
