import clsx from "clsx";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useState, useRef } from "react";
import { SkinTypeItem } from "./skin-type-item";

export function SkinType({ name, skin_items, id, onEdit, onEditItem, onDelete, onDeleteItem, onAddNewItem, ...props }) {
	const optionsContainer = useRef(null);
	const [showMenuOptions, setShowMenuOptions] = useState(false);

	useOnClickOutside(optionsContainer, () => setShowMenuOptions(false));

	return (
		<>
			<div className="flex items-center mb-5">
				<div className="text-sm text-black font-semibold mr-3">{name}</div>
				<div className="dropdown" ref={optionsContainer}>
					<button
						onClick={() => setShowMenuOptions(!showMenuOptions)}
						className="leading-tight w-9 h-9 rounded-full border border-solid border-grey-10 bg-white relative flex items-center justify-center text-left"
						type="button">
						<span className="icon-ico-bullets text-2xl text-red-50 rotate-90"></span>
					</button>
					<ul
						class={clsx(
							"dropdown-menu min-w-max absolute hidden bg-white text-base z-50 py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none w-[150px] ",
							showMenuOptions && "show",
						)}>
						<li>
							<button
								onClick={() => {
									onEdit();
									setShowMenuOptions(false);
								}}
								className=" dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 focus:bg-white font-medium ">
								Edit
							</button>
						</li>
						<li>
							<button
								disabled={skin_items.length > 0}
								onClick={() => {
									onDelete();
									setShowMenuOptions(false);
								}}
								className="disabled:opacity-25 dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-gray-700 hover:text-red-50 font-medium focus:bg-white">
								Delete
							</button>
						</li>
					</ul>
				</div>
			</div>
			<div className="flex flex-wrap mb-5">
				{skin_items.map(skinItem => (
					<SkinTypeItem
						{...skinItem}
						key={skinItem.id}
						onEdit={() => onEditItem({ skinItemId: skinItem.id })}
						onDelete={() => onDeleteItem(skinItem)}
					/>
				))}

				<div className="skin-type mr-4 mb-4" onClick={onAddNewItem}>
					<button className="w-[98px] h-32 py-4 rounded-xl border flex flex-col items-center justify-center border-solid cursor-pointer border-grey-10">
						<span className="icon-ico-circle-plus text-2xl text-red-50"></span>
						<div className="text-black font-semibold text-sm text-center px-2">Add Item</div>
					</button>
				</div>
			</div>
		</>
	);
}
