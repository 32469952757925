import React, { useCallback, useEffect, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, createEditor, Element as SlateElement } from "slate";
import { withHistory } from "slate-history";

import { Button, Icon, Toolbar } from "./text-sub-editor.component";

const HOTKEYS = {
	"mod+b": "bold",
	"mod+i": "italic",
	"mod+u": "underline",
	"mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const DEFAULT_EDITOR_EMPTY_VALUE = [
	{
		type: "paragraph",
		align: "left",
		children: [{ text: "" }],
	},
];

const TextEditor = ({
	onChange = () => {},
	initialValue,
	disabled,
	contentValue = undefined,
	triggerChangesOnBlurOnly = false,
	placeholder = "Enter description...",
}) => {
	const [editorValue, setEditorValue] = useState(
		(typeof initialValue === "string" ? JSON.parse(initialValue) : initialValue) || DEFAULT_EDITOR_EMPTY_VALUE,
	);
	const renderElement = useCallback(props => <Element {...props} />, []);
	const renderLeaf = useCallback(props => <Leaf {...props} />, []);

	const editor = useMemo(() => withHistory(withReact(createEditor())), []);

	useEffect(() => {
		if (initialValue) {
			if (typeof initialValue === "string") {
				setEditorValue(JSON.parse(initialValue));
			} else {
				setEditorValue(initialValue);
			}
		}
	}, [initialValue]);

	return (
		<Slate
			editor={editor}
			value={editorValue}
			onBlur={() => {
				if (triggerChangesOnBlurOnly) {
					onChange(JSON.stringify(editorValue));
				}
			}}
			onChange={value => {
				setEditorValue(value);
				!triggerChangesOnBlurOnly && onChange(JSON.stringify(value));
			}}>
			<Toolbar>
				<MarkButton format="bold" icon="format_bold" />
				<MarkButton format="italic" icon="format_italic" />
				<MarkButton format="underline" icon="format_underlined" />
				<BlockButton format="numbered-list" icon="format_list_numbered" />
				<BlockButton format="bulleted-list" icon="format_list_bulleted" />
				<BlockButton format="left" icon="format_align_left" />
				<BlockButton format="center" icon="format_align_center" />
				<BlockButton format="right" icon="format_align_right" />
			</Toolbar>
			<Editable
				disabled={disabled}
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				className="text-sm text-black border border-grey-10 px-2 py-2 rounded-md min-h-[300px]"
				placeholder={placeholder}
				spellCheck
				onKeyDown={event => {
					for (const hotkey in HOTKEYS) {
						if (isHotkey(hotkey, event)) {
							event.preventDefault();
							const mark = HOTKEYS[hotkey];
							toggleMark(editor, mark);
						}
					}
				}}
			/>
		</Slate>
	);
};

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: n =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	});
	let newProperties;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		};
	} else {
		newProperties = {
			type: isActive ? "paragraph" : isList ? "list-item" : format,
		};
	}
	Transforms.setNodes(editor, newProperties);

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const isBlockActive = (editor, format, blockType = "type") => {
	const { selection } = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
		}),
	);

	return !!match;
};

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};

const isLinkActive = editor => {
	const [link] = Editor.nodes(editor, { match: n => n.type === "link" });
	return !!link;
};

const insertLink = (editor, url) => {
	if (isLinkActive(editor)) {
		Editor.unwrapNodes(editor, { match: n => n.type === "link" });
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);

	if (isCollapsed) {
		const link = {
			type: "link",
			url,
			children: [{ text: url }],
		};
		Transforms.insertNodes(editor, link);
	} else {
		const link = {
			type: "link",
			url,
			children: [],
		};
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: "end" });
	}
};

const Element = ({ attributes, children, element }) => {
	const style = { textAlign: element.align };
	switch (element.type) {
		case "block-quote":
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			);
		case "bulleted-list":
			return (
				<ul className="ml-5 list-disc" style={style} {...attributes}>
					{children}
				</ul>
			);
		case "heading-one":
			return (
				<h1 style={style} {...attributes}>
					{children}
				</h1>
			);
		case "heading-two":
			return (
				<h2 style={style} {...attributes}>
					{children}
				</h2>
			);
		case "list-item":
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			);
		case "numbered-list":
			return (
				<ol className="ml-5 list-decimal" style={style} {...attributes}>
					{children}
				</ol>
			);
		default:
			return (
				<p style={{ ...style, wordBreak: "break-all" }} {...attributes}>
					{children}
				</p>
			);
	}
};

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.code) {
		children = <code>{children}</code>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
	const editor = useSlate();
	return (
		<Button
			active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
			onMouseDown={event => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}>
			<Icon>{icon}</Icon>
		</Button>
	);
};

const MarkButton = ({ format, icon }) => {
	const editor = useSlate();
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault();
				toggleMark(editor, format);
			}}>
			<Icon>{icon}</Icon>
		</Button>
	);
};

export default TextEditor;
