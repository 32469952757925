import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Formik } from "formik";

import Layout from "components/layout";
import { CreateActivityValidatioSchema } from "config/form/schema/activity/create-activity";
import { useCreateActivity } from "hooks/activity";
import { ButtonSubmit } from "components/button/submit.button";
import { convertTimeToSeconds } from "utils/date";
import { ModalConfirmation } from "components/modal/modal.confirmation";

// Page Component
import { Introduction } from "./components/create-sections/introduction.component";
import { ClassMaterial } from "./components/create-sections/class-material.component";
import { ClassLinks } from "./components/create-sections/class-link.component";
import { RegistrationDate } from "./components/create-sections/registration-date.component";
import { Attendance } from "./components/create-sections/attedance.component";
import { Challange } from "./components/create-sections/challange.component";
import { Review } from "./components/create-sections/review.component";
import { Exam } from "./components/create-sections/exam.component";
import { urlSafeEncrypt } from "utils/encryption";

const SUBMIT_BUTTON_SOURCE = {
	BUTTON_EXAM: "BUTTON_EXAM",
	BUTTON_SUBMIT: "BUTTON_SUBMIT",
};

const formInitialValues = {
	introduction: {
		title: "",
		activity_category_id: "",
		description: "",
		total_point: "",
		level_id: "",
		start_date: "",
		thumbnail_desktop: "",
		thumbnail_mobile: "",
		end_date: "",
	},
	class_material: {
		is_active: false,
		title: "",
		description: "",
		uploaded_files: [],
		redirect_urls: [],
	},
	class_video_conference: {
		is_active: false,
		title: "",
		description: "",
		redirect_urls: [],
	},
	registration: {
		is_active: false,
		start_date: "",
		end_date: "",
	},
	attendance: {
		is_active: false,
		title: "",
		description: "",
		code: "",
		point: "",
		start_date: "",
		end_date: "",
	},
	challenge: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		example_redirect_url: "",
		example_uploaded_files: [],
		start_date: "",
		end_date: "",
	},
	review: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		start_date: "",
		end_date: "",
	},
	exam: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		duration: "",
		start_date: "",
		end_date: "",
	},
};

export function CreateActivityPage() {
	const navigate = useNavigate();
	const formikRef = useRef();

	const [selectedUsers, setSelectedUsers] = useState([]);

	const { mutate: createActivity, isLoading: isSubmitting } = useCreateActivity();
	const [showModalConfirmation, setShowModalConfirmation] = useState(false);
	const [sectionExpaned, setSectionExpaned] = useState({
		introduction: true,
		class_material: false,
		class_video_conference: false,
		registration: false,
		attendance: false,
		challenge: false,
		review: false,
		exam: false,
	});

	const handleCreateActivity = () => {
		const data = formikRef.current.values;
		const formPayload = {
			...data,
			introduction: {
				...data.introduction,
				activity_category_id: +data.introduction.activity_category_id,
				level_id: +data.introduction.level_id,
				user_ids: selectedUsers.map(user => user.id),
			},
			class_material: {
				...data.class_material,
				uploaded_files: data.class_material.uploaded_files.map(file => file.url),
			},
			challenge: {
				...data.challenge,
				example_uploaded_files: data.challenge.example_uploaded_files.map(file => file.url),
			},
			exam: {
				...data.exam,
				duration: convertTimeToSeconds(data.exam.duration),
			},
		};

		createActivity(formPayload, {
			onSuccess: response => {
				if (formikRef.current.values.exam.is_active) {
					response.data.activity_exam_id &&
						navigate(`/activity/create-quiz/${urlSafeEncrypt(response.data.activity_exam_id)}`);
				} else {
					navigate("/activity/list");
				}
			},
		});
	};

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-6"
			breadCumbTitle="Activity"
			breadCumbDesc="Create Activity">
			<Formik
				innerRef={formikRef}
				initialValues={formInitialValues}
				onSubmit={data => handleCreateActivity(data, SUBMIT_BUTTON_SOURCE.BUTTON_SUBMIT)}
				validationSchema={CreateActivityValidatioSchema}>
				{({ values, handleBlur, handleChange, setFieldValue, errors, touched, handleSubmit }) => (
					<>
						<Introduction
							selectedUsers={selectedUsers}
							setSelectedUsers={setSelectedUsers}
							values={values.introduction}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.introduction}
							touched={touched.introduction}
							setFieldValue={setFieldValue}
							sectionId="introduction"
							expanded={sectionExpaned.introduction}
							onToggleExpand={() => {
								setSectionExpaned({ ...sectionExpaned, introduction: !sectionExpaned.introduction });
							}}
						/>
						<ClassMaterial
							values={values.class_material}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.class_material}
							touched={touched.class_material}
							setFieldValue={setFieldValue}
							sectionId="class_material"
							expanded={sectionExpaned.class_material}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									class_material: !sectionExpaned.class_material,
								});
							}}
						/>
						<ClassLinks
							values={values.class_video_conference}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.class_video_conference}
							touched={touched.class_video_conference}
							setFieldValue={setFieldValue}
							sectionId="class_video_conference"
							expanded={sectionExpaned.class_video_conference}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									class_video_conference: !sectionExpaned.class_video_conference,
								});
							}}
						/>
						<RegistrationDate
							values={values.registration}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.registration}
							touched={touched.registration}
							setFieldValue={setFieldValue}
							sectionId="registration"
							expanded={sectionExpaned.registration}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									registration: !sectionExpaned.registration,
								});
							}}
						/>
						<Attendance
							values={values.attendance}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.attendance}
							touched={touched.attendance}
							setFieldValue={setFieldValue}
							sectionId="attendance"
							expanded={sectionExpaned.attendance}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									attendance: !sectionExpaned.attendance,
								});
							}}
						/>
						<Challange
							values={values.challenge}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.challenge}
							touched={touched.challenge}
							setFieldValue={setFieldValue}
							sectionId="challenge"
							expanded={sectionExpaned.challenge}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									challenge: !sectionExpaned.challenge,
								});
							}}
						/>
						<Review
							values={values.review}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.review}
							touched={touched.review}
							setFieldValue={setFieldValue}
							sectionId="review"
							expanded={sectionExpaned.review}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									review: !sectionExpaned.review,
								});
							}}
						/>
						<Exam
							values={values.exam}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors.exam}
							touched={touched.exam}
							setFieldValue={setFieldValue}
							sectionId="exam"
							expanded={sectionExpaned.exam}
							buttonExamDisabled={!CreateActivityValidatioSchema.isValidSync(values)}
							onClickButtonExam={() => setShowModalConfirmation(true)}
							onToggleExpand={() => {
								setSectionExpaned({
									...sectionExpaned,
									exam: !sectionExpaned.exam,
								});
							}}
						/>
						<div className="flex justify-center my-9">
							<div className="w-2/6">
								<ButtonSubmit
									disabled={!CreateActivityValidatioSchema.isValidSync(values)}
									onClick={() => setShowModalConfirmation(true)}
									title="Save All Changes"
									className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
								/>
							</div>
						</div>
					</>
				)}
			</Formik>
			<ModalConfirmation
				buttons={[]}
				loading={isSubmitting}
				message={"Are you sure to save this activity?"}
				onApprove={handleCreateActivity}
				onClose={() => setShowModalConfirmation(false)}
				onReject={() => setShowModalConfirmation(false)}
				visible={showModalConfirmation}
			/>
		</Layout>
	);
}
