import { Transition } from "@headlessui/react";

export function ModalContainer({ children, visible }) {
	return (
		<Transition
			show={visible}
			className="fixed z-100 backdrop-blur-sm bg-[rgba(0,0,0,0.38)] top-0 bottom-0 left-0 right-0 w-full h-full flex justify-center items-center"
			enter="transform transition duration-[300ms]"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transform duration-[200ms] transition ease-in-out"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			{children}
		</Transition>
	);
}
