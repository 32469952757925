import _ from "lodash";
import { useEffect, useRef, useState, useMemo } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";
import { matchSorter } from "match-sorter";

import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { EmptyState } from "components/table/empty-state.component";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { PageSettingLayout } from "../layout";
import { EventArtikelItem } from "../component/event-artikel-item";
import dayjs from "dayjs";

const formikInitialValues = {
	is_visible: false,
};

export function GalleryPreviewSetting() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "about", slug: "gallery" });
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [galleryData, setGalleryData] = useState({ published: [], unpublished: [] });
	const [searchKeyword, setSearchKeyword] = useState({ query: "", type: null });
	const [showModalResult, setShowModalResult] = useState(false);

	const listData = useMemo(() => {
		if (!galleryData) return [];
		if (!searchKeyword.type) return galleryData;
		return {
			...galleryData,
			[searchKeyword.type]: matchSorter(galleryData[searchKeyword.type], searchKeyword.query, {
				keys: ["title"],
			}),
		};
	}, [galleryData, searchKeyword]);

	const handleSearchInput = e => {
		setSearchKeyword({ query: e.target.value, type: e.target.name });
	};

	const handleMoveItem = (itemObj, statusDirection) => {
		const newDataGalleris = { ...galleryData };
		// lodash find and remove item from array
		_.remove(newDataGalleris[statusDirection === "published" ? "unpublished" : "published"], {
			id: itemObj.id,
		});
		// add item to other array
		newDataGalleris[statusDirection].push({
			...itemObj,
			sequence: newDataGalleris[statusDirection].length + 1,
		});
		// sort array by sequence
		newDataGalleris[statusDirection] = _.sortBy(newDataGalleris[statusDirection], "sequence");
		setGalleryData(newDataGalleris);
	};
	const handleSubmitForm = data => {
		const payloadData = {
			...data,
			unpublished_galleries: galleryData.unpublished.map(item => item.id),
			published_galleries: galleryData.published.map(item => item.id),
		};
		updatePageSetting(
			{ page: "about", slug: "gallery", formData: payloadData },
			{
				onSuccess: () => {
					setShowModalResult(true);
					refetchPageSettingData();
				},
			},
		);
	};

	useEffect(() => {
		if (data) {
			setGalleryData({
				published: data?.data?.published_galleries,
				unpublished: data?.data?.unpublished_galleries,
			});
			formikRef.current.setFieldValue("is_visible", data?.data?.is_visible);
		}
	}, [data]);

	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="ABOUT"
			desc="About Page">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik innerRef={formikRef} initialValues={formikInitialValues} onSubmit={handleSubmitForm}>
						{({ values, handleChange, handleSubmit }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">Gallery Preview</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} From Home Page
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>

								<div className="flex justify-between py-4">
									<div className="w-[50%] pr-2">
										<div className="text-base text-black font-bold mb-3">List of All Galleries</div>
										<div className="border border-solid border-grey-10 rounded-lg p-4">
											<div className="relative w-full mb-4">
												<input
													type="text"
													className="block w-full pr-4 py-2.5 pl-10 text-base font-normal text-red-80 bg-white outline-none bg-clip-padding border border-solid border-grey-30 placeholder:text-grey-30 placeholder:italic rounded-lg form-control focus:text-red-50 focus:bg-[#FFFBFC] focus:border-red-50 focus:outline-none focus:shadow-none transition-all"
													placeholder="cari gallery..."
													name="unpublished"
													onChange={handleSearchInput}
												/>
												<div className="absolute left-3 top-2 text-red-50">
													<span className="icon-ico-search text-xl"></span>
												</div>
											</div>
											<div className="h-[calc(100vh-275px)] scroll">
												{listData.unpublished.length === 0 && (
													<EmptyState message="Gallery not found" />
												)}
												{listData.unpublished.map(galleryItem => (
													<EventArtikelItem
														imageClassName=" object-cover object-center rounded-lg h-32  w-36"
														image={galleryItem.images[0]?.url}
														title={galleryItem.title}
														description={galleryItem.description}
														onMove={() => handleMoveItem(galleryItem, "published")}
														date={dayjs(galleryItem.created_at).format("DD MMMM YYYY")}
														buttonUnpublishTitle="Publish to About Page"
														published={false}
													/>
												))}
											</div>
										</div>
									</div>
									<div className="w-[50%] pl-2">
										<div className="text-base text-black font-bold mb-3">
											Appeared Galleries At About Page
										</div>
										<div className="border border-solid border-grey-10 rounded-lg p-4">
											<div className="relative w-full mb-4">
												<input
													type="text"
													name="published"
													className="block w-full pr-4 py-2.5 pl-10 text-base font-normal text-red-80 bg-white outline-none bg-clip-padding border border-solid border-grey-30 placeholder:text-grey-30 placeholder:italic rounded-lg form-control focus:text-red-50 focus:bg-[#FFFBFC] focus:border-red-50 focus:outline-none focus:shadow-none transition-all"
													placeholder="cari gallery..."
													onChange={handleSearchInput}
												/>
												<div className="absolute left-3 top-2 text-red-50">
													<span className="icon-ico-search text-xl"></span>
												</div>
											</div>
											<div className="h-[calc(100vh-275px)] scroll">
												{listData.published.length === 0 && (
													<EmptyState message="Gallery not found" />
												)}
												{listData.published.map(galleryItem => (
													<EventArtikelItem
														image={galleryItem.images[0]?.url}
														imageClassName=" object-cover object-center rounded-lg h-32  w-36"
														title={galleryItem.title}
														onMove={() => handleMoveItem(galleryItem, "unpublished")}
														desciption={galleryItem.description}
														date={dayjs(galleryItem.created_at).format("DD MMMM YYYY")}
														published
													/>
												))}
											</div>
										</div>
									</div>
								</div>

								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											onClick={handleSubmit}
											loading={isUpdating}
											title="Save All Changes"
											className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
