import { ButtonSubmit } from "components/button/submit.button";

export function ResutComponent({
	buttonActionComponent = <ButtonSubmit title="Login Now!" />,
	title,
	description,
	type = "success",
	containerClassName = "",
}) {
	return (
		<div class={containerClassName}>
			<div className="forgot-password-success text-center">
				{type === "success" && (
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
						<circle
							className="path circle"
							fill="none"
							stroke="#b52029"
							stroke-width="6"
							stroke-miterlimit="10"
							cx="65.1"
							cy="65.1"
							r="62.1"
						/>
						<polyline
							className="path check"
							fill="none"
							stroke="#b52029"
							stroke-width="6"
							stroke-linecap="round"
							stroke-miterlimit="10"
							points="100.2,40.2 51.5,88.8 29.8,67.5 "
						/>
					</svg>
				)}
				{type === "error" && (
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
						<circle
							className="path circle"
							fill="none"
							stroke="#D06079"
							stroke-width="6"
							stroke-miterlimit="10"
							cx="65.1"
							cy="65.1"
							r="62.1"
						/>
						<line
							className="path line"
							fill="none"
							stroke="#D06079"
							stroke-width="6"
							stroke-linecap="round"
							stroke-miterlimit="10"
							x1="34.4"
							y1="37.9"
							x2="95.8"
							y2="92.3"
						/>
						<line
							className="path line"
							fill="none"
							stroke="#D06079"
							stroke-width="6"
							stroke-linecap="round"
							stroke-miterlimit="10"
							x1="95.8"
							y1="38"
							x2="34.4"
							y2="92.2"
						/>
					</svg>
				)}
			</div>
			<p className="text-sm mt-5 text-grey-60">{description}</p>
			{buttonActionComponent}
		</div>
	);
}
