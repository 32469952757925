import * as Yup from "yup";

import { websiteUrlRegExp } from "../regexValidation";

/**
 * Form validation schema for /activity/crete
 *
 */

const SOCIAL_MEDIA_VALIDATION_SCHEMA = Yup.array()
	.of(
		Yup.object().shape({
			title: Yup.string().required("URL Title is required"), // these constraints take precedence
			redirect_link: Yup.string().matches(websiteUrlRegExp, "URL is not valid").required("URL is required"), // these constraints take precedence
			fileBuffer: Yup.string().required("File is required"),
		}),
	)
	.required("Must have at lease one url");

export const FooterSocialMediasSchema = Yup.object().shape({
	social_medias: SOCIAL_MEDIA_VALIDATION_SCHEMA,
});
