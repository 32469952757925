import clsx from "clsx";
import { useState, useMemo, useEffect } from "react";
import { matchSorter } from "match-sorter";

import { ModalContainer } from "components/modal/modal.container";
import { convertToTitleCase } from "utils/string";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { useGetAllCitiesList } from "hooks/member";

export function ModalSelectCity({ visible, initialValue, onClose, onChange }) {
	const [searchKeyword, setSearchKeyword] = useState("");
	const { data: allCities } = useGetAllCitiesList();
	const [selected, setSelected] = useState(
		{
			name: "",
			id: null,
		} || initialValue,
	);

	useEffect(() => {
		if (initialValue) setSelected(initialValue);
	}, [initialValue]);

	const listCities = useMemo(() => {
		if (!allCities?.data) return [];
		return matchSorter(allCities.data, searchKeyword, { keys: ["name"] });
	}, [allCities, searchKeyword]);

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">City</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<SearchFilterComponent
					placeholder="Search city.."
					containerClassName="relative w-[100%] mr-2"
					onSearch={({ keyword }) => setSearchKeyword(keyword)}
				/>

				<div className="content-scroll">
					<div className="my-6">
						{listCities?.map(itemLevel => (
							<div className="rounded-lg border border-solid border-grey-10 mb-2">
								<div className="form-check flex items-center my-3 mx-4">
									<input
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
										type="checkbox"
										id={itemLevel.id}
										checked={selected.id === itemLevel.id}
										onChange={() => {
											setSelected({
												name: itemLevel.name,
												id: itemLevel.id,
											});
										}}
									/>
									<label
										className="form-check-label w-[90%] block text-grey-60 font-semibold text-sm pl-2 cursor-pointer"
										htmlFor={itemLevel.id}>
										{itemLevel.name && convertToTitleCase(itemLevel.name)}
									</label>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onClose();
								setSearchKeyword("");
								onChange({ selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
