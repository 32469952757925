import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";

import { PageSettingLayout } from "../layout";

import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { InputComponent } from "components/form/input.component";

import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { UploadBannerComponent } from "../upload-banner.component";

const formikInitialValues = {
	is_visible: false,
	image_desktop: null,
	image_mobile: null,
	redirect_url: "",
};

export function RubiTaskSetting() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "home", slug: "rubi-task-corner" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [imageFileBuffer, setImageFileBuffer] = useState({ image_desktop: null, image_mobile: null });
	const [showModalResult, setShowModalResult] = useState(false);

	useEffect(() => {
		Object.keys(formikInitialValues).forEach(key => {
			if (data?.data?.[key]) {
				formikRef.current.setFieldValue(key, data?.data?.[key]);
			}
		});
	}, [data]);

	const handleSubmitForm = async payloadData => {
		try {
			if (Object.values(imageFileBuffer).some(value => value)) {
				const images = Object.keys(imageFileBuffer)
					.filter(itemKey => imageFileBuffer[itemKey])
					.map(itemKey => ({
						version: itemKey.replace("image_", ""),
						file: imageFileBuffer[itemKey],
					}));

				const imagePromise = images.map(image => {
					const formData = new FormData();
					formData.append("image", image.file);
					formData.append("version", image.version);
					formData.append("type", "banner");
					return uploadImageAsync({ formData, uploadId: `image_${image.version}` });
				});

				const res = await Promise.all(imagePromise);

				payloadData.image_desktop =
					res.find(item => item.uploadId === "image_desktop")?.data?.image_desktop ||
					payloadData.image_desktop;
				payloadData.image_mobile =
					res.find(item => item.uploadId === "image_mobile")?.data?.image_mobile || payloadData.image_mobile;
			}

			updatePageSetting(
				{ page: "home", slug: "rubi-task-corner", formData: payloadData },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setImageFileBuffer({ image_desktop: null, image_mobile: null });
					},
				},
			);
		} catch (error) {
			// TODO: Create global toast component to show error message
			console.log(error);
		}
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="HOME"
			desc="Home Page">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik innerRef={formikRef} initialValues={formikInitialValues} onSubmit={handleSubmitForm}>
						{({ values, handleChange, handleSubmit }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">Rubi Task</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} From Home Page
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>

								<div className="flex justify-between py-4 px-6">
									<div className="w-[47%]">
										<UploadBannerComponent
											showDeleteButton={false}
											initialImage={data?.data?.image_desktop}
											name="image_desktop"
											onChange={imageFileBuffer => {
												setImageFileBuffer(prevState => ({ ...prevState, ...imageFileBuffer }));
											}}
											title="Desktop Banner (Aspect Ration 14:3)"
										/>
									</div>
									<div className="w-[47%]">
										<UploadBannerComponent
											showDeleteButton={false}
											initialImage={data?.data?.image_mobile}
											name="image_mobile"
											onChange={imageFileBuffer => {
												setImageFileBuffer(prevState => ({ ...prevState, ...imageFileBuffer }));
											}}
											title="Mobile Banner (Aspect Ration 16:8)"
										/>
									</div>
								</div>
								<InputComponent
									containerclassName="mb-4 px-6"
									labelclassName="text-sm text-black font-semibold mb-3"
									label="Link / URL"
									placeholder="Link to page / external url"
									name="redirect_url"
									value={values.redirect_url}
									onChange={handleChange}
								/>

								{/* <div className="mb-4 px-6">
					<div className="text-sm text-black font-semibold mb-3">Title</div>
					<input
						type="text"
						className="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
						placeholder="masukkan judul"
					/>
				</div>

				<div className="mb-4 px-6">
					<div className="text-sm text-black font-semibold mb-3">Description</div>

					<textarea
						className="border-grey-10 outline-none w-full border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
						rows="6"
						placeholder="type description"></textarea>
				</div> */}

								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											onClick={handleSubmit}
											loading={isUploading || isUpdating}
											title="Save All Changes"
											className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
