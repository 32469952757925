import _ from "lodash";
import { useState } from "react";

import { useUploadImage } from "hooks/upload";
import { ButtonSubmit } from "components/button/submit.button";

const initialValuesOption = [
	{
		option_id: 1,
		is_correct: false,
		label: "",
		type: "text",
		image_desktop: null,
		image_mobile: null,
		image_preview_url: null,
		image_buffer: null,
	},
];
export function CreateQuestionComponent({
	initialOptions,
	onSubmit,
	editingMode,
	onCancel,
	disabled,
	onSubmitEditing,
	editId,
	initialImage,
	intialQuestion,
}) {
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const [question, setQuestion] = useState(intialQuestion || "");
	const [questionImageBuffer, setQuestionImageBuffer] = useState(null);
	const [questionImagePreviewUrl, setQuestionImagePrevURL] = useState(initialImage?.image_desktop || null);
	const [questionImagePreviewUrlMobile, setQuestionImagePrevURLMobile] = useState(initialImage?.image_mobile || null);
	const [options, setOptions] = useState(initialOptions || initialValuesOption);

	const handleAddNewOption = () => {
		setOptions([
			...options,
			{
				is_correct: false,
				label: "",
				type: "text",
				image_desktop: null,
				image_mobile: null,
				image_preview_url: null,
				image_buffer: null,
				option_id: options.length + 1,
			},
		]);
	};

	const handleDeleteItem = index => {
		setOptions(options.filter((_, i) => i !== index));
	};

	const handleAddQuestion = async () => {
		const optionsWithImage = options
			.filter(option => option.image_buffer)
			.map(option => {
				const formData = new FormData();
				formData.append("image", option.image_buffer);
				formData.append("type", "option");
				return uploadImageAsync({ formData, uploadId: option.option_id });
			});

		const res = await Promise.all(optionsWithImage);

		// filter and replace response from server to options based on option id and update in current options

		const optionsWithImageResponse = options.map(option => {
			const response = res.find(res => res.uploadId === option.option_id);
			if (response) {
				return {
					...option,
					image_desktop: response.data?.image_desktop,
					image_mobile: response.data?.image_mobile,
					type: "image",
					image_preview_url: null,
					image_buffer: null,
					label: response.data?.image_desktop,
				};
			}
			return option;
		});
		let questionImage = {
			image_desktop: questionImagePreviewUrl,
			image_mobile: questionImagePreviewUrlMobile,
		};

		if (questionImageBuffer) {
			const formData = new FormData();
			formData.append("image", questionImageBuffer);
			formData.append("type", "question");
			const questionImageResponse = await uploadImageAsync({ formData, optionId: null });
			questionImage.image_desktop = questionImageResponse.data?.image_desktop;
			questionImage.image_mobile = questionImageResponse.data?.image_mobile;
		}

		if (editingMode) {
			onSubmitEditing({
				id: editId,
				question,
				...questionImage,
				options: optionsWithImageResponse,
				editId,
			});
		} else {
			onSubmit({ question, options: optionsWithImageResponse, ...questionImage });
		}

		setOptions([
			{
				option_id: 1,
				is_correct: false,
				label: "",
				type: "text",
				image_desktop: null,
				image_mobile: null,
				image_preview_url: null,
				image_buffer: null,
			},
		]);
		setQuestion("");
		setQuestionImageBuffer(null);
		setQuestionImagePrevURL(null);
	};

	const handleCancelEdit = () => {
		onCancel();
	};

	const handleFormChange = (index, event) => {
		let data = _.cloneDeep(options);
		data[index][event.target.name] = event.target.value;
		setOptions(data);
	};

	const handleUploadImage = (optionId, e) => {
		let data = [...options];
		const file = e.target.files[0];

		// find index by optionId
		const index = data.findIndex(item => item.option_id === optionId);
		data[index]["image_preview_url"] = URL.createObjectURL(file);
		data[index]["image_buffer"] = file;
		data[index]["label"] = file;
		setOptions(data);
	};
	const handleUploadImageQuestion = e => {
		const file = e.target.files[0];
		setQuestionImageBuffer(file);
		setQuestionImagePrevURL(URL.createObjectURL(file));
	};

	const handleRemoveQuestionImage = () => {
		setQuestionImageBuffer(null);
		setQuestionImagePrevURL(null);
		setQuestionImagePrevURLMobile(null);
	};

	const handleChangeOption = optionId => {
		let data = [...options];

		const newOptions = data
			.map(item => ({ ...item, is_correct: false }))
			.map(item => {
				if (item.option_id === optionId) {
					return { ...item, is_correct: true };
				}
				return item;
			});

		setOptions(newOptions);
	};

	return (
		<div className="rounded-lg border border-solid border-red-50 drop-shadow-main p-4 mb-6">
			<div className="flex justify-between items-center mb-3">
				<div className="w-[calc(100%-55px)]">
					<input
						type="text"
						disabled={disabled}
						value={question}
						onChange={e => setQuestion(e.target.value)}
						className="border-grey-40 w-full outline-none border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
						placeholder="type a question"
					/>
				</div>

				<div className="w-[46px]">
					<input
						type="file"
						id={editId ? `question-image-${editId}` : "question-image-1"}
						className="hidden"
						onChange={handleUploadImageQuestion}
						disabled={disabled}
					/>
					<label
						className="flex w-[46px] h-[46px] items-center justify-center border border-solid border-grey-40 cursor-pointer rounded-lg"
						for={editId ? `question-image-${editId}` : "question-image-1"}>
						<div className="text-[10px] text-red-50">
							<strong>
								<span className="icon-ico-plus"></span>
							</strong>
						</div>
						<div className="text-[20px] text-red-50">
							<span className="icon-ico-img"></span>
						</div>
					</label>
				</div>
			</div>
			{questionImagePreviewUrl && (
				<div className="w-[70%] ml-2">
					<div className="relative overflow-hidden rounded-lg">
						<img src={questionImagePreviewUrl} className="max-w-full mb-4 rounded-md" alt="question " />
						<button
							onClick={handleRemoveQuestionImage}
							className="absolute top-2 right-3 w-10 h-10 leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
							<span className="icon-ico-delete"></span>
						</button>
					</div>
				</div>
			)}

			{options.map((option, index) => {
				if (option.label && option.isEditing) {
					return (
						<div className="test-item py-4 relative" key={index}>
							<input type="radio" name="answer" className="hidden" />
							<label className=" flex items-center font-semibold text-black text-sm">
								<span className="pl-3">Ini adalah opsi ketiga tanpa gambar</span>
							</label>
						</div>
					);
				} else {
					return (
						<div className="test-item py-0 relative" key={index}>
							<input
								type="radio"
								disabled={disabled}
								name="answer"
								className="hidden"
								id={`option-${index}-${editId}`}
								checked={option.is_correct}
								onClick={() => handleChangeOption(option.option_id)}
							/>
							<label
								className="flex pt-[2.5px] pb-[2px] items-center font-semibold text-black text-sm"
								htmlFor={`option-${index}-${editId}`}>
								<div className="w-[calc(100%-15px)]">
									<div className="flex justify-between items-center">
										<div className="pl-3 w-[calc(100%-50px)]">
											{option.type === "image" || option.image_preview_url ? (
												<div className="w-2/6">
													<span className="pl-4">
														<img
															src={option.image_preview_url || option.label}
															alt="Option"
															className=" rounded-lg border border-solid border-grey-30  max-w-full"
														/>
													</span>
												</div>
											) : (
												<input
													disabled={disabled}
													value={option.label}
													onChange={e => handleFormChange(index, e)}
													name="label"
													type="text"
													className="border-grey-10 outline-none font-normal placeholder:italic w-full bg-transparent border-l-0 border-t-0 border-r-0 border-b text-sm border-solid pr-4 pb-[8px] text-red-50 transition-all focus:bg-white focus:border-t-0 focus:border-r-0 focus:border-l-0 hover:border-t-0 hover:border-r-0 hover:border-l-0 focus:text-red-50 focus:border-red-50 focus:outline-none focus:shadow-none"
													placeholder="ketik disini untuk tambah opsi, bisa juga opsi gambar"
												/>
											)}
										</div>

										<div className="w-[46px]">
											<input
												type="file"
												disabled={disabled}
												id={`upload-image-${option.option_id}`}
												className="hidden"
												onChange={e => handleUploadImage(option.option_id, e)}
											/>
											<div className="flex items-center justify-center w-full">
												<label
													className="flex no-ba w-[46px] h-[46px] items-center justify-center border border-solid border-transparent cursor-pointer rounded-lg"
													htmlFor={`upload-image-${option.option_id}`}>
													<div className="text-[10px] text-red-50">
														<strong>
															<span className="icon-ico-plus"></span>
														</strong>
													</div>
													<div className="text-[20px] text-red-50">
														<span className="icon-ico-img"></span>
													</div>
												</label>
												<div
													className="text-[20px] text-red-50 cursor-pointer"
													onClick={() => !disabled && handleDeleteItem(index)}>
													<span className="icon-ico-delete"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</label>
						</div>
					);
				}
			})}

			{/* display div with flex tailwind */}
			<div className="flex justify-between items-center">
				<button
					className="
              mt-5 block py-2.5 px-7 border text-center w-50 border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold disabled:bg-grey-30 disabled:border-none"
					type="button"
					disabled={
						disabled ||
						options?.[options?.length - 1]?.label === "" ||
						(options?.[options?.length - 1]?.label === "" &&
							!options?.[options?.length - 1]?.image_preview_url) ||
						!question
					}
					onClick={handleAddNewOption}>
					<span className="icon-ico-user-register"></span>
					Add New Field
				</button>
				{editingMode ? (
					<div className="flex justify-around">
						<ButtonSubmit
							loading={isUploading}
							disabled={!options?.every(option => option.label) || !options.length}
							title="Save"
							onClick={handleAddQuestion}
							className="
						flex items-center mt-5 py-2.5 px-3 mr-2 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold disabled:border-none disabled:cursor-not-allowed disabled:opacity-50"
						/>
						<ButtonSubmit
							loading={isUploading}
							title="Cancel"
							onClick={handleCancelEdit}
							className="
						flex items-center mt-5 mr-2 py-2.5 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold disabled:border-none disabled:cursor-not-allowed disabled:opacity-50"
						/>
					</div>
				) : (
					<ButtonSubmit
						disabled={
							disabled ||
							options?.[options?.length - 1]?.label === "" ||
							!question ||
							!options.length ||
							!options?.some(option => option.is_correct)
						}
						loading={isUploading}
						title="Add Question"
						onClick={handleAddQuestion}
						className="
              mt-5 block py-2.5 px-7 border text-center w-50 border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold disabled:border-none disabled:bg-grey-30"
					/>
				)}
			</div>
		</div>
	);
}
