import axios from "api/http.api";
import { useQuery } from "react-query";
import qs from "query-string";
import { useNavigate } from "react-router-dom";

export function useGetMemberList({ type, filter, body }) {
	return useQuery(["MEMBERS", { filter, body, type }], () =>
		axios.post(`/api/v1/admin/member/${type}?${qs.stringify(filter)}`, body).then(res => res.data),
	);
}

export function useGetMemberProfileById({ userId, type }) {
	const navigate = useNavigate();
	return useQuery(
		["MEMBER_PROFILE", { userId, type }],
		() => axios.get(`/api/v1/admin/member/profile/${type}/${userId}`).then(res => res.data),
		{
			onSuccess: data => {
				if (data.data === null) navigate("/member/not-found?countBack=2");
			},
		},
	);
}

export function useGetMemberBeautyProfofile({ userId }) {
	return useQuery(["MEMBER_BEAUTY_PROFILE", { userId }], () =>
		axios.get(`/api/v1/admin/member/beauty-profile/${userId}`).then(res => res.data),
	);
}

export function useGetMemberBatch({ userId }) {
	return useQuery(["MEMBER_BATCH", { userId }], () =>
		axios.get(`/api/v1/admin/member/followed-batch/${userId}`).then(res => res.data),
	);
}

export function useGetMemberPointHistory({ userId, batchId, queryFilter }) {
	return useQuery(
		["MEMBER_POINT_HISTORY", { userId, batchId, queryFilter }],
		() =>
			axios
				.get(`/api/v1/admin/member/point-history/${batchId}/${userId}?${qs.stringify(queryFilter)}`)
				.then(res => res.data),
		{
			enabled: !!batchId,
		},
	);
}

export function useGetMemberActivityHistory({ userId, batchId, queryFilter }) {
	return useQuery(
		["MEMBER_ACTIVITY_HISTORY", { userId, batchId, queryFilter }],
		() =>
			axios
				.get(`/api/v1/admin/member/activity-history/${batchId}/${userId}?${qs.stringify(queryFilter)}`)
				.then(res => res.data),
		{
			enabled: !!batchId,
		},
	);
}

export function useGetAllCitiesList() {
	return useQuery("MASTER_CITIES", () => axios.get("/api/v1/master-data/city/all").then(res => res.data));
}
