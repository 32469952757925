import clsx from "clsx";
import { ModalContainer } from "components/modal/modal.container";

/**
 * It's a modal that displays a success or error message
 * @returns A modal component that is used to display a result of an operation message to the user.
 */
export function ModalResult({
	title = "Success",
	message = "Change succesfully updated!",
	visible = false,
	type = "success",
	onClose = () => {},
}) {
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[515px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					{title && <div className="font-bold text-red-50">{title}</div>}

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="my-6">
					{visible && type === "success" && (
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
							<circle
								className="path circle"
								fill="none"
								stroke="#b52029"
								stroke-width="6"
								stroke-miterlimit="10"
								cx="65.1"
								cy="65.1"
								r="62.1"
							/>
							<polyline
								className="path check"
								fill="none"
								stroke="#b52029"
								stroke-width="6"
								stroke-linecap="round"
								stroke-miterlimit="10"
								points="100.2,40.2 51.5,88.8 29.8,67.5 "
							/>
						</svg>
					)}

					{visible && type === "error" && (
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
							<circle
								className="path circle"
								fill="none"
								stroke="#D06079"
								stroke-width="6"
								stroke-miterlimit="10"
								cx="65.1"
								cy="65.1"
								r="62.1"
							/>
							<line
								className="path line"
								fill="none"
								stroke="#D06079"
								stroke-width="6"
								stroke-linecap="round"
								stroke-miterlimit="10"
								x1="34.4"
								y1="37.9"
								x2="95.8"
								y2="92.3"
							/>
							<line
								className="path line"
								fill="none"
								stroke="#D06079"
								stroke-width="6"
								stroke-linecap="round"
								stroke-miterlimit="10"
								x1="95.8"
								y1="38"
								x2="34.4"
								y2="92.2"
							/>
						</svg>
					)}
					<p className="mt-2 mb-6 text-center text-main-grey">{message}</p>
					<div className="flex justify-center mt-2 mt-5 align-item">
						<div className="w-2/4 ">
							<button
								onClick={onClose}
								className="block   w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
								type="button">
								<span>Ok</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
