import qs from "query-string";
import { useMutation, useQueryClient } from "react-query";
import axios from "api/http.api";

export function useAddNewEvent() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/event/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(["PAGE_SETTING"]);
			queryClient.removeQueries(["PAGE_SETTING"]);
			queryClient.invalidateQueries(["EVENT_LIST"]);
			queryClient.removeQueries(["EVENT_LIST"]);
		},
	});
}

export function useDeleteEvent() {
	const queryClient = useQueryClient();
	return useMutation(
		({ eventId }) => axios.delete(`/api/v1/admin/event/delete/${eventId}`, {}).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["PAGE_SETTING", { page: "home", slug: "event" }]);
				queryClient.removeQueries(["PAGE_SETTING", { page: "home", slug: "event" }]);
			},
		},
	);
}

export function useEditEvent() {
	const queryClient = useQueryClient();
	return useMutation(
		({ eventId, body }) => axios.post(`/api/v1/admin/event/update/${eventId}`, body).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["PAGE_SETTING"]);
				queryClient.removeQueries(["PAGE_SETTING"]);
				queryClient.invalidateQueries(["EVENT_LIST"]);
				queryClient.removeQueries(["EVENT_LIST"]);
			},
		},
	);
}

export function useExportEvent() {
	return useMutation(filter =>
		axios
			.post(
				`/api/v2/admin/event/export?${qs.stringify(filter)}`,
				{},
				{
					responseType: "blob",
				},
			)
			.then(res => res),
	);
}
