import { InputComponent } from "components/form/input.component";

import { InsertLinks } from "../insert-links.component";
import { AccordionWrapper } from "../accordion-wrapper.component";
import TextEditor from "components/text-editor";
import { IsJsonString } from "utils/string";

export function ClassLinks({
	expanded,
	onToggleExpand,
	onChange,
	onBlur,
	values,
	errors,
	touched,
	setFieldValue,
	descriptionInitialValue = undefined,
	initialValues = { redirect_urls: [] },
	sectionId = "class_video_conference",
}) {
	return (
		<AccordionWrapper
			onToggleExpand={onToggleExpand}
			expaned={expanded}
			title="Link Kelas"
			onChange={onChange}
			sectionId={sectionId}
			values={values}>
			<div className="py-4 px-2">
				<div className="flex justify-between ">
					<div className="w-[48%]">
						<InputComponent
							label="Title"
							placeholder="Insert Title"
							onBlur={onBlur}
							onChange={onChange}
							value={values?.title}
							name={`${sectionId}.title`}
							error={touched?.title && errors?.title}
						/>

						<div className="mb-4">
							<div className="text-sm text-black font-semibold mb-3">Description</div>
							<TextEditor
								initialValue={
									IsJsonString(descriptionInitialValue) ? descriptionInitialValue : undefined
								}
								onChange={nodeValue => setFieldValue(`${sectionId}.description`, nodeValue)}
							/>
						</div>
					</div>
					<div className="w-[48%]">
						<InsertLinks
							initialValues={initialValues.redirect_urls}
							onChange={data => {
								setFieldValue(`${sectionId}.redirect_urls`, data);
							}}
						/>
					</div>
				</div>
			</div>
		</AccordionWrapper>
	);
}
