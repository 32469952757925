import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik, FieldArray } from "formik";
import { toast } from "react-hot-toast";

import { PageSettingLayout } from "pages/page-settings/layout";

import { InputComponent } from "components/form/input.component";

import { UploadSocmedIcon } from "./../components/upload-social-media-icon";

import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";

import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";

import { ToogleSwitchComponent } from "components/form/toogle-switch.component";
import { useUpdateSettingFooter, useGetFooterSetting } from "hooks/general-settings";

import { SliderSocialMediaFooter } from "../components/slider-social-media";
import { FooterSocialMediasSchema } from "config/form/schema/general-setting/footer";

const formikInitialValues = {
	is_visible: false,
	social_medias: [
		{
			title: "",
			redirect_link: "",
			image_desktop: "",
		},
	],
};

export const GENERAL_SETTING = {
	FOOTER: [
		{
			id: "general-setting-footer",
			label: "Logo",
			path: "/footer/logo",
		},
		{
			id: "general-setting-footer-social-media",
			label: "Social Media",
			path: "/footer/social-media",
		},
	],
};
export function SocialMediaFooter() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = useGetFooterSetting({ page: "footer", slug: "social-media" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdateSettingFooter();

	const [showModalResult, setShowModalResult] = useState(false);
	const [dataBanner, setDataBanner] = useState([
		{
			id: "social_medias-0",
			isExpand: false,
			image_desktop: null,
			image_mobile: null,
			title: "",
			redirect_link: "",
		},
	]);
	const [filesBuffer, setFilesBuffer] = useState([]);

	useEffect(() => {
		if (data) {
			Object.keys(formikInitialValues).forEach(key => {
				if (data?.data?.[key]) {
					if (key === "social_medias") {
						formikRef.current.setFieldValue(
							key,
							data?.data?.social_medias.map((item, index) => ({
								...item,
								id: `social_medias-${index + 1}`,
								fileBuffer: item.image_desktop,
							})),
						);
					} else {
						formikRef.current.setFieldValue(key, data?.data?.[key]);
					}
				}
			});
			setDataBanner(
				data?.data?.social_medias.map((item, index) => ({
					...item,
					isExpand: index === 0,
					id: `social_medias-${index + 1}`,
				})),
			);
		}
	}, [data]);

	const handleSubmitForm = async payloadData => {
		try {
			const generateBodyRequestWithNewImage = () => {
				return new Promise(async (resolve, reject) => {
					const newPayloadData = _.cloneDeep(payloadData);
					if (!!filesBuffer.length) {
						const res = filesBuffer.map(async fileImageBuffer => {
							const images = Object.keys(fileImageBuffer.file)
								.filter(itemKey => fileImageBuffer.file[itemKey])
								.map(itemKey => ({
									version: itemKey.replace("image_", ""),
									file: fileImageBuffer.file[itemKey],
								}));

							const imagePromise = images.map(image => {
								const formData = new FormData();
								formData.append("image", image.file);
								formData.append("version", "desktop");
								formData.append("type", "banner");
								return uploadImageAsync({
									formData,
									uploadId: `image_${image.version}_${fileImageBuffer.id}`,
								});
							});
							const response = await Promise.all(imagePromise);
							const responseDataUrls = response.map(item => item.data);
							return { ...responseDataUrls[0], id: fileImageBuffer.id };
						});

						const response = await Promise.all(res);
						newPayloadData.social_medias = newPayloadData?.social_medias.map(item => {
							const newImageData = response.find(itemRes => itemRes.id === item.id);
							if (newImageData) {
								return {
									...item,
									...newImageData,
								};
							}
							return item;
						});

						resolve(newPayloadData);
					} else {
						newPayloadData.social_medias = payloadData.social_medias;

						resolve(newPayloadData);
					}
				});
			};

			const newBodyRequest = await generateBodyRequestWithNewImage();

			updatePageSetting(
				{ page: "footer", slug: "social-media", formData: newBodyRequest },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setFilesBuffer([]);
						window.scrollTo({ top: 0, behavior: "smooth" });
					},
				},
			);
		} catch (error) {
			toast.error(error?.response?.message || "Oops, something went wrong");
		}
	};

	const handleChangeImage = (bannerId, data, index) => {
		const newFilesBuffer = [...filesBuffer];
		const newData = _.mapKeys(data, (value, key) => key.replace(`-${bannerId}`, ""));
		const itemByBannerId = newFilesBuffer.find(item => item.id === bannerId);
		formikRef.current?.setFieldValue(
			`social_medias.${index}.fileBuffer`,
			newData?.image_desktop ? URL.createObjectURL(newData.image_desktop) : null,
		);

		if (itemByBannerId) {
			itemByBannerId.file[Object.keys(newData)[0]] = newData[Object.keys(newData)[0]];

			newFilesBuffer[index] = itemByBannerId;
		} else {
			newFilesBuffer.push({
				id: bannerId,
				file: {
					image_desktop: null,
					image_mobile: null,
					...newData,
				},
			});
		}
		setFilesBuffer(newFilesBuffer);
	};

	const handleExpand = index => {
		const newData = [...dataBanner];
		newData[index].isExpand = !newData[index].isExpand;
		setDataBanner(newData);
	};

	const handleAddNewItem = () => {
		const newData = [...dataBanner];
		newData.push({
			id: `social_medias-${newData.length + 1}`,
			isExpand: true,
			image_desktop: null,
			image_mobile: null,
			title: "",
			redirect_link: "",
		});
		setDataBanner(newData);
	};

	const handleDelete = index => {
		const newData = [...dataBanner];
		newData.splice(index, 1);
		if (newData.length !== 0) newData[0].isExpand = true;
		setDataBanner(newData);

		const currentFormValues = formikRef.current.values;
		const newFormValues = {
			...currentFormValues,
			social_medias: currentFormValues.social_medias.filter((item, itemIndex) => itemIndex !== index),
		};
		formikRef.current.setFieldValue("social_medias", newFormValues.social_medias);
	};

	const handleMoveOrder = (direction, index) => {
		const currentFormValues = formikRef.current.values;
		const newSocialMedias = _.cloneDeep(currentFormValues.social_medias);
		const newData = [...dataBanner];
		const item = newData[index];
		const itemFormValues = newSocialMedias[index];

		newData.splice(index, 1);
		newSocialMedias.splice(index, 1);
		if (direction === "UP") {
			newSocialMedias.splice(index - 1, 0, itemFormValues);
			newData.splice(index - 1, 0, item);
		} else {
			newSocialMedias.splice(index + 1, 0, itemFormValues);
			newData.splice(index + 1, 0, item);
		}

		setDataBanner(newData);
		formikRef.current.setFieldValue("social_medias", newSocialMedias);
	};
	return (
		<PageSettingLayout
			routes={GENERAL_SETTING}
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="General Setting"
			sidebarMenu="FOOTER"
			desc="Footer">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik
						innerRef={formikRef}
						initialValues={formikInitialValues}
						validationSchema={FooterSocialMediasSchema}
						onSubmit={handleSubmitForm}>
						{({ values, handleChange, handleSubmit, handleBlur, errors, touched, setFieldValue }) => {
							return (
								<>
									<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
										<div className="flex items-center">
											<div className="text-2xl text-black font-semibold">Social Media</div>
											<div
												class={clsx(
													"text-base italic  ml-5",
													clsx(values.is_visible ? "text-main-green" : "text-red-50"),
												)}>
												{values.is_visible ? "Appear" : "Hide"} At All Pages Footer
											</div>
										</div>
										<div className="flex flex-row-reverse items-center">
											<ToogleSwitchComponent
												inputClassName="toggle-checkbox"
												labelClassName="toggle enable"
												onChange={handleChange}
												name="is_visible"
												checked={values.is_visible}
											/>
										</div>
									</div>
									<FieldArray
										name="social_medias"
										render={arrayHelpers => (
											<>
												{values?.social_medias?.map((bannerItem, index) => (
													<SliderSocialMediaFooter
														data={bannerItem}
														key={index}
														title={`Social Media ${index + 1}`}
														expand={dataBanner[index]?.isExpand}
														onExpand={() => handleExpand(index)}
														disabledUpButton={index === 0}
														disabledDownButton={index === dataBanner.length - 1}
														onMove={direction => handleMoveOrder(direction, index)}>
														<div className="flex p-4">
															<div className="w-40">
																<UploadSocmedIcon
																	initialImage={bannerItem.image_desktop}
																	name={`image_desktop-${bannerItem.id}`}
																	key={`image_desktop-${bannerItem.id}`}
																	onChange={imageFileBuffer => {
																		handleChangeImage(
																			bannerItem.id,
																			imageFileBuffer,
																			index,
																		);
																	}}
																/>
															</div>
															<div className="w-1/2 ml-8">
																<div className="text-sm text-black font-semibold mb-3">
																	Title
																</div>
																<InputComponent
																	value={bannerItem.title}
																	name={`social_medias.${index}.title`}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	error={
																		touched.social_medias?.[index]?.title &&
																		errors.social_medias?.[index]?.title
																	}
																	placeholder="link title"
																	inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
																/>
																<div className="text-sm text-black font-semibold mb-3">
																	Link/URL
																</div>
																<InputComponent
																	value={bannerItem.redirect_link}
																	name={`social_medias[${index}].redirect_link`}
																	error={
																		touched.social_medias?.[index]?.redirect_link &&
																		errors.social_medias?.[index]?.redirect_link
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	placeholder="link to page / external url"
																	inputclassName="border-grey-10 w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
																/>
															</div>
														</div>
														<div className="border-t border-grey-10 py-3 px-4 flex justify-end">
															<button
																onClick={() => handleDelete(index)}
																className="disabled:opacity-30 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
																type="button">
																<span className="icon-ico-delete text-red-50 text-xl"></span>
																<span className="pl-2">Delete</span>
															</button>
														</div>
													</SliderSocialMediaFooter>
												))}
												<button
													onClick={() => {
														arrayHelpers.push({
															id: "social_medias-" + (values.social_medias.length + 1),
															title: "",
															redirect_link: "",
															image_desktop: "",
															fileBuffer: null,
														});
														handleAddNewItem();
													}}
													className="flex items-center py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
													type="button">
													<span className="icon-ico-circle-plus text-red-50 text-xl"></span>
													<span className="pl-2">Add New Item</span>
												</button>
											</>
										)}
									/>

									<div className="flex justify-center my-9">
										<div className="w-2/6">
											<ButtonSubmit
												onClick={handleSubmit}
												disabled={!FooterSocialMediasSchema.isValidSync(values)}
												loading={isUploading || isUpdating}
												title="Save All Changes"
												className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
											/>
										</div>
									</div>
								</>
							);
						}}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
