import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";

import TextEditor from "components/text-editor";
import { TitlePageSettingValidationSchema } from "config/form/schema/page-setting";
import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { useUploadImage, useUploadVideo } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { InputComponent } from "components/form/input.component";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";

import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { PageSettingLayout } from "../layout";
import { UploadBannerComponent } from "../upload-banner.component";
import { UploadVideoComponent } from "../upload-video.component";
import { IsJsonString } from "utils/string";

const formikInitialValues = {
	is_visible: false,
	thumbnail_video_desktop: null,
	thumbnail_video_mobile: null,
	title: "",
	redirect_url_video: "",
	description: undefined,
};

export function WeAreRubiCommunitySettingPage() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "rubi-ambasador", slug: "we-are-rubi-community" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploadingImage } = useUploadImage();
	const { mutateAsync: uploadVideoAsync, isLoading: isUploadingVideo } = useUploadVideo();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [imageFileBuffer, setImageFileBuffer] = useState(null);
	const [videoFileBuffer, setVideoFileBuffer] = useState(null);
	const [showModalResult, setShowModalResult] = useState(false);

	useEffect(() => {
		Object.keys(formikInitialValues).forEach(key => {
			if (data?.data?.[key]) {
				formikRef.current.setFieldValue(key, data?.data?.[key]);
			}
		});
	}, [data]);

	const handleSubmitForm = async payloadData => {
		try {
			if (imageFileBuffer) {
				const formData = new FormData();
				formData.append("image", imageFileBuffer);
				formData.append("type", "banner");

				const { data: uploadImageResult } = await uploadImageAsync({ formData });

				payloadData.thumbnail_video_desktop = uploadImageResult.image_desktop;
				payloadData.thumbnail_video_mobile = uploadImageResult.image_mobile;
			}

			if (videoFileBuffer) {
				const formData = new FormData();
				formData.append("video", videoFileBuffer);
				formData.append("type", "banner");

				const { data: uploadVideoResult } = await uploadVideoAsync({ formData });

				payloadData.redirect_url_video = uploadVideoResult.video_url;
			}

			updatePageSetting(
				{ page: "rubi-ambasador", slug: "we-are-rubi-community", formData: payloadData },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setImageFileBuffer(null);
					},
				},
			);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="AMBASSADOR"
			desc="RUBI Ambassador">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik
						innerRef={formikRef}
						initialValues={formikInitialValues}
						onSubmit={handleSubmitForm}
						validationSchema={TitlePageSettingValidationSchema}>
						{({ values, handleChange, handleSubmit, handleBlur, setFieldValue, touched, errors }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">We Are RUBI Community</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} From RUBI Ambassador Page
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>

								<div className="flex justify-between py-4 px-6">
									<div className="w-[47%]">
										<UploadBannerComponent
											initialImage={data?.data?.thumbnail_video_desktop}
											name="thumbnail_video_desktop"
											onChange={imageFileBuffer => {
												setImageFileBuffer(imageFileBuffer["thumbnail_video_desktop"]);
											}}
											title="Insert Image or Video Thumbnail"
										/>
									</div>
									<div className="w-[47%]">
										<UploadVideoComponent
											initialValue={data?.data?.redirect_url_video}
											name="video-upload-file"
											onChange={selectedData => {
												if (selectedData["video-upload-file"].type === "video-file") {
													setVideoFileBuffer(selectedData["video-upload-file"].data);
												} else {
													formikRef.current?.setFieldValue(
														"redirect_url_video",
														selectedData["video-upload-file"].data,
													);
												}
											}}
										/>
									</div>
								</div>
								<InputComponent
									containerclassName="mb-4 px-6"
									labelclassName="text-sm text-black font-semibold mb-3"
									label="Title"
									placeholder="Title"
									name="title"
									value={values.title}
									error={touched.title && errors.title}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								<div className="mb-4 px-6">
									<div className="text-sm text-black font-semibold mb-3">Description</div>
									<TextEditor
										initialValue={
											IsJsonString(data?.data?.description) ? data?.data?.description : undefined
										}
										onChange={nodeValue => setFieldValue("description", nodeValue)}
									/>
								</div>

								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											disabled={!TitlePageSettingValidationSchema.isValidSync(values)}
											onClick={handleSubmit}
											loading={isUploadingImage || isUploadingVideo || isUpdating}
											title="Save All Changes"
											className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
