import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";

import { InputComponent } from "components/form/input.component";
import { ButtonSubmit } from "components/button/submit.button";
import { RadioButtonComponent } from "components/form/radiobutton.component";
import { ImageWithZoom } from "components/image/image-zoom.component";
import { SOCIAL_MEDIA_MASTER_DATA } from "constant/socialMediaConstant";
import { ModalResult } from "components/modal/modal.result";
import { ProfileValidationSchema } from "config/form/schema/members/member-profile-details";

import { useGetAllCitiesList, useGetMemberProfileById, useUpdateProfileMember } from "hooks/member";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalSelectCity } from "components/modal/modal.selecty-city";

import { MemberProfileLayout } from "../../components/profile.layout";
import { getInitialsName } from "utils/string";
import { ModalConfirmation } from "components/modal/modal.confirmation";

const GENDER_OPTIONS = [
	{
		label: "Female",
		value: "female",
	},
	{
		label: "Male",
		value: "male",
	},
];

const formikInitialValues = {
	profile_picture: "",
	full_name: "",
	email: "",
	phone_number: "",
	gender: "",
	city: "",
	birth_date: "",
	instagram: "",
	tiktok: "",
	facebook: "",
	twitter: "",
	youtube: "",
};

export function MemberProfilePage() {
	const formikRef = useRef();
	const { userId, userStatus } = useParams();

	const [showModalResult, setShowModalResult] = useState(false);
	const [showModalCity, setShowModalCity] = useState(false);
	const [modalConfirmation, setModalConfirmation] = useState(false);
	const [modalKey, setModalKey] = useState(0);

	const { data: masterCities } = useGetAllCitiesList();

	const { data: memberProfileDetails, isLoading } = useGetMemberProfileById({ userId, type: userStatus });
	const { mutate: updateProfileMember, isLoading: isUpdating } = useUpdateProfileMember({ type: userStatus, userId });

	useEffect(() => {
		if (memberProfileDetails?.data) {
			Object.keys(formikInitialValues).forEach(key => {
				formikRef.current.setFieldValue(key, memberProfileDetails.data[key]);

				if (memberProfileDetails.data.social_media.find(item => item.name === key)) {
					formikRef.current.setFieldValue(
						key,
						memberProfileDetails.data.social_media.find(item => item.name === key).user_social_media
							?.username || "",
					);
				}

				if (key === "city" && masterCities?.data) {
					const cityId = memberProfileDetails.data.city_id;
					formikRef.current.setFieldValue(
						key,
						masterCities?.data.find(item => item.id === cityId),
					);
				}
			});
		}
	}, [memberProfileDetails, masterCities]);

	const handleOnUpdateProfileMember = data => {
		const socialMedias = SOCIAL_MEDIA_MASTER_DATA.map(item => {
			return {
				social_media_id: item.id,
				username: data[item.name],
			};
		});
		const formPayload = { ...data, user_social_medias: socialMedias, city_id: data?.city?.id || null };
		updateProfileMember(formPayload, {
			onSuccess: () => {
				setShowModalResult(true);
				setModalConfirmation(false);
				window.scroll({
					top: 0,
					behavior: "smooth",
				});
			},
		});
	};

	return (
		<MemberProfileLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="User"
			desc="User Detail">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[40vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik
						validationSchema={ProfileValidationSchema}
						initialValues={formikInitialValues}
						innerRef={formikRef}
						onSubmit={handleOnUpdateProfileMember}>
						{({ values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue }) => {
							return (
								<>
									<div className="mb-14 py-2 p-3">
										<div className="text-title font-semibold text-grey-70 mb-7">Profile</div>
										<div className="flex mb-8 items-center">
											<div className="mr-5">
												{memberProfileDetails?.data?.profile_picture ? (
													<ImageWithZoom
														src={memberProfileDetails?.data?.profile_picture}
														className="rounded-full w-36 h-36 overflow-hidden"
														alt={memberProfileDetails?.data?.full_name}
													/>
												) : (
													<div className="initial">
														{getInitialsName(memberProfileDetails?.data?.full_name) || "A"}
													</div>
												)}
											</div>
											<div className="w-auto">
												<div className="text-xl text-black font-bold">
													{memberProfileDetails?.data?.full_name}
												</div>
												<div className="text-sm text-main-grey">
													{memberProfileDetails?.data?.email}
												</div>
											</div>
										</div>
										<div className="flex justify-between">
											<div className="w-[48%]">
												<InputComponent
													disabled={isUpdating}
													error={touched.full_name && errors.full_name}
													label="Full Name"
													name="full_name"
													value={values.full_name}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<InputComponent
													label="Email"
													name="email"
													value={values.email}
													disabled
												/>
												<InputComponent
													label="Phone Number"
													numberOnly
													disabled
													name="phone_number"
													value={values.phone_number}
												/>
											</div>
											<div className="w-[48%]">
												<div className="mb-4">
													<InputComponent
														disabled={isUpdating}
														label="Date of Birth"
														error={touched.birth_date && errors.birth_date}
														name="birth_date"
														type="date"
														max={new Date().toISOString().split("T")[0]}
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.birth_date}
													/>
												</div>
												<RadioButtonComponent
													disabled={isUpdating}
													label="Gender"
													value={values.gender}
													error={touched.gender && errors.gender}
													name="gender"
													onChange={handleChange}
													options={GENDER_OPTIONS}
												/>

												<div className="mb-4">
													<InputComponent
														disabled={isUpdating}
														label="City"
														placeholder="Pilih kota domisili"
														error={touched.city && errors.city}
														name="city"
														onClick={() => {
															setModalKey(modalKey + 1);
															setShowModalCity(true);
														}}
														onBlur={handleBlur}
														value={values.city?.name}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="py-2 p-3">
										<div className="text-title font-semibold text-grey-70 mb-7">Social Media</div>
										<div className="flex justify-between">
											<div className="w-[48%]">
												<InputComponent
													disabled={isUpdating}
													label="Instagram"
													error={touched.instagram && errors.instagram}
													name="instagram"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.instagram}
													placeholder="Instagram Username"
												/>
												<InputComponent
													disabled={isUpdating}
													onChange={handleChange}
													onBlur={handleBlur}
													error={touched.tiktok && errors.tiktok}
													value={values.tiktok}
													label="Tiktok"
													name="tiktok"
													placeholder="Tiktok Username"
												/>
												<InputComponent
													disabled={isUpdating}
													onChange={handleChange}
													onBlur={handleBlur}
													error={touched.facebook && errors.facebook}
													value={values.facebook}
													label="Facebook"
													name="facebook"
													placeholder="Facebook Username"
												/>
											</div>
											<div className="w-[48%]">
												<InputComponent
													disabled={isUpdating}
													onChange={handleChange}
													onBlur={handleBlur}
													error={touched.youtube && errors.youtube}
													value={values.youtube}
													label="Youtube"
													name="youtube"
													placeholder="Youtube Username"
												/>
												<InputComponent
													disabled={isUpdating}
													onChange={handleChange}
													onBlur={handleBlur}
													error={touched.twitter && errors.twitter}
													value={values.twitter}
													label="Twitter"
													name="twitter"
													placeholder="Twitter Username"
												/>
											</div>
										</div>

										<div className="flex justify-center my-9">
											<div className="w-2/6">
												<ButtonSubmit
													onClick={() => setModalConfirmation(true)}
													disabled={!ProfileValidationSchema.isValidSync(values)}>
													<span className="icon-ico-save mr-2"></span> Save All Changes
												</ButtonSubmit>
											</div>
										</div>
									</div>
									<ModalSelectCity
										key={`modal-select-city-${modalKey}`}
										visible={showModalCity}
										onClose={() => setShowModalCity(false)}
										onChange={({ selected }) => setFieldValue("city", selected)}
										initialValue={values.city}
									/>
									<ModalConfirmation
										visible={modalConfirmation}
										title="Simpan Perubahan"
										labelReject="Cancel"
										loading={isUpdating}
										labelApprove="Save"
										onApprove={handleSubmit}
										onReject={() => setModalConfirmation(false)}
										onClose={() => setModalConfirmation(false)}>
										<div className="my-6">
											<img src="/img/icons/save.svg" alt="Delete" className="mx-auto" />
											<p className="my-6 text-center text-main-grey">
												Apakah ingin menyimpan perubahan ?
											</p>
										</div>
									</ModalConfirmation>
								</>
							);
						}}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</MemberProfileLayout>
	);
}
