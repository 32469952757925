import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-hot-toast";
import { catchErrorMessage } from "utils/string";
import RoutePage from "./routes";

import { store, persistor } from "./store";

const defaultQueryConfig = {
	refetchInterval: false,
	refetchIntervalInBackground: false,
	refetchOnMount: false,
	refetchOnWindowFocus: false,
	onError: (error, variables) => {
		toast.error(
			catchErrorMessage(
				error?.response?.data?.message || error?.message || "Something went wrong, please try again later.",
			),
		);
	},
};

function App() {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					mutations: defaultQueryConfig,
					queries: defaultQueryConfig,
				},
			}),
	);

	return (
		<QueryClientProvider client={queryClient}>
			<PersistGate loading={null} persistor={persistor}>
				<Provider store={store}>
					<Toaster />
					<BrowserRouter>
						<RoutePage />
					</BrowserRouter>
				</Provider>
			</PersistGate>
		</QueryClientProvider>
	);
}

export default App;
