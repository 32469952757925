import { useMemo } from "react";
import clsx from "clsx";

import { ModalContainer } from "./modal.container";

const socialMedia = [
	{
		value: "facebook",
		label: "Facebook",
		iconClassName: "icon-ico-facebook",
		baseUrl: "https://www.facebook.com/",
		followerLabel: "followers",
	},
	{
		value: "twitter",
		label: "Twitter",
		iconClassName: "icon-ico-twitter",
		baseUrl: "https://www.twitter.com/",
		followerLabel: "followers",
	},
	{
		value: "tiktok",
		label: "TikTok",
		iconClassName: "icon-ico-tiktok",
		baseUrl: "https://www.tiktok.com/",
		followerLabel: "followers",
	},
	{
		value: "instagram",
		label: "Instagram",
		iconClassName: "icon-ico-instagram",
		baseUrl: "https://www.instagram.com/",
		followerLabel: "followers",
	},
	{
		value: "youtube",
		label: "Youtube",
		iconClassName: "icon-ico-youtube",
		baseUrl: "https://www.youtube.com/",
		followerLabel: "subscribers",
	},
	{
		value: "linkedin",
		label: "Linkedin",
		iconClassName: "icon-ico-linkedin",
		baseUrl: "https://www.youtube.com/",
		followerLabel: "followers",
	},

	{
		value: "whatsapp",
		label: "WhatsApp",
		iconClassName: "icon-ico-whatsapp",
		baseUrl: "https://www.youtube.com/",
		followerLabel: "followers",
	},

	{
		value: "snapchat",
		label: "Snapchat",
		iconClassName: "icon-ico-snapchat",
		baseUrl: "https://www.youtube.com/",
		followerLabel: "followers",
	},
];

export function ModalSocialMedia({ visible, onClose, data }) {
	const socialMediaData = useMemo(() => {
		if (!data.length) return [];
		const finalData = socialMedia.map(item => {
			const dataItem = data.find(i => i.name === item.value);
			if (dataItem) {
				return {
					...item,
					disabled: false,
					socmedLabel: item.label,
					user: {
						user_social_media_url: `${item.baseUrl}${dataItem.user_social_media?.username}`,
						logo: dataItem.logo,
						followers: dataItem.user_social_media?.followers,
						username: dataItem.user_social_media?.username,
					},
				};
			} else {
				return {
					...item,
					disabled: true,
					socmedLabel: item.label,
					user: null,
				};
			}
		});

		return finalData;
	}, [data]);
	return (
		<ModalContainer visible={visible}>
			<div id="social" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[360px]", !visible && "hidden")}>
				<div className="relative text-center mb-10">
					<div className="font-bold text-red-50">User’s Social Media</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div>
					<ul className=" max-h-[40vh] mt-3 scroll overflow-auto">
						{socialMediaData.map(item => (
							<li
								onClick={() => {
									window.open(item.user.user_social_media_url, "_blank");
								}}
								className="flex mb-2  cursor-pointer  text-grey-60 text-sm font-semibold items-center p-4 border border-solid border-grey-10 rounded-lg  hover:border-red-50">
								<button
									disabled={item.disabled}
									className="w-10 h-10 flex items-center justify-center text-3xl transition-all border border-solid border-grey-10 text-center hover:bg-red-50 hover:border-red-50 hover:text-white text-black rounded-full bg-white leading-14 
                                
                                disabled:bg-grey-10 disabled:text-grey-40 disabled:hover:bg-grey-10 disabled:hover:text-grey-40 disabled:hover:border-grey-40
                                ">
									<span class={item.iconClassName}></span>
								</button>
								<div className="flex flex-col justify-start ml-2">
									<div className=" text-sm">{item.socmedLabel}</div>
									<div className=" text-sm font-normal">
										{item?.user?.username ? `@${item.user?.username}` : "-"}
									</div>
									<div className=" text-xs mt-1 italic font-light ">
										{item.user?.followers
											? `${item.user?.followers.toLocaleString()} ${item.followerLabel}`
											: "-"}
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</ModalContainer>
	);
}
