import _ from "lodash";
import { useEffect, useState } from "react";
import { clsx } from "clsx";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { PageSettingLayout } from "../layout";
import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { useUploadImage } from "hooks/upload";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";
import { SliderBenefitComponent } from "../component/slider-benefit.component";
import { DEFAULT_EDITOR_EMPTY_VALUE } from "components/text-editor";
import { InputComponent } from "components/form/input.component";
import { RubiPilarValidationSchema } from "config/form/schema/page-setting";

const formikInitialValues = {
	is_visible: false,
	banners: [],
	title: "",
};

export function RubiPilarAmbassador() {
	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "rubi-ambasador", slug: "pillar" });
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [showModalResult, setShowModalResult] = useState(false);
	const [dataBanner, setDataBanner] = useState([
		{
			id: "banner-0",
			isExpand: false,
			name: null,
			image_desktop: null,
			image_mobile: null,
			background_color: "",
			description: "",
		},
	]);
	const [filesBuffer, setFilesBuffer] = useState([]);

	useEffect(() => {
		if (data?.data) {
			Object.keys(formikInitialValues).forEach(key => {
				if (data?.data?.[key]) {
					if (key === "banners") {
						formik.setFieldValue(
							key,
							data?.data?.banners.map((item, index) => ({
								...item,
								id: `benefit-${index + 1}`,
							})),
						);
					} else {
						formik.setFieldValue(key, data?.data?.[key]);
					}
				}
			});
			setDataBanner(
				data?.data?.banners.map((item, index) => ({
					...item,
					isExpand: index === 0,
					id: `benefit-${index + 1}`,
					initialDescription: item.description,
				})),
			);
		}
	}, [data?.data]);

	const handleSubmitForm = async payloadData => {
		try {
			const generateBodyRequestWithNewImage = () => {
				return new Promise(async (resolve, reject) => {
					const newPayloadData = _.cloneDeep(payloadData);
					let cloneBannerData = _.cloneDeep(dataBanner);
					const filteredFileBuffer = filesBuffer.filter(item => !!item);
					if (!!filteredFileBuffer.length) {
						const processFileBuffers = async () => {
							for (const fileImageBuffer of filteredFileBuffer) {
								const images = Object.keys(fileImageBuffer.file)
									.filter(itemKey => fileImageBuffer.file[itemKey])
									.map(itemKey => ({
										version: itemKey.replace("image_", ""),
										file: fileImageBuffer.file[itemKey],
									}));

								const imagePromise = images.map(image => {
									const formData = new FormData();
									formData.append("image", image.file);
									formData.append("version", image.version);
									formData.append("type", "banner");
									return uploadImageAsync({
										formData,
										uploadId: `image_${image.version}_${fileImageBuffer.id}`,
									});
								});

								const response = await Promise.all(imagePromise);

								const responseDataUrls = response.map(item => item.data);

								cloneBannerData = cloneBannerData.map(item => {
									if (item.id === fileImageBuffer.id) {
										return { ...item, ...Object.assign({}, ...responseDataUrls) };
									}
									return item;
								});
								newPayloadData.title = payloadData.title;
								newPayloadData.description = payloadData.description;
								newPayloadData.banners = cloneBannerData;
							}
						};
						await processFileBuffers();
						resolve(newPayloadData);
					} else {
						newPayloadData.banners = dataBanner;

						resolve(newPayloadData);
					}
				});
			};

			const newBodyRequest = await generateBodyRequestWithNewImage();
			const newPayloadData = {
				...newBodyRequest,
				features: newBodyRequest.banners.map((item, index) => {
					if (item.id) {
						delete item.id;
					}
					return item;
				}),
			};

			updatePageSetting(
				{ page: "rubi-ambasador", slug: "pillar", formData: newPayloadData },
				{
					onSuccess: () => {
						setShowModalResult(true);
						refetchPageSettingData();
						setFilesBuffer([]);
						window.scrollTo({ top: 0, behavior: "smooth" });
					},
				},
			);
		} catch (error) {
			console.error(error);
			toast.error(error?.response?.message || "Oops, something went wrong");
		}
	};

	const formik = useFormik({
		initialValues: formikInitialValues,
		onSubmit: handleSubmitForm,
		validationSchema: RubiPilarValidationSchema,
	});

	const handleChangeImage = (bannerId, data, index) => {
		const newFilesBuffer = [...filesBuffer];
		const newData = _.mapKeys(data, (value, key) => key.replace(`-${bannerId}`, ""));
		const itemByBannerId = newFilesBuffer?.find(item => item?.id === bannerId);
		const itemByBannerIdIndex = newFilesBuffer?.findIndex(item => item?.id === bannerId);
		if (itemByBannerId) {
			itemByBannerId.file[Object.keys(newData)[0]] = newData[Object.keys(newData)[0]];

			newFilesBuffer[itemByBannerIdIndex] = itemByBannerId;
		} else {
			newFilesBuffer.push({
				id: bannerId,
				file: {
					image_desktop: null,
					image_mobile: null,
					...newData,
				},
			});
		}
		setFilesBuffer(newFilesBuffer);

		const currentData = [...formik.values.banners];
		const currentBanner = currentData[index];
		const currentBannerIndex = index;
		const newBanner = {
			...currentBanner,
			...newData,
		};

		const currentFormikData = [...formik.values.banners];
		currentFormikData[currentBannerIndex] = newBanner;
		formik.setFieldValue("banners", currentFormikData);
	};

	const handleExpand = index => {
		const newData = [...dataBanner];
		newData[index].isExpand = !newData[index].isExpand;
		setDataBanner(newData);
	};

	const handleChangeName = (data, index) => {
		const newData = [...dataBanner];

		newData[index].name = data;
		setDataBanner(newData);
		const currentFormikData = [...formik.values.banners];
		currentFormikData[index].name = data;
		formik.setFieldValue("banners", currentFormikData);
	};

	const handleChangeDesc = (data, index) => {
		const newData = [...dataBanner];
		newData[index].description = data;
		setDataBanner(newData);

		const currentFormikData = [...formik.values.banners];
		currentFormikData[index].description = data;
		formik.setFieldValue("banners", currentFormikData);
	};

	const handleAddNewItem = () => {
		const newData = [...dataBanner];
		newData.push({
			id: `banner-${newData.length + 1}`,
			isExpand: true,
			name: "",
			image_desktop: null,
			image_mobile: null,
			redirect_url: "",
			description: JSON.stringify(DEFAULT_EDITOR_EMPTY_VALUE),
		});
		setDataBanner(newData);

		const currentFormikData = [...formik.values.banners];
		currentFormikData.push({
			id: `banner-${newData.length + 1}`,
			isExpand: true,
			name: "",
			image_desktop: null,
			image_mobile: null,
			redirect_url: "",
			description: JSON.stringify(DEFAULT_EDITOR_EMPTY_VALUE),
		});
		formik.setFieldValue("banners", currentFormikData);
	};

	const handleDelete = index => {
		const newData = [...dataBanner];
		newData.splice(index, 1);
		newData[0].isExpand = true;
		setDataBanner(newData);

		const currentFormikData = [...formik.values.banners];
		currentFormikData.splice(index, 1);
		formik.setFieldValue("banners", currentFormikData);
	};

	const handleMoveOrder = (direction, index) => {
		const newData = [...dataBanner];
		const item = newData[index];
		newData.splice(index, 1);
		if (direction === "UP") {
			newData.splice(index - 1, 0, item);
		} else {
			newData.splice(index + 1, 0, item);
		}

		setDataBanner(newData);

		const currentFormikData = [...formik.values.banners];
		const itemFormik = currentFormikData[index];
		currentFormikData.splice(index, 1);
		if (direction === "UP") {
			currentFormikData.splice(index - 1, 0, itemFormik);
		} else {
			currentFormikData.splice(index + 1, 0, itemFormik);
		}

		formik.setFieldValue("banners", currentFormikData);
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="AMBASSADOR"
			desc="RUBI Ambassador">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<>
						<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
							<div className="flex items-center">
								<div className="text-2xl text-black font-semibold">RUBI Pilar</div>
								<div
									class={clsx(
										"text-base italic  ml-5",
										clsx(formik.values.is_visible ? "text-main-green" : "text-red-50"),
									)}>
									{formik.values.is_visible ? "Appear" : "Hide"} From Ambassador Page
								</div>
							</div>
							<div className="flex flex-row-reverse items-center">
								<ToogleSwitchComponent
									inputClassName="toggle-checkbox"
									labelClassName="toggle enable"
									onChange={formik.handleChange}
									name="is_visible"
									checked={formik.values.is_visible}
								/>
							</div>
						</div>

						<InputComponent
							containerClass="mb-4 px-6"
							labelclassName="text-sm text-black font-semibold mb-3"
							label="Section Title"
							placeholder="insert title"
							name="title"
							value={formik.values.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.title && formik.errors.title}
						/>

						<div className="px-6">
							<div className="text-sm text-black font-semibold mb-3">Rubi Pilar Items</div>
							<div className="divide-y divide-solid divide-grey-10 mb-3" />
							{dataBanner.map((bannerItem, index) => (
								<SliderBenefitComponent
									bannerTitle={{
										desktop: "Desktop Banner (Aspect Ration 16:4)",
										mobile: "Mobile Banner (Aspect Ration 16:8)",
									}}
									data={bannerItem}
									key={index}
									title={`Item ${index + 1}`}
									expand={bannerItem.isExpand}
									onExpand={() => handleExpand(index)}
									errors={formik.errors.banners && formik.errors.banners[index]}
									touched={formik.touched.banners && formik.touched.banners[index]}
									handleOnBlur={e =>
										formik.handleBlur({
											target: {
												name: `banners[${index}].${e.target.name}`,
											},
										})
									}
									onDelete={() => handleDelete(index)}
									onChangeName={e => handleChangeName(e.target.value, index)}
									onChange={data => handleChangeImage(bannerItem.id, data, index)}
									onChangeDesc={data => handleChangeDesc(data, index)}
									disabledDelete={dataBanner.length === 1}
									disabledUpButton={index === 0}
									disabledDownButton={index === dataBanner.length - 1}
									onMove={direction => handleMoveOrder(direction, index)}
								/>
							))}
						</div>

						<button
							onClick={handleAddNewItem}
							className="flex items-center py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
							type="button">
							<span className="icon-ico-circle-plus text-red-50 text-xl"></span>
							<span className="pl-2">Add New Item</span>
						</button>

						<div className="flex justify-center my-9">
							<div className="w-2/6">
								<ButtonSubmit
									onClick={formik.handleSubmit}
									disabled={!RubiPilarValidationSchema.isValidSync(formik.values)}
									loading={isUploading || isUpdating}
									title="Save All Changes"
									className="mt-5 w-full block py-2.5 px-7 
									disabled:opacity-50
									disabled:cursor-not-allowed
									border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
								/>
							</div>
						</div>
					</>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
