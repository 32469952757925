import toast from "react-hot-toast";
import dayjs from "dayjs";
import dayjsPluginDuration from "dayjs/plugin/duration";
import { useRef, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Layout from "components/layout";
import TextEditor from "components/text-editor";
import { CreateEventSchema } from "config/form/schema/event";
import { useUploadImage } from "hooks/upload";
import { useAddNewEvent, useEventCategories } from "hooks/event";
import { ButtonSubmit } from "components/button/submit.button";
import { InputComponent } from "components/form/input.component";
import { ModalResult } from "components/modal/modal.result";

import { UploadBannerComponent } from "../page-settings/upload-banner.component";

dayjs.extend(dayjsPluginDuration);

const formInitialValues = {
	title: "",
	image_desktop: "",
	image_mobile: "",
	event_category_id: "",
	event_date: "",
	description: "",
	redirect_url: "",
	is_published: "",
	status: "",
};

export function CreateEvent() {
	const formikRef = useRef();
	const navigate = useNavigate();

	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();
	const { mutate: createNewEvent, isLoading: isSubmitting } = useAddNewEvent();
	const { data: eventCategories } = useEventCategories();

	const [showModalResult, setShowModalResult] = useState(false);
	const [imageFileBuffer, setImageFileBuffer] = useState(null);

	const handleSubmitData = async payloadData => {
		try {
			if (imageFileBuffer) {
				const formData = new FormData();
				formData.append("image", imageFileBuffer);
				formData.append("type", "event");

				const { data: uploadImageResult } = await uploadImageAsync({ formData });

				payloadData.image_desktop = uploadImageResult.image_desktop;
				payloadData.image_mobile = uploadImageResult.image_mobile;
			}

			createNewEvent(payloadData, {
				onSuccess: () => {
					setShowModalResult(true);
					setImageFileBuffer(null);
				},
			});
		} catch (error) {
			toast.error(error?.message || error.response?.data?.message || "Something went wrong");
		}
	};

	const handleSubmitForm = eventStatus => {
		formikRef.current.setFieldValue("status", eventStatus);
		formikRef.current.handleSubmit();
	};

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-5"
			breadCumbTitle="Event"
			breadCumbDesc="Create Event">
			<div className="text-title text-black font-semibold">Create Event</div>
			<hr className="border-grey-10 mt-3 mb-7" />

			<Formik
				validationSchema={CreateEventSchema}
				innerRef={formikRef}
				initialValues={formInitialValues}
				onSubmit={handleSubmitData}>
				{({ values, handleBlur, handleChange, touched, errors, handleSubmit, setFieldValue }) => {
					return (
						<>
							<div className="mb-4">
								<div className="text-sm text-black font-semibold">Event Title</div>
								<div className="w-full">
									<input
										name="title"
										value={values.title}
										onChange={handleChange}
										onBlur={handleBlur}
										type="text"
										className="border-grey-10 outline-none w-full border-l-0 border-t-0 border-r-0 border-b text-2xl border-solid px-4 py-3 text-red-50 transition-all focus:bg-white focus:border-t-0 focus:border-r-0 focus:border-l-0 hover:border-t-0 hover:border-r-0 hover:border-l-0 focus:text-red-50 focus:border-red-50 focus:outline-none focus:shadow-none"
										placeholder="Write event title here"
									/>
									{errors.title && touched.title && (
										<p className="form-input-error">{errors.title}</p>
									)}
								</div>
							</div>
							<div className="flex justify-between">
								<div className="w-[48%]">
									<UploadBannerComponent
										imageClassName="w-full h-96 object-cover object-center"
										name="image_desktop"
										onChange={imageFileBuffer => {
											setImageFileBuffer(imageFileBuffer["image_desktop"]);
											formikRef.current.setFieldValue(
												"image_buffer",
												imageFileBuffer["image_desktop"],
											);
										}}
										title="Thumbnail"
									/>
								</div>
								<div className="w-[48%]">
									<div className="mb-4">
										<div className="text-sm text-black font-semibold mb-3">Category</div>
										<div className="relative">
											<select
												defaultValue="0"
												name="event_category_id"
												value={values.event_category_id}
												onChange={handleChange}
												onBlur={handleBlur}
												className="appearance-none border-grey-10 italic w-full border outline-none placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg focus:bg-white  transition-all focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
												aria-label="Default select example">
												<option value="">Select Category</option>
												{eventCategories?.data?.map(item => (
													<option value={item.id}>{item.name}</option>
												))}
											</select>

											<div className="absolute top-3 right-3">
												<span className="icon-ico-down"></span>
											</div>
										</div>
										{touched.event_category_id && errors.event_category_id && (
											<p className="form-input-error">{errors.event_category_id}</p>
										)}
									</div>
									<div className="mb-4">
										<InputComponent
											label="Event Date"
											type="date"
											onChange={handleChange}
											onBlur={handleBlur}
											name="event_date"
											error={touched.event_date && errors.event_date}
											min={new Date().toISOString().split("T")[0]}
										/>
									</div>

									<div className="mb-4">
										<InputComponent
											label="Link / URL"
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Contoh: https://avo.co.id/event/product-launching"
											error={touched.redirect_url && errors.redirect_url}
											name="redirect_url"
										/>
									</div>
								</div>
							</div>
							<div className="mb-4">
								<div className="text-sm text-black font-semibold mb-3">Description</div>
								<TextEditor
									onChange={nodeValue => setFieldValue("description", nodeValue)}
									value={values.description && JSON.parse(values.description)}
								/>
							</div>
							<div className="flex items-center justify-center my-9">
								<div className="w-3/12 mx-1">
									<ButtonSubmit
										loading={isUploading || isSubmitting}
										disabled={!CreateEventSchema.isValidSync(values)}
										onClick={() => handleSubmitForm("draft")}
										className="flex items-center justify-center w-full px-3 py-2 font-semibold text-black transition-all border border-solid rounded-lg disabled:opacity-50 border-grey-10 hover:bg-red-hover hover:border-red-50"
										type="button">
										<span className="icon-ico-sort text-red-50 text-xl"></span>
										<span className="pl-3">Save as Draft</span>
									</ButtonSubmit>
								</div>
								<div className="w-3/12 mx-1">
									<ButtonSubmit
										disabled={!CreateEventSchema.isValidSync(values)}
										loading={isUploading || isSubmitting}
										onClick={() => handleSubmitForm("admin published")}
										className="disabled:opacity-50 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										type="button">
										<span className="icon-ico-save mr-2"></span> Create Event
									</ButtonSubmit>
								</div>
							</div>
						</>
					);
				}}
			</Formik>

			<ModalResult
				visible={showModalResult}
				title={false}
				onClose={() => navigate("/event/list")}
				message="Create new event successfully!"
			/>
		</Layout>
	);
}
