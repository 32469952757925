import React from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

export const Button = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
	<span
		{...props}
		ref={ref}
		className={clsx(className, "cursor-pointer hover:text-black", active ? "text-black" : "text-grey-30")}
	/>
));

export const EditorValue = React.forwardRef(({ className, value, ...props }, ref) => {
	const textLines = value.document.nodes
		.map(node => node.text)
		.toArray()
		.join("\n");
	return (
		<div ref={ref} {...props} className={className}>
			<div>Slate's value as text</div>
			<div>{textLines}</div>
		</div>
	);
});

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
	<span {...props} ref={ref} className={clsx("material-icons px-1", className)} />
));

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
	<div {...props} ref={ref} className={clsx(className)} />
));

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
	<div {...props} ref={ref} className={clsx(className, "flex justify-start ")} />
));

export const Portal = ({ children }) => {
	return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
	<Menu {...props} ref={ref} className={clsx(className, "border-b-2 border-grey-30 rounded-sm mb-4")} />
));
