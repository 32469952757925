// Auth
import { LoginPage } from "pages/auth/login";
import { ForgotPasswordPage } from "pages/auth/forgot-password";

// Registration
import { RegistrationUserPage } from "pages/registration/users";
import { RegistrationSettingPage } from "pages/registration/settings";
import { TestSettingPage } from "pages/registration/test";

// Page Settings
import { SliderBannerSetting } from "pages/page-settings/home/slider-banner";
import { RubiTaskSetting } from "pages/page-settings/home/rubi-taks";
import { RubiSafeSpaceSetting } from "pages/page-settings/home/rubi-safe-space";
import { RunningTextSetting } from "pages/page-settings/home/running-text";
import { AboutRubiSettingHome } from "pages/page-settings/home/about-rubi";
import { EventSetting } from "pages/page-settings/home/events";
import { ArticleSetting } from "pages/page-settings/home/article";
import { AboutRubiSetting } from "pages/page-settings/about/about-rubi";
import { FeatureSetting } from "pages/page-settings/about/feature";
import { IntroRubiSettingPage } from "pages/page-settings/about/intro-rubi";
import { CommunityAmbassadorSetting } from "pages/page-settings/about/community-ambassador";
import { FullBannerSetting } from "pages/page-settings/visi-misi/full-banner";
import { VisiSetting } from "pages/page-settings/visi-misi/visi";
import { MisiSetting } from "pages/page-settings/visi-misi/misi";
import { AmbassadorCommunitySetting } from "pages/page-settings/rubi-ambassador/community";
import { FullBannerAmbassadorSetting } from "pages/page-settings/rubi-ambassador/full-banner";
import { WhatWeDoSetting } from "pages/page-settings/rubi-ambassador/what-we-do";
import { BecomeInfluencerSetting } from "pages/page-settings/rubi-ambassador/become-influencer";
import { WeAreRubiCommunitySettingPage } from "pages/page-settings/rubi-ambassador/we-are-rubi-community";
import { JoinUsSetting } from "pages/page-settings/rubi-ambassador/join-us";
import { BenefitSettings } from "pages/page-settings/about/benefits";

// Event
import { EventListPage } from "pages/event/list-event";
import { CreateEvent } from "pages/event/create-event";
import { EditEvent } from "pages/event/edit-event";

// General Settings
import { BeautyProfileSetting } from "pages/general-settings/beauty-profile";
import { LevelBatchGeneralSettingPage } from "pages/general-settings/batch-level";
import { UserLevelPointGeneralSettingPage } from "pages/general-settings/user-level-point";
import { GeneralSettingLogo } from "pages/general-settings/footer/logo";
import { SocialMediaFooter } from "pages/general-settings/footer/social-media";
// Activity
import { CreateActivityPage } from "pages/activity/create-activity";
import { EditActivityPage } from "pages/activity/create-activity/edit";
import { ActivityListPage } from "pages/activity/list-activity";
import { ActivityQuizPage } from "pages/activity/activity-quiz";
import { ActivityDetailsPage } from "pages/activity/activity-details";

// Members
import { UserListPage } from "pages/members/members-list";
// Member Detail
import { MemberProfilePage } from "pages/members/member-profile/subpage/profile";
import { MemberBeautyProfilePage } from "pages/members/member-profile/subpage/beauty-profile";
import { MemberPointHistoryy } from "pages/members/member-profile/subpage/point-history";
import { MemberActivityHistory } from "pages/members/member-profile/subpage/activity-history";
import { MemberAccountPage } from "pages/members/member-profile/subpage/account";

// Discussion
import { DiscussionCategoryPage } from "pages/discussion/category";
import { DiscussionCreateTopicPage } from "pages/discussion/create-topic";
import { DiscussionEditTopicPage } from "pages/discussion/edit-topic";
import { DiscussionListPage } from "pages/discussion/discussion-list";
import { DiscussionDetails } from "pages/discussion/details";
import { ItemRewardListPage } from "pages/reward/item-reward";
import { RewardListPage } from "pages/reward/reward-list";
import { CreateRewardPage } from "pages/reward/create-reward";
import { EditRewardPage } from "pages/reward/edit-reward";
import { ClaimRewardListPage } from "pages/reward/claim-reward";
import { GalleryPreviewSetting } from "pages/page-settings/about/gallery-preview";
import { PopupBannerSetting } from "pages/page-settings/banner/popup-banner";
import { GallerySetting } from "pages/page-settings/gallery/gallery-setting";
import { CreateGallery } from "pages/page-settings/gallery/create-gallery";
import { EditGallery } from "pages/page-settings/gallery/edit-gallery";
import { BenefitAmbassador } from "pages/page-settings/rubi-ambassador/ambassador-benefit";
import { RubiPilarAmbassador } from "pages/page-settings/rubi-ambassador/rubi-pilar";

export const appRoutes = [
	{
		id: "auth-login",
		path: "/auth/login",
		exact: true,
		isPrivate: false,
		component: <LoginPage />,
	},
	{
		id: "auth-forgot-password",
		path: "/auth/forgot-password",
		exact: true,
		isPrivate: false,
		component: <ForgotPasswordPage />,
	},
	{
		id: "registration-user",
		path: "/registration/users",
		exact: true,
		isPrivate: true,
		component: <RegistrationUserPage />,
	},
	{
		id: "registration-setting",
		path: "/registration/setting",
		exact: true,
		isPrivate: true,
		component: <RegistrationSettingPage />,
	},
	{
		id: "registration-setting",
		path: "/registration/test",
		exact: true,
		isPrivate: true,
		component: <TestSettingPage />,
	},
	{
		id: "create-activity",
		path: "/activity/create",
		exact: true,
		isPrivate: true,
		component: <CreateActivityPage />,
	},
	{
		id: "edit-activity",
		path: "/activity/list/edit/:activityId*",
		exact: true,
		component: <EditActivityPage />,
	},
	{
		id: "list-activity",
		path: "/activity/list",
		exact: true,
		isPrivate: true,
		component: <ActivityListPage />,
	},
	{
		id: "activity-details",
		path: "/activity/list/details/:activityId*",
		exact: true,
		isPrivate: true,
		component: <ActivityDetailsPage />,
	},
	{
		id: "list-activity-create-quiz",
		path: "/activity/create-quiz/:activityId*",
		exact: true,
		isPrivate: true,
		component: <ActivityQuizPage />,
	},
	{
		id: "list-users",
		path: "/member",
		exact: true,
		isPrivate: true,
		component: <UserListPage />,
	},
	{
		id: "list-users",
		path: "/member/profile-details/:userStatus/:userId",
		exact: true,
		isPrivate: true,
		component: <MemberProfilePage />,
	},
	{
		id: "member-details-beauty-profile",
		path: "/member/beauty-profile/:userStatus/:userId",
		exact: true,
		isPrivate: true,
		component: <MemberBeautyProfilePage />,
	},
	{
		id: "member-details-point-history",
		path: "/member/point-history/:userStatus/:userId",
		exact: true,
		isPrivate: true,
		component: <MemberPointHistoryy />,
	},
	{
		id: "member-details-activity-history",
		path: "/member/activity-history/:userStatus/:userId",
		exact: true,
		isPrivate: true,
		component: <MemberActivityHistory />,
	},
	{
		id: "member-details-account",
		path: "/member/account/:userStatus/:userId",
		exact: true,
		isPrivate: true,
		component: <MemberAccountPage />,
	},
	{
		id: "page-setting-home-slider-banner",
		path: "/page-setting/home/slider-banner",
		exact: true,
		isPrivate: true,
		component: <SliderBannerSetting />,
	},
	{
		id: "page-setting-home-rubi-task",
		path: "/page-setting/home/rubi-task",
		exact: true,
		isPrivate: true,
		component: <RubiTaskSetting />,
	},
	{
		id: "page-setting-home-rubi-safespace",
		path: "/page-setting/home/rubi-safespace",
		exact: true,
		isPrivate: true,
		component: <RubiSafeSpaceSetting />,
	},
	{
		id: "page-setting-home-running-text",
		path: "/page-setting/home/running-text",
		exact: true,
		isPrivate: true,
		component: <RunningTextSetting />,
	},
	{
		id: "page-setting-home-about-rubi",
		path: "/page-setting/home/about-rubi",
		exact: true,
		isPrivate: true,
		component: <AboutRubiSettingHome />,
	},
	{
		id: "page-setting-home-events",
		path: "/page-setting/home/events",
		exact: true,
		isPrivate: true,
		component: <EventSetting />,
	},
	{
		id: "page-setting-home-article",
		path: "/page-setting/home/article",
		exact: true,
		isPrivate: true,
		component: <ArticleSetting />,
	},
	{
		id: "page-setting-about-rubi-about",
		path: "/page-setting/about/rubi-about",
		exact: true,
		isPrivate: true,
		component: <AboutRubiSetting />,
	},
	{
		id: "page-setting-about-intro-rubi",
		path: "/page-setting/about/intro-rubi",
		exact: true,
		isPrivate: true,
		component: <IntroRubiSettingPage />,
	},
	{
		id: "page-setting-about-feature",
		path: "/page-setting/about/feature",
		exact: true,
		isPrivate: true,
		component: <FeatureSetting />,
	},
	{
		id: "page-setting-about-benefit",
		path: "/page-setting/about/benefit",
		exact: true,
		isPrivate: true,
		component: <BenefitSettings />,
	},
	{
		id: "page-setting-about-gallery",
		path: "/page-setting/about/gallery-preview",
		exact: true,
		isPrivate: true,
		component: <GalleryPreviewSetting />,
	},
	{
		id: "page-setting-banner-popup-banner",
		path: "/page-setting/banner/popup-banner",
		exact: true,
		isPrivate: true,
		component: <PopupBannerSetting />,
	},
	{
		id: "page-setting-gallery",
		path: "/page-setting/gallery",
		exact: true,
		isPrivate: true,
		component: <GallerySetting />,
	},
	{
		id: "page-setting-create-gallery",
		path: "/page-setting/gallery/create",
		exact: true,
		isPrivate: true,
		component: <CreateGallery />,
	},
	{
		id: "page-setting-edit-gallery",
		path: "/page-setting/gallery/edit/:galleryId*",
		exact: true,
		isPrivate: true,
		component: <EditGallery />,
	},
	{
		id: "page-setting-about-community-ambassador",
		path: "/page-setting/about/community-ambassador",
		exact: true,
		isPrivate: true,
		component: <CommunityAmbassadorSetting />,
	},
	{
		id: "page-setting-visi-misi-full-banner",
		path: "/page-setting/visi-misi/full-banner",
		exact: true,
		isPrivate: true,
		component: <FullBannerSetting />,
	},
	{
		id: "page-setting-visi-misi-misi",
		path: "/page-setting/visi-misi/misi",
		exact: true,
		isPrivate: true,
		component: <MisiSetting />,
	},
	{
		id: "page-setting-visi-misi-visi",
		path: "/page-setting/visi-misi/visi",
		exact: true,
		isPrivate: true,
		component: <VisiSetting />,
	},
	{
		id: "page-setting-comm-ambassador",
		path: "/page-setting/ambassador/community",
		exact: true,
		isPrivate: true,
		component: <AmbassadorCommunitySetting />,
	},
	{
		id: "page-setting-comm-ambassador",
		path: "/page-setting/ambassador/full-banner",
		exact: true,
		isPrivate: true,
		component: <FullBannerAmbassadorSetting />,
	},
	{
		id: "page-setting-ambassador-benefit",
		path: "/page-setting/ambassador/benefits",
		exact: true,
		isPrivate: true,
		component: <BenefitAmbassador />,
	},
	{
		id: "page-setting-ambassador-rubi-pilar",
		path: "/page-setting/ambassador/rubi-pilar",
		exact: true,
		isPrivate: true,
		component: <RubiPilarAmbassador />,
	},
	{
		id: "page-setting-comm-ambassador",
		path: "/page-setting/ambassador/what-we-do",
		exact: true,
		isPrivate: true,
		component: <WhatWeDoSetting />,
	},
	{
		id: "page-setting-comm-ambassador",
		path: "/page-setting/ambassador/influencer",
		exact: true,
		isPrivate: true,
		component: <BecomeInfluencerSetting />,
	},
	{
		id: "page-setting-we-are-rubi-community",
		path: "/page-setting/ambassador/we-are-rubi",
		exact: true,
		isPrivate: true,
		component: <WeAreRubiCommunitySettingPage />,
	},
	{
		id: "page-setting-comm-ambassador",
		path: "/page-setting/ambassador/join-us",
		exact: true,
		isPrivate: true,
		component: <JoinUsSetting />,
	},
	{
		id: "event-list",
		path: "/event/list",
		exact: true,
		isPrivate: true,
		component: <EventListPage />,
	},
	{
		id: "event-create",
		path: "/event/create",
		exact: true,
		isPrivate: true,
		component: <CreateEvent />,
	},
	{
		id: "event-edit",
		path: "/event/edit/:eventId*",
		exact: true,
		isPrivate: true,
		component: <EditEvent />,
	},
	{
		id: "general-setting-beauty-profile",
		path: "/general-setting/beauty-profile",
		exact: true,
		isPrivate: true,
		component: <BeautyProfileSetting />,
	},
	{
		id: "general-setting-level-batch",
		path: "/general-setting/level-batch",
		exact: true,
		isPrivate: true,
		component: <LevelBatchGeneralSettingPage />,
	},
	{
		id: "general-setting-user-level-point",
		path: "/general-setting/user-level-point",
		exact: true,
		isPrivate: true,
		component: <UserLevelPointGeneralSettingPage />,
	},
	{
		id: "general-settting-footer-logo",
		path: "/footer/logo",
		exact: true,
		isPrivate: true,
		component: <GeneralSettingLogo />,
	},
	{
		id: "general-settting-footer-social-media",
		path: "/footer/social-media",
		exact: true,
		isPrivate: true,
		component: <SocialMediaFooter />,
	},
	{
		id: "discussion-category",
		path: "/discussion/category",
		exact: true,
		isPrivate: true,
		component: <DiscussionCategoryPage />,
	},

	{
		id: "discussion-list",
		path: "/discussion/list",
		exact: true,
		isPrivate: true,
		component: <DiscussionListPage />,
	},
	{
		id: "discussion-details",
		path: "/discussion/list/details/:topicId",
		exact: true,
		isPrivate: true,
		component: <DiscussionDetails />,
	},
	{
		id: "discussion-create-topic",
		path: "/discussion/create-topic",
		exact: true,
		isPrivate: true,
		component: <DiscussionCreateTopicPage />,
	},
	{
		id: "discussion-edit-topic",
		path: "/discussion/list/edit-topic/:topicId*",
		exact: true,
		isPrivate: true,
		component: <DiscussionEditTopicPage />,
	},
	{
		id: "item-reward-list",
		path: "/reward/item-reward",
		exact: true,
		isPrivate: true,
		component: <ItemRewardListPage />,
	},
	{
		id: "reward-list",
		path: "/reward/list",
		exact: true,
		isPrivate: true,
		component: <RewardListPage />,
	},
	{
		id: "claim-reward-list",
		path: "/reward/claim",
		exact: true,
		isPrivate: true,
		component: <ClaimRewardListPage />,
	},
	{
		id: "create-reward",
		path: "/reward/list/create",
		exact: true,
		isPrivate: true,
		component: <CreateRewardPage />,
	},
	{
		id: "edit-reward",
		path: "/reward/list/edit/:rewardId*",
		exact: true,
		isPrivate: true,
		component: <EditRewardPage />,
	},
	{
		id: "home",
		path: "",
		exact: true,
		isPrivate: true,
		component: null,
	},
];
