import { useEffect, useRef } from "react";
import { FieldArray, Formik } from "formik";
import { ButtonUpload } from "components/button/upload.button";
import { InputComponent } from "components/form/input.component";

import { MultipleLinkValidationSchema } from "config/form/schema/activity/create-activity";

const formInitialValues = {
	redirect_urls: [
		{
			title: "",
			url: "",
		},
	],
};
export function InsertLinks({ onChange, initialValues = [] }) {
	const formikRef = useRef();
	const handleFormChange = () => {
		setTimeout(() => {
			onChange(formikRef.current.values.redirect_urls);
		}, 500);
	};

	useEffect(() => {
		if (initialValues && initialValues.length) formikRef.current.setFieldValue("redirect_urls", initialValues);
	}, [initialValues]);

	return (
		<Formik innerRef={formikRef} initialValues={formInitialValues} validationSchema={MultipleLinkValidationSchema}>
			{({ values, handleChange, handleBlur, errors, touched }) => (
				<FieldArray
					name="redirect_urls"
					render={arrayHelpers => (
						<>
							{values.redirect_urls.map((item, index) => (
								<div className="rounded-lg border border-solid border-grey-10 p-4 mb-4">
									<InputComponent
										name={`redirect_urls.${index}.title`}
										onChange={e => {
											handleChange(e);
											handleFormChange();
										}}
										onBlur={e => {
											handleBlur(e);
											handleFormChange();
										}}
										error={
											touched.redirect_urls?.[index]?.title &&
											errors.redirect_urls?.[index]?.title
										}
										value={item.title}
										label="Title Link"
										placeholder="Insert Title"
									/>
									<InputComponent
										name={`redirect_urls.${index}.url`}
										onChange={e => {
											handleChange(e);
											handleFormChange();
										}}
										onBlur={e => {
											handleBlur(e);
											handleFormChange();
										}}
										error={
											touched.redirect_urls?.[index]?.url && errors.redirect_urls?.[index]?.url
										}
										label="URL / Link File"
										value={item.url}
										placeholder="URL: Zoom, Google Meet"
									/>
								</div>
							))}
							<div className="w-48">
								<ButtonUpload
									onClick={() =>
										arrayHelpers.push({
											title: "",
											url: "",
										})
									}>
									<span className="icon-ico-circle-plus text-red-50 mr-1 text-xl"></span>
									<span className="pl-2">Tambah Link</span>
								</ButtonUpload>
							</div>
						</>
					)}
				/>
			)}
		</Formik>
	);
}
