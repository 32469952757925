import { serializeEvent } from "components/text-editor/serializer";
import { IsJsonString } from "utils/string";

export function EventArtikelItem({
	image,
	title,
	imageClassName = " object-cover object-center rounded-lg h-48  w-36",
	description,
	published,
	onMove,
	date,
	type,
	buttonPublishTitle = "Move To Unpublished",
	buttonUnpublishTitle = "Publish to Home Page",
	disabled = false,
}) {
	return (
		<div className="border border-solid border-grey-30 flex  items-center rounded-lg mb-4 py-3 px-3">
			<img src={image} className={imageClassName} alt="Item" />
			<div className="pl-3 ">
				<div className="text-xs font-bold text-red-50 italic mb-2">{type}</div>
				<div className=" text-md font-semibold text-black mb-2">{title}</div>
				{IsJsonString(description) ? (
					serializeEvent(
						JSON.parse(description),
						{
							paragraph: "font-medium w-12/12  text-sm text-grey-60 mb-2 line-clamp-1",
						},
						{ paragraph: 29 },
					)
				) : (
					<p className="text-sm text-grey-50 line-clamp-2 mb-3">{description}</p>
				)}

				<div className="whitespace-nowrap text-ellipsis  italic text-xs text-grey-60 mb-2">{date}</div>
				{published ? (
					<button
						disabled={disabled}
						onClick={onMove}
						className="disabled:opacity-50 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span className="icon-ico-archive text-red-50 text-xl"></span>
						<span className="pl-2 text-ellipsis whitespace-nowrap ">{buttonPublishTitle}</span>
					</button>
				) : (
					<button
						disabled={disabled}
						onClick={onMove}
						className="disabled:opacity-50  flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span className="icon-ico-plane text-red-50 text-xl"></span>
						<span className="pl-2 text-ellipsis whitespace-nowrap ">{buttonUnpublishTitle}</span>
					</button>
				)}
			</div>
		</div>
	);
}
