import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useUploadFile } from "hooks/upload";
import { ButtonUpload } from "components/button/upload.button";
import { loadStaticPublicAsset } from "utils/loader";

export function UploadMultipeFiles({ containerClassName = "mb-4", onChange, initialValues = [], id = "upload_file" }) {
	const [files, setFiles] = useState([]);
	const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile();

	const handleUploadFile = async e => {
		try {
			const file = e.target.files[0];
			// if file size more than 70mb
			if (file.size > 70 * 1024 * 1024) {
				toast.error("File size must be less than 70mb");
				return;
			}

			if (/\.(pdf)$/i.test(file.name)) {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("type", "class_material");

				const { data: uploadFileResult } = await uploadFile({ formData });
				const newFilesData = [...files, { url: uploadFileResult.file_url, fileName: file.name }];
				setFiles(newFilesData);
				onChange(newFilesData);
			} else {
				toast.error("File type is not supported, only pdf is allowed");
			}
		} catch (error) {
			toast.error(error.message || "Something went wrong");
		}
	};

	const handleDeleteFile = index => {
		const newFilesData = [...files.filter((_, i) => i !== index)];
		setFiles(newFilesData);
		onChange(newFilesData);
	};

	const handleOpenFile = file => {
		window.open(file.url, "_blank");
	};

	useEffect(() => {
		if (initialValues && initialValues.length) {
			setFiles(initialValues);
		}
	}, [initialValues]);

	return (
		<div class={containerClassName}>
			<div className="text-sm text-black font-semibold mb-3">Upload File</div>
			<div className="rounded-lg border border-solid border-grey-10 p-4">
				{files.length === 0 && <p className="mb-6 text-main-grey">Tidak ada file untuk materi kelas ini</p>}
				{files.map((file, index) => (
					<div className="flex items-center justify-between mb-3">
						<div className="overflow-hidden w-[90%] pr-3">
							<div className="flex items-center">
								<span className="icon-ico-file text-2xl mr-2"></span>
								<div className="overflow-hidden text-ellipsis text-sm whitespace-nowrap text-main-grey font-semibold w-[90%]">
									{file?.fileName}
								</div>
							</div>
						</div>
						<button
							disabled={isUploading}
							onClick={() => handleDeleteFile(index)}
							className="disabled:opacity-50 flex justify-center items-center w-10 h-10 border text-sm border-solid border-grey-10 rounded-lg text-red-50 hover:bg-red-hover hover:border-red-60 transition-all font-semibold">
							<span className="icon-ico-delete text-lg"></span>
						</button>
						<button
							disabled={isUploading}
							onClick={() => handleOpenFile(file)}
							className="disabled:opacity-50 ml-2 flex justify-center items-center w-10 h-10 border text-sm border-solid border-grey-10 rounded-lg text-red-50 hover:bg-red-hover hover:border-red-60 transition-all font-semibold">
							<img
								className="w-6 h-6"
								src={loadStaticPublicAsset("/img/icons/external-link.svg")}
								alt=""
							/>
						</button>
					</div>
				))}

				<div className="flex">
					<input type="file" className="hidden" id={id} onChange={handleUploadFile} />
					<ButtonUpload htmlFor={id} loading={isUploading}>
						<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
						<span className="pl-2">Upload</span>
					</ButtonUpload>
				</div>
			</div>
		</div>
	);
}
