import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";
import { Formik } from "formik";

import TextEditor from "components/text-editor";
import { usePageSettingsData, useUpdatePageSettingsData } from "hooks/page-settings";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { ToogleSwitchComponent } from "components/form/toogle-switch.component";

import { PageSettingLayout } from "../layout";
import { IsJsonString } from "utils/string";

const formikInitialValues = {
	is_visible: false,
	description: undefined,
};

export function RunningTextSetting() {
	const formikRef = useRef();

	const {
		data,
		isLoading,
		refetch: refetchPageSettingData,
	} = usePageSettingsData({ page: "home", slug: "running-text" });
	const { mutate: updatePageSetting, isLoading: isUpdating } = useUpdatePageSettingsData();

	const [showModalResult, setShowModalResult] = useState(false);

	useEffect(() => {
		Object.keys(formikInitialValues).forEach(key => {
			if (data?.data?.[key]) {
				formikRef.current.setFieldValue(key, data?.data?.[key]);
			}
		});
	}, [data]);

	const handleSubmitForm = async payloadData => {
		updatePageSetting(
			{ page: "home", slug: "running-text", formData: payloadData },
			{
				onSuccess: () => {
					setShowModalResult(true);
					refetchPageSettingData();
				},
			},
		);
	};
	return (
		<PageSettingLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="Page Setting"
			sidebarMenu="HOME"
			desc="Home Page">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<Formik innerRef={formikRef} initialValues={formikInitialValues} onSubmit={handleSubmitForm}>
						{({ values, handleChange, handleSubmit, setFieldValue }) => (
							<>
								<div className="flex justify-between py-3 px-6 items-center rounded-lg border border-solid border-grey-10 mb-6 shadow-md">
									<div className="flex items-center">
										<div className="text-2xl text-black font-semibold">Running Text</div>
										<div
											class={clsx(
												"text-base italic  ml-5",
												clsx(values.is_visible ? "text-main-green" : "text-red-50"),
											)}>
											{values.is_visible ? "Appear" : "Hide"} From Home Page
										</div>
									</div>
									<div className="flex flex-row-reverse items-center">
										<ToogleSwitchComponent
											inputClassName="toggle-checkbox"
											labelClassName="toggle enable"
											onChange={handleChange}
											name="is_visible"
											checked={values.is_visible}
										/>
									</div>
								</div>

								<div className="mb-4 px-6">
									<div className="text-sm text-black font-semibold mb-3">Description</div>
									<TextEditor
										initialValue={
											IsJsonString(data?.data?.description) ? data?.data?.description : undefined
										}
										onChange={nodeValue => setFieldValue("description", nodeValue)}
									/>
								</div>

								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											onClick={handleSubmit}
											loading={isUpdating}
											title="Save All Changes"
											className="mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						)}
					</Formik>
				)}
			</div>

			<ModalResult visible={showModalResult} title={false} onClose={() => setShowModalResult(false)} />
		</PageSettingLayout>
	);
}
