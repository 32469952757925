import clsx from "clsx";
import { useEffect, useState } from "react";

import { ModalContainer } from "components/modal/modal.container";

export function ButtonEventCategoryFilter({ disabled, onClick, active }) {
	return (
		<button
			onClick={onClick}
			disabled={disabled}
			className="flex items-center px-3 py-2 font-semibold text-black transition-all border border-solid rounded-lg border-grey-10 hover:bg-red-hover hover:border-red-50"
			type="button">
			<span className="icon-ico-filter text-red-50 text-xl"></span>
			<span className="pl-1">Filter</span>
			{active && <span className="icon-ico-circle-check text-red-50 ml-2"></span>}
		</button>
	);
}

const categoryOptions = [
	{
		id: null,
		name: "All Categories",
	},
];

export function TableEventCategoryModal({
	visible,
	initialValue,
	onClose,
	onChange,
	title = "Filter by category",
	options = categoryOptions,
}) {
	const [selected, setSelected] = useState(initialValue || categoryOptions[0]);

	useEffect(() => {
		if (initialValue) {
			setSelected(initialValue);
		}
	}, [initialValue]);

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">{title}</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="content-scroll">
					{options.map(item => (
						<div className="popup-item mb-3" onClick={() => setSelected(item)} key={item.label}>
							<input type="radio" name="sort" className="hidden" checked={item.id === selected.id} />
							<label className="flex relative cursor-pointer justify-between text-grey-60 text-sm font-semibold items-center p-4 border border-solid border-grey-10 rounded-lg">
								<span>{item.name}</span>
							</label>
						</div>
					))}
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
