import { useState } from "react";

import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";

import { useGetLevelAndBatch, useUpgradeBatch, useUpgradeLevel } from "hooks/general-settings";
import clsx from "clsx";

function checkDisabledButton(currentLevel, level) {
	if (!currentLevel) return true;
	if (currentLevel === "graduate" && level === null) return false;
	if (currentLevel?.toLowerCase() === level?.toLowerCase()) {
		return false;
	}
	return true;
}

function getModalConfirmationMessage(type, data) {
	if (type === "UPGRADE_BATCH") return `Are you sure you want to upgrade current batch to next batch?`;
	return `Are you sure you want to upgrade from level ${data?.from} to ${data?.to}?`;
}

const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
};

export function LevelBatchGeneralSettingPage() {
	const [leveSettingItems] = useState([
		{
			id: "beginner-to-intermediate",
			from: "Beginner",
			fromClassName: "text-main-tosca bg-light-tosca",
			type: "UPGRADE_LEVEL",
			to: "Intermediate",
			toClassName: "text-main-purple bg-light-purple",
			desc: "Menaikkan 1 level dari Beginner ke Intermediate untuk seluruh peserta beginner saat ini",
			buttonText: "Upgrade To Intermediate",
		},
		{
			id: "intermediate-to-advance",
			from: "Intermediate",
			fromClassName: "text-main-purple bg-light-purple",
			type: "UPGRADE_LEVEL",
			to: "Advance",
			toClassName: "text-main-pink bg-light-pink",
			desc: "Menaikkan 1 level dari Intermediate ke Advace untuk seluruh peserta Intermediate saat ini",
			buttonText: "Upgrade To Advance",
		},
		{
			id: "advance-to-graduation",
			from: "Advance",
			fromClassName: "text-main-pink bg-light-pink",
			type: "UPGRADE_LEVEL",
			to: "Graduate",
			toClassName: "text-main-green bg-light-green",
			desc: "Menaikkan 1 level dari Advance ke Graduation untuk seluruh peserta Advance saat ini",
			buttonText: "Upgrade To Graduate",
		},
		{
			id: "upgrade-to-batch",
			from: null,
			fromClassName: null,
			type: "UPGRADE_BATCH",
			to: "Graduation",
			toClassName: "text-main-purple bg-light-purple",
			desc: "Menaikkan 1 batch untuk semua batch saat ini",
			buttonText: "Upgrade Batch",
		},
	]);

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	// Hooks API Call
	const { data: currentLevel, isLoading } = useGetLevelAndBatch();
	const { mutate: upgradeBatch, isLoading: isUpgradingBatch } = useUpgradeBatch();
	const { mutate: upgradeLevel, isLoading: isUpgradingLevel } = useUpgradeLevel();

	const handleUpdateLevelOrBatch = () => {
		if (modalConfirmation.type === "UPGRADE_BATCH") {
			upgradeBatch(
				{},
				{
					onSuccess: () => {
						setShowModalResult({
							showModal: true,
							message: "Successfully upgraded batch!",
						});
						setModalConfirmation(modalConfirmationInitialState);
					},
				},
			);
		} else {
			upgradeLevel(
				{ upgrade_to: modalConfirmation.data },
				{
					onSuccess: () => {
						setShowModalResult({
							showModal: true,
							message: "Successfully upgraded level!",
						});
						setModalConfirmation(modalConfirmationInitialState);
					},
				},
			);
		}
	};

	return (
		<Layout breadCumbTitle="General Setting" breadCumbDesc="Level & Batch">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="bg-white rounded-lg drop-shadow-main filter-none p-6">
					<div className="text-2xl font-semibold mb-6">Level &amp; Batch</div>

					{leveSettingItems.map(item => (
						<div className="rounded-lg flex items-center justify-between border border-solid border-grey-10 p-5 mb-7">
							<div className="w-1/2">
								<div className="flex items-center mb-3">
									{item.from ? (
										<>
											<div
												class={clsx(
													"rounded-md text-sm px-3 py-1 font-semibold ",
													item.fromClassName,
												)}>
												{item.from}
											</div>
											<div className="mx-2 text-red-50 text-lg">
												<span className="icon-ico-chev-right"></span>
											</div>
											<div
												class={clsx(
													"rounded-md text-sm px-3 py-1  font-semibold ",
													item.toClassName,
												)}>
												{item.to}
											</div>
										</>
									) : (
										<div className="tet-black text-base font-semibold">Upgrade Batch</div>
									)}
								</div>
								<p className="text-grey-60 text-sm">{item.desc}</p>
							</div>
							<div>
								<button
									disabled={checkDisabledButton(currentLevel?.data?.level, item.from)}
									onClick={() => {
										setModalConfirmation({
											showModal: true,
											data: item.type === "UPGRADE_LEVEL" ? item.to.toLowerCase() : null,
											type: item.type,
											message: getModalConfirmationMessage(item.type, item),
										});
									}}
									className="disabled:opacity-50 disabled:cursor-not-allowed flex w-60 justify-center items-center rounded-md py-2 px-3 border border-solid border-grey-10 hover:bg-light-red hover:border-red-50 transition-all">
									<img
										src="/img/icons/chevrons-up.svg"
										className="max-w-full"
										alt={item.buttonText}
									/>
									<span className="text-sm text-grey-70 font-semibold ml-2">{item.buttonText}</span>
								</button>
							</div>
						</div>
					))}
				</div>
			)}

			<ModalConfirmation
				buttons={[]}
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				loading={isUpgradingBatch || isUpgradingLevel}
				onClose={() => setModalConfirmation(modalConfirmationInitialState)}
				onReject={() => setModalConfirmation(modalConfirmationInitialState)}
				onApprove={handleUpdateLevelOrBatch}
			/>
			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
