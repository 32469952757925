import * as Yup from "yup";

export const ProfileValidationSchema = Yup.object()
	.shape({
		full_name: Yup.string()
			.required("Full name is required")
			.matches(/^[a-z ,.'-]+$/i, "Full name is not valid"),
		email: Yup.string().email("Email is not valid").required("Email is required"),
		phone_number: Yup.string()
			.required("Phone number is required")
			.min(11, "Phone number is not valid")
			.max(15, "Phone number is not valid"),
		city: Yup.object().required("City is required").nullable(),
		gender: Yup.string().required("Gender is required").nullable(),
		birth_date: Yup.string().required("Birth date is required"),
		instagram: Yup.string(),
		facebook: Yup.string(),
		twitter: Yup.string(),
		tiktok: Yup.string(),
		youtube: Yup.string(),
	})
	.test({
		name: "atLeastOneRequired",
		test: function (values) {
			const isValid = ["instagram", "facebook", "twitter", "tiktok", "youtube"].some(field => !!values[field]);

			if (isValid) return true;
			return this.createError({
				path: "instagram | facebook | twitter | tiktok | youtube",
				message: "One field must be set",
			});
		},
	});
