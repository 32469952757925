import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState, useRef } from "react";

import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalResult } from "components/modal/modal.result";
import { InputComponent } from "components/form/input.component";
import { UserLevelPointSchema } from "config/form/schema/general-setting/user-level-point";
import { useGetPointSetting, useUpdatePointSetting } from "hooks/general-settings";

const formInitialValues = {
	welcome_point: {
		beginner: "",
		intermediate: "",
		advance: "",
	},
	min_exam_access_point: {
		beginner: "",
		intermediate: "",
		advance: "",
	},
};
const bodyRequestStructureModel = [
	{
		id: 1,
		name: "beginner",
		welcome_point: null,
		min_exam_access_point: null,
	},
	{
		id: 2,
		name: "intermediate",
		welcome_point: null,
		min_exam_access_point: null,
	},
	{
		id: 3,
		name: "advance",
		welcome_point: null,
		min_exam_access_point: null,
	},
];
export function UserLevelPointGeneralSettingPage() {
	const formikRef = useRef(null);

	const [initialDataPersist, setInitialDataPersist] = useState(null);

	// Modal State
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		message: null,
		hiddenButtons: false,
	});
	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	// Hooks API Call
	const { data: currentPointSettings, isLoading } = useGetPointSetting();
	const { mutate: updatePointSeting, isLoading: isUpdating } = useUpdatePointSetting();

	const handleOnClickSubmit = () => {
		if (_.isEqual(initialDataPersist, formikRef.current.values)) {
			setModalConfirmation({
				showModal: true,
				message: "No changes detected!",
				hiddenButtons: true,
			});
		} else {
			setModalConfirmation({
				showModal: true,
				message: "Are you sure you want to update user level point?",
			});
		}
	};

	const handleOnUpdatePoint = () => {
		const data = formikRef.current.values;
		const bodyRequest = bodyRequestStructureModel.map(item => {
			return {
				...item,
				welcome_point: data.welcome_point[item.name],
				min_exam_access_point: data.min_exam_access_point[item.name],
			};
		});
		updatePointSeting(
			{ levels: bodyRequest },
			{
				onSuccess: () => {
					setModalConfirmation({
						showModal: false,
						message: null,
					});
					setShowModalResult({
						showModal: true,
						message: "Successfully updated point data!",
					});
				},
			},
		);
	};

	const handleOnCloseModalConfirmation = () => {
		setModalConfirmation({
			showModal: false,
			message: null,
			hiddenButtons: false,
		});
	};

	useEffect(() => {
		if (currentPointSettings?.data) {
			const initialData = currentPointSettings.data.reduce((acc, item) => {
				acc.welcome_point[item.name] = item.welcome_point || "";
				acc.min_exam_access_point[item.name] = item.min_exam_access_point || "";
				return acc;
			}, formInitialValues);
			setInitialDataPersist(initialData);

			formikRef.current.setValues(initialData);
		}
	}, [currentPointSettings]);

	return (
		<Layout breadCumbTitle="General Setting" breadCumbDesc="User Level Point">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="bg-white rounded-lg drop-shadow-main filter-none p-6">
					<div className="text-2xl font-semibold mb-6">User Level Point</div>
					<Formik
						innerRef={formikRef}
						initialValues={formInitialValues}
						validationSchema={UserLevelPointSchema}
						onSubmit={handleOnClickSubmit}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
							return (
								<>
									<div className="border border-solid border-grey-10 rounded-lg overflow-hidden mb-7">
										<div className="bg-grey-10 py-4 px-5 text-grey-70 text-base font-bold">
											Welcoming Point
										</div>
										<div className="p-5">
											<div className="flex justify-between py-2">
												<div className="w-[48%]">
													<InputComponent
														value={values.welcome_point.beginner}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.welcome_point?.beginner &&
															errors.welcome_point?.beginner
														}
														name="welcome_point.beginner"
														numberOnly
														label="Beginner"
														placeholder="insert point"
													/>
													<InputComponent
														value={values.welcome_point.intermediate}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.welcome_point?.intermediate &&
															errors.welcome_point?.intermediate
														}
														name="welcome_point.intermediate"
														numberOnly
														label="Intermediate"
														placeholder="insert point"
													/>
												</div>
												<div className="w-[48%]">
													<InputComponent
														value={values.welcome_point.advance}
														numberOnly
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.welcome_point?.advance &&
															errors.welcome_point?.advance
														}
														name="welcome_point.advance"
														label="Advance"
														placeholder="insert point"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="border border-solid border-grey-10 rounded-lg overflow-hidden mb-7">
										<div className="bg-grey-10 py-4 px-5 text-grey-70 text-base font-bold">
											Minimum Exam Access Point
										</div>
										<div className="p-5">
											<div className="flex justify-between py-2">
												<div className="w-[48%]">
													<InputComponent
														value={values.min_exam_access_point.beginner}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.min_exam_access_point?.beginner &&
															errors.min_exam_access_point?.beginner
														}
														name="min_exam_access_point.beginner"
														numberOnly
														label="Beginner"
														placeholder="insert point"
													/>
													<InputComponent
														value={values.min_exam_access_point.intermediate}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.min_exam_access_point?.intermediate &&
															errors.min_exam_access_point?.intermediate
														}
														name="min_exam_access_point.intermediate"
														numberOnly
														label="Intermediate"
														placeholder="insert point"
													/>
												</div>
												<div className="w-[48%]">
													<InputComponent
														value={values.min_exam_access_point.advance}
														onChange={handleChange}
														onBlur={handleBlur}
														error={
															touched.min_exam_access_point?.advance &&
															errors.min_exam_access_point?.advance
														}
														name="min_exam_access_point.advance"
														numberOnly
														label="Advance"
														placeholder="insert point"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="flex justify-center my-9">
										<div className="w-2/6 mx-1">
											<ButtonSubmit
												disabled={!UserLevelPointSchema.isValidSync(values)}
												loading={isUpdating}
												onClick={handleSubmit}
												className="disabled:opacity-50 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
												type="button">
												<span className="icon-ico-save mr-2"></span> Save Changes
											</ButtonSubmit>
										</div>
									</div>
								</>
							);
						}}
					</Formik>
				</div>
			)}

			<ModalConfirmation
				hiddenButtons={modalConfirmation.hiddenButtons}
				buttons={[]}
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={handleOnCloseModalConfirmation}
				onReject={handleOnCloseModalConfirmation}
				onApprove={handleOnUpdatePoint}
			/>
			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
