import { useState, useEffect } from "react";

export const ImagePreview = ({ file, onRemove, index }) => {
	const [fileUrl, setFileUrl] = useState(null);
	useEffect(() => {
		if (file) {
			setFileUrl(URL.createObjectURL(file));
		}
	}, [file]);

	return fileUrl ? (
		<div className="w-[100%] mx-1 rounded-lg rounded-lg relative mb-4">
			<img className="w-full rounded-lg h-[95px] object-cover object-center" alt="pic" src={fileUrl} />
			<button
				onClick={onRemove}
				className="absolute shadow-md top-1 right-1 w-5 h-5  text-xs rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
				<span className="icon-ico-close"></span>
			</button>
		</div>
	) : null;
};
