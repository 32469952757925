export const SOCIAL_MEDIA_MASTER_DATA = [
	{
		id: 1,
		name: "instagram",
		logo: null,
	},
	{
		id: 2,
		name: "tiktok",
		logo: null,
	},
	{
		id: 3,
		name: "facebook",
		logo: null,
	},
	{
		id: 4,
		name: "youtube",
		logo: null,
	},
	{
		id: 5,
		name: "twitter",
		logo: null,
	},
];
