import dayjs from 'dayjs';

export function isTokenExpired(state) {
  const { accessToken, expiredDate } = state.auth;

  if (expiredDate === 'NEVER') return false;

  let expired = true;

  if (dayjs(expiredDate).isValid()) {
    expired = dayjs() > dayjs(expiredDate);
  }
  return accessToken && expired;
}
