import clsx from "clsx";
import { useRef, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { InputComponent } from "components/form/input.component";
import { ModalContainer } from "components/modal/modal.container";
import { Formik } from "formik";
import { loadStaticPublicAsset } from "utils/loader";
import { CreateRewardItemSchema } from "config/form/schema/reward/create-reward-item";
import { ButtonSubmit } from "components/button/submit.button";

import { useUploadImage } from "hooks/upload";
import { Select } from "components/form/select.component";
import {
	useGetRewardCategoryList,
	useGetRewardItemDetails,
	useAddNewRewardItem,
	useEditRewardItem,
} from "hooks/reward";

const formInitialValues = { name: "", qty: "", code: "", item_category_id: null, image: null };

export function ModalAddItemReward({
	visible,
	onSuccess,
	onClose,
	operation = "ADD",
	editId,
	data = { skinTypeId: null },
}) {
	const formikRef = useRef(null);

	const [imageFileBuffer, setImageBuffer] = useState(null);
	const [imagePreview, setImagePreview] = useState(data?.image || null);
	const { data: rewardCategoryList } = useGetRewardCategoryList();
	const { mutate: addRewardItem, isLoading: isSubmitting } = useAddNewRewardItem();
	const { mutate: editRewardItem, isLoading: isUpdating } = useEditRewardItem();
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();

	const { data: rewardItemDetails } = useGetRewardItemDetails(editId);

	const handleSubmitForm = async payloadData => {
		if (imageFileBuffer) {
			const formData = new FormData();
			formData.append("image", imageFileBuffer);
			formData.append("type", "beauty_profile");

			const { data: uploadImageResult } = await uploadImageAsync({ formData });

			payloadData.image_desktop = uploadImageResult.image_desktop;
			payloadData.image_mobile = uploadImageResult.image_mobile;
		}

		if (operation === "ADD") {
			addRewardItem(payloadData, {
				onSuccess: () => {
					onSuccess("Successfully created new reward item!");
					setImagePreview(null);
					formikRef.current.resetForm();
					onClose();
				},
			});
		} else {
			editRewardItem(
				{ itemId: editId, formData: payloadData },
				{
					onSuccess: () => {
						onSuccess("Successfully updated reward item!");
						setImagePreview(null);
						formikRef.current.resetForm();
					},
				},
			);
		}
	};

	const handleChangeImage = e => {
		const file = e.target.files[0];
		const reader = new FileReader();
		formikRef.current.setFieldValue("image", file);
		if (/\.(jpe?g|png)$/i.test(file.name)) {
			reader.readAsDataURL(file);
			reader.onload = () => {
				setImagePreview(URL.createObjectURL(file));
			};
			setImageBuffer(file);
		} else {
			toast.error("File type is not supported, only jpg, png, and jpeg are allowed");
		}
	};

	const handleOnRemoveImage = () => {
		setImagePreview(null);
		setImageBuffer(null);
		formikRef.current.setFieldValue("image", null);
	};

	useEffect(() => {
		if (operation === "EDIT" && rewardItemDetails) {
			Object.keys(formInitialValues).forEach(key => {
				formikRef?.current?.setFieldValue(
					key,
					rewardItemDetails?.data?.[key] === null ? "" : rewardItemDetails?.data?.[key],
				);
			});
			formikRef?.current?.setFieldValue("image", rewardItemDetails?.data?.image_desktop);
			setImagePreview(rewardItemDetails?.data?.image_desktop);
		}
	}, [operation, rewardItemDetails]);

	return (
		<Formik
			innerRef={formikRef}
			onSubmit={handleSubmitForm}
			initialValues={formInitialValues}
			validationSchema={CreateRewardItemSchema}>
			{({ values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
				<ModalContainer visible={visible}>
					<div class={clsx("rounded-2xl  bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
						<div className="relative text-center py-4 mb-4">
							<div className="font-bold text-red-50">
								{operation === "ADD" ? "Create New Item" : "Edit Item"}
							</div>

							<button
								type="button"
								className="absolute top-0 right-0 border-0"
								onClick={() => {
									onClose();
									setImagePreview(null);
									// formikRef.current.resetForm();
								}}>
								<span className="icon-ico-close"></span>
							</button>
						</div>
						<div className="content-scroll">
							<div className="relative flex justify-center pt-4">
								{imagePreview && (
									<div className="w-28 h-28 relative">
										<img
											alt="Item"
											src={imagePreview}
											className="w-28 h-28 rounded-full overflow-hidden object-cover object-center"
										/>

										<button
											onClick={handleOnRemoveImage}
											className="absolute top-[-10px] right-[-10px] shadow-md w-10 h-10 leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
											<span className="icon-ico-delete"></span>
										</button>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5 mb-5">
								<input
									type="file"
									className="hidden"
									accept="image/*"
									id="upload"
									onChange={handleChangeImage}
								/>
								<label
									className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
									for="upload">
									<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
									<span className="pl-2">Upload</span>
								</label>
							</div>
							<div className="mb-4 px-2">
								<InputComponent
									label="Item Name"
									name="name"
									placeholder="Ex: YSB Alpha Arbutin Grapressed.."
									value={values.name}
									onBlur={handleBlur}
									onChange={handleChange}
									error={touched.name && errors.name}
								/>
							</div>
							<div className="mb-4 px-2">
								<Select
									name="item_category_id"
									onBlur={handleBlur}
									error={touched.item_category_id && errors.item_category_id}
									onChange={handleChange}
									value={values.item_category_id}
									options={rewardCategoryList?.data || []}
									label="Category"
									placeholder="Select Category"
								/>
							</div>
							<div className="mb-4 px-2">
								<InputComponent
									label="Kode Item"
									name="code"
									placeholder="Contoh: CLN03232344"
									value={values.code}
									onBlur={handleBlur}
									onChange={handleChange}
									error={touched.code && errors.code}
								/>
							</div>
							<div className="mb-4 px-2">
								<InputComponent
									label="Total QTY"
									name="qty"
									placeholder="Contoh: 200"
									value={values.qty}
									onChange={e => {
										setFieldValue("qty", e.target.value.replace(/\D/g, ""));
									}}
									onBlur={handleBlur}
									error={touched.qty && errors.qty}
								/>
							</div>
						</div>

						<div className="my-6 flex justify-center">
							<div className="w-1/2 px-2">
								<button
									disabled={isSubmitting || isUpdating || isUploading}
									onClick={() => {
										onClose();
										setImagePreview(null);
										// formikRef.current.resetForm();
									}}
									className="flex items-center w-full rounded-xl bg-white font-semibold text-grey-70 border border-solid border-grey-10 py-2 px-8 uppercase hover:bg-grey-10 hover:text-red-50 transition-all"
									type="button">
									<img
										src={loadStaticPublicAsset("img/icons/x-circle.svg")}
										className="max-w-full w-5"
										alt="Cancel Operation"
									/>
									<span className="ml-2">Cancel</span>
								</button>
							</div>
							<div className="w-1/2 px-2">
								<ButtonSubmit
									loading={isSubmitting || isUpdating || isUploading}
									onClick={handleSubmit}
									disabled={!CreateRewardItemSchema.isValidSync(values) || !imagePreview}
									className="disabled:opacity-50 block w-full rounded-xl font-semibold bg-red-50 text-white py-2 px-8 uppercase hover:bg-red-70 transition-all">
									<span className="icon-ico-user-register"></span>
									<span className="ml-2">{operation === "ADD" ? "Add" : "Save"}</span>
								</ButtonSubmit>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</Formik>
	);
}
