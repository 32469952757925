import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Formik } from "formik";

import Layout from "components/layout";
import { CreateActivityValidatioSchema } from "config/form/schema/activity/create-activity";
import { useActivityDetails, useEditActivity } from "hooks/activity";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalResult } from "components/modal/modal.result";
import { ButtonSubmit } from "components/button/submit.button";
import { convertMilisecondsToTime, convertTimeToSeconds } from "utils/date";

// Page Component
import { Introduction } from "./components/create-sections/introduction.component";
import { ClassMaterial } from "./components/create-sections/class-material.component";
import { ClassLinks } from "./components/create-sections/class-link.component";
import { RegistrationDate } from "./components/create-sections/registration-date.component";
import { Attendance } from "./components/create-sections/attedance.component";
import { Challange } from "./components/create-sections/challange.component";
import { Review } from "./components/create-sections/review.component";
import { Exam } from "./components/create-sections/exam.component";
const SUBMIT_BUTTON_SOURCE = {
	BUTTON_EXAM: "BUTTON_EXAM",
	BUTTON_SUBMIT: "BUTTON_SUBMIT",
};

const formInitialValues = {
	introduction: {
		title: "",
		activity_category_id: "",
		description: "",
		total_point: "",
		level_id: "",
		start_date: "",
		thumbnail_desktop: "",
		thumbnail_mobile: "",
		end_date: "",
	},
	class_material: {
		is_active: false,
		title: "",
		description: "",
		uploaded_files: [],
		redirect_urls: [],
	},
	class_video_conference: {
		is_active: false,
		title: "",
		description: "",
		redirect_urls: [],
	},
	registration: {
		is_active: false,
		start_date: "",
		end_date: "",
	},
	attendance: {
		is_active: false,
		title: "",
		description: "",
		code: "",
		point: "",
		start_date: "",
		end_date: "",
	},
	challenge: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		example_redirect_url: "",
		example_uploaded_files: [],
		start_date: "",
		end_date: "",
	},
	review: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		start_date: "",
		end_date: "",
	},
	exam: {
		is_active: false,
		title: "",
		description: "",
		point: "",
		duration: "",
		start_date: "",
		end_date: "",
	},
};

export function EditActivityPage() {
	const formikRef = useRef();
	const params = useParams();

	const { activityId } = params;

	const [selectedUsers, setSelectedUsers] = useState([]);

	const { data: activityDetails, isLoading } = useActivityDetails(activityId);
	const [staleInitialValues, setStaleInitialValues] = useState(null);

	const navigate = useNavigate();

	const { mutate: updateActivity, isLoading: isSubmitting } = useEditActivity();

	const [showModalResult, setShowModalResult] = useState({ show: false, message: "", navigateTo: null });
	const [sectionExpaned, setSectionExpaned] = useState({
		introduction: true,
		class_material: false,
		class_video_conference: false,
		registration: false,
		attendance: false,
		challenge: false,
		review: false,
		exam: false,
	});

	const handleOnCloseModalResult = () => {
		navigate(showModalResult.navigateTo);
	};

	const handleCreateActivity = (data, submitButtonSource) => {
		const formPayload = {
			...data,
			introduction: {
				...data.introduction,
				activity_category_id: +data.introduction.activity_category_id,
				level_id: +data.introduction.level_id,
				user_ids: selectedUsers.map(user => user.id),
			},
			class_material: {
				...data.class_material,
				uploaded_files: data.class_material.uploaded_files.map(file => file.url),
			},
			challenge: {
				...data.challenge,
				example_uploaded_files: data.challenge.example_uploaded_files.map(file => file.url),
			},
			exam: {
				...data.exam,
				duration:
					typeof data.exam.duration === "string"
						? convertTimeToSeconds(data.exam.duration)
						: data.exam.duration,
			},
		};

		updateActivity(
			{ bodyRequest: formPayload, activityId },
			{
				onSuccess: response => {
					setShowModalResult({
						show: true,
						message: "Successfully saved changes!",
						navigateTo:
							submitButtonSource === SUBMIT_BUTTON_SOURCE.BUTTON_EXAM || formPayload.exam.is_active
								? `/activity/create-quiz/${activityId}`
								: "/activity/list",
					});
				},
			},
		);
	};

	useEffect(() => {
		if (activityDetails?.data) {
			const newInitialData = _.cloneDeep(activityDetails.data);
			const data = Object.keys(newInitialData)
				.map(key => {
					if (Object.keys(newInitialData[key]).includes("user_ids")) {
						console.log({
							userIds: newInitialData[key].user_ids,
						});
						setSelectedUsers(newInitialData[key].user_ids);
					}
					// Mapping data from API is start date or end date is null or "Invalid date"
					if (Object.keys(newInitialData[key]).includes("start_date")) {
						if (newInitialData[key].start_date === "Invalid date") {
							newInitialData[key].start_date = "";
						}
					}
					if (Object.keys(newInitialData[key]).includes("end_date")) {
						if (newInitialData[key].end_date === "Invalid date") {
							newInitialData[key].end_date = "";
						}
					}
					// Mapping data from API for uploaded files
					// Since API return array of string, we need to map it to array of object
					// with url and name properties to fulfill the requirement on the web app
					if (Object.keys(newInitialData[key]).includes("uploaded_files")) {
						if (newInitialData[key].uploaded_files.length > 0) {
							newInitialData[key].uploaded_files = newInitialData[key].uploaded_files.map(
								(fileUrl, index) => ({
									url: fileUrl,
									fileName: `file-${index + 1}`,
								}),
							);
						}
					}
					if (Object.keys(newInitialData[key]).includes("example_uploaded_files")) {
						if (newInitialData[key].example_uploaded_files.length > 0) {
							newInitialData[key].example_uploaded_files = newInitialData[key].example_uploaded_files.map(
								(fileUrl, index) => ({
									url: fileUrl,
									fileName: `example-file-${index + 1}`,
								}),
							);
						}
					}

					// Convert duration to time

					if (Object.keys(newInitialData[key]).includes("duration")) {
						const newDuration = convertMilisecondsToTime(newInitialData[key]?.duration * 1000, true);

						newInitialData[key].duration = newDuration;
					}
					return { [key]: newInitialData[key] };
				})
				.reduce((acc, curr) => ({ ...acc, ...curr }), {});

			setStaleInitialValues({ data });

			Object.keys(formInitialValues).forEach(key => {
				formikRef.current.setFieldValue(key, data[key]);
			});
		}
	}, [activityDetails]);

	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none p-6"
			breadCumbTitle="Activity"
			breadCumbDesc="Edit Activity">
			{isLoading ? (
				<div className="h-[90vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<Formik
					innerRef={formikRef}
					initialValues={formInitialValues}
					onSubmit={data => handleCreateActivity(data, SUBMIT_BUTTON_SOURCE.BUTTON_SUBMIT)}
					validationSchema={CreateActivityValidatioSchema}>
					{({ values, handleBlur, handleChange, setFieldValue, errors, touched, handleSubmit }) => {
						return (
							<>
								<Introduction
									values={values.introduction}
									onChange={handleChange}
									onBlur={handleBlur}
									selectedUsers={selectedUsers}
									setSelectedUsers={setSelectedUsers}
									errors={errors.introduction}
									touched={touched.introduction}
									setFieldValue={setFieldValue}
									descriptionInitialValue={activityDetails?.data?.introduction?.description}
									sectionId="introduction"
									expanded={sectionExpaned.introduction}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											introduction: !sectionExpaned.introduction,
										});
									}}
								/>
								<ClassMaterial
									values={values.class_material}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.class_material}
									touched={touched.class_material}
									setFieldValue={setFieldValue}
									sectionId="class_material"
									descriptionInitialValue={activityDetails?.data?.class_material?.description}
									initialValues={{
										redirect_urls: staleInitialValues?.data?.class_material?.redirect_urls,
										uploaded_files: staleInitialValues?.data?.class_material?.uploaded_files,
									}}
									expanded={sectionExpaned.class_material}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											class_material: !sectionExpaned.class_material,
										});
									}}
								/>
								<ClassLinks
									initialValues={{
										redirect_urls: staleInitialValues?.data?.class_video_conference?.redirect_urls,
									}}
									values={values.class_video_conference}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.class_video_conference}
									touched={touched.class_video_conference}
									setFieldValue={setFieldValue}
									descriptionInitialValue={activityDetails?.data?.class_video_conference?.description}
									sectionId="class_video_conference"
									expanded={sectionExpaned.class_video_conference}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											class_video_conference: !sectionExpaned.class_video_conference,
										});
									}}
								/>
								<RegistrationDate
									values={values.registration}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.registration}
									touched={touched.registration}
									setFieldValue={setFieldValue}
									sectionId="registration"
									expanded={sectionExpaned.registration}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											registration: !sectionExpaned.registration,
										});
									}}
								/>
								<Attendance
									values={values.attendance}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.attendance}
									touched={touched.attendance}
									setFieldValue={setFieldValue}
									sectionId="attendance"
									descriptionInitialValue={activityDetails?.data?.attendance?.description}
									expanded={sectionExpaned.attendance}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											attendance: !sectionExpaned.attendance,
										});
									}}
								/>
								<Challange
									values={values.challenge}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.challenge}
									touched={touched.challenge}
									setFieldValue={setFieldValue}
									sectionId="challenge"
									expanded={sectionExpaned.challenge}
									descriptionInitialValue={activityDetails?.data?.challenge?.description}
									initialValues={{
										example_uploaded_files:
											staleInitialValues?.data?.challenge?.example_uploaded_files,
									}}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											challenge: !sectionExpaned.challenge,
										});
									}}
								/>
								<Review
									values={values.review}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.review}
									touched={touched.review}
									setFieldValue={setFieldValue}
									sectionId="review"
									expanded={sectionExpaned.review}
									descriptionInitialValue={activityDetails?.data?.review?.description}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											review: !sectionExpaned.review,
										});
									}}
								/>
								<Exam
									values={values.exam}
									onChange={handleChange}
									onBlur={handleBlur}
									errors={errors.exam}
									touched={touched.exam}
									setFieldValue={setFieldValue}
									sectionId="exam"
									expanded={sectionExpaned.exam}
									descriptionInitialValue={activityDetails?.data?.exam?.description}
									buttonExamDisabled={!CreateActivityValidatioSchema.isValidSync(values)}
									buttonExamLoading={isSubmitting}
									onClickButtonExam={() => {
										if (_.isEqual(values, staleInitialValues?.data)) {
											return navigate(`/activity/create-quiz/${activityId}`);
										} else {
											handleCreateActivity(values, SUBMIT_BUTTON_SOURCE.BUTTON_EXAM);
										}
									}}
									onToggleExpand={() => {
										setSectionExpaned({
											...sectionExpaned,
											exam: !sectionExpaned.exam,
										});
									}}
								/>
								<div className="flex justify-center my-9">
									<div className="w-2/6">
										<ButtonSubmit
											disabled={!CreateActivityValidatioSchema.isValidSync(values)}
											onClick={handleSubmit}
											loading={isSubmitting}
											title="Save All Changes"
											className="disabled:opacity-50 mt-5 w-full block py-2.5 px-7 border text-center border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold"
										/>
									</div>
								</div>
							</>
						);
					}}
				</Formik>
			)}
			<ModalResult
				visible={showModalResult.show}
				title={false}
				onClose={handleOnCloseModalResult}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
