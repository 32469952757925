import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useGetMemberProfileById, useGetMemberBatch, useGetMemberPointHistory } from "hooks/member";
import { BounceLoading } from "components/loading/bounce.loading";
import { Pagination } from "components/pagination.component";

import { MemberProfileLayout } from "../../components/profile.layout";
import { getInitialsName } from "utils/string";

function TabBacthList({ data, onTabChange, currentActive }) {
	return (
		<div className="border-b flex border-solid border-grey-10 mb-3 px-6">
			{data?.map(item => (
				<div className="inline-block mr-4" key={item}>
					<button
						onClick={() => onTabChange(item)}
						class={clsx(
							"rounded-tl-lg rounded-tr-lg uppercase text-sm font-semibold   transition-all inline-block px-6 py-3  mb-[-1px] border border-solid border-b-transparent ",
							currentActive === item ? "text-red-50  border-grey-10" : "border-transparent",
						)}>
						{`Batch ${item}`}
					</button>
				</div>
			))}
		</div>
	);
}

export function MemberPointHistoryy() {
	const { userId, userStatus } = useParams();

	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 10,
		order_by: "created_at",
		order: "desc",
	});
	const [selectedBatchId, setSelectedBatchId] = useState(0);

	const { data: memberProfileDetails } = useGetMemberProfileById({ userId, type: userStatus });
	const { data: memberPointItems, isLoading } = useGetMemberPointHistory({
		userId,
		batchId: selectedBatchId,
		queryFilter,
	});
	const { data: memberBatchs, isLoading: isLoadingBatch } = useGetMemberBatch({ userId });

	useEffect(() => {
		if (memberBatchs?.data) setSelectedBatchId(memberBatchs?.data?.followed_batch[0]);
	}, [memberBatchs]);

	const handleOnTabChange = selectedBatchId => {
		setSelectedBatchId(selectedBatchId);
		setQueryFilter({
			...queryFilter,
			page: 1,
		});
	};

	return (
		<MemberProfileLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="User"
			desc="User Detail">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading && isLoadingBatch ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<>
						<div className="px-6 py-6">
							<div className="flex justify-between items-center">
								<div className="text-title font-semibold text-grey-70">Point History</div>

								<div className="flex justify-end items-center">
									{memberBatchs?.data?.followed_batch?.length !== 0 && (
										<div className="rounded-lg border border-solid border-[#E8CDD2] bg-[#FCF7F6] px-3 py-2 text-center mr-3 ">
											<div className="text-red-30 text-xs font-bold">Total Point</div>
											<div className="text-red-30 text-2xl font-bold">
												{memberPointItems?.data?.total_point}
											</div>
										</div>
									)}

									<div className="rounded-xl border min-w-[280px] py-2 px-3 overflow-hidden flex items-center border-solid border-grey-10">
										<div className="mr-4">
											{memberProfileDetails?.data?.profile_picture ? (
												<img
													src={memberProfileDetails?.data?.profile_picture}
													alt="profile"
													className="w-12 h-12 overflow-hidden rounded-full object-cover object-center"
												/>
											) : (
												<div className="initial">
													{getInitialsName(memberProfileDetails?.data?.full_name) || "A"}
												</div>
											)}
										</div>
										<div className="w-auto">
											<div className="text-sm font-bold">
												{memberProfileDetails?.data?.full_name}
											</div>
											<div className="text-sm text-grey-40 italic">
												{memberProfileDetails?.data?.email}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<TabBacthList
							data={memberBatchs?.data?.followed_batch}
							currentActive={selectedBatchId}
							onTabChange={handleOnTabChange}
						/>
						<div className="px-6">
							{memberBatchs?.data?.followed_batch?.length === 0 && (
								<div className="text-sm font-bold text-center mt-20">No activity found</div>
							)}
							{memberPointItems?.data?.rows?.map((poinHistoryItem, index) => (
								<div className="flex items-center justify-between border-b border-solid border-b-grey-10 py-4">
									<div className="w-auto">
										<div className="text-red-30 italic text-sm font-semibold mb-2">
											{poinHistoryItem.activity_name}
										</div>
										<div className="text-grey-70 text-sm font-semibold mb-2">
											{poinHistoryItem.activity_title}
										</div>
										<div className="text-grey-50 text-sm italic mb-2">
											{poinHistoryItem.point_earned_at}
										</div>
									</div>

									<div className="min-w-[80px] px-3 text-center">
										<div className="text-2xl text-red-30 font-semibold">
											{poinHistoryItem.point_earned}
										</div>
										<div className="text-xs text-red-30 font-semibold">Points</div>
									</div>
								</div>
							))}
						</div>
						<Pagination
							currentPage={queryFilter.page}
							pageSize={queryFilter.limit}
							siblingCount={1}
							onPageChange={selectedPage => {
								window.scroll({
									top: 0,
									behavior: "smooth",
								});
								setQueryFilter({ ...queryFilter, page: selectedPage });
							}}
							totalCount={memberPointItems?.data?.total_result || 0}
						/>
					</>
				)}
			</div>
		</MemberProfileLayout>
	);
}
