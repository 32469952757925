import { useState } from "react";
import { Player } from "video-react";
import { useSpring, animated } from "react-spring";
import { useParams } from "react-router-dom";

import Layout from "components/layout";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { IsJsonString } from "utils/string";
import { serializeEvent } from "components/text-editor/serializer";
import { useDeleteTopicCategory, useGetDiscussionTopicDetails } from "hooks/discussion";
import { ImageWithZoom } from "components/image/image-zoom.component";
import { TopicThreadsList } from "./components/topic-thread";
import { ActionLog } from "./components/action-log";
import { numberAbbreviator } from "utils/number";

const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
};

function AnimatedNumber({ num }) {
	const { number } = useSpring({
		from: { number: 0 },
		to: { number: num },
		config: { mass: 1, tension: 180, friction: 40 },
		delay: 100,
	});
	if (num > 10000) return <span>{numberAbbreviator(num)}</span>;

	return <animated.span>{number.to(x => x.toFixed(0))}</animated.span>;
}

export function DiscussionDetails() {
	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);
	const { topicId } = useParams();
	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully created new data!",
	});

	// Hooks API Call
	const { mutate: deleteCategoryById, isLoading: isDeleting } = useDeleteTopicCategory();
	const { data: topicDetails, isLoading } = useGetDiscussionTopicDetails(topicId);

	const handleDeleteCategory = () => {
		deleteCategoryById(
			{ categoryId: modalConfirmation.data?.categoryId },
			{
				onSuccess: () => {
					setModalConfirmation({
						message: "",
						showModal: false,
						data: {},
					});
					setShowModalResult(prev => ({
						...prev,
						showModal: true,
						message: "Category has been deleted!",
					}));
				},
			},
		);
	};

	return (
		<Layout breadCumbTitle="Discussion" breadCumbDesc="Detail Discussion">
			{isLoading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="bg-white rounded-lg drop-shadow-main filter-none p-5">
					<div className="flex justify-between py-4 ">
						<div className="w-[47%]">
							<div className="text-xl font-bold text-grey-70 mb-3">{topicDetails?.data?.title}</div>
							{topicDetails?.data.description &&
								IsJsonString(topicDetails?.data.description) &&
								serializeEvent(JSON.parse(topicDetails?.data.description), {
									paragraph: "text-sm text-grey-70 mb-3",
								})}

							{topicDetails?.data?.video_url ? (
								<div className="relative h-auto rounded-lg overflow-hidden rounded-xl mb-4 w-full">
									<Player playsInline src={topicDetails?.data?.video_url} aspectRatio="16:9" />
								</div>
							) : (
								<ImageWithZoom
									src={topicDetails?.data?.image_desktop}
									className="w-full rounded-lg overflow-hidden mb-4"
								/>
							)}

							<TopicThreadsList />

							<hr className="border-grey-10 border-t my-6" />
						</div>
						<div className="w-[47%]">
							<div className="flex items-center rounded-md border border-solid border-grey-10 py-3 px-6 mb-6">
								<div className="w-4/12 flex items-center">
									<span className="icon-ico-heart text-red-50 text-xl"></span>
									<span className="ml-3 text-black font-semibold">
										<AnimatedNumber num={topicDetails?.data?.total_liked} />
									</span>
								</div>
								<div className="w-3/12 flex items-center border-r border-solid border-grey-10">
									<span className="icon-ico-bubble text-red-50 text-xl"></span>
									<span className="ml-3 text-black font-semibold">
										<AnimatedNumber num={topicDetails?.data?.total_commented} />
									</span>
								</div>
								<div className="w-2/5 flex items-center pl-5">
									<span className="icon-ico-flag text-red-50 text-xl"></span>
									<span className="ml-3 text-black font-semibold">
										<AnimatedNumber num={topicDetails?.data?.total_report} /> Reported
									</span>
								</div>
							</div>

							<div className="flex items-center px-6 mb-6">
								<div className="w-5/12 ">
									<div className="text-black text-sm font-semibold mb-1">Category</div>
									<div className="text-black text-sm">{topicDetails?.data?.topic_category?.name}</div>
								</div>
								<div className="w-3/12 ">
									<div className="text-black text-sm font-semibold mb-1">Batch</div>
									<div className="text-black text-sm">
										{topicDetails?.data?.topic_batches
											?.map(item => ({
												...item,
												label: `Batch ${item?.batch}`,
											}))
											?.map(item => item?.label)
											.join(", ")}
									</div>

									{topicDetails?.data?.topic_batches?.length === 0 && (
										<div className="text-black text-sm">General</div>
									)}
								</div>
								<div className="w-4/12 ">
									<div className="text-black text-sm font-semibold mb-1">Regional</div>
									<div className="text-black text-sm">
										{topicDetails?.data?.provinces?.map(item => item?.name).join(", ")}
									</div>
								</div>
							</div>

							<ActionLog />
						</div>
					</div>
				</div>
			)}

			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteCategory()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus data ini?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
