import clsx from "clsx";

import { ModalContainer } from "components/modal/modal.container";
import { ImageWithZoom } from "components/image/image-zoom.component";
import { useActivityChallangeResult } from "hooks/activity";
import { ButtonSubmit } from "components/button/submit.button";
import { isUrlMedia } from "utils/string";

const RenderMedia = ({ data, onPreviewImage }) => {
	if (!data.length) return null;
	return (
		<div className="mb-6">
			<label className="text-grey-70 font-semibold text-sm mb-3 block uppercase">Media</label>
			<div className="grid grid-cols-4 gap-4">
				{data.map(itemUrl => {
					if (isUrlMedia(itemUrl).isImage) {
						return (
							<>
								<div className="cursor-pointer" onClick={() => onPreviewImage(itemUrl)}>
									<img
										alt="Item"
										src={itemUrl}
										className=" w-full h-32 overflow-hidden object-cover object-center"
									/>
								</div>
							</>
						);
					}
					if (isUrlMedia(itemUrl).isVideo) {
						return (
							<div className="relative">
								<video
									src={itemUrl}
									alt={itemUrl}
									preload="auto"
									className="rounded-lg w-32 h-32 overflow-hidden object-cover object-center">
									<source src={itemUrl} type="video/mp4" />
									<source src={itemUrl} type="video/webm" />
								</video>

								<button
									onClick={() => {
										window.open(itemUrl, "_blank");
									}}
									className="absolute cursor-pointer top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] z-10 hover:opacity-100 opacity-90 transition-all">
									<img src="/img/play.png" alt="Play" />
								</button>
							</div>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};
const RenderLink = ({ data, type = "link" }) => {
	if (!data.length) return null;
	return (
		<div className="mb-6">
			<label className="text-grey-70 font-semibold text-sm mb-3 block uppercase ">
				{type === "link" ? "Link" : "Gdrive"}
			</label>

			{data.map((itemUrl, index) => (
				<ButtonSubmit
					key={itemUrl}
					onClick={() => window.open(itemUrl, "_blank")}
					className="flex mb-2 justify-center items-center py-2 px-3 w-56 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all uppercase font-semibold"
					type="button">
					{type === "gdrive" ? (
						<>
							<img src="/img/icons/hard-drive.svg" className="max-w-full" alt="Gdrive Icon" />
							<span className="pl-1">{`Go to Gdrive (${index + 1})`}</span>
						</>
					) : (
						<>
							<span className="icon-ico-link text-red-50 text-xl"></span>
							<span className="pl-1">{`Go to Link (${index + 1})`}</span>
						</>
					)}
				</ButtonSubmit>
			))}
		</div>
	);
};

export function ModalChallangeResult({ visible, onClose, userActivityId, onPreviewImage }) {
	const { data: challangeResult, isLoading } = useActivityChallangeResult(userActivityId, visible);

	const renderResultByType = (type, data) => {
		switch (type) {
			case "media":
				return <RenderMedia data={data} onPreviewImage={onPreviewImage} />;
			case "link":
				return <RenderLink data={data} type="link" />;
			case "gdrive":
				return <RenderLink data={data} type="gdrive" />;
			default:
				return null;
		}
	};

	if (isLoading) return null;
	if (!challangeResult) return null;

	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-8 w-[620px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Challenge Result</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>
				<div className="content-scroll">
					{Object.keys(challangeResult?.data).map(resultType =>
						renderResultByType(resultType, challangeResult?.data[resultType]),
					)}
				</div>
			</div>
		</ModalContainer>
	);
}
