import clsx from "clsx";

export function SliderSocialMediaFooter({
	onExpand,
	onMove,
	title,
	expand,
	disabledUpButton,
	disabledDownButton,
	children,
}) {
	return (
		<div className="overflow-hidden rounded-lg border border-solid border-grey-10 mb-4">
			<div
				class={clsx(
					"items-tab flex items-center justify-between py-2.5 px-4 bg-[#F2F2F2] tracking-wide hover:bg-[rgba(0,0,0,0.1)] transition duration-150 ease-in-out ",
					expand && " collapsed",
				)}>
				<div className="text-black text-base font-bold">{title}</div>
				<div className="flex">
					<button
						disabled={disabledUpButton}
						className="disabled:opacity-30 flex items-center bg-white w-10 h-10 mr-2 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("UP")}>
						<span className="icon-ico-arrow-up text-red-50 text-xl"></span>
					</button>
					<button
						disabled={disabledDownButton}
						className="disabled:opacity-30  flex items-center bg-white w-10 h-10 mr-5 justify-center border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button"
						onClick={() => onMove("DOWN")}>
						<span className="icon-ico-arrow-down text-red-50 text-xl"></span>
					</button>

					<button
						onClick={onExpand}
						className="items-btn flex items-center bg-white w-10 h-10 rounded-full justify-center border text-sm border-solid border-grey-10  text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
						type="button">
						<span class={clsx("text-red-50 text-base", expand ? "icon-ico-down" : "icon-ico-up")}></span>
					</button>
				</div>
			</div>
			<div class={clsx("collapse", expand && "show")} id="items-1">
				{children}
			</div>
		</div>
	);
}
