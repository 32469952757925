import axios from "api/http.api";
import { useQuery } from "react-query";

export function useDataBeautyProfile() {
	return useQuery(["BEAUTY_PROFILE"], () => axios.get(`/api/v1/admin/beauty-profile/all`).then(res => res.data));
}

export function useSkinTypeDetails(skinTypeId) {
	return useQuery(
		["SKIN_TYPE_DETAILS", { skinTypeId }],
		() => axios.get(`/api/v1/admin/beauty-profile/type/info/${skinTypeId}`).then(res => res.data),
		{ enabled: !!skinTypeId },
	);
}

export function useSkinItemDetails(skinItemId) {
	return useQuery(
		["SKIN_ITEM_DETAILS", { skinItemId }],
		() => axios.get(`/api/v1/admin/beauty-profile/item/info/${skinItemId}`).then(res => res.data),
		{ enabled: !!skinItemId },
	);
}

export function useGetLevelAndBatch() {
	return useQuery(["LEVEL_AND_BATCH"], () => axios.get(`/api/v1/admin/upgrade/check`).then(res => res.data));
}
export function useGetPointSetting() {
	return useQuery(["POINT_SETTING"], () => axios.get(`/api/v1/admin/level/point`).then(res => res.data));
}

export function useGetFooterSetting({ page, slug }) {
	return useQuery(["FOOTER_SETTING", { page, slug }], () =>
		axios.get(`/api/v1/admin/settings/${page}/info/${slug}`).then(res => res.data),
	);
}
