import _ from "lodash";
import clsx from "clsx";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToolsReducer } from "store/reducer/tools.reducer";

import { getRelativeTime } from "utils/date";
import { IsJsonString, getInitialsName, urlify } from "utils/string";
import { serializeEvent } from "components/text-editor/serializer";
import { BounceLoading } from "components/loading/bounce.loading";
import { useDeleteAcitivyAdminComment } from "hooks/activity";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { urlSafeEncrypt } from "utils/encryption";

export function TableChallange(props) {
	const {
		columns,
		dataSource,
		loading,
		expandedRowIds = [],
		rowClassName = "flex items-center py-4 border border-transparent border-solid rounded-lg",
		rowSelectedClassName = "flex items-center py-4 border border-transparent border-solid rounded-lg",
		autoExpandAllRows = false,
		onDeleteCommentItemCompleted = () => {},
	} = props;
	const dispatch = useDispatch();
	const { selectedRowKeys, selectedRows } = useSelector(state => state.toolsReducer);
	const { mutate: deleteCommentById, isLoading: isDeleting } = useDeleteAcitivyAdminComment();

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		id: null,
	});

	const onCheckedAllHandler = e => {
		if (e.target.checked) {
			let rowKeys = getRowKeys(dataSource);
			dispatch(setToolsReducer({ selectedRowKeys: rowKeys, selectedRows: dataSource }));
		} else {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const getRowKeys = data => {
		let rowKeys = [];
		data.forEach(i => {
			rowKeys.push(i.id);
		});

		return rowKeys;
	};

	const onCheckHandler = (e, value) => {
		let rows = [];

		if (e.target.checked) {
			rows = [...selectedRows, value];
		} else {
			rows = selectedRows.filter(item => item !== value);
		}

		let newSelectedRowKeys = getRowKeys(rows);

		dispatch(setToolsReducer({ selectedRowKeys: newSelectedRowKeys, selectedRows: rows }));
	};

	const handleOnDeleteComment = () => {
		deleteCommentById(
			{ commentId: urlSafeEncrypt(modalConfirmation.id) },
			{
				onSuccess: () => {
					onDeleteCommentItemCompleted();
					setModalConfirmation({ showModal: false, id: null });
				},
			},
		);
	};

	return (
		<>
			<div className="flex items-center py-2 rounded bg-red-10 mb-4">
				{columns.map((column, index) => {
					if (column.type === "checkbox") {
						return (
							<div className="form-check px-2">
								<input
									disabled={loading}
									className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
									type="checkbox"
									checked={selectedRowKeys.length === dataSource.length && dataSource.length > 0}
									onChange={e => onCheckedAllHandler(e)}
									id="check"
								/>
							</div>
						);
					}
					return <div class={column.className}>{column.name}</div>;
				})}
			</div>
			{loading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				dataSource.map((item, index) => (
					<div className="rounded-lg border border-solid border-grey-10 mb-3">
						<div
							key={index.toString()}
							className={selectedRowKeys.includes(item.id) ? rowSelectedClassName : rowClassName}>
							{columns.map((columnItem, idx) => {
								if (idx === 0) {
									return (
										<div className="form-check px-2">
											<input
												checked={selectedRowKeys.includes(item.id)}
												className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
												type="checkbox"
												onChange={e => onCheckHandler(e, item, index)}
											/>
										</div>
									);
								} else {
									if (!!columnItem.render) {
										return columnItem.render(item, item[columnItem.dataIndex]);
									}
									return (
										<div key={index.toString().concat(idx.toString())} className={"text-center"}>
											{item[columnItem.dataIndex]}
										</div>
									);
								}
							})}
						</div>
						<div
							class={clsx(
								"collapse",
								autoExpandAllRows || expandedRowIds.includes(item?.id) ? "show" : null,
							)}
							id="items-2">
							{!!item.user_activity_admin_replies.length &&
								_.orderBy(item.user_activity_admin_replies, ["reply_date"], ["desc"]).map(
									(reply, index) => (
										<div className="flex justify-between p-4 border-t border-solid border-grey-10">
											<div className="w-9/12 overflow-hidden pr-5">
												<div className="pl-3 flex items-center overflow-hidden mb-3">
													<div className="font-bold text-red-60 text-sm mr-2">
														<span className="icon-ico-reply"></span>
													</div>
													{reply?.admin?.profile_picture || item?.user?.profile_picture ? (
														<img
															src={
																reply?.admin?.profile_picture ||
																item?.user?.profile_picture
															}
															className="w-9 h-9 rounded-full overflow-hidden object-cover object-center"
															alt="User"
														/>
													) : (
														<div className="initial w-9 h-9 leading-[34px] text-[17px]">
															{getInitialsName(
																reply?.admin?.full_name || item?.user?.full_name,
															)}
														</div>
													)}

													<div className="font-bold overflow-hidden text-ellipsis whitespace-nowrap text-grey-70 text-sm ml-4">
														{reply?.admin?.full_name || item?.user?.full_name}
													</div>
												</div>
												{IsJsonString(reply.reply_message) ? (
													serializeEvent(JSON.parse(reply.reply_message))
												) : (
													<p
														className="text-sm text-main-grey "
														dangerouslySetInnerHTML={{
															__html: urlify(reply.reply_message),
														}}></p>
												)}
											</div>
											<div className="w-1/5">
												<div className="flex justify-end mb-3">
													<div className="text-main-grey text-sm">
														{getRelativeTime(reply.reply_date)}
													</div>
												</div>
												<div className="flex justify-end">
													<button
														onClick={() => {
															setModalConfirmation({ showModal: true, id: reply.id });
														}}
														className=" flex items-center justify-center py-1 w-32 px-5 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold uppercase "
														type="button">
														<span className="icon-ico-delete text-red-50 text-xl"></span>
														<span className="pl-3">Delete</span>
													</button>
												</div>
											</div>
										</div>
									),
								)}
						</div>
					</div>
				))
			)}
			<ModalConfirmation
				buttons={[]}
				message="Are you sure you want to delete this comment?"
				loading={isDeleting}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={handleOnDeleteComment}
			/>
		</>
	);
}
