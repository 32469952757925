import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import { useActivityList, useExportActivity, useBulkDeleteActivity } from "hooks/activity";

import Layout from "components/layout";
import { ButtonSubmit } from "components/button/submit.button";
import { BounceLoading } from "components/loading/bounce.loading";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { ButtonDataFilterComponent } from "components/table/data-filter.component";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { ButtonExport } from "components/table/button-export.component";
import { EmptyState } from "components/table/empty-state.component";
import { Pagination } from "components/pagination.component";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ActivityItem } from "../components/activity-list-item.component";
import { ModalFilterActivity } from "../components/modal-filter-activity.component";
import clsx from "clsx";

const sortOptions = [
	{ label: "Terbaru", sortType: "desc", sortBy: "start_date" },
	{ label: "Terlama", sortType: "asc", sortBy: "start_date" },
	{ label: "Point Tertinggi", sortType: "desc", sortBy: "total_point" },
	{ label: "Point Terendah", sortType: "asc", sortBy: "total_point" },
];

const Status = [
	{
		label: "Up Coming",
		value: "upcoming",
	},
	{
		label: "On Going",
		value: "ongoing",
	},
	{
		label: "Past",
		value: "past",
	},
];

export function ActivityListPage() {
	const [currentParams] = useSearchParams();

	const dataStatus = currentParams.get("dataStatus") || undefined;
	const navigate = useNavigate();
	const [selectedActivityIds, setSelectedActivityIds] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(dataStatus || Status[0].value);
	const [searchKeyword, setSearchKeyword] = useState("");
	// Modal Filter State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalFilter: false,
		showModalSort: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		data: null,
	});

	const [modalResult, setModalResult] = useState({
		showModal: false,
		message: "",
	});

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "start_date",
		order: "desc",
		search: "",
	});
	const [bodyRequestFilter, setBodyRequestFilter] = useState({
		status: dataStatus || Status[0].value,
		level_ids: [],
		activity_category_ids: [],
	});

	const {
		data: acitivityList,
		isSuccess: isSuccessFetchList,
		isLoading,
	} = useActivityList({
		filter: queryFilter,
		body: { ...bodyRequestFilter, status: selectedStatus },
	});

	const { mutate: exportActivitlyList, isLoading: isExporting } = useExportActivity({
		filter: queryFilter,
	});

	const { mutate: bulkDeleteActivity, isLoading: isDeleting } = useBulkDeleteActivity();

	const isCheckAll = useMemo(() => {
		if (acitivityList?.data?.rows.length === 0) return false;
		return selectedActivityIds.length === acitivityList?.data?.rows.length;
	}, [acitivityList?.data?.rows.length, selectedActivityIds.length]);

	useEffect(() => {
		if (selectedActivityIds.length) setSelectedActivityIds([]);
		setQueryFilter({
			page: 1,
			limit: 20,
			order_by: "start_date",
			order: "desc",
			search: "",
		});
		setBodyRequestFilter({
			status: Status[0].value,
			level_ids: [],
			activity_category_ids: [],
		});
		if (searchKeyword) setSearchKeyword("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStatus]);

	const handleOnSelectActivity = ({ isSelected, id }) => {
		if (isSelected) {
			setSelectedActivityIds([...selectedActivityIds, id]);
		} else {
			setSelectedActivityIds(selectedActivityIds.filter(item => item !== id));
		}
	};

	const handleOnSelectAllActivity = e => {
		const { checked } = e.target;
		if (checked) {
			setSelectedActivityIds(acitivityList?.data?.rows?.map(item => item.id));
		} else {
			setSelectedActivityIds([]);
		}
	};

	const handleOnPageChange = selectedPage => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		setQueryFilter({ ...queryFilter, page: selectedPage });
		selectedActivityIds.length && setSelectedActivityIds([]);
	};

	const handleOnDataSizeChange = ({ limit }) => {
		setQueryFilter(prevState => ({
			...prevState,
			limit,
			page: 1,
		}));
		setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
	};

	const handleOnSortChange = ({ selected }) => {
		setQueryFilter(prevState => ({
			...prevState,
			order_by: selected.sortBy,
			order: selected.sortType,
		}));
		setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
	};

	const handleOnFilterDataChange = ({ selected }) => {
		setBodyRequestFilter(prevState => ({
			...prevState,
			...selected,
		}));
		setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }));
	};

	const handleExportData = () => {
		exportActivitlyList(
			{ ...bodyRequestFilter, activity_ids: selectedActivityIds, status: selectedStatus },
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName("ActivityList", EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const handleOnDeleteActivity = () => {
		bulkDeleteActivity(
			{ activity_ids: selectedActivityIds },
			{
				onSuccess: () => {
					setSelectedActivityIds([]);
					setModalConfirmation(prevState => ({ ...prevState, showModal: false }));
					setModalResult(prevState => ({
						...prevState,
						showModal: true,
						message: "Successfully delete acitivites!",
					}));
				},
			},
		);
	};
	return (
		<Layout
			buttonToTop
			containerChildrenClassName="bg-white rounded-lg drop-shadow-main filter-none py-4"
			breadCumbTitle="Activity"
			breadCumbDesc="Activity List">
			<div className=" border-b flex border-solid border-grey-10 px-5">
				{Status.map(statusItem => (
					<div className="inline-block mr-4">
						<button
							onClick={() => {
								setSelectedStatus(statusItem.value);
								navigate({
									pathname: window.location.pathname,
									search: `?dataStatus=${statusItem.value}`,
								});
							}}
							class={clsx(
								statusItem.value === selectedStatus
									? "rounded-tl-lg rounded-tr-lg uppercase text-sm font-semibold text-red-50  transition-all inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-grey-10"
									: "rounded-tl-lg rounded-tr-lg uppercase text-sm hover:text-red-50 transition-all font-semibold text-black inline-block px-6 py-3 border border-solid mb-[-1px] border-b-transparent border-transparent",
							)}>
							{statusItem.label}
						</button>
					</div>
				))}
			</div>
			<div className="flex items-center justify-between mb-3 p-5">
				<div className="sm:w-[75%] xl:w-[75%] flex">
					<SearchFilterComponent
						key={selectedStatus}
						disabled={isLoading}
						containerClassName="relative sm:w-1/3 md:w-[40%] xl:w-2/5 mr-2"
						placeholder="Cari Activity..."
						value={searchKeyword}
						controllerInput
						onChange={e => {
							setSearchKeyword(e.target.value);
						}}
						onSearch={() => {
							setQueryFilter(prevState => ({ ...prevState, search: searchKeyword }));
						}}
					/>

					<div className="mr-2">
						<ButtonSortComponent
							disabled={isLoading}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonDataFilterComponent
							disabled={isLoading}
							active={
								!!bodyRequestFilter.level_ids.length || !!bodyRequestFilter.activity_category_ids.length
							}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonLimitComponent
							disabled={isLoading}
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport
							disabled={isLoading || selectedActivityIds.length === 0}
							onClick={handleExportData}
							loading={isExporting}
						/>
					</div>
				</div>

				<div className="flex sm:w-[25%] lg:w-[19%] justify-end">
					<ButtonSubmit
						onClick={() => setModalConfirmation(prevState => ({ ...prevState, showModal: true }))}
						disabled={isLoading || selectedActivityIds.length === 0}
						className="disabled:opacity-50 flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
						<span className="icon-ico-delete mr-2"></span> DELETE
					</ButtonSubmit>
				</div>
			</div>
			<div className="px-5 mb-6">
				<ButtonSubmit
					disabled={isLoading}
					onClick={() => navigate("/activity/create")}
					className="disabled:opacity-50 inline-block py-2.5 uppercase px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold mb-4">
					<span className="icon-ico-plus mr-2"></span> Create activity
				</ButtonSubmit>

				<div className="flex justify-between items-center">
					{isSuccessFetchList && acitivityList?.data?.total_result > 0 && (
						<div className="flex py-2 px-3 bg-red-10 rounded-lg ">
							<div className="form-check">
								<input
									className="form-check-input appearance-none h-4 w-4 border border-red-40 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
									type="checkbox"
									checked={isCheckAll}
									onChange={handleOnSelectAllActivity}
									id="all"
								/>
							</div>
							<div className="text-red-50 uppercase font-semibold">Select All</div>
						</div>
					)}

					{isSuccessFetchList && (
						<div className="text-sm italic text-grey-50 font-medium">{`Total ${acitivityList?.data?.total_result} Activities`}</div>
					)}
				</div>
			</div>
			<div className="px-5">
				{!isLoading && acitivityList?.data?.rows?.length === 0 && !queryFilter.search && (
					<div className="h-[60vh] flex items-center justify-center">
						<EmptyState message={`No activity found`} />
					</div>
				)}
				{!isLoading && acitivityList?.data?.rows?.length === 0 && !!queryFilter.search && (
					<div className="h-[60vh] flex items-center justify-center">
						<EmptyState
							message={`Opps, no data matched your criteria "${queryFilter.search}". Try searching for something else.`}
						/>
					</div>
				)}

				{isLoading ? (
					<div className="h-[70vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<div className="grid grid-cols-4 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
						{acitivityList?.data?.rows?.map(activityItem => (
							<ActivityItem
								key={activityItem.id}
								editable={selectedStatus === "upcoming"}
								data={activityItem}
								selected={selectedActivityIds.includes(activityItem.id)}
								onSelect={handleOnSelectActivity}
							/>
						))}
					</div>
				)}

				{!isLoading && (
					<Pagination
						currentPage={queryFilter.page}
						pageSize={queryFilter.limit}
						siblingCount={1}
						onPageChange={handleOnPageChange}
						totalCount={acitivityList?.data?.total_result || 0}
					/>
				)}
			</div>
			<TableLimitModalComponent
				key={selectedStatus}
				visible={tableFilterModal.showModalLimit}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }))}
				onChange={handleOnDataSizeChange}
			/>
			<TableSortModalComponent
				options={sortOptions}
				key={selectedStatus}
				initialValue={sortOptions?.[0]}
				visible={tableFilterModal.showModalSort}
				onChange={handleOnSortChange}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }))}
			/>
			<ModalFilterActivity
				initialValue={bodyRequestFilter}
				key={selectedStatus}
				visible={tableFilterModal.showModalFilter}
				onClose={() => setShowTableFilterModal(prevState => ({ ...prevState, showModalFilter: false }))}
				onChange={handleOnFilterDataChange}
			/>
			<ModalConfirmation
				buttons={[]}
				message={`Are you sure want to delete ${selectedActivityIds.length} activities?`}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={handleOnDeleteActivity}
				loading={isDeleting}
			/>
			<ModalResult
				key={selectedStatus}
				visible={modalResult.showModal}
				title={false}
				onClose={() => setModalResult({ ...modalResult, showModal: false })}
				message={modalResult.message}
			/>
		</Layout>
	);
}
