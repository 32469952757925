import clsx from "clsx";
import { useRef, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { InputComponent } from "components/form/input.component";
import { ModalContainer } from "components/modal/modal.container";
import { Formik } from "formik";
import { loadStaticPublicAsset } from "utils/loader";
import { CreateSkinTypeSchema } from "config/form/schema/general-setting/beauty-profile";
import { ButtonSubmit } from "components/button/submit.button";

import { useAddNewSkinItem, useSkinItemDetails, useEditSkinItem } from "hooks/general-settings";
import { useUploadImage } from "hooks/upload";

const formInitialValues = { name: "", description: "", skin_type_id: null, image: null };

export function ModalAddTypeSkinItem({
	visible,
	onSuccess,
	onClose,
	operation = "ADD",
	editId,
	data = { skinTypeId: null },
}) {
	const formikRef = useRef(null);

	const [imageFileBuffer, setImageBuffer] = useState(null);
	const [imagePreview, setImagePreview] = useState(data?.image || null);

	const { mutate: addNewSkinItem, isLoading: isSubmitting } = useAddNewSkinItem();
	const { mutate: editSkinItemById, isLoading: isUpdating } = useEditSkinItem();
	const { mutateAsync: uploadImageAsync, isLoading: isUploading } = useUploadImage();

	const { data: skinItemDetails } = useSkinItemDetails(editId);

	const handleSubmitForm = async payloadData => {
		if (imageFileBuffer) {
			const formData = new FormData();
			formData.append("image", imageFileBuffer);
			formData.append("type", "beauty_profile");

			const { data: uploadImageResult } = await uploadImageAsync({ formData });

			payloadData.image = uploadImageResult.image_desktop;
		}

		if (operation === "ADD") {
			addNewSkinItem(
				{ ...payloadData, skin_type_id: data.skinTypeId },
				{
					onSuccess: () => {
						onSuccess("Successfully created new item data!");
						setImagePreview(null);
						formikRef.current.resetForm();
					},
				},
			);
		} else {
			editSkinItemById(
				{ skinItemId: editId, formData: payloadData },
				{
					onSuccess: () => {
						onSuccess("Successfully updated item data!");
						setImagePreview(null);
						formikRef.current.resetForm();
					},
				},
			);
		}
	};

	const handleChangeImage = e => {
		const file = e.target.files[0];
		const reader = new FileReader();
		if (/\.(jpe?g|png)$/i.test(file.name)) {
			reader.readAsDataURL(file);
			reader.onload = () => {
				setImagePreview(URL.createObjectURL(file));
			};
			setImageBuffer(file);
		} else {
			toast.error("File type is not supported, only jpg, png, and jpeg are allowed");
		}
	};

	useEffect(() => {
		if (operation === "EDIT" && skinItemDetails) {
			Object.keys(formInitialValues).forEach(key => {
				formikRef.current?.setFieldValue(
					key,
					skinItemDetails?.data?.[key] === null ? "" : skinItemDetails?.data?.[key],
				);
			});
			setImagePreview(skinItemDetails?.data?.image);
		}
	}, [data, operation, skinItemDetails, formikRef]);

	return (
		<ModalContainer visible={visible}>
			<Formik
				innerRef={formikRef}
				onSubmit={handleSubmitForm}
				initialValues={formInitialValues}
				validationSchema={CreateSkinTypeSchema}>
				{({ values, handleBlur, handleChange, handleSubmit, errors, touched }) => (
					<div
						id="new-type"
						class={clsx("rounded-2xl  bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
						<div className="relative text-center py-4 mb-4">
							<div className="font-bold text-red-50">
								{operation === "ADD" ? "Create New Item" : "Edit Item"}
							</div>

							<button
								type="button"
								className="absolute top-0 right-0 border-0"
								onClick={() => {
									onClose();
									setImagePreview(null);
									formikRef.current.resetForm();
								}}>
								<span className="icon-ico-close"></span>
							</button>
						</div>
						<div className="content-scroll">
							<div className="relative flex justify-center pt-4">
								{imagePreview && (
									<div className="w-28 h-28 relative">
										<img
											alt="Item"
											src={imagePreview}
											className="w-28 h-28 rounded-full overflow-hidden object-cover object-center"
										/>

										<button className="absolute top-[-10px] right-[-10px] shadow-md w-10 h-10 leading-[42px] text-2xl rounded-full text-red-50 hover:text-red-70 text-center outline-none border-0 bg-white">
											<span className="icon-ico-delete"></span>
										</button>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5 mb-5">
								<input type="file" className="hidden" id="upload" onChange={handleChangeImage} />
								<label
									className="flex cursor-pointer items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
									for="upload">
									<span className="icon-ico-upload text-red-50 mr-1 text-xl"></span>
									<span className="pl-2">Upload</span>
								</label>
							</div>
							<div className="mb-4 px-2">
								<InputComponent
									label="Item Name"
									name="name"
									placeholder="Contoh: Berminyak"
									value={values.name}
									onBlur={handleBlur}
									onChange={handleChange}
									error={touched.name && errors.name}
								/>
							</div>
							<div className="mb-4 px-2">
								<div className="text-sm text-black font-semibold mb-3">Item Description</div>
								<textarea
									name="description"
									value={values.description}
									onBlur={handleBlur}
									onChange={handleChange}
									className="border-grey-10 outline-none w-full border placeholder:italic border-solid px-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
									rows="6"
									placeholder="Short description"></textarea>
								{touched.description && errors.description && (
									<p className="form-input-error">{errors.description}</p>
								)}
							</div>
						</div>

						<div className="my-6 flex justify-center">
							<div className="w-1/2 px-2">
								<button
									onClick={() => {
										onClose();
										setImagePreview(null);
										formikRef.current.resetForm();
									}}
									className="flex items-center w-full rounded-xl bg-white font-semibold text-grey-70 border border-solid border-grey-10 py-2 px-8 uppercase hover:bg-grey-10 hover:text-red-50 transition-all"
									type="button">
									<img
										src={loadStaticPublicAsset("img/icons/x-circle.svg")}
										className="max-w-full w-5"
										alt="Cancel Operation"
									/>
									<span className="ml-2">Cancel</span>
								</button>
							</div>
							<div className="w-1/2 px-2">
								<ButtonSubmit
									loading={isSubmitting || isUpdating || isUploading}
									onClick={handleSubmit}
									disabled={!CreateSkinTypeSchema.isValidSync(values) || !imagePreview}
									className="disabled:opacity-50 block w-full rounded-xl font-semibold bg-red-50 text-white py-2 px-8 uppercase hover:bg-red-70 transition-all">
									<span className="icon-ico-user-register"></span>
									<span className="ml-2">Save</span>
								</ButtonSubmit>
							</div>
						</div>
					</div>
				)}
			</Formik>
		</ModalContainer>
	);
}
