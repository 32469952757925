import qs from "query-string";
import { useMutation, useQueryClient } from "react-query";
import axios from "api/http.api";

export function useCreateActivity() {
	const queryClient = useQueryClient();
	return useMutation(bodyRequest => axios.post(`/api/v1/admin/activity/create`, bodyRequest).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(["ACTIVITY_LIST"]);
			queryClient.removeQueries(["ACTIVITY_LIST"]);
		},
	});
}

export function useEditActivity() {
	const queryClient = useQueryClient();
	return useMutation(
		({ bodyRequest, activityId }) =>
			axios.post(`/api/v1/admin/activity/update/${activityId}`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_LIST"]);
				queryClient.resetQueries(["ACTIVITY_LIST"]);
				queryClient.resetQueries(["ACTIVITY_DETAILS"]);
			},
		},
	);
}

export function useCreateQuizActivty() {
	const queryClient = useQueryClient();
	return useMutation(
		bodyRequest => axios.post(`/api/v1/admin/activity-exam/save`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_QUIZ_BY_ID"]);
			},
		},
	);
}

export function useExportActivity({ filter }) {
	return useMutation(bodyRequest =>
		axios
			.post(`/api/v2/admin/activity/export?${qs.stringify(filter)}`, bodyRequest, {
				responseType: "blob",
			})
			.then(res => res),
	);
}

export function useBulkDeleteActivity() {
	const queryClient = useQueryClient();
	return useMutation(
		bodyRequest => axios.post(`/api/v1/admin/activity/bulk-delete`, bodyRequest).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_LIST"]);
			},
		},
	);
}

export function useChangeStatusUserActivityResult() {
	const queryClient = useQueryClient();
	return useMutation(
		payload => {
			const { activityId, type, ...rest } = payload;
			return axios
				.post(`/api/v1/admin/activity-result/approval/${activityId}/${type}`, rest)
				.then(res => res.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_RESULT_DETAILS"]);
			},
		},
	);
}

export function useAddComment() {
	const queryClient = useQueryClient();
	return useMutation(
		payload => {
			const { activityId, type, ...rest } = payload;
			return axios
				.post(`/api/v1/admin/activity-result/comment/${activityId}/${type}`, rest)
				.then(res => res.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_RESULT_DETAILS"]);
			},
		},
	);
}

export function useExportActivityResult() {
	return useMutation(payload => {
		const { activityId, type, bodyRequest, filterParams } = payload;
		return axios
			.post(
				`/api/v2/admin/activity-result/export/${activityId}/${type}?${qs.stringify(filterParams)}`,
				bodyRequest,
				{
					responseType: "blob",
				},
			)
			.then(res => res);
	});
}

export function useDeleteAcitivyAdminComment() {
	const queryClient = useQueryClient();
	return useMutation(
		payload => {
			const { commentId } = payload;
			return axios.delete(`/api/v1/admin/activity-result/comment/${commentId}`).then(res => res.data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["ACTIVITY_RESULT_DETAILS"]);
			},
		},
	);
}
