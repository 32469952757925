import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ButtonSubmit } from "components/button/submit.button";
import { ImageWithZoom } from "components/image/image-zoom.component";
import { ModalResult } from "components/modal/modal.result";

import { useGetMemberProfileById, useDeleteMemberById } from "hooks/member";
import { BounceLoading } from "components/loading/bounce.loading";
import { getInitialsName } from "utils/string";
import { loadStaticPublicAsset } from "utils/loader";
import { ModalChangePassword } from "pages/members/components/change-password.modal";

import { MemberProfileLayout } from "../../components/profile.layout";
import { ModalConfirmation } from "components/modal/modal.confirmation";

export function MemberAccountPage() {
	const navigate = useNavigate();
	const { userId, userStatus } = useParams();

	const [showModalResult, setShowModalResult] = useState({
		show: false,
		message: "",
	});
	const [showModalPassword, setShowModalPassword] = useState(false);
	const [modalConfirmation, setModalConfirmation] = useState(false);

	const { data: memberProfileDetails, isLoading } = useGetMemberProfileById({ userId, type: userStatus });

	const { mutate: deleteMemberById, isLoading: isDeleting } = useDeleteMemberById();

	const handleOnDeleteUser = () => {
		deleteMemberById(
			{ userId },
			{
				onSuccess: () => {
					setModalConfirmation(false);
					setShowModalResult({
						show: true,
						message: `User "${memberProfileDetails?.data?.full_name}" has been deleted`,
					});
					navigate(`/member/account/deleted/${userId}`);
				},
			},
		);
	};

	return (
		<MemberProfileLayout
			containerChildrenClassName="bg-white flex rounded-lg drop-shadow-main filter-none"
			title="User"
			desc="User Detail">
			<div className="basis-[calc(100%-200px)] p-3">
				{isLoading ? (
					<div className="h-[40vh] flex items-center">
						<BounceLoading color="#90011f" />
					</div>
				) : (
					<div className="mb-14 py-2 p-3">
						<div className="text-title font-semibold text-grey-70 mb-7">Account</div>
						<div className="flex mb-8 justify-between ">
							<div className="flex items-center">
								<div className="mr-5">
									{memberProfileDetails?.data?.profile_picture ? (
										<ImageWithZoom
											src={memberProfileDetails?.data?.profile_picture}
											className="rounded-full w-36 h-36 overflow-hidden"
											alt={memberProfileDetails?.data?.full_name}
										/>
									) : (
										<div className="initial">
											{getInitialsName(memberProfileDetails?.data?.full_name) || "A"}
										</div>
									)}
								</div>
								<div className="w-auto">
									<div className="text-xl text-black font-bold">
										{memberProfileDetails?.data?.full_name}
									</div>
									<div className="text-sm text-main-grey mb-5">
										{memberProfileDetails?.data?.email}
									</div>
									<div className="w-[13%]">
										<div
											class={clsx(
												"text-ellipsis whitespace-nowrap overflow-hidden md:overflow-visible py-1 px-2 rounded-lg text-sm font-semibold inline-block  ",
												userStatus === "active"
													? "text-main-green bg-light-green"
													: "bg-light-pink text-main-pink",
											)}>
											{userStatus === "active" ? "Active" : "Deleted"}
										</div>
									</div>
								</div>
							</div>
							{userStatus === "active" && (
								<div className="flex flex-col">
									<ButtonSubmit
										onClick={() => setShowModalPassword(true)}
										className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
										<img
											src={loadStaticPublicAsset("/img/icons/key.svg")}
											alt="Generate new password"
											className="mr-2"
										/>
										Generate new password
									</ButtonSubmit>
									<ButtonSubmit onClick={() => setModalConfirmation(true)}>
										<span className="icon-ico-delete mr-2"></span> Delete Account
									</ButtonSubmit>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			<ModalChangePassword
				visible={showModalPassword}
				onClose={() => setShowModalPassword(false)}
				onSuccess={() => {
					setShowModalResult({ ...showModalResult, show: true, message: "Password has been changed" });
				}}
			/>
			<ModalResult
				visible={showModalResult.show}
				title={false}
				message={showModalResult.message}
				onClose={() => setShowModalResult({ ...showModalResult, show: false })}
			/>
			<ModalConfirmation
				visible={modalConfirmation}
				labelReject="Cancel"
				loading={isDeleting}
				labelApprove="Delete"
				onApprove={handleOnDeleteUser}
				onReject={() => setModalConfirmation(false)}
				onClose={() => setModalConfirmation(false)}>
				<div className="my-6">
					<img src="/img/icons/user-x.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Yakin ingin menghapus akun ini? Setelah dihapus tidak dapat dipulihkan kembali
					</p>
				</div>
			</ModalConfirmation>
		</MemberProfileLayout>
	);
}
