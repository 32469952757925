import clsx from "clsx";
import { usePagination, DOTS } from "hooks/usePagination";

export const Pagination = ({
	onPageChange = () => {},
	totalCount = 1,
	siblingCount = 1,
	currentPage = 1,
	pageSize = 0,
	containerClassName = "flex justify-center items-center my-8",
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount: siblingCount || 1,
		pageSize,
	});

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];

	return (
		<ul class={containerClassName}>
			<li className="mr-3">
				<span
					class={clsx(
						"text-sm inline-block font-bold text-red-50 hover:text-red-80",
						currentPage === 1 && "not-active",
					)}
					disabled={currentPage === 1}
					style={{ cursor: "pointer" }}
					onClick={() => currentPage !== 1 && onPrevious()}>
					PREV
				</span>
			</li>

			{paginationRange.map(pageNumber => {
				if (pageNumber === DOTS) {
					return (
						<li key={`${pageNumber}-page-number`} className="pagination-item dots">
							&#8230;
						</li>
					);
				}

				return (
					<li className="mx-4">
						<span
							class={clsx(
								"text-sm inline-block font-bold  w-6 h-6 rounded-full bg-white text-center leading-6 hover:bg-red-60 hover:text-white transition-all",
								pageNumber === currentPage && "bg-red-50 text-white",
							)}
							style={(pageNumber === currentPage && { cursor: "default" }) || { cursor: "pointer" }}
							onClick={() => {
								if (pageNumber === currentPage) return;
								onPageChange(pageNumber);
							}}>
							{pageNumber}
						</span>
					</li>
				);
			})}

			<li className="ml-3">
				<span
					className={clsx(
						"text-sm inline-block font-bold text-red-50 hover:text-red-80",
						currentPage === lastPage && "not-active",
					)}
					disabled={currentPage === lastPage}
					href={null}
					style={{ cursor: "pointer" }}
					onClick={() => currentPage !== lastPage && onNext()}>
					NEXT
				</span>
			</li>
		</ul>
	);
};
