import { Link } from "react-router-dom";
import { Formik } from "formik";

import { loadStaticPublicAsset } from "utils/loader";
import { useForgotPassword } from "hooks/auth";
import { InputComponent } from "components/form/input.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ResutComponent } from "components/result/result.component";
import { ForgotPasswordValidationSchema } from "config/form/schema/auth/forgot-password";

const formInitialValues = {
	email: "",
};

export function ForgotPasswordPage() {
	const { mutate, isLoading, error, isSuccess } = useForgotPassword();

	const handleSubmitForm = formData => {
		mutate(formData, {
			onSuccess: () => {},
		});
	};

	return (
		<div className="bg-fixed main-bg">
			<div className="flex justify-center items-center h-screen relative">
				<div className="w-[500px] bg-white py-10 px-6 drop-shadow-main-red rounded-lg relative">
					{isSuccess ? (
						<ResutComponent
							description="Email forgot password has been sent to your email. Please check your email."
							type="success"
							buttonActionComponent={
								<Link to="/auth/login">
									<ButtonSubmit title="Login Now!" />
								</Link>
							}
						/>
					) : (
						<>
							<div className="text-center text-[32px] text-red-50 font-bold mb-7">Forgot Password</div>
							<p className="text-sm mb-5 text-grey-60">
								Masukkan email anda yang terdaftar sebagai akun di RUBI Community. Kami akan mengirimkan
								link ubah password ke email anda
							</p>
							{error && (
								<p className="mb-5 form-input-error bg">
									{error?.response?.data?.message ||
										"Opps, something went wrong please try again later"}
								</p>
							)}
							<Formik
								validationSchema={ForgotPasswordValidationSchema}
								initialValues={formInitialValues}
								onSubmit={handleSubmitForm}>
								{({ handleBlur, handleChange, handleSubmit, values, touched, errors }) => (
									<>
										<InputComponent
											onBlur={handleBlur}
											onChange={handleChange}
											name="email"
											value={values.email}
											error={touched.email && errors.email}
											containerclassName="mb-8"
											label="Username/Email"
											placeholder="Input Username/Email"
										/>
										<ButtonSubmit
											disabled={!ForgotPasswordValidationSchema.isValidSync(values)}
											title="SUBMIT"
											loading={isLoading}
											onClick={handleSubmit}
										/>
									</>
								)}
							</Formik>
							<div className="text-center text-sm font-medium mt-4 mb-8">
								Already have an account yet?{" "}
								<Link
									to="/auth/login"
									className="inline-block text-sm font-bold transition-all text-grey-70 hover:text-red-50">
									Login Now!
								</Link>
							</div>
						</>
					)}

					<div className="flex justify-center flex-wrap translate-x-[-50%] left-1/2 top-[-25%] lg:top-[-30%] absolute w-24">
						<div className="w-20">
							<img
								src={loadStaticPublicAsset("img/logo/logo.png")}
								className="max-w-full"
								alt="Logo Avo"
							/>
						</div>
						<div className="text-sm text-black text-center font-semibold mt-2">DASHBOARD</div>
					</div>
				</div>
			</div>
		</div>
	);
}
