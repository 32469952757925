import { useState } from "react";
import { useParams } from "react-router-dom";
import { BounceLoading } from "components/loading/bounce.loading";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { useTopicThread } from "hooks/discussion/thread";
import {
	useLikeThread,
	useDeleteThread,
	useReplyThread,
	ACTION_TYPE,
	useLikeThreadReply,
} from "hooks/discussion/thread/useMutationThread";

import ModalReply from "./modals/modal.reply";
import { ThreadItem } from "./thread-item";
import { ModalResult } from "components/modal/modal.result";
import { urlSafeDecrypt } from "utils/encryption";

const modalConfirmationInitialState = {
	showModal: false,
	type: null,
	message: null,
	data: null,
	source: null,
};

const modalReplyInitialState = {
	showModal: false,
	type: null,
	data: null,
	key: 0,
};

function TopicThread() {
	const { topicId } = useParams();
	const [filter, setFilter] = useState({ page: 1, limit: 20, search: undefined });
	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState(modalConfirmationInitialState);
	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "Successfully deleted comment!",
	});

	const [modalReply, setModalReply] = useState(modalReplyInitialState);
	const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useTopicThread(topicId, filter);
	const { mutate: setLikeThread } = useLikeThread(topicId, filter);
	const { mutate: setLikeThreadComent } = useLikeThreadReply(topicId, filter);
	const { mutate: deleteThread } = useDeleteThread(topicId, filter);
	const { mutate: submitReplyThread } = useReplyThread(topicId, filter);

	const handleOnChangeLike = (checked, data, actionType) => {
		if (actionType === ACTION_TYPE.THREAD) {
			setLikeThread({ like: checked, threadId: data?.id });
		} else {
			setLikeThreadComent({ like: checked, threadId: data?.topic_thread_id, threadCommentId: data?.id });
		}
	};

	const handleOnDeleteThread = () => {
		modalConfirmation.data &&
			deleteThread(
				{
					threadId: modalConfirmation.data,
					source: modalConfirmation.source,
					parentThreadId:
						modalConfirmation.source === "topic_thread_reply" ? modalConfirmation?.parentThreadId : null,
				},
				{
					onSuccess: () => {
						setModalConfirmation(modalConfirmationInitialState);
						setShowModalResult({ ...showModalResult, showModal: true });
					},
				},
			);
	};

	const handleSubmitReply = payload => {
		submitReplyThread(
			{ ...payload, parent: +urlSafeDecrypt(payload.parent) },
			{
				onSuccess: () => {
					setModalReply({ ...modalReplyInitialState, key: modalReply.key + 1 });
				},
			},
		);
	};

	return (
		<>
			<div className="text-2xl font-bold text-red-50 mb-4">Comments</div>
			<div className="relative mb-5">
				<SearchFilterComponent
					placeholder="Cari kata atau nama akun..."
					onSearch={({ keyword }) => setFilter({ ...filter, search: keyword })}
					containerClassName=""
					inputClassName="border-grey-10 w-full outline-none border placeholder:italic border-solid pl-11 pr-4 py-3 text-sm text-grey-60 rounded-lg transition-all focus:bg-white focus:text-grey-70 focus:border-red-50 focus:outline-none focus:shadow-none"
				/>
			</div>
			{isLoading ? (
				<div className="h-[40vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				<div className="content-scroll h-[60vh] px-2">
					<ul className="list-comment list-none">
						{data?.pages?.map(page => {
							if (page?.rows?.length === 0)
								return <div className="text-center text-grey-60 text-sm">No Comments found</div>;

							return page?.rows?.map(threadItem => (
								<ThreadItem
									data={threadItem}
									onChangeLike={handleOnChangeLike}
									onDeleteThread={(threadId, source, parentThreadId) => {
										setModalConfirmation({
											...modalConfirmationInitialState,
											showModal: true,
											data: threadId,
											parentThreadId,
											source,
										});
									}}
									onComment={data => {
										setModalReply({
											showModal: true,
											data: data,
										});
									}}
									key={threadItem?.id}
								/>
							));
						})}
					</ul>
					{hasNextPage && (
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a
							href={null}
							onClick={fetchNextPage}
							className="cursor-pointer block text-center text-red-30 font-semibold text-sm">
							{!isFetchingNextPage ? "Show More" : <BounceLoading color="#b70229" />}
						</a>
					)}
					<ModalConfirmation
						buttons={[]}
						title="Hapus Data"
						message={modalConfirmation.message}
						visible={modalConfirmation.showModal}
						onClose={() => setModalConfirmation(modalConfirmationInitialState)}
						onReject={() => setModalConfirmation(modalConfirmationInitialState)}
						onApprove={() => handleOnDeleteThread()}
						labelApprove="Delete">
						<div className="my-6">
							<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
							<p className="my-6 text-center text-main-grey">
								Apakah ingin menghapus thread ini?
								<br />
								Setelah dihapus tidak dapat dipulihkan
							</p>
						</div>
					</ModalConfirmation>
					<ModalReply
						onSubmit={handleSubmitReply}
						key={`reply-modal-${modalReply.key}`}
						visible={modalReply.showModal}
						data={modalReply.data}
						onClose={() => setModalReply(() => ({ ...modalReplyInitialState, key: modalReply.key + 1 }))}
					/>
					<ModalResult
						visible={showModalResult.showModal}
						title={false}
						onClose={() => setShowModalResult(prev => ({ ...prev, showModal: false }))}
						message={showModalResult.message}
					/>
				</div>
			)}
		</>
	);
}

export const TopicThreadsList = TopicThread;
