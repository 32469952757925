var pow = Math.pow,
	floor = Math.floor,
	abs = Math.abs,
	log = Math.log;
var abbrev = "kmb"; // could be an array of strings: [' m', ' Mo', ' Md']

function round(number, precision) {
	var prec = Math.pow(10, precision);
	return Math.round(number * prec) / prec;
}

export function numberAbbreviator(number) {
	var base = floor(log(abs(number)) / log(1000));
	var suffix = abbrev[Math.min(2, base - 1)];
	base = abbrev.indexOf(suffix) + 1;
	return suffix ? round(number / pow(1000, base), 2) + suffix : "" + number;
}
