export function ToogleSwitchComponent({
	inputClassName = "toggle-checkbox",
	labelClassName = "toggle",
	checked = false,
	onChange = () => {},
	name = "",
}) {
	return (
		<label class={labelClassName}>
			<input
				class={inputClassName}
				name={name}
				type="checkbox"
				id="check-1"
				checked={checked}
				onChange={onChange}
			/>
			<div className="toggle-switch"></div>
		</label>
	);
}
