import axios from "api/http.api";
import { useMutation, useQueryClient } from "react-query";

export function useUpdatePageSettingsData() {
	return useMutation(({ formData, slug, page }) =>
		axios.post(`/api/v1/admin/settings/${page}/update/${slug}`, formData).then(res => {
			return res.data;
		}),
	);
}

export function useAddNewGallery() {
	const queryClient = useQueryClient();
	return useMutation(values => axios.post(`/api/v1/admin/gallery/create`, values).then(res => res.data), {
		onSuccess: () => {
			queryClient.refetchQueries(["GALLERY_LIST"]);
			queryClient.removeQueries(["GALLERY_LIST"]);
		},
	});
}

export function useDeleteGallery() {
	const queryClient = useQueryClient();
	return useMutation(
		({ galleryId }) => axios.delete(`/api/v1/admin/gallery/delete/${galleryId}`, {}).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.refetchQueries(["GALLERY_LIST"]);
			},
		},
	);
}

export function useEditGallery() {
	const queryClient = useQueryClient();
	return useMutation(
		({ galleryId, body }) => axios.put(`/api/v1/admin/gallery/update/${galleryId}`, body).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.refetchQueries(["GALLERY_LIST"]);
				queryClient.refetchQueries(["GALLERY_DETAILS"]);
			},
		},
	);
}
