import { useDispatch } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "components/layout";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";

import { EmptyState } from "components/table/empty-state.component";
import { UserListTable } from "pages/registration/users/user-table.component";
import { useDeleteReward, useGetRewardList } from "hooks/reward";

import { convertToTitleCase } from "utils/string";
import { urlSafeEncrypt } from "utils/encryption";

// Main Page Component
export function RewardListPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [modalKey] = useState(0);

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "",
	});

	// Hooks API Call
	const { data, isLoading } = useGetRewardList();
	const { mutate: deleteReward, isLoading: isDeleting } = useDeleteReward();

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	const columns = useMemo(
		() => [
			{
				name: "Reward Title",
				className:
					"  w-[38%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-left ml-2 text-sm font-semibold",
				dataIndex: "title",
				render: (record, title) => (
					<div className="w-[38%] mr-4  pl-3 text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-left text-sm font-semibold">
						{title}
					</div>
				),
			},
			{
				name: "Level",
				className: "w-[25%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "level",
				render: (record, level) => (
					<div className=" w-[25%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis flex items-center font-medium text-sm">
						<img src={`/img/${level.name}.png`} alt={level.name} className="w-4" />
						<span className="ml-3">{convertToTitleCase(level.name)}</span>
					</div>
				),
			},

			{
				name: "Batch",
				className: " w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "batch",
				render: (record, batch) => (
					<div className=" w-[15%] overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						{`Batch ${batch}`}
					</div>
				),
			},

			{
				name: "Action",
				className:
					" w-[20%] text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "-",
				render: record => (
					<div className=" w-[15%] text-sm text-center flex items-center justify-center font-semibold">
						<ButtonSubmit
							onClick={() => {
								navigate(`/reward/list/edit/${urlSafeEncrypt(record.id)}`);
							}}
							className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-edit text-xl text-red-50 mr-2"></span>
							<span className="pl-1">Edit</span>
						</ButtonSubmit>
						<ButtonSubmit
							onClick={() => {
								setModalConfirmation({
									data: record,
									showModal: true,
								});
							}}
							className="disabled:opacity-50 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-delete text-xl text-red-50 mr-2"></span>
							<span className="pl-1">Delete</span>
						</ButtonSubmit>
					</div>
				),
			},
		],
		[navigate],
	);

	useEffect(() => {
		resetCheckList();
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnDeleteData = () => {
		deleteReward(
			{ id: urlSafeEncrypt(modalConfirmation?.data?.id) },
			{
				onSuccess: () => {
					setModalConfirmation({
						showModal: false,
						type: null,
						data: null,
					});
					setShowModalResult({
						showModal: true,
						message: `Successfully delete  reward "${modalConfirmation?.data?.title}"!`,
					});
					resetCheckList();
				},
			},
		);
	};

	return (
		<Layout breadCumbTitle="Reward" breadCumbDesc="Reward List" buttonToTop>
			<div className="flex items-center justify-between mb-4">
				<div className="text-2xl text-grey-70 font-semibold mb-4">Reward List</div>

				<div className="">
					<ButtonSubmit
						onClick={() => {
							navigate("/reward/list/create");
						}}
						className="disabled:opacity-50 flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
						<span className=" icon-ico-circle-plus mr-2"></span> Create Reward
					</ButtonSubmit>
				</div>
			</div>
			<UserListTable key={modalKey} columns={columns} dataSource={data?.data || []} loading={isLoading} />
			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}

			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				labelApprove="Delete"
				onApprove={handleOnDeleteData}
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus reward "{modalConfirmation?.data?.title}"?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(false)}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
