/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { BounceLoading } from "components/loading/bounce.loading";

export function UserListTable(props) {
	const {
		columns,
		dataSource,
		loading,
		disabled = false,
		rowClassName = "flex items-center py-4 border border-transparent border-solid rounded-lg",
		rowSelectedClassName = "flex items-center py-4 border border-transparent border-solid rounded-lg",
	} = props;
	const dispatch = useDispatch();
	const { selectedRowKeys, selectedRows } = useSelector(state => state.toolsReducer);

	const onCheckedAllHandler = e => {
		if (e.target.checked) {
			let rowKeys = getRowKeys(dataSource);
			dispatch(setToolsReducer({ selectedRowKeys: rowKeys, selectedRows: dataSource }));
		} else {
			dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
		}
	};

	const getRowKeys = data => {
		let rowKeys = [];
		data.forEach(i => {
			rowKeys.push(i.id);
		});

		return rowKeys;
	};

	const onCheckHandler = (e, value) => {
		let rows = [];

		if (e.target.checked) {
			rows = [...selectedRows, value];
		} else {
			rows = selectedRows.filter(item => item !== value);
		}

		let newSelectedRowKeys = getRowKeys(rows);

		dispatch(setToolsReducer({ selectedRowKeys: newSelectedRowKeys, selectedRows: rows }));
	};

	return (
		<>
			<div className="flex items-center py-2 rounded bg-red-10 mb-4">
				{columns.map((column, index) => {
					if (column.type === "checkbox") {
						return (
							<div className="form-check px-2" key={`${column}-${index}`}>
								<input
									disabled={loading}
									className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
									type="checkbox"
									checked={selectedRowKeys.length === dataSource.length && dataSource.length > 0}
									onChange={e => onCheckedAllHandler(e)}
									id="check"
								/>
							</div>
						);
					}
					if (column.type === "custom-checkbox") {
						return (
							<div className="flex items-center w-2/5">
								<div className="form-check px-2">
									<input
										disabled={loading}
										className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
										type="checkbox"
										checked={selectedRowKeys.length === dataSource.length && dataSource.length > 0}
										onChange={e => onCheckedAllHandler(e)}
										id="check"
									/>
								</div>
								<div className="pl-12 text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center text-sm font-semibold">
									Topic
								</div>
							</div>
						);
					}
					return <div class={column.className}>{column.name}</div>;
				})}
			</div>
			{loading ? (
				<div className="h-[70vh] flex items-center">
					<BounceLoading color="#90011f" />
				</div>
			) : (
				dataSource.map((item, index) => (
					<div
						key={index.toString()}
						className={selectedRowKeys.includes(item.id) ? rowSelectedClassName : rowClassName}>
						{columns.map((columnItem, idx) => {
							if (idx === 0 && columnItem.type === "checkbox") {
								return (
									<div className="form-check px-2" key={index.toString().concat(idx.toString())}>
										<input
											checked={selectedRowKeys.includes(item.id)}
											className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
											type="checkbox"
											onChange={e => onCheckHandler(e, item, index)}
										/>
									</div>
								);
							} else {
								if (!!columnItem.render) {
									return columnItem.render(item, item[columnItem.dataIndex]);
								}
								return (
									<div key={index.toString().concat(idx.toString())} className={"text-center"}>
										{item[columnItem.dataIndex]}
									</div>
								);
							}
						})}
					</div>
				))
			)}
		</>
	);
}
