import clsx from "clsx";
import { useState } from "react";

import { ModalContainer } from "components/modal/modal.container";

export function ButtonSortComponent({ disabled, onClick }) {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className="disabled:opacity-50 flex items-center py-2 px-3 border border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold"
			type="button">
			<span className="icon-ico-sort text-red-50 text-xl"></span>
			<span className="pl-1">Sort</span>
		</button>
	);
}

const sortOptions = [
	{ label: "Urutkan Nama A-Z", sortType: "asc", sortBy: "full_name" },
	{ label: "Urutkan Nama Z-A", sortType: "desc", sortBy: "full_name" },
	{ label: "Tanggal Lahir Terbaru - Terdahulu", sortType: "desc", sortBy: "birth_date" },
	{ label: "Tanggal Lahir Terdahulu - Terbaru", sortType: "asc", sortBy: "birth_date" },
	{ label: "Tanggal Reg. Terbaru - Terdahulu", sortType: "desc", sortBy: "last_registered_at" },
	{ label: "Tanggal Reg. Terdahulu - Terbaru", sortType: "asc", sortBy: "last_registered_at" },
];

export function TableSortModalComponent({ visible, initialValue, onClose, onChange, options = sortOptions }) {
	const [selected, setSelected] = useState(initialValue || sortOptions[4]);

	return (
		<ModalContainer visible={visible}>
			<div
				id="show-item"
				className={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]  ", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Sort</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>
				<div className="max-h-[340px] scroll">
					{options.map((item, index) => (
						<div className="popup-item mb-3" onClick={() => setSelected(item)} key={item.label}>
							<input
								type="radio"
								name="sort"
								className="hidden"
								checked={item.label === selected.label}
							/>
							<label className="flex relative cursor-pointer justify-between text-grey-60 text-sm font-semibold items-center p-4 border border-solid border-grey-10 rounded-lg">
								<span>{item.label}</span>
							</label>
						</div>
					))}
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<button
							className="block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all"
							type="button"
							onClick={() => {
								onChange({ selected });
							}}>
							<span className="icon-ico-user-register"></span>
							<span>Apply</span>
						</button>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
