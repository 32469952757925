import clsx from "clsx";
import { useSelector } from "react-redux";
import { useState, useRef, useEffect, memo } from "react";
import { Player as VideoPlayer } from "video-react";
import { BounceLoading } from "components/loading/bounce.loading";

import { useGetThreadReply } from "hooks/discussion/thread";
import { ACTION_TYPE } from "hooks/discussion/thread/useMutationThread";
import { getRelativeTime } from "utils/date";
import { convertToTitleCase, isUrlMedia, urlify } from "utils/string";
import { ImageWithZoom } from "components/image/image-zoom.component";

const Player = memo(VideoPlayer);

function ThreadItemComponent({ data, onChangeLike, onDeleteThread, onComment }) {
	const playerRef = useRef(null);
	const { profile } = useSelector(state => state.auth);
	const [filter, setFilter] = useState({ page: 1, limit: 1, search: undefined });
	const { data: threradReplies, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetThreadReply(data?.id);

	// listener on scroll stop the playerRef
	useEffect(() => {
		const handleScroll = () => {
			if (!playerRef?.current?.getState()?.player?.paused) {
				playerRef?.current?.pause();
			}
		};
		window.addEventListener("wheel", handleScroll);
		return () => window.removeEventListener("wheel", handleScroll);
	}, []);

	const handleNextPage = () => {
		setFilter(prev => ({
			...prev,
			page: prev.page + 1,
		}));
		fetchNextPage({ pageParam: { ...filter, limit: 2, page: filter.page + 1 } });
	};

	return (
		<>
			<li className={clsx("mt-4", threradReplies?.pages?.[0]?.rows?.length > 0 && "replies ")}>
				<div className="w-[50px]">
					<img
						src={data?.commentator?.profile_picture || "/img/default-user.png"}
						alt="user"
						className="overflow-hidden w-[50px] h-[50px] object-cover object-center rounded-full"
					/>
				</div>
				<div className="w-[calc(100%-50px)] pl-3">
					<div className="flex items-center justify-between mb-2">
						<div className="w-[70%]">
							<div className="flex items-center">
								<div className="text-ellipsis w-2/5 md:w-2/4 whitespace-nowrap overflow-hidden text-sm font-semibold">
									{data?.commentator?.full_name}
								</div>
								<div className="text-grey-60 text-sm whitespace-nowrap block">
									<span className="italic ">Replying to</span>
									<strong className="ml-2 text-bold inline-block text-grey-70">Main Topic</strong>
								</div>
							</div>
						</div>
						<div className="w-[30%]">
							<div className="text-right text-grey-60 text-sm italic">
								{getRelativeTime(data?.created_at)}
							</div>
						</div>
					</div>

					{data?.attachment && (
						<>
							{!isUrlMedia(data?.attachment).isImage ? (
								<div className="relative h-auto rounded-lg overflow-hidden rounded-xl mb-4 w-[65%]">
									<Player
										ref={playerRef}
										playsInline
										src={data?.attachment}
										aspectRatio="16:9"
										key={`video-player-${data?.id}`}
									/>
								</div>
							) : (
								<div className="w-[25%]">
									<ImageWithZoom
										src={data?.attachment}
										className="w-full rounded-lg overflow-hidden mb-4"
									/>
								</div>
							)}
						</>
					)}
					<div className="flex mb-2">
						<div
							className="w-11/12 text-sm text-grey-70 whitespace-pre-line"
							dangerouslySetInnerHTML={{ __html: urlify(data?.comment) }}></div>
						<div className="w-1/12">
							<button
								className="text-base border-0 text-red-50 w-full text-right"
								type="button"
								onClick={() => onDeleteThread(data?.id, "topic_thread")}>
								<span className="icon-ico-delete"></span>
							</button>
						</div>
					</div>

					<div className="flex items-center">
						<div className="like">
							<input
								type="checkbox"
								id={`like-${data?.id}`}
								className="hidden"
								checked={data?.is_liked}
								onChange={e => {
									onChangeLike(e.target.checked, data, ACTION_TYPE.THREAD);
								}}
							/>
							<label for={`like-${data?.id}`} className="cursor-pointer">
								<span className="text-grey-70 ml-2 text-sm">{data?.total_liked}</span>
							</label>
						</div>
						<div className="text-sm mx-5 cursor-pointer" onClick={() => onComment(data)}>
							<span className="icon-ico-bubble text-grey-40"></span>{" "}
							<span className="text-grey-70 ml-2">{data?.total_commented}</span>
						</div>
						<div className="text-sm">
							<span
								class={clsx(
									"icon-ico-flag",
									data?.total_reported > 0 ? "text-red-40" : "text-grey-40",
								)}></span>{" "}
							<span className="text-grey-70 ml-2">{data?.total_reported}</span>
						</div>
						{data?.total_reported > 0 && (
							<div className="bg-red-50 color-white text-sm rounded font-semibold text-white mx-2 px-3 py-1">
								Reported
							</div>
						)}
					</div>
				</div>
			</li>
			<div className="replies-wrapper">
				{threradReplies?.pages?.map(page => {
					return page?.rows?.map(threadReply => (
						<li className="replies list-replies">
							<div className="w-[50px]">
								<img
									src={threadReply?.commentator?.profile_picture || "/img/default-user.png"}
									alt="user"
									className="overflow-hidden w-[50px] h-[50px] object-cover object-center rounded-full"
								/>
							</div>
							<div className="w-[calc(100%-50px)] pl-3">
								<div className="flex items-center justify-between mb-2">
									<div className="w-[70%]">
										<div className="flex items-center">
											<div className="text-ellipsis w-2/5 md:w-2/4 whitespace-nowrap overflow-hidden text-sm font-semibold">
												{threadReply?.commentator?.full_name || profile?.full_name}
											</div>

											<div className="text-grey-60 text-sm whitespace-nowrap block">
												<span className="italic ">Replying to</span>
												<strong className="ml-2 text-bold inline-block text-grey-70">
													{threadReply?.recipient?.full_name}
												</strong>
											</div>
										</div>
									</div>
									<div className="w-[30%]">
										<div className="text-right text-grey-60 text-sm italic">
											{getRelativeTime(threadReply?.created_at)}
										</div>
									</div>
								</div>
								{threadReply?.commentator?.type === "admin" && (
									<div className="-mt-2 mb-2 text-red-50 text-sm italic">
										{convertToTitleCase(profile?.role?.name)}
									</div>
								)}
								{threadReply?.attachment && (
									<>
										{!isUrlMedia(threadReply?.attachment).isImage ? (
											<div className="relative h-auto rounded-lg overflow-hidden rounded-xl mb-4 w-[65%]">
												<Player
													ref={playerRef}
													playsInline
													src={threadReply?.attachment}
													aspectRatio="16:9"
													key={`video-player-replies-${data?.id}`}
												/>
											</div>
										) : (
											<div className="w-[25%]">
												<ImageWithZoom
													src={threadReply?.attachment}
													className="w-full rounded-lg overflow-hidden mb-4"
												/>
											</div>
										)}
									</>
								)}

								<div className="flex mb-2">
									<div
										className="w-11/12 text-sm text-grey-70 whitespace-pre-line"
										dangerouslySetInnerHTML={{ __html: urlify(threadReply?.comment) }}></div>
									<div className="w-1/12">
										<button
											className="text-base border-0 text-red-50 w-full text-right"
											type="button"
											onClick={() =>
												onDeleteThread(threadReply?.id, "topic_thread_reply", data?.id)
											}>
											<span className="icon-ico-delete"></span>
										</button>
									</div>
								</div>

								<div className="flex items-center">
									<div className="like">
										<input
											type="checkbox"
											id={`replies-like-${threadReply?.id}`}
											className="hidden"
											checked={threadReply?.is_liked}
											onChange={e => {
												onChangeLike(e.target.checked, threadReply, ACTION_TYPE.THREAD_REPLY);
											}}
										/>
										<label for={`replies-like-${threadReply?.id}`} className="cursor-pointer">
											<span className="text-grey-70 ml-2 text-sm">
												{threadReply?.total_liked}
											</span>
										</label>
									</div>
									<div
										className="text-sm mx-5 cursor-pointer"
										onClick={() => onComment({ parent: threadReply?.id, ...threadReply })}>
										<span className="icon-ico-bubble text-grey-40"></span>{" "}
										<span className="text-grey-70 ml-2">{threadReply?.total_commented}</span>
									</div>
									<div className="text-sm">
										<span className="icon-ico-flag text-grey-40"></span>{" "}
										<span className="text-grey-70 ml-2">{threadReply?.total_reported}</span>
									</div>
									{threadReply?.total_reported > 0 && (
										<div className="bg-red-50 color-white text-sm rounded font-semibold text-white mx-2 px-3 py-1">
											Reported
										</div>
									)}
								</div>
							</div>
						</li>
					));
				})}
			</div>
			{hasNextPage && (
				<span
					className="-mt-4 ml-16 cursor-pointer block text-left text-red-30 font-semibold text-sm"
					onClick={handleNextPage}>
					{!isFetchingNextPage ? "Show Replies" : <BounceLoading color="#b70229" />}
				</span>
			)}
			<hr className="border-grey-10 border-t my-6 last:border-none " />
		</>
	);
}

export const ThreadItem = memo(ThreadItemComponent);
