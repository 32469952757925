import axios from "api/http.api";
import axiosRaw from "axios";
import { useMutation } from "react-query";

export function useUploadImage() {
	return useMutation(({ formData, uploadId }) =>
		axios.post(`/api/v1/admin/image/upload`, formData).then(res => {
			if (uploadId) return { ...res.data, uploadId };
			return res.data;
		}),
	);
}

export function useUploadFile() {
	return useMutation(({ formData, uploadId }) =>
		axios.post(`/api/v1/admin/file/upload`, formData).then(res => {
			if (uploadId) return { ...res.data, uploadId };
			return res.data;
		}),
	);
}

export function useUploadVideo() {
	return useMutation(({ formData, uploadId }) =>
		axios.post(`/api/v1/admin/video/upload`, formData).then(res => {
			if (uploadId) return { ...res.data, uploadId };
			return res.data;
		}),
	);
}

export function useGetAwsTokenForUploadS3() {
	return useMutation(request => axios.post(`/api/v1/admin/gallery/storage-url`, request).then(res => res.data));
}

export function useUploadToS3() {
	return useMutation(({ formData, url }) =>
		axiosRaw
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(res => res.data),
	);
}
