import clsx from "clsx";
import { useEffect, useState } from "react";

import { ModalContainer } from "components/modal/modal.container";
import { ButtonSubmit } from "components/button/submit.button";
import { useAddComment } from "hooks/activity";

import TextEditor from "components/text-editor";

const textEditorInitValue = [
	{
		type: "paragraph",
		align: "left",
		children: [{ text: "" }],
	},
];
export function ModalComment({
	visible,
	initialValue,
	onClose,
	onCompleted = () => {},
	activityId,
	selectedIds = [],
	type = "challenge",
}) {
	const { mutate, isLoading } = useAddComment();

	const [value, setValue] = useState(JSON.stringify(textEditorInitValue));

	useEffect(() => {
		setValue(initialValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	const handleSubmitComment = () => {
		const bodyRequest = {
			activityId,
			type,
			comment: value,
			filter: {
				user_activity_ids: selectedIds,
			},
		};

		mutate(bodyRequest, {
			onSuccess: () => {
				onCompleted();
				setValue(JSON.stringify(textEditorInitValue));
			},
		});
	};
	const isCommentEmpty = value === JSON.stringify(textEditorInitValue);
	return (
		<ModalContainer visible={visible}>
			<div id="show-item" class={clsx("rounded-2xl bg-white shadow-md p-4 w-[400px]", !visible && "hidden")}>
				<div className="relative text-center py-4">
					<div className="font-bold text-red-50">Reply</div>

					<button type="button" className="absolute top-0 right-0 border-0" onClick={onClose}>
						<span className="icon-ico-close"></span>
					</button>
				</div>

				<div className="mb-4 content-scroll">
					<TextEditor onChange={nodeValue => setValue(nodeValue)} />
				</div>

				<div className="my-6 flex justify-center">
					<div className="w-2/4">
						<ButtonSubmit
							onClick={handleSubmitComment}
							disabled={isCommentEmpty || !value}
							loading={isLoading}
							title="Submit"
							className="disabled:opacity-50 block w-full rounded-xl bg-red-50 text-white py-2 px-8 hover:bg-red-70 transition-all">
							<span className="icon-ico-user-register"></span>
							<span>Submit</span>
						</ButtonSubmit>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
}
