import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "components/layout";
import { setToolsReducer } from "store/reducer/tools.reducer";
import { Pagination } from "components/pagination.component";
import { ButtonSubmit } from "components/button/submit.button";
import { ModalConfirmation } from "components/modal/modal.confirmation";
import { ModalResult } from "components/modal/modal.result";
import { TableLimitModalComponent, ButtonLimitComponent } from "components/table/limit-filter.component";
import { TableSortModalComponent, ButtonSortComponent } from "components/table/sort-filter.component";
import { SearchFilterComponent } from "components/table/search-filter.component";
import { EmptyState } from "components/table/empty-state.component";
import { ButtonExport } from "components/table/button-export.component";
import { UserListTable } from "pages/registration/users/user-table.component";
import { useGetRewardItemList, useDeleteRewardItem, useExportRewardItem } from "hooks/reward";
import { EXPORT_FILE_TYPE, downloadFileFromURLBloob, getExportFileName } from "utils/download";

import { ModalAddItemReward } from "./components/modal-add-item-reward";
import { urlSafeEncrypt } from "utils/encryption";
const sortOptions = [
	{ label: "Tanggal dibuat - Terbaru", sortType: "desc", sortBy: "created_at" },
	{ label: "Tanggal dibuat - Terdahulu", sortType: "asc", sortBy: "created_at" },
	{ label: "Nama Item - [A-Z]", sortType: "asc", sortBy: "name" },
	{ label: "Nama Item - [Z-A]", sortType: "desc", sortBy: "name" },
	{ label: "Category Item - [A-Z]", sortType: "asc", sortBy: "category" },
	{ label: "Category Item - [Z-A]", sortType: "desc", sortBy: "category" },
	{ label: "Code Item - [A-Z]", sortType: "asc", sortBy: "code" },
	{ label: "Code Item - [Z-A]", sortType: "desc", sortBy: "code" },
	{ label: "Quantity - Paling banyak", sortType: "desc", sortBy: "qty" },
	{ label: "Quantity - Paling sedikit", sortType: "asc", sortBy: "qty" },
	{ label: "Request - Paling banyak", sortType: "desc", sortBy: "request" },
	{ label: "Request - Paling sedikit", sortType: "asc", sortBy: "request" },
];
// Main Page Component
export function ItemRewardListPage() {
	const { modal = null, editId = null } = qs.parse(window.location.search) || undefined;
	const { selectedRowKeys: selectedIds } = useSelector(state => state.toolsReducer);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [modalKey, setModalKey] = useState(0);

	// Modal State
	const [tableFilterModal, setShowTableFilterModal] = useState({
		showModalLimit: false,
		showModalSort: false,
		showModalDataFilter: false,
	});

	// Modal Confirmation Action state
	const [modalConfirmation, setModalConfirmation] = useState({
		showModal: false,
		type: null,
		data: null,
	});

	const [showModalResult, setShowModalResult] = useState({
		showModal: false,
		message: "",
	});

	// Data filter on query params
	const [queryFilter, setQueryFilter] = useState({
		page: 1,
		limit: 20,
		order_by: "created_at",
		order: "desc",
		search: "",
	});

	// Hooks API Call
	const { data, isLoading } = useGetRewardItemList({ filter: queryFilter });
	const { mutate: deleteRewardItem, isLoading: isDeleting } = useDeleteRewardItem();
	const { mutate: exportRewardItem, isLoading: isExporting } = useExportRewardItem();

	const resetCheckList = () => {
		dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
	};

	const handleDeleteMembers = () => {
		deleteRewardItem(
			{ itemId: urlSafeEncrypt(modalConfirmation?.data?.id) },
			{
				onSuccess: () => {
					setModalConfirmation({
						showModal: false,
						type: null,
						data: null,
					});
					setShowModalResult({
						showModal: true,
						message: `Successfully delete item reward "${modalConfirmation?.data?.name}"!`,
					});
					resetCheckList();
				},
			},
		);
	};

	const handleExportData = () => {
		exportRewardItem(
			{
				filter: queryFilter,
				bodyFilter: {
					item_ids: selectedIds,
				},
			},
			{
				onSuccess: res => {
					downloadFileFromURLBloob(
						URL.createObjectURL(res.data),
						getExportFileName(`RewardItems`, EXPORT_FILE_TYPE.EXCEL),
					);
				},
			},
		);
	};

	const columns = useMemo(
		() => [
			{
				type: "checkbox",
				isRightBorderAvailable: true,
				render: record => {
					return (
						<div className="form-check px-2">
							<input
								className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-50 checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
								type="checkbox"
								readOnly
							/>
						</div>
					);
				},
			},
			{
				name: "Reward",
				className:
					"  w-[28%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-center text-sm font-semibold",
				dataIndex: "name",
				render: (record, name) => (
					<div className="w-[28%] text-grey-70 text-sm font-medium">
						<div className="flex flex-row items-center">
							<div className="basis-1/6">
								<img
									src={record?.image_desktop}
									alt="reward"
									className="w-10 h-10 overflow-hidden object-cover object-center"
								/>
							</div>
							<div className="basis-5/6 pl-6 pr-6 text-sm">{name}</div>
						</div>
					</div>
				),
			},
			{
				name: "Category",
				className: "w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "item_category",
				render: (record, item_category) => (
					<div className=" w-[15%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						{item_category?.name}
					</div>
				),
			},

			{
				name: "Code",
				className: " w-[15%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "code",
				render: (record, code) => (
					<div className=" w-[15%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						{code}
					</div>
				),
			},
			{
				name: "QTY",
				className: " w-[10%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "qty",
				render: (record, qty) => (
					<div className="w-[10%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						{qty?.toLocaleString()}
					</div>
				),
			},
			{
				name: "Request",
				className: " w-[10%] text-red-60 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-semibold",
				dataIndex: "request",
				render: (record, request) => (
					<div className="w-[10%] text-grey-70 overflow-hidden whitespace-nowrap text-ellipsis text-sm font-medium">
						{request?.toLocaleString()}
					</div>
				),
			},

			{
				name: "Action",
				className:
					" w-[10%] text-red-60 text-center overflow-hidden whitespace-nowrap text-ellipsis font-semibold",
				dataIndex: "-",
				render: record => (
					<div className=" w-[15%] text-sm text-center flex items-center justify-center font-semibold">
						<ButtonSubmit
							onClick={() => {
								navigate(`/reward/item-reward?modal=edit&editId=${urlSafeEncrypt(record.id)}`);
							}}
							className="flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-edit text-xl text-red-50 mr-2"></span>
							<span className="pl-1">Edit</span>
						</ButtonSubmit>
						<ButtonSubmit
							onClick={() => {
								setModalConfirmation({
									data: record,
									showModal: true,
								});
							}}
							className="disabled:opacity-50 flex items-center mr-2 py-2 px-3 border text-sm border-solid border-grey-10 rounded-lg text-black hover:bg-red-hover hover:border-red-50 transition-all font-semibold">
							<span className="icon-ico-delete text-xl text-red-50 mr-2"></span>
							<span className="pl-1">Delete</span>
						</ButtonSubmit>
					</div>
				),
			},
		],
		[navigate],
	);

	useEffect(() => {
		return () => {
			resetCheckList();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		resetCheckList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryFilter]);

	return (
		<Layout breadCumbTitle="Reward" breadCumbDesc="Item Reward" buttonToTop>
			<div className="text-2xl text-grey-70 font-semibold mb-4">Item Reward</div>
			<div className="flex items-center justify-between mb-4">
				<div className="w-[80%] flex">
					<SearchFilterComponent
						placeholder="Cari Item Reward..."
						containerClassName="relative md:w-2/5 lg:w-[40%] xl:w-2/5 mr-2"
						onSearch={search => {
							setQueryFilter({ ...queryFilter, search: search.keyword, page: 1 });
						}}
					/>
					<div className="mr-2">
						<ButtonSortComponent
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: true }))
							}
						/>
					</div>

					<div className="mr-2">
						<ButtonLimitComponent
							value={queryFilter.limit}
							onClick={() =>
								setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: true }))
							}
						/>
					</div>
					<div className="mr-2">
						<ButtonExport onClick={handleExportData} loading={isExporting} />
					</div>
				</div>
				<div className="flex w-[20%] justify-end">
					<div className="">
						<ButtonSubmit
							onClick={() => {
								navigate("/reward/item-reward?modal=add");
							}}
							className="disabled:opacity-50 flex items-center py-2.5 px-7 border border-solid border-red-50 rounded-lg text-white bg-red-50 hover:bg-red-60 hover:border-red-50 transition-all font-semibold">
							<span className=" icon-ico-circle-plus mr-2"></span> Create Reward
						</ButtonSubmit>
					</div>
				</div>
			</div>
			<UserListTable key={modalKey} columns={columns} dataSource={data?.data?.rows || []} loading={isLoading} />
			{!isLoading && data?.data?.rows?.length === 0 && (
				<div className="h-[60vh] flex items-center justify-center">
					<EmptyState message="Opps, no data matched your criteria. Try searching for something else. " />
				</div>
			)}
			{!isLoading && (
				<Pagination
					currentPage={queryFilter.page}
					pageSize={queryFilter.limit}
					siblingCount={1}
					onPageChange={selectedPage => {
						window.scrollTo(0, 0);
						setQueryFilter({ ...queryFilter, page: selectedPage });
					}}
					totalCount={data?.data?.total_result || 0}
				/>
			)}

			<TableLimitModalComponent
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
				visible={tableFilterModal.showModalLimit}
				initialValue={queryFilter.limit}
				onChange={selected => {
					setQueryFilter({ ...queryFilter, limit: selected.limit, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalLimit: false }));
				}}
			/>
			<TableSortModalComponent
				initialValue={sortOptions[0]}
				options={sortOptions}
				onClose={() => {
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
				visible={tableFilterModal.showModalSort}
				onChange={({ selected }) => {
					setQueryFilter({ ...queryFilter, order_by: selected.sortBy, order: selected.sortType, page: 1 });
					setShowTableFilterModal(prevState => ({ ...prevState, showModalSort: false }));
				}}
			/>
			<ModalConfirmation
				buttons={[]}
				title="Hapus Data"
				message={modalConfirmation.message}
				visible={modalConfirmation.showModal}
				onClose={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onReject={() => setModalConfirmation(prev => ({ ...prev, showModal: false }))}
				onApprove={() => handleDeleteMembers()}
				labelApprove="Delete"
				loading={isDeleting}>
				<div className="my-6">
					<img src="/img/icons/trash-2.svg" alt="Delete" className="mx-auto" />
					<p className="my-6 text-center text-main-grey">
						Apakah ingin menghapus reward item "{modalConfirmation?.data?.name} [
						{modalConfirmation?.data?.code}]"?
						<br />
						Setelah dihapus tidak dapat dipulihkan
					</p>
				</div>
			</ModalConfirmation>
			<ModalAddItemReward
				key={`modal-creation-${modalKey}`}
				editId={editId}
				visible={Boolean(modal)}
				operation={modal?.toUpperCase()}
				onSuccess={message => {
					setShowModalResult({ showModal: true, message });
					setModalKey(prev => prev + 1);
					navigate("/reward/item-reward");
				}}
				onClose={() => {
					setModalKey(prev => prev + 1);
					navigate("/reward/item-reward");
				}}
			/>

			<ModalResult
				visible={showModalResult.showModal}
				title={false}
				onClose={() => setShowModalResult(false)}
				message={showModalResult.message}
			/>
		</Layout>
	);
}
